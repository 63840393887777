import styles from "./ServiceDetail.module.css";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ServideDetail_1 from "@assets/images/service/ServiceDetail_1.webp";
import ServideDetail_2 from "@assets/images/service/ServiceDetail_2.webp";
import ServideDetail_3 from "@assets/images/service/ServiceDetail_3.webp";
import makeInfoImg1 from "@assets/images/service/make-info_1.webp";
import makeInfoImg2 from "@assets/images/service/make-info_2.webp";
import makeInfoImg3 from "@assets/images/service/make-info_3.webp";
import makeInfoImg4 from "@assets/images/service/make-info_4.webp";
import cardImage3 from "@assets/images/service/card_icon_3.webp";

import Faq_1 from "@assets/images/service/faq_all.svg";
import Faq_2 from "@assets/images/service/faq_make.svg";
import Faq_3 from "@assets/images/service/faq_delivery.svg";
import Faq_4 from "@assets/images/service/faq_refund.svg";
import arrowDown from "@assets/images/service/arrow_drop_down.svg";
import arrowUp from "@assets/images/service/arrow_drop_up.svg";
import close from "@assets/images/service/CloseCircle-icon.svg";

import BasicTitleDesc from "@components/Ui/Title/BasicTitleDesc";
import { TemplateObj } from "@interfaces/admin";

// FAQ 데이터 타입 정의
interface FAQItem {
  question: string;
  answer: string;
}

// FAQ 데이터 배열
const faqMakeData: FAQItem[] = [
  {
    question:
      "장례식 기간 안에 제작해야 하나요?\n장례식 이후에도 제작이 가능한가요?",
    answer:
      "장례식 이후에도 15일까지 제작 및 수정이 가능합니다.\n다만, 장례식장에서 상영하려면 장례식 기간 안에 제작이 완료되어야 합니다.",
  },
  {
    question: "페이지 오류나 영상 업로드가\n안 될 때는 어떻게 하나요?",
    answer:
      "오류가 발생하는 단계와 내용을 카카오톡 채널로 알려주시면 빠르게 도움을 드리겠습니다.\n업로드 문제는 사용자의 핸드폰 기종이나 네트워크(Wi-Fi, LTE) 문제로 발생할 수 있으며, 사진은 카카오톡 채널로 보내주실 수 있습니다.",
  },
  {
    question: "사진뿐만 아니라 영상도\n추가할 수 있나요?",
    answer:
      "장례식장에서 상영되는 버전에는 영상 추가가 불가능하지만,  장례식 후 수정 단계에서 가능합니다.\n추가 사진이나 영상을 넣고 싶으시면 카카오톡 채널로 문의해 주시면 안내해 드리겠습니다.",
  },
  {
    question: "추가로 영상을 제작하고 싶은데\n어떻게 해야 하나요?",
    answer:
      "추가 영상 제작은 카카오톡 채널을 통해 문의해 주시면 안내해 드리겠습니다",
  },
];
const faqDeliveryData: FAQItem[] = [
  {
    question: "배송비는 얼마인가요?",
    answer:
      "일반적으로 배송은 결제 완료 후 2~3일 정도 소요됩니다.\n지역에 따라 다소 차이가 있을 수 있으며, 공휴일이나 주말에는 배송이 지연될 수 있습니다.",
  },
  {
    question: "배송은 얼마나 걸리나요?",
    answer:
      "기본 배송비는 무료입니다.\n단, 제주 및 산간 지역은 별도 추가 비용이 발생할 수 있습니다.",
  },
  {
    question: "배송지 변경이 가능할까요?",
    answer:
      "주문 완료 후 추모영상 확정 이전에 배송지 변경이 가능합니다.\n확정 이후에는 배송 준비가 시작되니 변경을 원하실 경우, 고객센터로 빠르게 연락해 주시면 안내해 드리겠습니다.",
  },
  {
    question: "해외 배송도 가능한가요?",
    answer: "국내 배송만 가능하며, 해외 배송 서비스는 지원하지 않습니다.",
  },
  {
    question: "상품이 도착하지 않거나\n배송이 지연될 때 어떻게 하나요?",
    answer:
      "배송이 지연되거나 상품이 도착하지 않을 경우, 고객센터로 문의해 주시면 빠르게 확인 후 안내해 드리겠습니다.",
  },
];
const faqRefundData: FAQItem[] = [
  {
    question: "어떤 경우에 환불이 불가능한가요?",
    answer:
      "영상 제작이 시작된 이후에는 환불이 제한될 수 있습니다.\n다만, 기술적인 문제로 인해 서비스가 원활하지 않을 경우에 상황 확인 후 환불 가능 여부에 대해 안내해 드리겠습니다.",
  },
  {
    question: "환불 처리 기간은 얼마나 걸리나요?",
    answer:
      "환불 요청이 접수된 후\n카드 결제의 경우, 카드사마다 약 5~7일 이내의 처리기간이 소요될 수 있습니다.\n간편 결제의 경우, 실시간 취소 혹은 2일 이내의 처리기간이 소요될 수 있습니다.\n무통장 입금의 경우, 당일 오후 ~ 익일 오후 중 입금 처리해 드립니다.",
  },
  {
    question: "결제 수단을 변경해서\n환불 받을 수 있나요?",
    answer:
      "환불은 결제하신 수단으로만 가능합니다.\n다른 결제 수단으로의 변경은 불가능합니다.",
  },
  {
    question: "제작된 영상에 문제가 있는 경우\n어떻게 하나요?",
    answer:
      "제작된 영상에 오류나 문제가 있을 경우, 고객센터로 문의하시면 수정 또는 다시 제작이 가능합니다.",
  },
];

const makeInfoData = [
  {
    id: "1",
    step: "STEP 1",
    title: "사진 모으기",
    desc: "소중한 추억을 담은 사진 \n또는 현장 사진을 모아주세요.",
    image: makeInfoImg1,
  },
  {
    id: "2",
    step: "STEP 2",
    title: "사진 업로드",
    desc: "모인 사진을 올려주세요.",
    image: makeInfoImg2,
  },
  {
    id: "3",
    step: "STEP 3",
    title: "자막 편집",
    desc: "고인에게 전하고 싶은 말을 \n입력하여 전해보세요.",
    image: makeInfoImg3,
  },
  {
    id: "4",
    step: "STEP 4",
    title: "확인 및 수정",
    desc: "완성된 영상을 확인하고 \n필요에 따라 수정하실 수 있습니다.",
    image: makeInfoImg4,
  },
];

interface ServiceDetailProps {
  template?: TemplateObj;
}

const ServiceDetail: React.FC<ServiceDetailProps> = ({ template }) => {
  const [info, setInfo] = useState("service");
  const [faqInfo, setFaqInfo] = useState<
    "all" | "make" | "delivery" | "refund"
  >("all");
  const [openQuestions, setOpenQuestions] = useState({
    make: null as number | null,
    delivery: null as number | null,
    refund: null as number | null,
  });
  const [isReady, setIsReady] = useState(false);

  const tapNavRef = useRef<HTMLElement>(null);
  const serviceInfoRef = useRef<HTMLElement>(null);
  const faqSectionRef = useRef<HTMLDivElement>(null);

  const [showBasicPreview, setShowBasicPreview] = useState(false);
  const [showPremiumPreview, setShowPremiumPreview] = useState(false);

  const toggleQuestion = (
    section: "make" | "delivery" | "refund",
    index: number
  ) => {
    setOpenQuestions((prev) => ({
      ...prev,
      [section]: prev[section] === index ? null : index,
    }));
  };

  const handleFaqInfoChange = (
    newFaqInfo: "all" | "make" | "delivery" | "refund"
  ) => {
    setFaqInfo(newFaqInfo);
    if (newFaqInfo === "all") {
      setOpenQuestions({
        make: 0,
        delivery: null,
        refund: null,
      });
    } else {
      setOpenQuestions({
        make: null,
        delivery: null,
        refund: null,
        [newFaqInfo]: 0,
      });
    }
  };

  const scrollToSection = (section: "service" | "faq") => {
    if (!isReady) return;

    setInfo(section);
    setTimeout(() => {
      const targetRef = section === "service" ? serviceInfoRef : faqSectionRef;
      const tapNavHeight = tapNavRef.current?.offsetHeight || 0;
      const targetPosition = (targetRef.current?.offsetTop || 0) - tapNavHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }, 0);
  };
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
    // Delay setting isReady to true to ensure all elements are properly rendered
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.serviceTemplateCheck}>
      {/* {isLoading && <DataLoading />} */}
      <main>
        <nav ref={tapNavRef} className={styles.typeNav}>
          <ul>
            <li
              className={info === "service" ? styles.active : ""}
              onClick={() => {
                setInfo("service");
                scrollToSection("service");
              }}
            >
              서비스 정보
            </li>
            <li
              className={info === "faq" ? styles.active : ""}
              onClick={() => {
                setInfo("faq");
                scrollToSection("faq");
              }}
            >
              자주묻는 질문
            </li>
          </ul>
        </nav>
        <article>
          {info === "service" ? (
            // 서비스 정보
            <section
              ref={serviceInfoRef}
              className={styles.serviceDetailSection}
            >
              <div className={styles.videoPreview}>
                <div className={styles.title}>
                  <p>
                    <span>2편의 영상</span>으로
                  </p>
                  <p>
                    <span>소중한 순간을</span> 남겨보세요
                  </p>
                </div>
                <section>
                  <div>
                    <div>
                      <h3>간단하고 신속한</h3>
                      <h2>기본 추모영상</h2>
                      <h4>
                        사진이 부족해도 신속하게 제작하여
                        <br /> 당신과 함께한 기억을 담아냅니다.
                      </h4>
                    </div>
                    <div>
                      <button onClick={() => setShowBasicPreview(true)}>
                        기본형 미리보기
                      </button>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h3>특별하고 완성도 있는</h3>
                      <h2>프리미엄 추모영상</h2>
                      <h4>
                        사진 편집과 자막 변경이 가능한
                        <br /> 특별한 영상으로 당신을 기억합니다.
                      </h4>
                    </div>
                    <div className={styles.line}></div>
                    <div>
                      <p>
                        <span>* 15장 이상</span> 업로드한 이후에 제작이 가능하며
                        <br />
                        부족한 사진은 <span>반복하여 사용</span>됩니다.
                      </p>
                    </div>
                    <div>
                      <button onClick={() => setShowPremiumPreview(true)}>
                        프리미엄 미리보기
                      </button>
                    </div>
                  </div>
                  <div className={styles.notes}>
                    <h3>음악 변경 안내</h3>
                    <h4>
                      음악을 변경하고 싶은 경우
                      <br />
                      장례 기간이 끝난 후 수정할 수 있습니다.
                      <br />
                      (단, 영상 길이는 변경되지 않아 어색할 수 있습니다).
                    </h4>
                  </div>
                </section>
              </div>
              <div className={styles.provisionalServices}>
                <div>
                  <div className={styles.provisionalServicesTitle}>
                    <h3>함께 기억하고픈 마음</h3>
                    <h2>장례식장 상영</h2>
                    <h4>
                      빈소 내 모니터에 상영되어
                      <br /> 고인을 함께 추모할 수 있습니다.
                    </h4>
                  </div>
                  <div className={styles.provisionalServicesImgRight}>
                    <img src={ServideDetail_1} alt="장례식장 상영" />
                  </div>
                </div>
                <div>
                  <div className={styles.provisionalServicesTitle}>
                    <h3>언제든 보고픈 마음</h3>
                    <h2>무제한 시청 및 다운로드</h2>
                    <h4>
                      영상 링크로 언제 어디서든
                      <br /> 고인을 기억할 수 있습니다.
                    </h4>
                  </div>
                  <div className={styles.provisionalServicesImgLeft}>
                    <img src={ServideDetail_2} alt="장례식장 상영" />
                  </div>
                </div>
                <div>
                  <div className={styles.provisionalServicesTitle}>
                    <h3>영원히 간직하고픈 마음</h3>
                    <h2>USB 제공</h2>
                    <h4>
                      USB에 담아 배송되어
                      <br /> 영원히 간직할 수 있습니다.
                    </h4>
                  </div>
                  <div className={styles.provisionalServicesImgRight}>
                    <img src={ServideDetail_3} alt="장례식장 상영" />
                  </div>
                </div>
              </div>
              <div className={styles.makeInfo}>
                <div className={styles.makeInfoTitle}>
                  <h6>제작방법</h6>
                </div>
                {makeInfoData.map((item) => (
                  <BasicTitleDesc
                    key={item.id}
                    step={item.step}
                    title={item.title}
                    desc={item.desc}
                    image={item.image}
                  />
                ))}
              </div>
              <div className={styles.newEventUsb}>
                <h3>USB 제작 및 배송</h3>
                <p>
                  <span>추모영상</span>과 <span>사진 원본</span>이 담긴 <br />
                  <span>USB를 보관함</span>에 담아 드립니다.
                </p>
                <p>
                  <span>영구 보관</span>이 가능하며,
                  <br />
                  <span>나누어 소장</span>할 수 있도록
                  <br />
                  <span>3개의 USB</span>를 제공합니다.
                </p>
                <div>
                  <img src={cardImage3} alt="usb" />
                </div>
              </div>
            </section>
          ) : (
            //자주묻는 질문
            <div ref={faqSectionRef} className={styles.faqSection}>
              <div className={styles.faqSectionButtons}>
                <button
                  className={faqInfo === "all" ? styles.buttonActive : ""}
                  // onClick={() => {
                  //   setFaqInfo("all");
                  // }}
                  onClick={() => {
                    handleFaqInfoChange("all");
                  }}
                >
                  <img src={Faq_1} alt="전체 보기" />
                  <div>전체 보기</div>
                </button>
                <button
                  className={faqInfo === "make" ? styles.buttonActive : ""}
                  onClick={() => {
                    handleFaqInfoChange("make");
                  }}
                >
                  <img src={Faq_2} alt="제작 관련" />
                  <div>제작 관련</div>
                </button>
                <button
                  className={faqInfo === "delivery" ? styles.buttonActive : ""}
                  onClick={() => {
                    handleFaqInfoChange("delivery");
                  }}
                >
                  <img src={Faq_3} alt="배송 관련" />
                  <div>배송 관련</div>
                </button>
                <button
                  className={faqInfo === "refund" ? styles.buttonActive : ""}
                  onClick={() => {
                    handleFaqInfoChange("refund");
                  }}
                >
                  <img src={Faq_4} alt="환불 관련" />
                  <div>환불 관련</div>
                </button>
              </div>

              <section>
                {(faqInfo === "all" || faqInfo === "make") && (
                  <div className={styles.faqSectionContent}>
                    {faqMakeData.map((item, index) => (
                      <div key={index}>
                        <div
                          className={styles.faqSectionContentQuestion}
                          onClick={() => toggleQuestion("make", index)}
                        >
                          <h3>
                            {item.question.split("\n").map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < item.question.split("\n").length - 1 && (
                                  <br />
                                )}
                              </React.Fragment>
                            ))}
                          </h3>
                          <button>
                            <img
                              src={
                                openQuestions.make === index
                                  ? arrowUp
                                  : arrowDown
                              }
                              alt="토글 버튼"
                            />
                          </button>
                        </div>
                        {openQuestions.make === index && (
                          <div className={styles.faqSectionContentAnswer}>
                            <span>A</span>
                            <h4>
                              {item.answer.split("\n").map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < item.answer.split("\n").length - 1 && (
                                    <br />
                                  )}
                                </React.Fragment>
                              ))}
                            </h4>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {(faqInfo === "all" || faqInfo === "delivery") && (
                  <div className={styles.faqSectionContent}>
                    {faqDeliveryData.map((item, index) => (
                      <div key={index}>
                        <div
                          className={styles.faqSectionContentQuestion}
                          onClick={() => toggleQuestion("delivery", index)}
                        >
                          <h3>
                            {item.question.split("\n").map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < item.question.split("\n").length - 1 && (
                                  <br />
                                )}
                              </React.Fragment>
                            ))}
                          </h3>
                          <button>
                            <img
                              src={
                                openQuestions.delivery === index
                                  ? arrowUp
                                  : arrowDown
                              }
                              alt="토글 버튼"
                            />
                          </button>
                        </div>
                        {openQuestions.delivery === index && (
                          <div className={styles.faqSectionContentAnswer}>
                            <span>A</span>
                            <h4>
                              {item.answer.split("\n").map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < item.answer.split("\n").length - 1 && (
                                    <br />
                                  )}
                                </React.Fragment>
                              ))}
                            </h4>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {(faqInfo === "all" || faqInfo === "refund") && (
                  <div className={styles.faqSectionContent}>
                    {faqRefundData.map((item, index) => (
                      <div key={index}>
                        <div
                          className={styles.faqSectionContentQuestion}
                          onClick={() => toggleQuestion("refund", index)}
                        >
                          <h3>
                            {item.question.split("\n").map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < item.question.split("\n").length - 1 && (
                                  <br />
                                )}
                              </React.Fragment>
                            ))}
                          </h3>
                          <button>
                            <img
                              src={
                                openQuestions.refund === index
                                  ? arrowUp
                                  : arrowDown
                              }
                              alt="토글 버튼"
                            />
                          </button>
                        </div>
                        {openQuestions.refund === index && (
                          <div className={styles.faqSectionContentAnswer}>
                            <span>A</span>
                            <h4>
                              {item.answer.split("\n").map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < item.answer.split("\n").length - 1 && (
                                    <br />
                                  )}
                                </React.Fragment>
                              ))}
                            </h4>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </section>
            </div>
          )}
        </article>
      </main>
      {/* 영상 미리보기 오버레이 */}
      {showBasicPreview && (
        <div className={styles.videoOverlay}>
          <div className={styles.videoContainer}>
            <h2>기본형 미리보기</h2>
            <h3>기본형 스타일의 추모영상입니다.</h3>
            <iframe
              className={styles.overlayVideo}
              src={template?.short_sample_url}
              title="Basic Preview"
              allowFullScreen
              width="90%"
              height="250px"
              style={{ maxWidth: "800px", maxHeight: "450px" }}
            ></iframe>
            <button
              className={styles.closeButton}
              onClick={() => setShowBasicPreview(false)}
            >
              <img src={close} alt="닫기" />
            </button>
          </div>
        </div>
      )}
      {showPremiumPreview && (
        <div className={styles.videoOverlay}>
          <div className={styles.videoContainer}>
            <h2>프리미엄 미리보기</h2>
            <h3>프리미엄 스타일의 추모영상입니다.</h3>
            <iframe
              className={styles.overlayVideo}
              src={template?.sample_url}
              title="Premium Preview"
              allowFullScreen
              width="90%"
              height="250px"
              style={{ maxWidth: "800px", maxHeight: "450px" }}
            ></iframe>
            <button
              className={styles.closeButton}
              onClick={() => setShowPremiumPreview(false)}
            >
              <img src={close} alt="닫기" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceDetail;

import React, { useRef, useState } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import styles from "./ImageCrop.module.css";

// css도 import 해야 크롭 화면이 정상적으로 동작합니다.
import "cropperjs/dist/cropper.css";

interface ImageCropProps {
  onCrop: (image: string) => void;
  aspectRatio: number;
  image: File;
}

const ImageCrop: React.FC<ImageCropProps> = ({
  onCrop,
  aspectRatio,
  image,
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  // const [image, setImage] = useState<null | string>(null);

  const [editStep, setEditStep] = useState<string>("rotate");

  const zoom = (percent: number) => {
    cropperRef.current?.cropper.zoom(percent);
  };
  const rotate = (percent: number) => {
    cropperRef.current?.cropper.rotate(percent);
  };

  const flipHorizontal = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const scaleX = cropper.getData().scaleX || 1;
      cropper.scale(-scaleX, 1);
    }
  };

  const flipVertical = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const scaleY = cropper.getData().scaleY || 1;
      cropper.scale(1, -scaleY);
    }
  };
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      onCrop(
        cropperRef.current?.cropper
          .getCroppedCanvas()
          .toDataURL("image/jpg", 1.0)
      );
      // setImage(null);
    }
  };

  if (!image) {
    return <div>사진 로딩을 실패하였습니다 </div>;
  }
  return (
    <>
      <div className={styles.cropperContainer}>
        <Cropper
          ref={cropperRef}
          initialAspectRatio={16 / 9}
          aspectRatio={aspectRatio}
          src={URL.createObjectURL(image)}
          width={477}
          height={269}
          responsive
          autoCropArea={1}
          guides
          viewMode={0}
          background={true} // 배경 표시
          checkCrossOrigin={true}
          checkOrientation={true}
        />
      </div>
      <div>
        <div>
          <button onClick={() => setEditStep("rotate")}>회전</button>
          <button onClick={() => setEditStep("scale")}>크기</button>
          <button onClick={() => setEditStep("light")}>밝기</button>
        </div>
        <div>
          {editStep === "rotate" && (
            <>
              <input type="range" name="" id="" />
              <div>
                <button onClick={() => rotate(90)}>90도</button>
                <button onClick={() => rotate(-90)}>-90도</button>
                <button onClick={flipHorizontal}>좌우반전</button>
                <button onClick={flipVertical}>상하반전</button>
              </div>
            </>
          )}

          {editStep === "scale" && (
            <>
              <input type="range" name="" id="" />
              <div>
                <button onClick={() => zoom(0.1)}>확대</button>
                <button onClick={() => zoom(-0.1)}>축소</button>
              </div>
            </>
          )}

          {editStep === "light" && (
            <>
              <input type="range" name="" id="" />
            </>
          )}
        </div>

        {/* <button onClick={() => setImage(null)}>취소</button> */}
        <div className={styles.bottomButtonWrap}>
          <button>이전 장면</button>
          <button onClick={getCropData}>적용하기</button>
          <button>다음 장면</button>
        </div>
      </div>
    </>
  );
};

export default ImageCrop;

import React, { ReactNode } from "react";
import styles from "./Table.module.css";

interface TableProps {
  children: ReactNode;
  className?: string;
}

const Table: React.FC<TableProps> = ({ children, className }) => {
  return (
    <div className={`${styles.tableWrap} ${className}`}>
      <table className={`${styles.table}`}>{children}</table>
    </div>
  );
};

export default Table;

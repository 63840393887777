import React, { useState } from "react";

import lackImage from "@assets/images/service/lack-image.png";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import Loading from "@components/Service/Ui/Loading";
import Error from "@components/Service/Ui/Error";

import ServiceMainHeader from "@components/Service/ServiceMainHeader";

import { LuPlayCircle } from "react-icons/lu";

interface ChangeStateProps {}

const ChangeState: React.FC<ChangeStateProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(["getEvent", uid], () => serviceApi.getEvent(uid), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    refetchInterval: false,
  });

  const handleStartShortQueue = () => {
    serviceApi.startShortQueue({ uid: event.uid }).then((res: any) => {
      if (!res) return;
      navigator(`/service/complete?uid=${event.uid}`);
    });
  };

  const handleChangePremium = () => {
    serviceApi
      .updateEvent({
        uid: event.uid,
        template_state: "premium",
      })
      .then(() => {
        navigator(`/service/text-uploads?uid=${event.uid}`);
      });
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  // 사진안내

  return (
    <div id="service-complete-page" className="photo-lack_wrap">
      <ServiceMainHeader
        handleLeftIconClick={() => navigator(`/service/uploads?uid=${uid}`)}
        isHelpIcon={false}
      />
      <section className="main-image_wrap">
        <div className="head-title-desc">
          <h1 className="head-title x-large">
            {`故 ${event?.dm_name} 님의`} 사진
          </h1>
          <h1 className="head-title x-large">
            <b>{`${event?.EventImages.length}장이 `}</b>
            업로드되었습니다
          </h1>
        </div>
        <div>
          <img
            src={lackImage}
            alt="메인 이미지"
            className="service_main-image"
          />
        </div>
      </section>

      <section className="tip-section">
        <div className="title_wrap">
          <h6>빠른 영상 제작</h6>
          <div className="play_btn">
            <LuPlayCircle />
          </div>
        </div>

        <p>
          간단하고 신속하게 제작하여 <br />
          최대한 빠르게 빈소 내에 상영해드립니다.
        </p>

        <div className="tip-bottom_btns">
          <button onClick={handleStartShortQueue}>제작하기</button>
        </div>
      </section>
      {event?.Template?.min_photo_length <= event.EventImages.length && (
        <section className="tip-section">
          <div className="title_wrap">
            <h6>프리미엄 영상</h6>
            <div className="play_btn">
              <LuPlayCircle />
            </div>
          </div>

          <p>
            구매하신 영상으로 제작됩니다. <br />
            업로드 하신 사진의 편집과 효과가 적용되어 제작되는 데 약 2-3시간이
            소요됩니다.
          </p>

          <p>사진이 30장 미만인 경우 부족한 사진은 반복되어 사용됩니다.</p>

          <div className="tip-bottom_btns">
            {event?.Template?.photo_length > event.EventImages.length && (
              <button
                className="upload_btn"
                onClick={() => navigator(`/service/uploads?uid=${uid}`)}
              >
                사진 추가 업로드
              </button>
            )}
            <button onClick={handleChangePremium}>제작하기</button>
          </div>
        </section>
      )}
    </div>
  );
};

export default ChangeState;

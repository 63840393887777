import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminOrderCalTable.module.css";

import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";

import AdminOrderInfoModal from "./AdminOrderInfoModal";

import NumberFormat from "@components/Ui/NumberFormat";

interface AdminOrderCalTableProps {
  orders: any;
  totalPages: number;

  updateOrders: () => void;
}

const AdminOrderCalTable: React.FC<AdminOrderCalTableProps> = ({
  orders,
  totalPages,
  updateOrders,
}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const [tagetOrder, setTargetOrder] = useState<null | { id: any }>({
    id: "",
  });
  const [orderAction, setOrderAction] = useState<string>("");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

  const pageCount = totalPages;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  return (
    <>
      <AdminOrderInfoModal
        order={tagetOrder}
        orderAction={orderAction}
        setOrderAction={setOrderAction}
        isOpen={isOrderModalOpen}
        updateOrders={updateOrders}
        onClose={() => {
          setTargetOrder(null);
          setIsOrderModalOpen(false);
          setOrderAction("");
        }}
        onRefresh={() => {
          setTargetOrder(
            orders.find((order: any) => order.id === tagetOrder?.id)
          );
        }}
      />
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.thState}>결제상태</th>
            <th className={styles.thOrderNum}>주문번호</th>
            <th className={styles.thShop}>상품명</th>

            <th className={styles.thOrder}>고객정보</th>
            <th className={styles.thMethod}>결제구분</th>
            <th className={styles.thShopPrice}>결제금액</th>
            <th className={styles.thShopPrice}>관리비</th>

            <th className={styles.thPaidDt}>결제완료일시</th>
            <th className={styles.thPaidDt}>결제취소일시</th>
          </TableHeader>
          {!orders || orders.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            orders?.map((order: any) => (
              <tr
                className={styles.orderTr}
                key={order?.uid}
                onClick={() => {
                  setIsOrderModalOpen(true);
                  setTargetOrder(order);
                  setOrderAction("detail");
                }}
              >
                <td>
                  <StatusButton state={order.status} target="order" />
                </td>
                <td>{order?.imp_uid || "-"}</td>
                <td>{order?.name || "-"}</td>
                <td>{order?.buyer_name}</td>

                <td>{order?.pay_method || "-"}</td>
                <td>
                  <NumberFormat number={order?.amount} />
                </td>
                <td>
                  <NumberFormat number={order?.shop_managed_cost} />
                </td>
                <td>
                  {order?.paid_dt
                    ? dayjs(order?.paid_dt).format("MM-DD HH:mm")
                    : "-"}
                </td>
                <td>
                  {order?.cacnel_dt
                    ? dayjs(order?.cacnel_dt).format("MM-DD HH:mm")
                    : "-"}
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminOrderCalTable;

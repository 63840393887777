import React, { useEffect, useState } from "react";
import styles from "./OrderList.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";

import { useLocation, useNavigate } from "react-router-dom";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import AdminOrderTable from "@components/Admin/Order/AdminOrderTable";
import AdminLoading from "@pages/Admin/AdminLoading";
import NumberFormat from "@components/Ui/NumberFormat";
import Table from "@components/Admin/Table/Table";
import TableBody from "@components/Admin/Table/TableBody";
import TableHeader from "@components/Admin/Table/TableHeader";

interface OrderListProps {}

const OrderList: React.FC<OrderListProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    imp_uid: "",
    buyer_name: "",
    buyer_tel: "",
    name: "",
    EventUid: "",
    pay_method: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),

    imp_uid: "",
    buyer_name: "",
    buyer_tel: "",
    name: "",
    EventUid: "",
    status: "",
    pay_method: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      status: searchParams.get("status") || "",
      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      imp_uid: searchParams.get("imp_uid") || "",
      buyer_name: searchParams.get("buyer_name") || "",
      buyer_tel: searchParams.get("buyer_tel") || "",
      name: searchParams.get("name") || "",
      EventUid: searchParams.get("EventUid") || "",
      pay_method: searchParams.get("pay_method") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getOrders", searchFilter],
    () => adminApi.getOrders({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateOrders = () => {
    queryClient.invalidateQueries(["getOrders"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.imp_uid) {
      queryParams.append("imp_uid", searchInput.imp_uid);
    }

    if (searchInput.buyer_name) {
      queryParams.append("buyer_name", searchInput.buyer_name);
    }

    if (searchInput.buyer_tel) {
      queryParams.append("buyer_tel", searchInput.buyer_tel);
    }

    if (searchInput.name) {
      queryParams.append("name", searchInput.name);
    }

    if (searchInput.EventUid) {
      queryParams.append("EventUid", searchInput.EventUid);
    }

    if (searchInput.pay_method) {
      queryParams.append("pay_method", searchInput.pay_method);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };

  if (isError) return <Error />;

  return (
    <div>
      <PageHeader title="전체 주문 관리" desc="판매된 주문 목록을 확인합니다" />
      {isLoading && <AdminLoading />}

      <section className={styles.funcSection}>
        <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="EventUid">행사번호</label>
              <input
                id="EventUid"
                type="text"
                value={searchInput.EventUid}
                placeholder="행사번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="imp_uid">주문번호</label>
              <input
                id="imp_uid"
                type="text"
                value={searchInput.imp_uid}
                placeholder="주문번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="buyer_name">주문자명</label>
              <input
                id="buyer_name"
                type="text"
                value={searchInput.buyer_name}
                placeholder="주문자명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="buyer_tel">전화번호</label>
              <input
                id="buyer_tel"
                type="text"
                value={searchInput.buyer_tel}
                placeholder="전화번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="name">상품명</label>
              <input
                id="name"
                type="text"
                value={searchInput.name}
                placeholder="상품명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <div className={searchStyles.filterItem}>
                <label htmlFor="pay_method">결제수단</label>
                <select
                  className={searchStyles.payMethodSelect}
                  value={searchInput.pay_method}
                  onChange={handleSearchInput}
                >
                  <option value="">전체</option>
                  <option value="card">신용카드</option>
                  <option value="point">포인트(간편) 결제</option>
                  <option value="vbank">가상계좌</option>
                  <option value="trans">실시간 계좌이체</option>
                  <option value="kakaopay">카카오 페이</option>
                  <option value="naverpay">네이버 페이</option>
                  <option value="samsung">삼성페이</option>
                  <option value="tosspay">토스페이</option>
                </select>
              </div>
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <div className={styles.filterWraps}>
                <nav className={searchStyles.statusFilterNav}>
                  <button
                    className={`${!searchFilter.status && searchStyles.active}`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.delete("status");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>전체</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blackTag}`}
                    >
                      {data?.totalOrderCount}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.status === "paid" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("status", "paid");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>결제완료</span>

                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blueTag}`}
                    >
                      {data?.paidOrderCount}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.status === "cancelled" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("status", "cancelled");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>결제취소</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.redTag}`}
                    >
                      {data?.cancelledOrderCount}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.status === "ready" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("status", "ready");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>입금대기</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.yellowTag}`}
                    >
                      {data?.readyOrderCount}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.status === "failed" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("status", "failed");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>결제중단</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.indigoTag}`}
                    >
                      {data?.failedOrderCount}
                    </span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </SearchInput>
      </section>

      <main className={styles.mainWrap}>
        <Table>
          <TableBody>
            <TableHeader>
              <th className={styles.exSmallTh}>전체</th>
              <th className={styles.exSmallTh}>결제완료</th>
              <th className={styles.exSmallTh}>결제취소</th>
              <th className={styles.exSmallTh}>입금대기</th>
              <th className={styles.exSmallTh}>결제대기</th>
              <th className={styles.exSmallTh}>결제중단</th>
            </TableHeader>

            <tr className={styles.summaryRow}>
              <td>
                <NumberFormat number={data?.totalOrderCount} />
              </td>
              <td>
                <NumberFormat number={data?.paidOrderCount} />
              </td>
              <td>
                <NumberFormat number={data?.cancelledOrderCount} />
              </td>
              <td>
                <NumberFormat number={data?.readyOrderCount} />
              </td>
              <td>
                <NumberFormat number={data?.waitedOrderCount} />
              </td>
              <td>
                <NumberFormat number={data?.failedOrderCount} />
              </td>
            </tr>
          </TableBody>
        </Table>

        <AdminOrderTable
          orders={data?.orders}
          totalPages={data?.totalPages || 0}
          location={location}
          updateOrders={updateOrders}
        />
      </main>
    </div>
  );
};

export default OrderList;

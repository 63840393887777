import React from "react";
import styles from "./Feedbacks.module.css";
import { FaStar, FaRegStar } from "react-icons/fa"; // react-icons 패키지 사용
import rightArrow from "@assets/images/service/chevron-right-blue.svg";
import plusCircle from "@assets/images/service/plusCircle.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";
import { limit } from "firebase/firestore";
import dayjs from "dayjs";

const Feedback: React.FC = () => {
  const { serviceApi } = useApi();

  const navigator = useNavigate();

  const handleViewMore = () => {
    navigator("/service/all-feedbacks");
  };

  const { isLoading, isError, data } = useQuery(
    ["feedbacks"],
    () => serviceApi.getFeedbacks({ limit: 4, offset: 0 }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>추모영상 제작 후기</div>
        <div className={styles.subtitle}>
          영상을 제작한 상주님께서
          <br />
          직접 작성해주신 후기입니다.
          {/* <br />총 {data?.total}개의 후기입니다 */}
        </div>
      </div>
      <div className={styles.feedbacks}>
        {data?.feedbacks?.map((feedback: any) => (
          <div className={styles.feedback} key={feedback.id}>
            <div className={styles.stars}>
              {Array(5)
                .fill(null)
                .map((_, starIndex) =>
                  starIndex < feedback.rating ? (
                    <FaStar key={starIndex} />
                  ) : (
                    <FaRegStar key={starIndex} />
                  )
                )}
            </div>
            <div className={styles.feedbackText}>
              <p>{feedback.description}</p>
            </div>
            <div className={styles.feedbackFooter}>
              <div>{feedback.name.replace(/.{2}$/, "**")}</div>
              <div>{dayjs(feedback.create_dt).format("YYYY.MM.DD")}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.viewMore}>
        <div className={styles.viewMoreButton} onClick={handleViewMore}>
          <span className={styles.viewMoreText}>후기 더 보기</span>
          <div className={styles.arrow}>
            <img
              src={plusCircle}
              alt="plus circle"
              className={styles.arrowInner}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

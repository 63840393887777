import React from "react";
import styles from "./AddButton.module.css";

interface AddButtonProps {
  handleAddButton: () => void;
  title: string;
}

const AddButton: React.FC<AddButtonProps> = ({ handleAddButton, title }) => {
  return (
    <button onClick={handleAddButton} className={styles.button}>
      {title}
    </button>
  );
};

export default AddButton;

import React, { useEffect, useRef, useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import styles from "./EvemtSubtitleTable.module.css";
import { useApi } from "@context/ApiContext";

interface SubtitleObj {
  id: number;
  default_text: string;
  event_text: string;
}

interface EvemtSubtitleTableProps {
  subtitles: SubtitleObj[] | undefined;
  onUpdate: () => void;
}

const EventSubtitleTable: React.FC<EvemtSubtitleTableProps> = ({
  subtitles,
  onUpdate,
}) => {
  const [editingId, setEditingId] = useState<number | null>(null);

  const [editingText, setEditingText] = useState("");

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { adminApi } = useApi();

  useEffect(() => {
    if (textareaRef.current && editingId !== null) {
      textareaRef.current.focus();
      textareaRef.current.select();
    }
  }, [editingId]);

  // 편집 모드 활성화
  const handleEdit = (subtitle: SubtitleObj) => {
    setEditingId(subtitle.id);
    setEditingText(subtitle.event_text);
  };

  // 저장 버튼 클릭 처리
  const handleSave = (id: number) => {
    // 여기에 저장 로직 구현
    adminApi
      .updateEventSubtitle({ id, event_text: editingText })
      .then((res: any) => {
        if (!res) return;

        alert("저장되었습니다.");

        onUpdate();
        setEditingId(null);
      });
  };

  // 취소 버튼 클릭 처리
  const handleCancel = () => {
    setEditingId(null);
  };

  return (
    <Table>
      <TableBody>
        <TableHeader>
          <th className={styles.exSmallTh}>id</th>
          <th>기본자막</th>
          <th className={styles.middleTh}>고객자막</th>
          <th className={styles.exSmallTh}>편집</th>
        </TableHeader>

        {subtitles?.map((subtitle) => (
          <tr key={subtitle.id}>
            <td>{subtitle.id}</td>
            <td className={styles.defaultText}>{subtitle.default_text}</td>
            <td className={styles.defaultText}>
              {editingId === subtitle.id ? (
                <textarea
                  className={styles.textarea}
                  ref={textareaRef}
                  value={editingText || ""}
                  onChange={(e) => setEditingText(e.target.value)}
                />
              ) : (
                <pre>{subtitle.event_text}</pre>
              )}
            </td>
            <td>
              {editingId === subtitle.id ? (
                <>
                  <button
                    className={styles.editButton}
                    onClick={() => handleSave(subtitle.id)}
                  >
                    저장
                  </button>
                  <button
                    className={styles.cancelButton}
                    onClick={handleCancel}
                  >
                    취소
                  </button>
                </>
              ) : (
                <button
                  className={styles.editButton}
                  onClick={() => handleEdit(subtitle)}
                >
                  수정
                </button>
              )}
            </td>
          </tr>
        ))}
      </TableBody>
    </Table>
  );
};

export default EventSubtitleTable;

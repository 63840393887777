import React from "react";
import { AiOutlineCheck, AiOutlineEdit } from "react-icons/ai";
import { ServiceImageProps } from "@interfaces/service";

interface ServiceImageBoxProps {
  image: ServiceImageProps;
  selectFlag: boolean;
  handleCheck: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSelectImage: (e: ServiceImageProps) => void;
}

const ServiceImageBox: React.FC<ServiceImageBoxProps> = ({
  image,
  image: { src, isChecked, uid, url, public_id },
  selectFlag,
  handleCheck,
  handleSelectImage,
}) => {
  const transformImageUrl = (
    url: string | undefined,
    transformation: string
  ) => {
    const parts = url?.split("/upload/");

    if (!parts) {
      return;
    }

    return `${parts[0]}/upload/${transformation || "c_thumb,w_200"}/${
      parts[1]
    }`;
  };

  if (selectFlag) {
    return (
      <button className="service-image-box" onClick={handleCheck}>
        {url && public_id ? (
          <img
            id={uid}
            src={transformImageUrl(url, "")}
            alt="업로드 된 이미지"
          />
        ) : (
          <img id={uid} src={URL.createObjectURL(src)} alt="업로드 된 이미지" />
        )}

        {isChecked && (
          <div className="image-box-checked">
            <AiOutlineCheck className="image-box-icon" />
          </div>
        )}
      </button>
    );
  }

  return (
    <div className="service-image-box">
      {url && public_id ? (
        <img id={uid} src={transformImageUrl(url, "")} alt="업로드 된 이미지" />
      ) : (
        <img id={uid} src={URL.createObjectURL(src)} alt="업로드 된 이미지" />
      )}

      <button className="edit-overlay">
        <AiOutlineEdit
          className="edit-icon"
          onClick={() => handleSelectImage(image)}
        />
      </button>
    </div>
  );
};

export default ServiceImageBox;

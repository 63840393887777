import React, { useRef, useState } from "react";
import styles from "./Event.module.css";
import dayjs from "dayjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import ContentCard from "@components/Admin/Design/Card/ContentCard";
import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import StatusButton from "@components/Admin/Design/Button/StatusButton";
import EvemtSubtitleTable from "@components/Admin/Event/EvemtSubtitleTable";
import EventImageTable from "@components/Admin/Event/EventImageTable";
import EventImageDownloader from "@components/Admin/Event/EventImageDownloader";

import { ImageEditProps } from "@interfaces/service";

import uuid from "react-uuid";
import imageCompression from "browser-image-compression";
import { uploadImage } from "@apis/uploader";
import EventImageEdit from "@components/Admin/Event/EventImageEdit";
import Table from "@components/Admin/Table/Table";
import TableBody from "@components/Admin/Table/TableBody";
import TableHeader from "@components/Admin/Table/TableHeader";
import QueueSettingInputModal from "@components/Admin/Queue/QueueSettingInputModal";
import AdminLoading from "@pages/Admin/AdminLoading";

interface EventProps {}

const Event: React.FC<EventProps> = ({}) => {
  const [files, setFiles] = useState<File[]>([]);
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const changeFileRef = useRef<HTMLInputElement | null>(null);
  const { event_no } = useParams();
  const navigator = useNavigate();

  const { adminApi, serviceApi } = useApi();

  const [uploadMode, setUploadMode] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageEditProps | null>(
    null
  );

  const [queueSettingModalFlag, setQueueSettingModalFlag] =
    useState<boolean>(false);
  const [imageList, setImageList] = useState<ImageEditProps[]>([]);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(
    ["getEvent"],
    () => {
      return adminApi.getEvent(event_no).then((res: any) => {
        if (!res) return null;

        setImageList(res.EventImages);

        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateEvent = () => {
    queryClient.invalidateQueries(["getEvent"]);
  };

  const handleVideoMake = () => {
    const cropImageList = imageList.filter((img) => img.state === "crop");

    if (imageList.length === cropImageList.length) {
      alert("수정된 이미지를 업로드 합니다.");
      setIsUploading(true);
      handleUploadComplete("edit-complete");
      return;
    }

    if (
      window.confirm("편집되지 않은 사진이 있습니다. 제작을 진행하시겠습니까?")
    ) {
      setIsUploading(true);
      handleUploadComplete("edit-complete");
    }
  };

  const handleImageUploadClick = () => {
    const photo_length = +(event?.Template?.photo_length || 0);
    if (files.length >= photo_length) {
      alert("사진 장수를 초과해서 업로드 할 수 없습니다");
      return;
    }

    if (uploadFileRef.current) {
      uploadFileRef.current.click();
      setUploadMode(true);
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files || files?.length === 0) return;

    const photo_length = +(event?.Template?.photo_length || 0);
    if (files.length > photo_length) {
      alert("필요한 사진 장수 만큼만 업로드 됩니다");
    }

    const fileListArray = Array.from(files).map((file) => ({
      uid: uuid(),
      src: file,
    }));

    setImageList((prev) => [...prev, ...fileListArray]);
  };

  const handleUploadComplete = async (step?: string) => {
    if (imageList.length === 0) {
      alert("업로드된 사진이 없습니다");
      return;
    }

    const compressImage = async (imageFile: any) => {
      const options = {
        maxSizeMB: 10, // 5MB로 설정
        useWebWorker: true,
        quality: 0.8,
      };

      try {
        return await imageCompression(imageFile, options);
      } catch (error) {
        console.error("압축 중 오류 발생:", error);
      }
    };

    Promise.all(
      imageList.map(async (image) => {
        const { uid, src, public_id, url, state } = image;
        return new Promise(async (resolve, reject) => {
          if (!src) {
            resolve(image);
          } else {
            // 이미지 압축

            if (step === "edit-complete") {
              uploadImage(
                src,
                `events/${event?.event_no}_${event?.dm_name}/cropImage`
              ).then((imageData) => {
                if (image.uid && image.uid.includes("repeat")) {
                  image.public_id = imageData.public_id;
                }

                image.state = "crop";
                image.updated_url = imageData.url;

                resolve(image);
              });
            } else {
              uploadImage(
                src,
                `events/${event?.event_no}_${event?.dm_name}`
              ).then((imageData) => {
                image.state = "new";
                image.url = imageData.url;
                resolve(image);
              });
            }
          }
        });
      })
    ).then((images) => {
      const newImages = images.filter(
        (img: any) => img.state === "crop" || img.state === "new"
      );

      const params = {
        uid: event?.uid,
        images: newImages,
      };

      serviceApi.saveEventImageURL(params).then((res: any) => {
        if (!res) return;
        setIsUploading(false);
        alert("편집된 이미지의 업로드가 완료되었습니다.");

        if (step === "edit-complete") {
          setEditModalOpen(false);
          setQueueSettingModalFlag(true);
        } else {
          updateEvent();
        }

        if (uploadMode) {
          setUploadMode(false);
        }
      });
    });
  };
  const startQueue = async (setting: any) => {
    try {
      setIsUploading(true);
      adminApi.startQueue({ uid: event?.uid, setting }).then((res: any) => {
        if (!res) return;

        setIsUploading(false);
        alert("제작이 시작됩니다.");
        navigator("/admin/dashboard/queues");
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectImage = (image: ImageEditProps) => {
    // setEditModalFlag(true);
    setSelectedImage(image);

    if (!changeFileRef.current) return;

    changeFileRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];

    handleImageSave(selectedImage?.uid, file);
    setSelectedImage(null);
  };

  const handleImageSave = (
    exImgId: string | undefined,
    newImgSrc: Blob | File
  ) => {
    // imageList에서 해당 ID를 가진 객체를 찾아 src를 업데이트
    const updatedImageList = imageList.map((img) => {
      if (img.uid === exImgId) {
        if (img.public_id) {
          delete img.public_id;
          delete img.url;
        }

        return { ...img, src: newImgSrc };
      }

      return img;
    });

    // 업데이트된 이미지 리스트로 상태 업데이트
    setImageList(updatedImageList);
  };

  const handleOpenEdit = () => {
    if (event?.template_state === "short") {
      alert("빠른제작은 편집이 불가능합니다");
      return;
    }

    if (event.state === "uploading" || imageList.length === 0) {
      alert("아직 업로드가 완료되지 않았습니다");
      return;
    }

    if (event.state !== "making") {
      serviceApi.updateEvent({ uid: event?.uid, state: "making" });
    }

    // 디바이스의 크기가 768px보다 작은 경우 모달을 띄우지 않음

    setEditModalOpen(true);
  };

  if (isError) return <Error />;

  return (
    <div>
      {isLoading && <AdminLoading />}
      {isUploading && <AdminLoading />}
      <PageHeader
        title="고객 정보 상세"
        desc="고객의 정보를 확인하고, 편집할 수 있습니다"
        addButton="뒤로가기"
        handleAddButton={() => {
          navigator("/admin/dashboard/events");
        }}
      />

      {event && (
        <QueueSettingInputModal
          event={event}
          isOpen={queueSettingModalFlag}
          onClose={() => setQueueSettingModalFlag(false)}
          startQueue={startQueue}
        />
      )}

      <main className={styles.mainWrap}>
        <div className={styles.infoWrap}>
          <ContentCard>
            <h4 className={styles.infoTitle}>기본 정보</h4>

            <div className={styles.basicInfoWrap}>
              <div>
                <div className={styles.titleWrap}>
                  <h6>고인명</h6>
                  <p>{event?.dm_name}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>장례식장</h6>
                  <p>{event?.FuneralInfo?.FUNERAL_NAME || "-"}</p>
                </div>
              </div>
              <div>
                <div className={styles.titleWrap}>
                  <h6>상태</h6>
                  <StatusButton state={event?.state} target="event" />
                </div>
              </div>
              <div>
                <div className={styles.titleWrap}>
                  <h6>생성일</h6>
                  <p>{dayjs(event?.create_dt).format("YYYY-MM-DD")}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>수정일</h6>
                  <p>{dayjs(event?.update_dt).format("YYYY-MM-DD")}</p>
                </div>
              </div>
            </div>
          </ContentCard>
          <ContentCard>
            <h4 className={styles.infoTitle}>상주 정보</h4>

            {event?.EventUsers?.length > 0 ? (
              <Table>
                <TableBody>
                  <TableHeader>
                    <th className={styles.exSmallTh}>id</th>
                    <th className={styles.smallTh}>상주명</th>
                    <th className={styles.smallTh}>상주번호</th>
                    <th className={styles.smallTh}>등록일시</th>
                  </TableHeader>

                  {event?.EventUsers?.map((eventUser: any) => (
                    <tr key={eventUser.id}>
                      <td>{eventUser.id}</td>
                      <td>{eventUser.name}</td>
                      <td>{eventUser.phone_number}</td>
                      <td>{dayjs(eventUser.create_dt).format("YYYY-MM-DD")}</td>
                    </tr>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <span>등록된 상주가 없습니다</span>
            )}
          </ContentCard>
          <ContentCard>
            <h4 className={styles.infoTitle}>자막 정보</h4>

            {event && (
              <EvemtSubtitleTable
                subtitles={event?.EventSubtitles}
                onUpdate={updateEvent}
              />
            )}
          </ContentCard>
          <ContentCard>
            <div className={styles.imageUploadWrap}>
              <input
                type="file"
                ref={changeFileRef}
                className="input-hidden"
                onChange={handleFileChange}
                accept="image/*"
                multiple={false}
              />
              <input
                type="file"
                className="input-hidden"
                ref={uploadFileRef}
                multiple={true}
                accept="image/*"
                onChange={handleUpload}
              />
              <div>
                <h4 className={styles.infoTitle}>이미지 정보</h4>
                <p>
                  필요한 사진 장수 : {imageList.length} /
                  {event?.Template?.photo_length}장
                </p>
              </div>
              {!uploadMode ? (
                <button onClick={handleImageUploadClick}>파일 업로드</button>
              ) : (
                <div>
                  <button onClick={handleImageUploadClick}>추가 업로드</button>

                  <button onClick={() => handleUploadComplete()}>
                    업로드 완료
                  </button>
                </div>
              )}
            </div>

            {imageList?.length !== 0 && (
              <EventImageTable
                images={imageList}
                files={files}
                onUpdate={updateEvent}
                handleSelectImage={handleSelectImage}
                handleUploadComplete={handleUploadComplete}
              />
            )}
          </ContentCard>
        </div>

        <div className={styles.videoWrap}>
          <ContentCard>
            <div className={styles.contentWrap}>
              <div>
                <h4 className={styles.infoTitle}>사진편집</h4>
                <button onClick={handleOpenEdit}>사진 편집</button>
              </div>
              <div>
                <h4 className={styles.infoTitle}>다운로드</h4>

                <div>
                  <EventImageDownloader
                    dm_name={event?.dm_name}
                    images={event?.EventImages}
                  />
                  {event?.video_download_url && (
                    <button>
                      <a
                        href={event?.video_download_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        영상 다운로드
                      </a>
                    </button>
                  )}
                </div>
              </div>
              <div>
                <h4 className={styles.infoTitle}>미리 보기</h4>

                {event?.embed_url ? (
                  <div className={styles.previewWrap}>
                    <iframe
                      title="Product Video"
                      src={event?.embed_url}
                      width="1920"
                      height="1080"
                      className="sample_video"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div>
                    <p className={styles.desc}>
                      제작된 영상이 없어 미리보기가 불가능 합니다
                    </p>
                  </div>
                )}
              </div>
            </div>
          </ContentCard>
        </div>
      </main>

      {imageList?.length !== 0 && editModalOpen && (
        <EventImageEdit
          isOpen={editModalOpen}
          onClose={() => {
            setEditModalOpen(false);
          }}
          event={event}
          imageList={imageList}
          setImageList={setImageList}
          onComplete={handleVideoMake}
        />
      )}
    </div>
  );
};

export default Event;

import React, { useEffect, useState } from "react";
import styles from "./EventImageEdit.module.css";
import Modal from "react-modal";
import { ImageEditProps } from "@interfaces/service";
import ServiceImageChange from "@components/Service/Uploads/Images/ServiceImageChange";
import EventImageEditPreview from "./EventImageEditPreview";
import { AiOutlineCheck } from "react-icons/ai";

// image asset
import HorizontalLine from "@assets/images/service/horizontal.svg";
import VerticalLine from "@assets/images/service/vertical.svg";

interface EventImageEditProps {
  isOpen: boolean;
  onClose: () => void;
  event: any;
  imageList: ImageEditProps[];
  setImageList: (imageList: ImageEditProps[]) => void;
  onComplete: () => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "1500px",
    height: "750px",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const EventImageEdit: React.FC<EventImageEditProps> = ({
  isOpen,
  onClose,
  imageList,
  setImageList,
  event,
  onComplete,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const [selectedImage, setSelectedImage] = useState<ImageEditProps | null>(
    imageList[0]
  );
  const [finalImageList, setFinalImageList] = useState<ImageEditProps[]>([]);

  const [selectedTemplateImageInfo, setSelectedTemplateImageInfo] = useState(
    event?.Template?.images[0]
  );

  const [isReorderMode, setIsReorderMode] = useState(false);
  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(
    null
  );
  const [reorderPreview, setReorderPreview] = useState<number | null>(null);

  const generateUniqueUid = (baseUid: string, index: number) => {
    return `repeat_${baseUid}_${index}_${Date.now()}_${Math.random()
      .toString(36)
      .substring(2, 15)}`;
  };

  const adjustImageList = (
    requiredImages: number,
    uploadedImages: ImageEditProps[]
  ) => {
    let tempList = [...uploadedImages];

    while (tempList.length < requiredImages) {
      uploadedImages.forEach((img, index) => {
        if (tempList.length < requiredImages) {
          delete img.id;
          const newImg = {
            ...img,
            uid: generateUniqueUid(img.uid, index),
            state: "repeat",
          };
          tempList.push(newImg);
        }
      });
    }

    return tempList;
  };

  useEffect(() => {
    const neededImages = adjustImageList(
      event?.Template.photo_length,
      imageList
    );

    setImageList(neededImages);
    setFinalImageList(neededImages);
    setSelectedImage(neededImages[0]); // 첫 번째 이미지를 기본으로 선택
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Check screen size on initial load
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageSave = (
    exImgId: string | undefined,
    newImgSrc: Blob | File,
    state: string
  ) => {
    // imageList에서 해당 ID를 가진 객체를 찾아 src를 업데이트
    const updatedImageList = finalImageList.map((img) => {
      if (img.uid === exImgId) {
        setSelectedImage({
          ...img,
          src: newImgSrc,
          state,
          layer_name: selectedTemplateImageInfo.layerName,
        });

        return {
          ...img,
          src: newImgSrc,
          state,
          layer_name: selectedTemplateImageInfo.layerName,
        };
      }

      return img;
    });

    setImageList(updatedImageList);
    setFinalImageList(updatedImageList);
  };

  const handleImageChange = (direction: string) => {
    // 현재 활성화된 자막의 인덱스 찾기
    const currentIndex = finalImageList.findIndex(
      (img) => img.uid === selectedImage?.uid
    );

    // 다음 또는 이전 자막의 인덱스 계산 및 경계 처리
    if (direction === "next") {
      if (currentIndex === finalImageList.length - 1) {
        alert("이미지 수정이 완료되었습니다.");
        return;
      }
      setSelectedImage(finalImageList[currentIndex + 1]);
      setSelectedTemplateImageInfo(event?.Template?.images[currentIndex + 1]);
    } else if (direction === "prev") {
      if (currentIndex === 0) {
        alert("첫 번째 이미지 입니다.");
        return;
      }

      setSelectedImage(finalImageList[currentIndex - 1]);
      setSelectedTemplateImageInfo(event?.Template?.images[currentIndex - 1]);
    }
  };

  const handleReorderClick = () => {
    setIsReorderMode((prev) => {
      if (prev) {
        handleReorderComplete();
      } else {
        setFirstSelectedIndex(
          finalImageList.findIndex((img) => img.uid === selectedImage?.uid)
        );

        setReorderPreview(null);
      }

      return !prev;
    });
    // 현재 선택된 사진을 첫번째 선택 인덱스로 설정
  };

  const handleReorderComplete = () => {
    if (firstSelectedIndex !== null && reorderPreview !== null) {
      const newImageList = [...finalImageList];
      [newImageList[firstSelectedIndex], newImageList[reorderPreview]] = [
        newImageList[reorderPreview],
        newImageList[firstSelectedIndex],
      ];

      // 순서가 바뀐 이미지들의 state를 초기화

      newImageList[firstSelectedIndex] = {
        layer_name: null,
        public_id: newImageList[firstSelectedIndex].public_id,
        state: "new",
        uid: newImageList[firstSelectedIndex].uid,
        updated_url: newImageList[firstSelectedIndex].updated_url,
        url: newImageList[firstSelectedIndex].url,
      };

      newImageList[reorderPreview] = {
        layer_name: null,
        public_id: newImageList[reorderPreview].public_id,
        state: "new",
        uid: newImageList[reorderPreview].uid,
        updated_url: newImageList[reorderPreview].updated_url,
        url: newImageList[reorderPreview].url,
      };

      setFinalImageList(newImageList);
      setImageList(newImageList);
      setSelectedImage(newImageList[firstSelectedIndex]);
      setSelectedTemplateImageInfo(
        event?.Template.images[
          firstSelectedIndex % event?.Template.images.length
        ]
      );
    }
    setIsReorderMode(false);
    setFirstSelectedIndex(null);
    setReorderPreview(null);
  };

  const handleImageClick = (index: number) => {
    if (isReorderMode) {
      if (firstSelectedIndex === null) {
        setFirstSelectedIndex(index);
      } else {
        setReorderPreview(index);
      }
    } else {
      setSelectedImage(finalImageList[index]);
      setSelectedTemplateImageInfo(
        event?.Template.images[index % event?.Template.images.length]
      );
    }
  };

  if (isMobile) {
    return (
      <div className={styles.mobileContainer}>
        <div className={styles.buttonWrap}>
          <button className={styles.cancel} onClick={onClose}>
            취소
          </button>
          <button onClick={onComplete}>영상 제작</button>
        </div>
        <div className={styles.mobileWrap}>
          <section className={styles.editSection}>
            <div className={styles.previewListWrap}>
              <div className={styles.listWrap}>
                {finalImageList.map((item, index) => (
                  <div className={styles.imageWrap} key={item.uid}>
                    <div className={styles.orderWrap}>{index + 1}</div>
                    <div className={styles.rateWrap}>
                      {event?.Template.images[index].image_width /
                        event?.Template.images[index].image_height >
                      1 ? (
                        <img src={HorizontalLine} alt="horizontal" />
                      ) : (
                        <img src={VerticalLine} alt="vertical" />
                      )}
                    </div>
                    {item.state === "crop" && (
                      <div className={styles.iconWrap}>
                        <AiOutlineCheck className={styles.checkIcon} />
                      </div>
                    )}
                    <img
                      id={item.uid}
                      src={
                        ["crop", "update", "repeat"].includes(
                          item?.state as string
                        )
                          ? item.src instanceof Blob || item.src instanceof File
                            ? URL.createObjectURL(item.src)
                            : item.updated_url
                            ? item.updated_url
                            : item.url
                          : item.url
                      }
                      alt="thumbnail"
                      onClick={() => handleImageClick(index)}
                      className={`${styles.previewImg} ${
                        item.uid === selectedImage?.uid ? styles.active : ""
                      } ${
                        isReorderMode &&
                        reorderPreview === index &&
                        styles.reorderActive
                      }`}
                    />
                  </div>
                ))}
              </div>

              <div className={styles.funcWrap}>
                <ServiceImageChange
                  selectedImage={selectedImage}
                  templateImageInfo={selectedTemplateImageInfo}
                  onImageSave={handleImageSave}
                  handleImageChange={handleImageChange}
                  handleReorderClick={handleReorderClick}
                  isReorderMode={isReorderMode}
                />
              </div>

              <div className={styles.previewWrap}>
                <EventImageEditPreview
                  image={
                    reorderPreview
                      ? finalImageList[reorderPreview]
                      : selectedImage
                  }
                  templateImageInfo={selectedTemplateImageInfo}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      // onRequestClose={onClose}
      style={customModalStyles}
    >
      <section className={styles.editSection}>
        <div className={styles.previewListWrap}>
          <div className={styles.previewWrap}>
            <EventImageEditPreview
              image={
                reorderPreview ? finalImageList[reorderPreview] : selectedImage
              }
              templateImageInfo={selectedTemplateImageInfo}
            />
          </div>

          <div className={styles.listWrap}>
            {/* {imageList.map((item: ServiceImageEditProps, index) => (
              <div className={styles.imageWrap}>
                {item?.state === "crop" && (
                  <div className={styles.iconWrap}>
                    <AiOutlineCheck className={styles.checkIcon} />
                  </div>
                )}

                <img
                  id={item.uid}
                  key={item.uid}
                  src={
                    item?.state === "crop" || item?.state === "update"
                      ? item?.src instanceof Blob || item?.src instanceof File
                        ? URL.createObjectURL(item.src)
                        : item.updated_url
                      : item.url
                  }
                  alt="text thum"
                  onClick={() => {
                    setSelectedImage(item);
                    setSelectedTemplateImageInfo(
                      event?.Template?.images[index]
                    );
                  }}
                  className={`${
                    item.uid === selectedImage?.uid && styles.active
                  }`}
                />
              </div>
            ))} */}

            {finalImageList.map((item, index) => (
              <div className={styles.imageWrap} key={item.uid}>
                <div className={styles.orderWrap}>{index + 1}</div>
                <div className={styles.rateWrap}>
                  {event?.Template.images[index].image_width /
                    event?.Template.images[index].image_height >
                  1 ? (
                    <img src={HorizontalLine} alt="horizontal" />
                  ) : (
                    <img src={VerticalLine} alt="vertical" />
                  )}
                </div>
                {item.state === "crop" && (
                  <div className={styles.iconWrap}>
                    <AiOutlineCheck className={styles.checkIcon} />
                  </div>
                )}
                <img
                  id={item.uid}
                  src={
                    ["crop", "update", "repeat"].includes(item?.state as string)
                      ? item.src instanceof Blob || item.src instanceof File
                        ? URL.createObjectURL(item.src)
                        : item.updated_url
                        ? item.updated_url
                        : item.url
                      : item.url
                  }
                  alt="thumbnail"
                  onClick={() => handleImageClick(index)}
                  className={`${
                    item.uid === selectedImage?.uid ? styles.active : ""
                  } ${
                    isReorderMode &&
                    reorderPreview === index &&
                    styles.reorderActive
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.funcWrap}>
          <ServiceImageChange
            selectedImage={selectedImage}
            templateImageInfo={selectedTemplateImageInfo}
            onImageSave={handleImageSave}
            handleImageChange={handleImageChange}
            handleReorderClick={handleReorderClick}
            isReorderMode={isReorderMode}
          />
        </div>
      </section>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={onComplete}>영상 제작</button>
      </div>
    </Modal>
  );
};

export default EventImageEdit;

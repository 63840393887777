import React, { useState } from "react";
import styles from "./EventImageTable.module.css";

import { AiOutlineCheck, AiOutlineEdit } from "react-icons/ai";
import { useApi } from "@context/ApiContext";
import { ImageEditProps } from "@interfaces/service";

interface EventImageTableProps {
  images: ImageEditProps[] | undefined;
  files: File[];
  onUpdate: () => void;
  handleSelectImage: (e: ImageEditProps) => void;
  handleUploadComplete: () => void;
}

const EventImageTable: React.FC<EventImageTableProps> = ({
  images,
  files,
  onUpdate,
  handleSelectImage,
  handleUploadComplete,
}) => {
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const { adminApi } = useApi();

  const toggleDeleteMode = (): void => {
    setDeleteMode(!deleteMode);
    if (deleteMode) {
      setSelectedImages([]);
    }
  };

  const handleImageSelect = (imageUid: string): void => {
    if (selectedImages.includes(imageUid)) {
      setSelectedImages(selectedImages.filter((uid) => uid !== imageUid));
    } else {
      setSelectedImages([...selectedImages, imageUid]);
    }
  };

  const handleDeleteImages = () => {
    if (selectedImages.length === 0) {
      alert("삭제할 이미지를 선택하세요.");
      return;
    }

    const confirmDelete = window.confirm(
      `${selectedImages.length}장의 사진을 삭제합니다.`
    );

    if (confirmDelete) {
      adminApi.deleteEventImages({ selectedImages }).then((res: any) => {
        if (!res) return;

        alert("삭제 되었습니다.");

        onUpdate();
        toggleDeleteMode();
      });
    }
  };

  const handleUpdateImages = () => {
    handleUploadComplete();
    setUpdateMode(false);
  };

  const transformImageUrl = (
    url: string | undefined,
    transformation: string
  ) => {
    const parts = url?.split("/upload/");

    if (!parts) {
      return;
    }

    return `${parts[0]}/upload/${transformation || "c_thumb,w_200"}/${
      parts[1]
    }`;
  };

  if (images?.length === 0 && files?.length === 0) {
    return (
      <div className={styles.noContentBox}>
        <p>업로드 된 사진이 없습니다</p>
      </div>
    );
  }
  return (
    <div>
      <div>
        {!deleteMode && !updateMode && (
          <div className={styles.buttonWrap}>
            <button
              className={styles.editButton}
              onClick={() => setUpdateMode(true)}
            >
              사진 수정
            </button>
            <button className={styles.deleteButton} onClick={toggleDeleteMode}>
              사진 삭제
            </button>
          </div>
        )}

        {/* 수정 모드일 때 */}
        {updateMode && (
          <div className={styles.buttonWrap}>
            <button
              className={styles.deleteButton}
              onClick={() => setUpdateMode(false)}
            >
              수정 취소
            </button>
            <button className={styles.editButton} onClick={handleUpdateImages}>
              수정 완료
            </button>
          </div>
        )}

        {/* 삭제 모드일 때 */}
        {deleteMode && (
          <div className={styles.buttonWrap}>
            <button className={styles.deleteButton} onClick={toggleDeleteMode}>
              삭제 취소
            </button>
            <button className={styles.editButton} onClick={handleDeleteImages}>
              삭제 완료
            </button>
          </div>
        )}
      </div>

      <div className={styles.imagePreviewWrap}>
        {deleteMode
          ? images?.map((image) => (
              <div
                key={image.uid}
                className={`${styles.imageContainer} ${
                  selectedImages.includes(image.uid) ? styles.selectedImage : ""
                }`}
                onClick={() => handleImageSelect(image.uid)}
              >
                <img
                  src={transformImageUrl(image.url, "")}
                  alt="이미지 미리보기"
                  className={styles.thumImg}
                />
                {selectedImages.includes(image.uid) && (
                  <div className={styles.checkIconWrap}>
                    <AiOutlineCheck className={styles.checkIcon} />
                  </div>
                )}
              </div>
            ))
          : updateMode
          ? images?.map((image) => (
              <div className="service-image-box">
                {image.url && image.public_id ? (
                  <img
                    id={image.uid}
                    src={transformImageUrl(image.url, "")}
                    alt="업로드 된 이미지"
                  />
                ) : (
                  <img
                    id={image.uid}
                    src={URL.createObjectURL(image.src)}
                    alt="업로드 된 이미지"
                  />
                )}

                <button className="edit-overlay">
                  <AiOutlineEdit
                    className="edit-icon"
                    onClick={() => handleSelectImage(image)}
                  />
                </button>
              </div>
            ))
          : images?.map((image) => (
              <div key={image.uid} className={styles.imageContainer}>
                {image.url && image.public_id ? (
                  <img
                    id={image.uid}
                    src={transformImageUrl(image.url, "")}
                    alt="업로드 된 이미지"
                  />
                ) : (
                  <img
                    id={image.uid}
                    src={URL.createObjectURL(image.src)}
                    alt="업로드 된 이미지"
                  />
                )}
              </div>
            ))}
      </div>
    </div>
  );
};

export default EventImageTable;

import React, { useEffect, useState } from "react";
import styles from "./MemberGroupAddModal.module.css";
import Modal from "react-modal";

import { useApi } from "@context/ApiContext";
import { useNavigate } from "react-router-dom";

interface MemberGroupAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateAdminGroups: () => void;
  adminGroup?: any;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const MemberGroupAddModal: React.FC<MemberGroupAddModalProps> = ({
  isOpen,
  onClose,
  updateAdminGroups,
  adminGroup,
}) => {
  const { adminApi } = useApi();

  const [group, setGroup] = useState({
    LV: "",
    group_name: "",
  });
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setGroup((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (adminGroup) {
      setGroup(adminGroup);
    } else {
      setGroup({
        LV: "",
        group_name: "",
      });
    }
  }, [adminGroup]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>{adminGroup ? "그룹 수정" : "그룹 등록"}</h3>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="group_name">그룹명</label>
          <input
            id="group_name"
            type="text"
            placeholder="그룹명을 입력하세요"
            value={group.group_name}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="LV">그룹 LV</label>
          <input
            id="LV"
            type="text"
            placeholder="고유 그룹 레벨을 입력하세요 예)99"
            value={group.LV}
            onChange={handleInputChange}
            readOnly={adminGroup}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            adminApi
              .saveOrUpdateAdminGroup({
                group: {
                  ...group,
                  LV: parseInt(group.LV),
                },
                state: adminGroup ? "update" : "create",
              })
              .then((res: any) => {
                if (!res) return;

                alert("완료되었습니다");
                updateAdminGroups();
                onClose();
              });
          }}
        >
          {adminGroup ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default MemberGroupAddModal;

import React, { useCallback, useRef } from "react";
import styles from "./AllFeedbacks.module.css";
import { FaStar, FaRegStar } from "react-icons/fa"; // react-icons 패키지 사용
import leftArrow from "@assets/images/service/chevron-left.svg";
import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import { useApi } from "@context/ApiContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

const AllFeedbacks: React.FC = () => {
  const { serviceApi } = useApi();

  const { data, isLoading, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ["feedbacks"],
      ({ pageParam = 0 }) =>
        serviceApi.getFeedbacks({ limit: 10, offset: pageParam }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const morePagesExist = lastPage?.feedbacks?.length === 10;
          if (!morePagesExist) return false;

          return allPages.length * 10;
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      }
    );

  const observer = useRef<IntersectionObserver | null>(null);
  const lastFeedbackElementRef = useCallback(
    (node: any) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, fetchNextPage, hasNextPage]
  );

  const handleLeftIconClick = () => {
    // 여기에 원하는 동작을 정의하세요. 예를 들어, 이전 페이지로 이동:
    window.history.back();
  };

  return (
    <div className={styles.container}>
      <ServiceMainHeader
        title="서비스 후기"
        handleLeftIconClick={handleLeftIconClick}
        page="all-feedbacks"
      />
      <div className={styles.header}>
        <div className={styles.subtitle}>
          상주님들께서 작성해주신
          <br />총 {data?.pages[0].total}개의 후기입니다
        </div>
      </div>
      <div className={styles.feedbacks}>
        {data?.pages.map((page, pageIndex) =>
          page.feedbacks.map((feedback: any, feedbackIndex: number) => {
            const isLastElement =
              pageIndex === data.pages.length - 1 &&
              feedbackIndex === page.feedbacks.length - 1;
            return (
              <div
                className={styles.feedback}
                key={feedback.id}
                ref={isLastElement ? lastFeedbackElementRef : null}
              >
                <div className={styles.stars}>
                  {Array(5)
                    .fill(null)
                    .map((_, starIndex) =>
                      starIndex < feedback.rating ? (
                        <FaStar key={starIndex} />
                      ) : (
                        <FaRegStar key={starIndex} />
                      )
                    )}
                </div>
                <div className={styles.feedbackText}>
                  <p>{feedback?.description}</p>
                </div>
                <div className={styles.feedbackFooter}>
                  <div>{feedback?.name.replace(/.{2}$/, "**")}</div>
                  <div>{dayjs(feedback.create_dt).format("YYYY.MM.DD")}</div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AllFeedbacks;

import React from "react";

import styles from "./MemberTable.module.css";

import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useApi } from "@context/ApiContext";

interface MemberTableProps {
  members: any;
  updateAdminUsers: () => void;
  handleMemberModalOpen: (member: any) => void;
}

const MemberTable: React.FC<MemberTableProps> = ({
  members,
  updateAdminUsers,
  handleMemberModalOpen,
}) => {
  const { adminApi } = useApi();

  return (
    <>
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>NO</th>
            <th className={styles.smallTh}>아이디</th>
            <th className={styles.middleTh}>이메일</th>
            <th className={styles.largeTh}>그룹</th>
            <th className={styles.largeTh}>등록일</th>
            <th className={styles.largeTh}>수정 및 삭제</th>
          </TableHeader>

          {members.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            members?.map((member: any, idx: number) => (
              <tr key={member?.id}>
                <td>{idx + 1}</td>
                <td>{member.user_id}</td>
                <td>{member.email || "-"}</td>
                <td>{member?.AdminGroup?.group_name}</td>
                <td>{member.create_dt}</td>
                <td>
                  <button
                    className={styles.changeButton}
                    onClick={() => handleMemberModalOpen(member)}
                  >
                    <MdOutlineEdit />
                  </button>
                  <button className={styles.changeButton}>
                    <MdDeleteOutline
                      onClick={() => {
                        if (
                          window.confirm(
                            `${member.user_id} 을 정말 삭제하시겠습니까?`
                          )
                        ) {
                          adminApi
                            .saveOrUpdateAdminUser({
                              user: {
                                user_id: member.user_id,
                              },
                              state: "delete",
                            })
                            .then(() => {
                              alert("삭제되었습니다");
                              updateAdminUsers();
                            });
                        }
                      }}
                    />
                  </button>
                </td>
              </tr>
            ))
          )}

          {}
        </TableBody>
      </Table>
    </>
  );
};

export default MemberTable;

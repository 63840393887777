import React from "react";
import styles from "./SubTitleSetting.module.css";

interface SubTitleSettingProps {
  subtitle: {
    id: string;
    img: Blob;
    thum: any;
    title: string;
    layerName: string;
    text: string;
    align: string;
    positionX: string;
    positionY: string;
    lineHeight: number;
    fontSize: number;
    font: string;
    fontColor: string;
    shadow: any;
  };
  idx: number;
  handleSubtitle: any;
}

const SubTitleSetting = React.memo<SubTitleSettingProps>(
  ({
    subtitle,
    subtitle: {
      id,
      title,
      layerName,
      text,
      fontColor,
      fontSize,
      shadow,
      lineHeight,
      align,
      positionX,
      positionY,
    },
    idx,
    handleSubtitle,
  }) => {
    return (
      <section className={styles.wrap} key={id}>
        <div>
          <span className={styles.title}>썸네일 및 자막 입력 ({idx + 1})</span>
          <input
            id="img"
            name={id}
            placeholder="자막 썸네일을 업로드하세요"
            type="file"
            className={styles.input}
            onChange={handleSubtitle}
          />
        </div>
        <div>
          <span className={styles.title}>자막 제목</span>
          <input
            id="title"
            name={id}
            placeholder="자막 제목을 입력하세요"
            className={styles.input}
            value={title || ""}
            onChange={handleSubtitle}
          />
        </div>
        <div>
          <span className={styles.title}>레이어 이름</span>
          <input
            id="layerName"
            name={id}
            placeholder="레이어 명을 입력하세요"
            className={styles.input}
            value={layerName || ""}
            onChange={handleSubtitle}
          />
        </div>
        <div>
          <span className={styles.title}>기본자막</span>
          <textarea
            id="text"
            name={id}
            placeholder="기본 자막을 입력하세요"
            className={styles.input}
            value={text || ""}
            onChange={handleSubtitle}
          />
        </div>
        <div>
          <span className={styles.title}>폰트 설정</span>
          <div className={styles.inputWrap}>
            <label htmlFor="font">폰트</label>
            <select id="font" name={id} onChange={handleSubtitle}>
              <option>폰트를 선택하여 주십시요</option>
              <option value="Grandpa_sharing">
                나눔손글씨_할아버지의 나눔
              </option>
              <option value="Daughter_handwriting">
                나눔손글씨_우리 딸 손글씨
              </option>
              <option value="Hardworking">나눔손글씨_열일체</option>
              <option value="Cafe24Oneprettynight">카페24_고운밤</option>
              <option value="MapoFlowerIsland">마포꽃섬</option>
              <option value="MapoAgape">마포아가페</option>
            </select>
          </div>
          <div className={styles.inputWrap}>
            <label htmlFor="fontSize">크기</label>
            <input
              id="fontSize"
              name={id}
              placeholder="ex)100"
              type="number"
              value={fontSize || ""}
              onChange={handleSubtitle}
            />
            <label htmlFor="fontColor">색상</label>
            <input
              id="fontColor"
              name={id}
              placeholder="ex)#0000d9"
              type="text"
              value={fontColor || ""}
              onChange={handleSubtitle}
            />
          </div>
        </div>

        <div>
          <span className={styles.title}>그림자</span>
          <div className={styles.inputWrap}>
            <input
              id="shadow"
              name={id}
              value="미적용"
              type="button"
              className={`${styles.buttonInput} ${
                shadow === "미적용" ? styles.active : ""
              } `}
              onClick={handleSubtitle}
            />
            <input
              id="shadow"
              name={id}
              value="적용"
              type="button"
              className={`${styles.buttonInput} ${
                shadow === "적용" ? styles.active : ""
              } `}
              onClick={handleSubtitle}
            />
          </div>
        </div>
        <div>
          <span className={styles.title}>텍스트 정렬</span>
          <div className={styles.inputWrap}>
            <input
              id="align"
              name={id}
              value="left"
              type="button"
              className={`${styles.buttonInput} ${
                align === "left" ? styles.active : ""
              } `}
              onClick={handleSubtitle}
            />
            <input
              id="align"
              name={id}
              value="center"
              type="button"
              className={`${styles.buttonInput} ${
                align === "center" ? styles.active : ""
              } `}
              onClick={handleSubtitle}
            />
            <input
              id="align"
              name={id}
              value="right"
              type="button"
              className={`${styles.buttonInput} ${
                align === "right" ? styles.active : ""
              } `}
              onClick={handleSubtitle}
            />
          </div>
        </div>

        <div>
          <span className={styles.title}>X좌표 / Y좌표 </span>
          <div className={styles.inputWrap}>
            <label htmlFor="positionX">X좌표</label>
            <input
              id="positionX"
              name={id}
              placeholder="ex)1920"
              type="number"
              value={positionX || ""}
              onChange={handleSubtitle}
            />

            <label htmlFor="positionY">Y좌표</label>
            <input
              id="positionY"
              name={id}
              placeholder="ex)1920"
              type="number"
              value={positionY || ""}
              onChange={handleSubtitle}
            />
          </div>
        </div>

        <div>
          <span className={styles.title}>행간</span>
          <div className={styles.inputWrap}>
            {lineHeight && <span> {lineHeight}</span>}
            <input
              id="lineHeight"
              name={id}
              type="range"
              min="50"
              max="200"
              style={{ cursor: "pointer" }}
              onChange={handleSubtitle}
            />
          </div>
        </div>
      </section>
    );
  }
);

export default SubTitleSetting;

import React, { useEffect, useState } from "react";
import styles from "./Project.module.css";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectAdd from "@components/Admin/Project/ProjectAdd";
import ProjectAssetsAdd from "@components/Admin/Project/ProjectAssetsAdd";
import ProjectTable from "@components/Admin/Project/ProjectTable";

import { useApi } from "@context/ApiContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TemplateObj } from "@interfaces/admin";
import { AiOutlineSetting } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import ProjectCategoryAddModal from "@components/Admin/Project/ProjectCategoryAddModal";

import ProjectAddModal from "@components/Admin/Project/ProjectAddModal";
import AdminLoading from "@pages/Admin/AdminLoading";
interface ProjectProps {}

const Project: React.FC<ProjectProps> = ({}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const mode = searchParams.get("mode");
  const templateId = searchParams.get("id");
  const cate = searchParams.get("cate");

  const navigator = useNavigate();

  const { serverApi } = useApi();
  const { adminApi } = useApi();

  const [targetCategory, setTargetCategory] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] =
    useState<boolean>(false);

  const [targetTemplate, setTargetTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    isLoading,
    error,
    data: templates,
  } = useQuery<TemplateObj[], Error>(
    ["getTemplates"],
    () => {
      return serverApi.getTemplates();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: categorys } = useQuery(
    ["getTemplateCategorys"],
    () => adminApi.getTemplateCategorys(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateCategorys = () => {
    queryClient.invalidateQueries(["getTemplateCategorys"]);
  };
  const updateTemplates = () => {
    queryClient.invalidateQueries(["getTemplates"]);
  };

  if (isLoading || !templates || !categorys) {
    return <AdminLoading />;
  }

  if (mode === "assets_add") {
    return (
      <ProjectAssetsAdd
        template={templates.find((template) => template.id == templateId)}
      />
    );
  }

  if (mode === "update") {
    return (
      <ProjectAdd
        template={templates.find((template) => template.id == templateId)}
      />
    );
  }

  return (
    <div className={styles.wrap}>
      <ProjectAddModal
        categorys={categorys}
        template={targetTemplate}
        isOpen={isModalOpen}
        onClose={() => {
          setTargetTemplate(null);
          setIsModalOpen(false);
        }}
        updateTemplates={updateTemplates}
      />
      <ProjectCategoryAddModal
        category={targetCategory}
        isOpen={isCategoryModalOpen}
        onClose={() => {
          setTargetCategory(null);
          setIsCategoryModalOpen(false);
        }}
        updateCategorys={updateCategorys}
      />
      <div className={styles.categoryWrap}>
        <div className={styles.categorList}>
          <li
            className={`${styles.total} ${!cate ? styles.active : ""}`}
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.delete("cate");
              navigator(`?${queryParams.toString()}`);
            }}
          >
            전체
          </li>
          {categorys?.map((item: any) => (
            <li
              key={item.id}
              className={`${styles.total} ${
                cate === `${item.id}` ? styles.active : ""
              }`}
              onClick={() => {
                const queryParams = new URLSearchParams(location.search);
                queryParams.set("cate", item.id);
                navigator(`?${queryParams.toString()}`);
              }}
            >
              <span>{item?.template_category_name}</span>

              <div className={styles.iconWrap}>
                <AiOutlineSetting
                  onClick={() => {
                    setTargetCategory(item);
                    setIsCategoryModalOpen(true);
                  }}
                />
                <MdDeleteOutline
                  onClick={() => {
                    if (
                      window.confirm(
                        `${item?.template_category_name} 을 정말 삭제하시겠습니까?`
                      )
                    ) {
                      adminApi
                        .saveOrUpdateTemplateCategory({
                          category: {
                            id: parseInt(item.id),
                          },
                          state: "delete",
                        })
                        .then(() => {
                          alert("삭제되었습니다");
                          updateCategorys();
                        });
                    }
                  }}
                />
              </div>
            </li>
          ))}
        </div>
        <div
          className={styles.setting}
          onClick={() => {
            setIsCategoryModalOpen(true);
          }}
        >
          새 카테고리 만들기
        </div>
      </div>
      <div className={styles.mainWrap}>
        <PageHeader
          title="프로젝트 업로드"
          desc="프로젝트를 추가하여 새로운 템플릿을 만들 수 있습니다"
          addButton="새 프로젝트"
          handleAddButton={() => {
            setIsModalOpen(true);
          }}
        />

        <main>
          <ProjectTable
            templates={
              cate
                ? templates.filter(
                    (temp: any) => temp.TemplateCategoryId === parseInt(cate)
                  )
                : templates
            }
            updateTemplates={updateTemplates}
            setTargetTemplate={setTargetTemplate}
            setIsModalOpen={setIsModalOpen}
          />
        </main>
      </div>
    </div>
  );
};

export default Project;

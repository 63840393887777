import React, { useEffect, useState } from "react";
import styles from "./ServiceInfoInputModal.module.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

import userIcon from "@assets/images/service/user-icon.svg";
import closeIcon from "@assets/images/service/close-icon.svg";
import checkBlue from "@assets/images/service/check-icon-blue.svg";
import checkGrey from "@assets/images/service/check-icon-grey.svg";
import { AgreeState } from "@interfaces/service";
import { useApi } from "@context/ApiContext";

interface ServiceInfoInputModalProps {
  event: any;
  isOpen: boolean;
  onClose: () => void;
  registEventMember: (eventUser: any) => void;
  agreeState: any;
  setAgreeState: React.Dispatch<React.SetStateAction<AgreeState>>;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "500px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "var(--dark-light-bg-color)",
    overflow: "hidden",
    borderRadius: "12px",
    border: "none",
  },
  overlay: {
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ServiceInfoInputModal: React.FC<ServiceInfoInputModalProps> = ({
  event,
  isOpen,
  onClose,
  registEventMember,
  agreeState,
  setAgreeState,
}) => {
  const { serviceApi } = useApi();
  const [eventUser, setEventUser] = useState<{
    id?: number;
    name: string;
    phone_number: string;
  }>({
    name: "",
    phone_number: "",
  });
  const [addFlag, setAddFlag] = useState(false);

  const navigator = useNavigate();

  useEffect(() => {
    setAddFlag(false);

    // 이미 eventUser가 있다면 첫번째 user를 Set
    if (event?.EventUsers?.length > 0) {
      setEventUser(event?.EventUsers[0]);
    }
  }, [isOpen]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    if (id === "phone_number") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setEventUser((prev) => ({ ...prev, [id]: sanitizedValue }));
    } else {
      setEventUser((prev) => ({ ...prev, [id]: value }));
    }
  };

  if (event?.agree_flag && event?.EventUsers?.length > 0 && !addFlag) {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        // onRequestClose={onClose}
        style={customModalStyles}
      >
        <div className={styles.iconWrap}>
          <img className={styles.userIcon} src={userIcon} alt="" />
          <img src={closeIcon} alt="" onClick={onClose} />
        </div>
        <h3 className={styles.title}>등록된 상주가 있습니다</h3>
        <p className={styles.desc}>
          알림을 받으실 번호를 추가하거나, <br />
          기존 등록된 상주로 제작을 계속 할 수 있습니다
        </p>
        <h4 className={styles.subtitle}>상주 목록</h4>
        {event?.EventUsers.map((user: any, idx: number) => (
          <div
            key={idx}
            className={styles.checkButtonWrap}
            onClick={() => {
              setEventUser(user);
            }}
          >
            <button
              className={`${styles.checkButton} ${
                eventUser.name === user.name && styles.active
              }`}
            ></button>
            <p key={idx} className={styles.desc}>
              {user.name}
            </p>
          </div>
        ))}

        <div className={styles.buttonWrap}>
          <button
            onClick={() => {
              serviceApi.selectedEventUser(event.uid, eventUser.id);
              navigator(
                `/service/info-check?uid=${event.uid}&userId=${eventUser.id}`
              );
            }}
          >
            선택한 상주로 계속하기
          </button>
          <button
            className={styles.cancel}
            onClick={() => {
              setAddFlag(true);
              setEventUser({
                name: "",
                phone_number: "",
              });
            }}
          >
            새로운 번호 추가하기
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} style={customModalStyles}>
      <div className={styles.iconWrap}>
        <img className={styles.userIcon} src={userIcon} alt="" />
        <img src={closeIcon} alt="" onClick={onClose} />
      </div>
      <h3 className={styles.title}>정보를 등록해주세요</h3>
      <p className={styles.desc}>
        추모영상 제작완료 알림과 <br />
        발인 후 수정 안내에 이용됩니다.
      </p>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="name">이름</label>
          <input
            id="name"
            type="text"
            placeholder="상주명을 입력하세요"
            value={eventUser.name}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="phone_number">휴대폰 번호</label>
          <input
            id="phone_number"
            type="text"
            placeholder="-를 제외하고 입력해주세요"
            value={eventUser.phone_number}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div>
        <div className="agreement-contents">
          <div
            className="all-agreement"
            onClick={() => {
              setAgreeState((prev) => {
                if (
                  !prev.serviceAgree ||
                  !prev.privacyAgree ||
                  !prev.marketingAgree
                ) {
                  return {
                    serviceAgree: true,
                    privacyAgree: true,
                    marketingAgree: true,
                  };
                }
                return {
                  serviceAgree: false,
                  privacyAgree: false,
                  marketingAgree: false,
                };
              });
            }}
          >
            <button
              className={`${
                agreeState.serviceAgree &&
                agreeState.privacyAgree &&
                agreeState.marketingAgree &&
                "active"
              }`}
            ></button>
            <div>전체 동의하기</div>
          </div>
          <div className="part-agreements">
            <div
              onClick={() => {
                setAgreeState((prev) => ({
                  ...prev,
                  serviceAgree: !prev.serviceAgree,
                }));
              }}
            >
              <button className="part-agreement">
                {agreeState.serviceAgree ? (
                  <img src={checkBlue} alt="" />
                ) : (
                  <img src={checkGrey} alt="" />
                )}
              </button>
              <div>서비스 이용약관 동의(필수)</div>
              <button></button>
            </div>
            <div
              onClick={() => {
                setAgreeState((prev) => ({
                  ...prev,
                  privacyAgree: !prev.privacyAgree,
                }));
              }}
            >
              <button className="part-agreement">
                {agreeState.privacyAgree ? (
                  <img src={checkBlue} alt="" />
                ) : (
                  <img src={checkGrey} alt="" />
                )}
              </button>
              <div>개인정보 수집 및 이용 동의(필수)</div>
              <button></button>
            </div>
            <div
              onClick={() => {
                setAgreeState((prev) => ({
                  ...prev,
                  marketingAgree: !prev.marketingAgree,
                }));
              }}
            >
              <button className="part-agreement">
                {agreeState.marketingAgree ? (
                  <img src={checkBlue} alt="" />
                ) : (
                  <img src={checkGrey} alt="" />
                )}
              </button>
              <div>SMS 마케팅 정보 수신 동의(선택)</div>
              <button></button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttonWrap}>
        <button
          onClick={() => {
            registEventMember(eventUser);
          }}
        >
          등록하기
        </button>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
      </div>
    </Modal>
  );
};

export default ServiceInfoInputModal;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface AdminProps {}

const Admin: React.FC<AdminProps> = ({}) => {
  const navigator = useNavigate();
  useEffect(() => {
    navigator("admin/dashboard/start");
  }, []);

  return <div>admin</div>;
};

export default Admin;

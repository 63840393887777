import React, { useEffect, useState } from "react";
import styles from "./ShopCategoryAddModal.module.css";
import Modal from "react-modal";

import { useApi } from "@context/ApiContext";
import { useNavigate } from "react-router-dom";

interface ShopCategoryAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateShopCategorys: () => void;
  shopCategory?: any;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ShopCategoryAddModal: React.FC<ShopCategoryAddModalProps> = ({
  isOpen,
  onClose,
  updateShopCategorys,
  shopCategory,
}) => {
  const { adminApi } = useApi();

  const [category, setCategory] = useState({
    shop_category_name: "",
  });
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setCategory((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (shopCategory) {
      setCategory(shopCategory);
    } else {
      setCategory({
        shop_category_name: "",
      });
    }
  }, [shopCategory]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>
        {shopCategory ? "카테고리 수정" : "카테고리 등록"}
      </h3>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="shop_category_name">카테고리명</label>
          <input
            id="shop_category_name"
            type="text"
            placeholder="카테고리 명을 입력하세요 예)USB"
            value={category.shop_category_name}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            adminApi
              .saveOrUpdateShopCategory({
                category: {
                  ...category,
                  shop_category_name: category.shop_category_name,
                },
                state: shopCategory ? "update" : "create",
              })
              .then((res: any) => {
                if (!res) return;

                alert("완료되었습니다");
                updateShopCategorys();
                onClose();
              });
          }}
        >
          {shopCategory ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default ShopCategoryAddModal;

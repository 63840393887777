import React, { useEffect, useState } from "react";
import styles from "./Template.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import TemplateMaking from "@components/Admin/Templates/TemplateMaking";
import { TemplateObj } from "@interfaces/admin";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";

interface TemplateProps {}

const Template: React.FC<TemplateProps> = ({}) => {
  const location = useLocation();
  const { templateId } = useParams();
  const navigator = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const mode = searchParams.get("mode");
  const { serverApi } = useApi();

  const {
    isLoading,
    error,
    data: template,
  } = useQuery<TemplateObj, Error>(
    ["templateId"],
    () => {
      return serverApi.getTemplate(templateId);
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || !template) {
    return <div>loading....</div>;
  }

  if (mode === "making") {
    return <TemplateMaking template={template} />;
  }

  return (
    <div>
      <PageHeader
        title={template.name}
        addButton="홈으로"
        handleAddButton={() => {
          navigator("/admin/dashboard/templates");
        }}
      />
      <main className={styles.mainWarp}>
        <div className={styles.infoWrap}>
          <div>
            <span>이름</span>
            <span>{template.name}</span>
          </div>
          <div>
            <span>유형</span>
            <span>{template.type}</span>
          </div>
          <div>
            <span>사진 장 수</span>
            <span>{template.photo_length}</span>
          </div>
          <div>
            <span>프로젝트</span>
            <span>{template.s3_url}</span>
          </div>

          <Link className={styles.makeButton} to="?mode=making">
            제작하기
          </Link>
        </div>
        <div className={styles.thumImg}>
          <img src={template.thum.url} alt="thum" />
        </div>
      </main>
    </div>
  );
};

export default Template;

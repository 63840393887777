import MainFooter from "@components/Home/MainFooter";
import styles from "./VideoSample.module.css";
import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import ServiceTemplateCard from "@components/Service/Templates/ServiceTemplateCard";
import Error from "@components/Service/Ui/Error";
import Loading from "@components/Service/Ui/Loading";
import DataLoading from "@components/Ui/Loading/DataLoading";
import { useApi } from "@context/ApiContext";
import { TemplateObj } from "@interfaces/admin";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface VideoSampleProps {
  templates: TemplateObj[] | undefined;
  categorys: any[];
  isLoading: boolean;
}

const VideoSample: React.FC<VideoSampleProps> = ({
  templates,
  categorys,
  isLoading,
}) => {
  // const { templateApi, serviceApi } = useApi();

  const navigator = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const cate = searchParams.get("cate");

  useEffect(() => {
    if (!cate && categorys && categorys.length > 0) {
      const firstCategory = categorys[0].id;
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("cate", firstCategory);
      navigator(`?${queryParams.toString()}`);
    }
  }, [categorys]);

  const handleCheckTemplate = async (templateId: any) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("templateId", templateId);
    navigator(`/shops/detail?${queryParams.toString()}`);
    return;
  };

  return (
    <div className={styles.serviceTemplateCheck}>
      {isLoading && <DataLoading />}

      <main>
        <h2>추모영상 미리보기</h2>
        <nav className={styles.typeNav}>
          <ul>
            {categorys?.map((item: any) => (
              <li
                key={item.id}
                className={item.id == cate ? styles.active : ""}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("cate", item.id);
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                {item?.template_category_name}
              </li>
            ))}
          </ul>
        </nav>

        <section className={styles.templatesSection}>
          {cate
            ? templates
                ?.filter(
                  (temp: any) => temp.TemplateCategoryId === parseInt(cate)
                )
                .map((template) => (
                  <ServiceTemplateCard template={template} key={template.id} />
                ))
            : templates?.map((template) => (
                <ServiceTemplateCard template={template} key={template.id} />
              ))}
        </section>
      </main>
    </div>
  );
};

export default VideoSample;

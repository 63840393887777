import React, { useState } from "react";
import styles from "./QueueSettingInputModal.module.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

interface QueueSettingInputModalProps {
  event: any;
  isOpen: boolean;
  onClose: () => void;
  startQueue: (eventUser: any) => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "500px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const QueueSettingInputModal: React.FC<QueueSettingInputModalProps> = ({
  event,
  isOpen,
  onClose,
  startQueue,
}) => {
  const [setting, setSetting] = useState({
    composition: "Render",
    outputExt: "mp4",
    outputModule: "H.264 - Match Render Settings - 15 Mbps",
    output: `D:\\memorialone\\premium\\final\\${event?.dm_name}님의 추모영상.mp4`,
    storage: "LOCAL",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSetting((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      // onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>랜더링 세팅</h3>

      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="composition">composition</label>
          <input
            id="composition"
            type="text"
            placeholder="composition명을 입력하세요"
            value={setting.composition}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="outputExt">outputExt</label>
          <input
            id="outputExt"
            type="text"
            placeholder="outputExt를 입력하세요"
            value={setting.outputExt}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="outputModule">outputModule</label>
          <input
            id="outputModule"
            type="text"
            placeholder="outputModule를 입력하세요"
            value={setting.outputModule}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="output">output</label>
          <input
            id="output"
            type="text"
            placeholder="output를 입력하세요"
            value={setting.output}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.storageButtonWrap}>
          <button
            className={setting.storage === "LOCAL" ? styles.active : ""}
            onClick={() => {
              setSetting((prev) => ({ ...prev, storage: "LOCAL" }));
            }}
          >
            LOCAL
          </button>
          <button
            className={setting.storage === "S3" ? styles.active : ""}
            onClick={() => {
              setSetting((prev) => ({ ...prev, storage: "S3" }));
            }}
          >
            S3
          </button>
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            startQueue(setting);
          }}
        >
          제작하기
        </button>
      </div>
    </Modal>
  );
};

export default QueueSettingInputModal;

import React, { useEffect, useState } from "react";
import styles from "./DeliveryInfo.module.css";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";
import { useNavigate } from "react-router-dom";
import searchIcon from "@assets/images/service/Search.svg";
import { FaArrowLeftLong, FaChevronDown } from "react-icons/fa6";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import Header from "@components/Admin/Header/Header";

interface DeliveryInfoProps {}

const DeliveryInfo: React.FC<DeliveryInfoProps> = ({}) => {
  const location = useLocation();
  const { serviceApi } = useApi();
  const navigator = useNavigate();

  const uid = new URLSearchParams(location.search).get("uid");

  const [order, setOrder] = useState<any>({
    receive_name: "",
    receive_postcode: "",
    receive_addr: "",
    receive_addr_detail: "",
    receive_tel: "",

    deli_memo: "",
  });

  const [customDeliMemo, setCustomDeliMemo] = useState("");

  const {
    isLoading,
    error,
    data: event,
  } = useQuery(["event"], () => serviceApi.getEvent(uid), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    refetchInterval: false,
  });

  useEffect(() => {
    setOrder((prev: any) => ({
      ...prev,
      receive_name: event?.cm_name || "",
      receive_tel: event?.cm_phone || "",
    }));
  }, [event]);

  const handleDelivery = async () => {
    const updateOrder = validateInput();
    if (!updateOrder) {
      return;
    }

    serviceApi
      .updateEventOrder({
        uid,
        order: updateOrder,
      })
      .then((res: any) => {
        console.log(res);
        if (res) {
          alert("배송정보가 수정되었습니다.");
          navigator(`/service/info-check?uid=${uid}`);
        }
      });
  };

  const validateInput = () => {
    const {
      receive_name,
      receive_postcode,
      receive_addr,
      receive_addr_detail,
      receive_tel,
    } = order;

    if (!receive_name?.trim()) {
      alert("주문자를 입력해 주세요.");
      return false;
    }

    if (!receive_postcode) {
      alert("우편번호를 입력하여 주십시오");
      return false;
    }
    if (!receive_name || !receive_addr.trim()) {
      alert("배송지를 입력하여 주십시오");
      return false;
    }

    if (!receive_name || !receive_addr_detail.trim()) {
      alert("상세주소를 입력하여 주십시오");
      return false;
    }

    if (!receive_tel?.trim()) {
      alert("휴대폰 번호를 입력하여 주십시오");
      return false;
    }

    // 개인정보 동의

    const updateOrder = {
      ...order,
      receive_tel,
      buyer_name: receive_name,
      buyer_tel: receive_tel,
    };

    // 배송 메모가 직접입력인 경우
    if (customDeliMemo.trim()) {
      updateOrder.deli_memo = customDeliMemo;
    }

    setOrder(updateOrder);
    return updateOrder;
  };

  const handleBackRouting = () => {
    if (
      window.confirm(
        "페이지를 벗어나면 입력된 주문 정보가 사라집니다. 계속하시겠습니까?"
      )
    ) {
      const queryParams = new URLSearchParams(location.search);
      navigator(`/service/new-event?event_no=${queryParams.get("uid")}`, {
        replace: true,
      });

      return;
    }
  };

  // 우편번호 검색 클릭 시
  const handleOpenPostCode = async () => {
    new (window as any).daum.Postcode({
      oncomplete: async (data: any) => {
        const mainAddress = data.roadAddress || data.jibunAddress;

        setOrder((prev: any) => ({
          ...prev,

          receive_postcode: data?.zonecode,
          receive_addr: mainAddress,
        }));
      },
    }).open();
  };

  return (
    <div className={styles.deliveryInfoPage}>
      {/* 배송정보 */}
      <section className={styles.orderSection}>
        <div className={styles.orderTitleWrap}>
          <h4>배송정보를 입력해주세요.</h4>
          <p>
            추모영상 확정 이후
            <br />
            USB를 받으실 정보를 입력해주세요.
          </p>
          {/* <FaChevronDown /> */}
        </div>
        <div className={styles.orderDeilInputWraps}>
          {/* 주문자명 */}
          <div className={styles.orderDeilInputWrap}>
            <label className={styles.required} htmlFor="receive_name">
              주문자명
            </label>
            <input
              className={styles.reciveName}
              id="receive_name"
              type="text"
              placeholder="주문자명을 입력하세요"
              value={order.receive_name}
              onChange={(e) =>
                setOrder({ ...order, receive_name: e.target.value })
              }
            />
          </div>

          {/* 연락처 */}
          <div className={styles.orderDeilInputWrap}>
            <label className={styles.required} htmlFor="receive_tel">
              연락처1
            </label>
            <div>
              <input
                className={styles.receivePhone}
                placeholder="-를 제외하고 입력해주세요"
                id="receive_tel"
                type="tel"
                value={order.receive_tel}
                onChange={(e) =>
                  setOrder({ ...order, receive_tel: e.target.value })
                }
              />
            </div>
          </div>

          {/* 배송지 */}
          <div className={styles.orderDeilInputWrap}>
            <label className={styles.required} htmlFor="receive_postcode">
              배송지
            </label>
            <div className={styles.receivePostcodeWrap}>
              <div>
                <input
                  className={styles.receivePostcode}
                  placeholder="우편번호"
                  id="receive_postcode"
                  type="text"
                  readOnly
                  value={order.receive_postcode}
                  onClick={handleOpenPostCode}
                />
                <button
                  className={styles.postcodeBtn}
                  onClick={handleOpenPostCode}
                >
                  <img src={searchIcon} alt="검색" />
                  <span>검색</span>
                </button>
              </div>
              <div>
                {/* <label htmlFor="receive_addr"></label> */}
                <input
                  className={styles.receiveAddr}
                  placeholder="주소"
                  id="receive_addr"
                  type="text"
                  value={order.receive_addr}
                  onChange={(e) =>
                    setOrder({ ...order, receive_addr: e.target.value })
                  }
                />
              </div>
              {/* 상세주소 */}
              <div>
                {/* <label htmlFor="receive_addr_detail"></label> */}

                <input
                  className={styles.receiveAddrDetail}
                  id="receive_addr_detail"
                  type="text"
                  placeholder="상세주소"
                  value={order.receive_addr_detail}
                  onChange={(e) =>
                    setOrder({ ...order, receive_addr_detail: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          {/* 배송시 요청사항 */}
          <div className={styles.orderDeilSelectWrap}>
            <select
              className={styles.deliMemo}
              id="deli_memo"
              value={order.deli_memo}
              onChange={(e) =>
                setOrder({ ...order, deli_memo: e.target.value })
              }
            >
              <option value="">배송시 요청사항을 선택해 주세요</option>
              <option value="문 앞에 놔주세요">문 앞에 놔주세요</option>
              <option value="경비실에 맡겨주세요">경비실에 맡겨주세요</option>
              <option value="custom">직접입력</option>
            </select>
          </div>

          {/* 배송시 요청사항 직접입력 */}
          {order.deli_memo === "custom" && (
            <div className={styles.orderDeilInputWrap}>
              <input
                type="text"
                placeholder="요청사항을 입력하세요"
                value={customDeliMemo}
                onChange={(e) => setCustomDeliMemo(e.target.value)}
              />
            </div>
          )}
        </div>
      </section>

      <div className={styles.orderBottomBtn}>
        <button onClick={handleDelivery}>입력 완료</button>
      </div>
    </div>
  );
};

export default DeliveryInfo;

import { addDays } from "date-fns";
import { ko } from "date-fns/locale";
import React, { useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AiOutlineCalendar } from "react-icons/ai";
import styles from "./DefaultCalendar.module.css";

interface DefaultCalendarProps {
  dateRange: Range[];
  handleDateRangeChanged: (ranges: RangeKeyDict) => void;
}

const DefaultCalendar: React.FC<DefaultCalendarProps> = ({
  dateRange,
  handleDateRangeChanged,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const handleCalendarShow = () => {
    setShowCalendar((current) => !current);
  };

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}년 ${
      date.getMonth() + 1
    }월 ${date.getDate()}일`;
  };

  const selectStartDay = dateRange[0].startDate
    ? formatDate(dateRange[0].startDate)
    : "시작 날짜 없음";
  const selectEndDay = dateRange[0].endDate
    ? formatDate(dateRange[0].endDate)
    : "종료 날짜 없음";
  return (
    <div className={styles.calendarWrap}>
      <button className={styles.previewButton} onClick={handleCalendarShow}>
        <span>
          {selectStartDay} ~ {selectEndDay}
        </span>
        <AiOutlineCalendar className={styles.calendarIcon} />
      </button>
      <div className={styles.datePickerWrap}>
        {showCalendar && (
          // form submit시 자동으로 열리지 않게
          <DateRangePicker
            locale={ko}
            onChange={handleDateRangeChanged}
            ranges={dateRange}
            months={1}
            direction="vertical"
            dateDisplayFormat={"yyyy-MM-dd"}
            onRangeFocusChange={(ranges) => {
              if (ranges.some((range) => range === 0)) {
                setShowCalendar(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultCalendar;

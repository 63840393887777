import React from "react";
import styles from "./KakaoInquiryButton.module.css";
import { Link } from "react-router-dom";
import kakao from "@assets/images/service/kakaoLogo.svg";

interface KakaoInquiryButtonProps {
  style?: React.CSSProperties;
}

const KakaoInquiryButton: React.FC<KakaoInquiryButtonProps> = ({ style }) => {
  return (
    <div className={styles.buttonWrap} style={style}>
      <button
        onClick={() => {
          window.open("http://pf.kakao.com/_tdxjxmK/chat", "_blank");
        }}
      >
        <img src={kakao} alt="kakao" />
        카카오톡으로 문의하기
      </button>
    </div>
  );
};

export default KakaoInquiryButton;

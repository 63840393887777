import React from "react";
import ReactDOM from "react-dom/client";
import "@assets/css/style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { WindowSizeProvider } from "@context/windowSizeContext";
import NotFound from "@pages/NotFound";
import Home from "@pages/Home";
import Serivce from "@pages/Service/Serivce";
import Uploads from "@pages/Service/Uploads";
import Complete from "@pages/Service/Complete";
import Admin from "@pages/Admin/Admin";
import Login from "@pages/Admin/Login";

import DashboardStart from "@pages/Admin/DashBoard/Start/DashboardStart";

import Template from "@pages/Admin/DashBoard/Template/Template";
import Project from "@pages/Admin/DashBoard/Project/Project";
import Queues from "@pages/Admin/DashBoard/Queue/Queues";
import Queue from "@pages/Admin/DashBoard/Queue/Queue";
import MemberList from "@pages/Admin/DashBoard/Member/MemberList";

import NewEvent from "@pages/Service/NewEvent";
import InfoCheck from "@pages/Service/InfoCheck";

import TemplateCheck from "@pages/Service/TemplateCheck";
import Templates from "@pages/Admin/DashBoard/Template/Templates";
import TextUploads from "@pages/Service/TextUploads";
import Events from "@pages/Admin/DashBoard/Event/Events";
import Event from "@pages/Admin/DashBoard/Event/Event";
import ImageEdit from "@pages/Service/ImageEdit";
import ProtectedRoute from "@pages/Admin/ProtectedRoute";
import OrderList from "@pages/Admin/DashBoard/Order/OrderList";
import FuneralList from "@pages/Admin/DashBoard/Funeral/FuneralList";
import ShopList from "@pages/Admin/DashBoard/Shop/ShopList";
import AdminCals from "@pages/Admin/DashBoard/Cal/AdminCals";
import ShopOrder from "@pages/Shop/ShopOrder";
import ShopDetail from "@pages/Shop/ShopDetail";
import PremiumPage from "@pages/Shop/PremiumPage";
import Shops from "@pages/Shop/Shops";
import AdminCalDetail from "@pages/Admin/DashBoard/Cal/AdminCalDetail";
import OrderCals from "@pages/Admin/DashBoard/Order/OrderCals";
import SampleVideos from "@pages/Shop/SampleVideos";

import ShopOrderComplete from "@pages/Shop/ShopOrderComplete";
import ChangeState from "@pages/Service/ChangeState";
import Delivery from "@pages/Admin/DashBoard/Order/Delivery";
import AdminContacts from "@pages/Admin/DashBoard/Contact/AdminContacts";
import AllFeedbacks from "@pages/Service/AllFeedbacks";
import Feedbacks from "@pages/Admin/DashBoard/Feedback/Feedbacks";
import DeliveryInfo from "@pages/Service/DeliveryInfo";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <Home /> }],
  },
  {
    path: "admin",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      // { index: true, element: <Admin /> },
      { path: "login", element: <Login /> },
      {
        path: "",
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        ),
      },
      {
        // 대시보드
        path: `dashboard/start`,
        element: (
          <ProtectedRoute>
            <DashboardStart />
          </ProtectedRoute>
        ),
      },
      {
        // 영상 제작
        path: `dashboard/templates`,
        element: (
          <ProtectedRoute>
            <Templates />
          </ProtectedRoute>
        ),
      },
      {
        // 영상 제작 상세
        path: `dashboard/templates/:templateId`,
        element: (
          <ProtectedRoute>
            <Template />
          </ProtectedRoute>
        ),
      },
      {
        // 프로젝트 관리
        path: `dashboard/project`,
        element: (
          <ProtectedRoute>
            <Project />
          </ProtectedRoute>
        ),
      },
      {
        // 렌더링 관리
        path: `dashboard/queues`,
        element: (
          <ProtectedRoute>
            <Queues />
          </ProtectedRoute>
        ),
      },
      {
        // 렌더링 관리 상세
        path: `dashboard/queues/:queueId`,
        element: (
          <ProtectedRoute>
            <Queue />
          </ProtectedRoute>
        ),
      },
      {
        // 추모영상 행사 관리
        path: `dashboard/events`,
        element: (
          <ProtectedRoute>
            <Events />
          </ProtectedRoute>
        ),
      },
      {
        // 추모영상 행사 상세
        path: `dashboard/events/:event_no`,
        element: (
          <ProtectedRoute>
            <Event />
          </ProtectedRoute>
        ),
      },
      {
        // 후기 관리
        path: `dashboard/feedback`,
        element: (
          <ProtectedRoute>
            <Feedbacks />
          </ProtectedRoute>
        ),
      },
      {
        // 전체 주문 관리
        path: `dashboard/orders`,
        element: (
          <ProtectedRoute>
            <OrderList />
          </ProtectedRoute>
        ),
      },
      {
        // 상품 관리비 관리
        path: `dashboard/delivery`,
        element: (
          <ProtectedRoute>
            <Delivery />
          </ProtectedRoute>
        ),
      },
      {
        // 상품 관리비 관리
        path: `dashboard/order-cals`,
        element: (
          <ProtectedRoute>
            <OrderCals />
          </ProtectedRoute>
        ),
      },

      {
        // 정산 관리
        path: `dashboard/cals`,
        element: (
          <ProtectedRoute>
            <AdminCals />
          </ProtectedRoute>
        ),
      },
      {
        // 정산 관리 - 상세
        path: `dashboard/cals/:FUNERAL_NO`,
        element: (
          <ProtectedRoute>
            <AdminCalDetail />
          </ProtectedRoute>
        ),
      },
      {
        // 장례식장 관리
        path: `dashboard/funerals`,
        element: (
          <ProtectedRoute>
            <FuneralList />
          </ProtectedRoute>
        ),
      },
      {
        // 상품 관리
        path: `dashboard/shops`,
        element: (
          <ProtectedRoute>
            <ShopList />
          </ProtectedRoute>
        ),
      },

      {
        // 제휴 문의
        path: `dashboard/contact`,
        element: (
          <ProtectedRoute>
            <AdminContacts />
          </ProtectedRoute>
        ),
      },

      {
        // 사용자 목록
        path: `dashboard/member/list`,
        element: (
          <ProtectedRoute>
            <MemberList />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "service",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Serivce /> },
      { path: "new-event", element: <NewEvent /> },
      { path: "delivery-info", element: <DeliveryInfo /> },
      { path: "info-check", element: <InfoCheck /> },
      { path: "template-check", element: <TemplateCheck /> },
      { path: "uploads", element: <Uploads /> },
      { path: "text-uploads", element: <TextUploads /> },
      { path: "change-state", element: <ChangeState /> },
      { path: "complete", element: <Complete /> },
      { path: "image-edit", element: <ImageEdit /> },
      { path: "all-feedbacks", element: <AllFeedbacks /> },
    ],
  },
  {
    path: "shops",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Shops />,
      },
      { path: "videos", element: <SampleVideos /> },
      { path: "premium", element: <PremiumPage /> },
      { path: "detail", element: <ShopDetail /> },
      { path: "order", element: <ShopOrder /> },
      { path: "complete", element: <ShopOrderComplete /> },
    ],
  },
]);

root.render(
  <WindowSizeProvider>
    <RouterProvider router={router} />
  </WindowSizeProvider>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

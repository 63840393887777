import React from "react";

interface ServiceMainButtonProps {
  title: string;
  isDisable?: boolean;
  handleClick: () => void;
}

const ServiceMainButton: React.FC<ServiceMainButtonProps> = ({
  title,
  isDisable,
  handleClick,
}) => {
  return (
    <button
      className={`service-main_btn ${isDisable && "disable"}`}
      onClick={handleClick}
    >
      <h6 className="head-title medium">{title}</h6>
    </button>
  );
};

export default ServiceMainButton;

import React from "react";
import styles from "./TitleCountCard.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

interface TitleCountCardProps {
  title: string;
  count: number;
}

const TitleCountCard: React.FC<TitleCountCardProps> = ({ title, count }) => {
  const getTagStyle = (state: string) => {
    switch (state) {
      case "제작예정":
      case "대기중":
        return searchStyles.blueTag;
      case "업로드중":
      case "시작":
        return searchStyles.yellowTag;
      case "업로드완료":
      case "렌더링":
        return searchStyles.indigoTag;
      case "제작완료":
      case "렌더완료":
        return searchStyles.greenTag;
      default:
        return searchStyles.redTag;
    }
  };

  return (
    <div className={styles.cardWrap}>
      <h6>{title}</h6>
      <div className={styles.countWrap}>
        <span className={getTagStyle(title)}>{count}</span>
      </div>
    </div>
  );
};

export default TitleCountCard;

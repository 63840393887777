import { jobAPI } from "./job"; // job 모듈을 import 합니다.
type ClientOptions = {
  host: string;
  secret?: string | undefined;
  polling?: number;
  headers?: Record<string, string | (() => Promise<string>)>;
};

const createClient = ({ host, secret, polling, headers }: ClientOptions) => {
  const wrappedFetch = async (path: string, options?: RequestInit) => {
    options = options || {};
    const defaultHeaders: Record<string, string> = {};

    if (headers) {
      for (const [key, value] of Object.entries(headers)) {
        if (typeof value === "string") {
          defaultHeaders[key] = value;
        } else if (typeof value === "function") {
          defaultHeaders[key] = await value();
        }
      }
    }

    options.headers = Object.assign(defaultHeaders, options.headers);

    if (secret) {
      (options.headers as Record<string, string>)["nexrender-secret"] = secret;
    }

    const response = await fetch(`${host}/api/v1${path}`, options);

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return await response.json();
  };

  return {
    secret,
    host,
    wrappedFetch,
    ...jobAPI(wrappedFetch, polling), // Assuming jobAPI is imported and correctly typed
  };
};

export default createClient;

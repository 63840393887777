import React, { useEffect, useRef, useState } from "react";
import styles from "./BottomOrderSection.module.css";
import NumberFormat from "@components/Ui/NumberFormat";
import { BiCaretDown, BiX } from "react-icons/bi";

interface BottomOrderSectionProps {
  templates: any;
  title: string;
  price: number;
  handlePurchase: (template: any) => void;
  handleDirectMakeVideo: (template: any) => void;
}

const BottomOrderSection: React.FC<BottomOrderSectionProps> = ({
  templates,
  title,
  price,
  handlePurchase,
  handleDirectMakeVideo,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBoxIsOpen, setSelectedBoxIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleOpen = () => {
    if (selectedTemplate) {
      if (price == 0) {
        handleDirectMakeVideo(selectedTemplate);
      } else {
        handlePurchase(selectedTemplate);
      }
    } else {
      if (isOpen) {
        alert("스타일을 선택해주세요");
      } else {
        setIsOpen(true);
      }
    }
  };

  if (price == 0) {
    return (
      <section className={styles.bottomOrderSection}>
        {isOpen && (
          <>
            <div
              className={styles.optionSelectWrap}
              onClick={() => setSelectedBoxIsOpen((prev) => !prev)}
            >
              <p>스타일 선택</p>
              <BiCaretDown />
            </div>
            {selectedBoxIsOpen && (
              <div className={styles.selectBox}>
                {templates.map((template: any) => (
                  <div
                    key={template.id}
                    onClick={() => {
                      setSelectedTemplate(template);
                      setSelectedBoxIsOpen(false);
                    }}
                  >
                    <p>{template.name}</p>
                  </div>
                ))}
              </div>
            )}

            {selectedTemplate && (
              <div className={styles.optionBoxWrap}>
                <p>{selectedTemplate.name}</p>
              </div>
            )}
          </>
        )}

        <div className={styles.infoWrap}>
          <button className={styles.makeBtn} onClick={handleOpen}>
            <span> 바로 제작하기 </span>
          </button>
        </div>
      </section>
    );
  }

  return (
    <>
      {isOpen && (
        <div
          className={styles.overlay}
          onClick={() => {
            setSelectedTemplate(null);
            setIsOpen(false);
          }}
        />
      )}
      <section className={styles.bottomOrderSection}>
        {isOpen && (
          <>
            <div
              className={styles.optionSelectWrap}
              onClick={() => setSelectedBoxIsOpen((prev) => !prev)}
            >
              <p>스타일 선택</p>
              <BiCaretDown />
            </div>

            {selectedBoxIsOpen && (
              <div className={styles.selectBox}>
                {templates.map((template: any) => (
                  <div
                    key={template.id}
                    onClick={() => {
                      setSelectedTemplate(template);
                      setSelectedBoxIsOpen(false);
                    }}
                  >
                    <p>{template.name}</p>
                  </div>
                ))}
              </div>
            )}

            {selectedTemplate && (
              <div className={styles.optionBoxWrap}>
                <p>{selectedTemplate.name}</p>
                <p className={styles.price}>
                  <NumberFormat number={price} />원
                </p>
              </div>
            )}
          </>
        )}

        <div className={styles.infoWrap}>
          <div>
            <p>{title}</p>
            <div className={styles.priceInfoWrap}>
              <span>20%</span>
              <p>
                <NumberFormat number={price} />원
              </p>
            </div>
          </div>

          <button className={styles.buyBtn} onClick={handleOpen}>
            <span> 구매하기 </span>
          </button>
        </div>
      </section>
    </>
  );
};

export default BottomOrderSection;

import React from "react";
import defaultLoading from "@assets/images/service/default-loading.gif";
import styles from "./AdminLoading.module.css";

interface AdminLoadingProps {}

const AdminLoading: React.FC<AdminLoadingProps> = ({}) => {
  return (
    <section className={styles.background}>
      <img src={defaultLoading} alt="default-loading" />
    </section>
  );
};

export default AdminLoading;

import React, { useEffect, useState } from "react";
import styles from "./FuneralList.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import { useLocation, useNavigate } from "react-router-dom";
import AdminFuneralTable from "@components/Admin/Funeral/AdminFuneralTable";
import AdminLoading from "@pages/Admin/AdminLoading";

interface FuneralListProps {}

const FuneralList: React.FC<FuneralListProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    FUNERAL_NAME: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    FUNERAL_NAME: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const initialFilter = {
      state: searchParams.get("state") || "",
      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      FUNERAL_NAME: searchParams.get("FUNERAL_NAME") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getFunerals", searchFilter],
    () => adminApi.getFunerals({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateFunerals = () => {
    queryClient.invalidateQueries(["getFunerals"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (searchInput.FUNERAL_NAME) {
      queryParams.append("FUNERAL_NAME", searchInput.FUNERAL_NAME);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader
        title="장례식장 관리"
        desc="장례식장 목록을 관리합니다. 지원율 설정 등 정산 비율 등을 수정할 수 있습니다"
      />

      <section className={styles.funcSection}>
        <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="FUNERAL_NAME">식장명</label>
              <input
                id="FUNERAL_NAME"
                value={searchInput.FUNERAL_NAME}
                type="text"
                placeholder="장례식장명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
        </SearchInput>
      </section>

      <main>
        <AdminFuneralTable
          funerals={data.funerals}
          totalPages={data?.totalPages || 0}
          location={location}
          updateFunerals={updateFunerals}
        />
      </main>
    </div>
  );
};

export default FuneralList;

import React, { useEffect, useRef, useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { ImageEditProps } from "@interfaces/service";
import editButton1 from "@assets/images/service/edit-button-icon_1.svg";
import editButton2 from "@assets/images/service/edit-button-icon_2.svg";
import editButton3 from "@assets/images/service/edit-button-icon_3.svg";
import editButton4 from "@assets/images/service/edit-button-icon_4.svg";
import editButton5 from "@assets/images/service/edit-button-icon_5.svg";
import rotateButton1 from "@assets/images/service/rotate-icon_1.svg";
import rotateButton2 from "@assets/images/service/rotate-icon_2.svg";
import rotateButton3 from "@assets/images/service/rotate-icon_3.svg";
import rotateButton4 from "@assets/images/service/rotate-icon_4.svg";
import ImageEditFunctionButton from "../../Ui/ImageEditFunctionButton";
import ImageEditRangeInput from "../../Ui/ImageEditRangeInput";

interface ServiceImageChangeProps {
  selectedImage: ImageEditProps | null;
  templateImageInfo: any;
  onImageSave: (exImgId: string, newImgSrc: Blob | File, state: string) => void;
  handleImageChange: (direction: string) => void;
  isReorderMode: boolean;
  handleReorderClick: () => void;
}

const ServiceImageChange: React.FC<ServiceImageChangeProps> = ({
  selectedImage,
  templateImageInfo,
  onImageSave,
  handleImageChange,
  isReorderMode,
  handleReorderClick,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);

  const [updateRotate, setUpdateRotate] = useState<number>(0); // 회전 각도 상태
  const [useBackground, setUseBackground] = useState(false); // 배경 사용 여부

  const [newImage, setNewImage] = useState<File | null>(null);
  const [editStatus, setEditStatus] = useState({
    id: "1",
    title: "자르기",
  });

  const [buttonList, setButtonList] = useState([
    {
      id: "1",
      image: editButton2,
      title: "자르기",
      isChecked: true,
      click: () => handleClickEditButton({ id: "1", title: "자르기" }),
    },
    {
      id: "2",
      image: editButton1,
      title: "회전",
      isChecked: false,
      click: () => handleClickEditButton({ id: "2", title: "회전" }),
    },

    {
      id: "3",
      image: editButton3,
      title: "밝기",
      isChecked: false,
      click: () => handleClickEditButton({ id: "3", title: "밝기" }),
    },
    {
      id: "4",
      image: editButton4,
      title: "사진변경",
      isChecked: false,
      click: () => {
        handleClickEditButton({ id: "4", title: "사진변경" });
        if (!fileRef.current) return;

        fileRef.current.click();
      },
    },
  ]);

  const [rotateButtonList] = useState([
    {
      id: "1-1",
      image: rotateButton1,
      title: "90 회전",
      click: () => handleRotate(90),
    },
    {
      id: "1-2",
      image: rotateButton2,
      title: "-90 회전",
      click: () => handleRotate(-90),
    },
    {
      id: "1-3",
      image: rotateButton3,
      title: "좌우반전",
      click: () => handleFlipHorizontal(),
    },
    {
      id: "1-4",
      image: rotateButton4,
      title: "상하반전",
      click: () => handleFlipVertical(),
    },
  ]);

  const processBackgroundImage = (imageSrc: string) => {
    // 블러 처리된 배경 이미지 생성 로직
    // 예: Cloudinary URL을 사용하여 블러 처리된 이미지 URL 생성
    return "https://res.cloudinary.com/deffsolgu/image/upload/c_fill,w_1920,h_1080,e_blur:2000/e_saturation:-50/e_brightness:-20/v1706770113/events/50013_%EA%B3%A0%EC%9D%B8%EB%AA%85/pjjimnrgi5kqroax6b5n.jpg";
  };

  const handleClickEditButton = (target: any) => {
    setButtonList((prev) =>
      prev.map((button) => ({
        ...button,
        isChecked: button.id === target.id,
      }))
    );

    if (target.id === "1") {
      handleCropButton();
    } else {
      handleOtherButton();
    }

    setEditStatus(target);
  };

  const handleCropButton = () => {
    if (cropperRef.current) {
      cropperRef.current.setDragMode("crop");
      cropperRef.current.crop(); // 크롭 박스 표시
    }
  };

  const handleOtherButton = () => {
    if (cropperRef.current) {
      cropperRef.current.clear(); // 크롭 박스 숨기기
    }
  };

  const handleRotate = (angle: number, target?: string) => {
    if (cropperRef.current) {
      console.log(cropperRef.current.rotate);
      if (target === "input") {
        cropperRef.current.rotateTo(angle);
      } else {
        cropperRef.current.rotate(angle);
      }
    }
  };

  const handleFlipHorizontal = () => {
    if (cropperRef.current) {
      const currentScaleX = cropperRef.current.getData().scaleX;
      cropperRef.current.scaleX(currentScaleX * -1);
    }
  };

  // 상하 반전 핸들러
  const handleFlipVertical = () => {
    if (cropperRef.current) {
      const currentScaleY = cropperRef.current.getData().scaleY;
      cropperRef.current.scaleY(currentScaleY * -1);
    }
  };

  // 밝기 조절을 위한 상태
  const [brightness, setBrightness] = useState(0); // 백분율로 초기화

  useEffect(() => {
    if (imageRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {
        // Cropper.js 옵션 설정
        aspectRatio:
          templateImageInfo.image_width / templateImageInfo.image_height,
        background: false,

        autoCrop: true,
        crop(event) {
          // console.log(event.detail.x);
          // console.log(event.detail.y);
          // console.log(event.detail.width);
          // console.log(event.detail.height);
          // console.log(event.detail.rotate);

          setUpdateRotate(event.detail.rotate);

          // console.log(event.detail.scaleX);
          // console.log(event.detail.scaleY);
        },
      });
    }

    // 컴포넌트가 언마운트될 때 Cropper 인스턴스 정리
    return () => {
      if (cropperRef.current) {
        cropperRef.current.destroy();
      }
    };
  }, [selectedImage, newImage]);

  if (!selectedImage) return <div></div>;

  const { uid, src, url, public_id } = selectedImage;

  // 밝기 조절 핸들러
  const handleBrightnessChange = (newBrightness: number) => {
    setBrightness(newBrightness);
    const parts = url?.split("/upload/");

    if (!parts) {
      return;
    }

    const imageUrl = `${parts[0]}/upload/e_brightness:${newBrightness}/${parts[1]}`;

    if (cropperRef.current) {
      cropperRef.current.replace(imageUrl);
    }
  };

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageId: string
  ) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    const reader = new FileReader();

    setNewImage(file);

    // reader.onload = (e) => {
    //   if (e.target && e.target.result) {
    //     // 결과 데이터를 상위 컴포넌트에 전달
    //     onImageSave(imageId, e.target.result);
    //   }
    // };

    // reader.readAsDataURL(file);
  };

  const handleSave = () => {
    if (cropperRef.current) {
      // 크롭된 캔버스를 가져옵니다.
      const croppedCanvas = cropperRef.current.getCroppedCanvas();

      if (!croppedCanvas) {
        createBlobFromOriginalImage();
        return;
      }

      croppedCanvas.toBlob((blob) => {
        if (blob) {
          onImageSave(selectedImage.uid, blob, "crop");
        }
      }, "image/jpeg");
    }
  };

  const createBlobFromOriginalImage = () => {
    const img = new Image();
    if (
      src instanceof Blob ||
      src instanceof File ||
      src instanceof MediaSource
    ) {
      img.src = URL.createObjectURL(src);
    } else {
      img.src = url as string;
    }
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        return;
      }

      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          onImageSave(selectedImage.uid, blob, "update");
        }
      }, "image/jpeg");
    };
  };

  const loadImageSrc = () => {
    return url;
    // if (["crop", "update", "repeat"].includes(selectedImage?.state as string)) {
    //   if (src instanceof Blob || src instanceof File) {
    //     return URL.createObjectURL(src);
    //   } else {
    //     return selectedImage.updated_url;
    //   }
    // } else {
    //   return url;
    // }
    // if (src) {
    //   // src가 Blob, File 또는 MediaSource인 경우
    //   return URL.createObjectURL(src);
    // } else if (url) {
    //   // 이미 업로드된 이미지의 URL인 경우
    //   return url;
    // }
  };

  const toggleBackground = () => {
    if (!cropperRef.current) {
      return;
    }
    const cropper = cropperRef.current as Cropper & { container: HTMLElement };

    // 크롭된 이미지 가져오기
    const croppedCanvas = cropper.getCroppedCanvas();

    // 배경 이미지 생성
    const backgroundImage = new Image();
    backgroundImage.crossOrigin = "anonymous";
    backgroundImage.src = processBackgroundImage("zz");

    // 배경 이미지 로드 완료 시
    backgroundImage.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      canvas.width = cropper.container.offsetWidth;
      canvas.height = cropper.container.offsetHeight;

      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

      const cropData = cropper.getData();
      const scaleX = canvas.width / cropData.width;
      const scaleY = canvas.height / cropData.height;
      const offsetX = cropData.x * scaleX;
      const offsetY = cropData.y * scaleY;

      ctx.drawImage(
        croppedCanvas,
        offsetX,
        offsetY,
        croppedCanvas.width,
        croppedCanvas.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          console.log(blob);
        }
      }, "image/jpeg");
    };
  };

  return (
    <section className="image-change_section">
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileSelect(e, uid)}
        accept="image/*"
      />

      <div className="image-preview_wrap">
        <img ref={imageRef} src={loadImageSrc()} alt="preview" />
      </div>
      <div className="edit-fuction_btns">
        {buttonList.map((button) => (
          <ImageEditFunctionButton
            button={button}
            key={button.title}
            target="edit"
          />
        ))}

        <button
          className={`edit-function_btn ${isReorderMode && "checked"}`}
          onClick={handleReorderClick}
        >
          <div className="image_wraps edit">
            <img src={editButton5} alt="아이콘" />
          </div>
          <span className="body-title small">
            {isReorderMode ? "순서변경 완료" : "순서변경"}
          </span>
        </button>
      </div>
      {editStatus.id === "2" && (
        <ImageEditRangeInput
          target="rotate"
          value={updateRotate}
          onChange={(e) => handleRotate(e, "input")}
        />
      )}
      {editStatus.id === "3" && (
        <ImageEditRangeInput
          target="brightness"
          value={brightness}
          onChange={handleBrightnessChange}
        />
      )}
      <div className="edit-fuction_btns rotate-btns">
        {editStatus.id === "2" &&
          rotateButtonList.map((button) => (
            <ImageEditFunctionButton
              target="rotate"
              button={button}
              key={button.title}
            />
          ))}
      </div>

      <div className="bottom-button_wraps">
        <button onClick={() => handleImageChange("prev")}>이전 장면</button>
        <button className="apply" onClick={handleSave}>
          적용
        </button>
        <button onClick={() => handleImageChange("next")}>다음 장면</button>
      </div>
    </section>
  );
};

export default ServiceImageChange;

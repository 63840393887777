import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface PremiumPageProps {}

const PremiumPage: React.FC<PremiumPageProps> = ({}) => {
  const navigator = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const FUNERAL_NO = searchParams.get("FUNERAL_NO");
  const uid = searchParams.get("uid");

  return (
    <div id="premium-page">
      <div className="title_box">
        <h4>
          오직 한 사람을 위한 <br />
          소중한 기억을
        </h4>
        <div className="border_line"></div>
        <h4>영원하게</h4>
      </div>

      <button
        className="bottom_btn"
        onClick={() =>
          navigator(
            `/shops/detail?shop_no=1&FUNERAL_NO=${FUNERAL_NO}&uid=${uid}`
          )
        }
      >
        <span>보관하기</span>
      </button>
    </div>
  );
};

export default PremiumPage;

import React, { ReactNode } from "react";
import styles from "./PageHeader.module.css";
import AddButton from "../Button/AddButton";

interface PageHeaderProps {
  children?: ReactNode;
  title: string;
  desc?: string | null;
  addButton?: string | null;
  handleAddButton?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  desc,
  addButton,
  handleAddButton,
}) => {
  return (
    <section className={styles.section}>
      <div>
        <div>
          <h1>{title}</h1>
          {desc && <p>{desc}</p>}
        </div>
        {addButton && handleAddButton && (
          <AddButton handleAddButton={handleAddButton} title={addButton} />
        )}
      </div>
      {children}
    </section>
  );
};

export default PageHeader;

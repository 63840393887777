import { createContext, useContext, useState, useEffect } from "react";
// import { login, logout, onUserStateChange } from "../api/firebase";
import { useApi } from "@context/ApiContext";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const { adminApi } = useApi();

  const location = useLocation();
  const navigator = useNavigate();

  const logout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      localStorage.removeItem("access_token");
      setUser(null);

      navigator("/admin/login");
    }
  };

  useEffect(() => {
    adminApi.onUserStateChange((user) => {
      console.log(user, "user");
      setUser(user);
      setLoading(false); // 로딩 완료
    });
    localStorage.setItem("preLoginPath", location.pathname);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, uid: user && user.uid, setUser, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}

import React, { useEffect, useState } from "react";
import styles from "./ShopList.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "@components/Admin/Design/Input/SearchInput";

import { useApi } from "@context/ApiContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Error from "@components/Service/Ui/Error";

import { AiOutlineSetting } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import ShopCategoryAddModal from "@components/Admin/Shop/ShopCategoryAddModal";
import ShopAddModal from "@components/Admin/Shop/ShopAddModal";
import ShopTable from "@components/Admin/Shop/ShopTable";
import AdminLoading from "@pages/Admin/AdminLoading";
import { TemplateObj } from "@interfaces/admin";

interface ShopListProps {}

const ShopList: React.FC<ShopListProps> = ({}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigator = useNavigate();
  const shop_category_id = searchParams.get("shop_category_id");

  const { adminApi, templateApi } = useApi();
  const [tagetCategory, setTargetCategory] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] =
    useState<boolean>(false);

  const [targetShop, setTargetShop] = useState(null);
  const [isShopModalOpen, setIsShopModalOpen] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState({
    shop_name: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    shop_name: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialFilter = {
      shop_name: searchParams.get("shop_name") || "",
    };
    setSearchInput(initialFilter);
    setSearchFilter(initialFilter);
  }, [location.search]);

  const {
    isLoading,
    isError,
    data: shops,
  } = useQuery(
    ["getShops", searchFilter],
    () => adminApi.getShops({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: shopCategory } = useQuery(
    ["getShopCategorys"],
    () => adminApi.getShopCategorys(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: templates } = useQuery<TemplateObj[], Error>(
    ["templates"],
    () => {
      return templateApi.getTemplates();
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const updateShopCategorys = () => {
    queryClient.invalidateQueries(["getShopCategorys"]);
  };
  const updateShops = () => {
    queryClient.invalidateQueries(["getShops"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (searchInput.shop_name) {
      queryParams.append("shop_name", searchInput.shop_name);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };
  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div className={styles.wrap}>
      <ShopAddModal
        shop={targetShop}
        shopCategory={shopCategory}
        isOpen={isShopModalOpen}
        updateShops={updateShops}
        templates={templates as TemplateObj[]}
        onClose={() => {
          setTargetShop(null);
          setIsShopModalOpen(false);
        }}
      />
      <ShopCategoryAddModal
        shopCategory={tagetCategory}
        isOpen={isCategoryModalOpen}
        updateShopCategorys={updateShopCategorys}
        onClose={() => {
          setTargetCategory(null);
          setIsCategoryModalOpen(false);
        }}
      />

      <div className={styles.categoryWrap}>
        <div className={styles.categorList}>
          <li
            className={`${styles.total} ${
              !shop_category_id ? styles.active : ""
            }`}
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.delete("shop_category_id");
              navigator(`?${queryParams.toString()}`);
            }}
          >
            전체 카테고리
          </li>

          {shopCategory?.map((cate: any) => (
            <li
              key={cate.id}
              className={`${styles.total} ${
                shop_category_id === `${cate.id}` ? styles.active : ""
              }`}
            >
              <span
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("shop_category_id", cate.id);
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                {cate.shop_category_name}
              </span>

              <div className={styles.iconWrap}>
                <AiOutlineSetting
                  onClick={() => {
                    setTargetCategory(cate);
                    setIsCategoryModalOpen(true);
                  }}
                />
                <MdDeleteOutline
                  onClick={() => {
                    if (
                      window.confirm(
                        `${cate.shop_category_name} 을 정말 삭제하시겠습니까?`
                      )
                    ) {
                      adminApi
                        .saveOrUpdateShopCategory({
                          category: cate,
                          state: "delete",
                        })
                        .then(() => {
                          alert("삭제되었습니다");
                          updateShopCategorys();
                        });
                    }
                  }}
                />
              </div>
            </li>
          ))}
        </div>
        <div
          className={styles.setting}
          onClick={() => setIsCategoryModalOpen(true)}
        >
          새 카테고리 만들기
        </div>
      </div>

      <div className={styles.mainWrap}>
        <PageHeader
          title="상품 목록"
          addButton="상품 추가"
          handleAddButton={() => {
            setTargetShop(null);
            setIsShopModalOpen(true);
          }}
        />
        <div className={styles.searchInputWrap}>
          <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
            <div className={searchStyles.filterItemWraps}>
              <div className={searchStyles.filterItem}>
                <label htmlFor="shop_name">상품명</label>
                <input
                  id="shop_name"
                  value={searchInput.shop_name}
                  type="text"
                  placeholder="상품명을 입력하세요"
                  onChange={handleSearchInput}
                />
              </div>
            </div>
          </SearchInput>
        </div>
        <main>
          <ShopTable
            shops={
              shop_category_id
                ? shops.filter(
                    (shop: any) =>
                      shop.ShopCategoryId === parseInt(shop_category_id)
                  )
                : shops
            }
            updateShops={updateShops}
            handleShopModalOpen={(shop) => {
              setTargetShop(shop);
              setIsShopModalOpen(true);
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default ShopList;

import { useAuthContext } from "@context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import AdminLoading from "./AdminLoading";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, loading } = useAuthContext();
  const location = useLocation();

  if (loading) {
    return <AdminLoading />;
  }

  if (!user) {
    return <Navigate to="/admin/login" replace />;
  }

  const allowedPaths = ["/admin/dashboard/funerals", "/admin/dashboard/cals"];

  //StartsWith 사용
  if (
    user.LV !== 99 &&
    !allowedPaths.some((path) => location.pathname.startsWith(path))
  ) {
    alert("접근 권한이 없습니다.");
    return <Navigate to="/admin/dashboard/funerals" replace />;
  }

  return <>{children}</>;
}

import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminCalDetailTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";
import NumberFormat from "@components/Ui/NumberFormat";

interface AdminCalDetailTableProps {
  LV: number;
  funeralCalList: any;
  totalPages: number;
  location?: any;
  updateFuneralCal?: () => void;
}

const AdminCalDetailTable: React.FC<AdminCalDetailTableProps> = ({
  LV,
  funeralCalList,
  totalPages,
  updateFuneralCal,
}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const pageCount = totalPages;
  const page = searchParams.get("page") || 1;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  if ([99, 89].includes(LV)) {
    return (
      <>
        <Table>
          <TableBody>
            <TableHeader>
              <th className={styles.exSmallTh}>번호</th>
              <th className={styles.smallTh}>빈소</th>
              <th className={styles.smallTh}>고인명</th>
              <th>주문자 / 번호</th>
              <th className={styles.middleTh}>제공가</th>

              <th className={styles.middleTh}>식장 (원)</th>
              <th className={styles.middleTh}>동성 (원)</th>
              <th className={styles.middleTh}>시네모 (원)</th>

              <th className={styles.smallTh}>상태</th>
              <th className={styles.middleTh}>결제/취소 일시</th>
            </TableHeader>
            {funeralCalList?.length === 0 ? (
              <tr>
                <td>데이터가 없습니다</td>
              </tr>
            ) : (
              funeralCalList?.map((cal: any, idx: number) => (
                <tr key={cal?.shop_order_no}>
                  <td> {idx + 1 + 10 * (+page - 1)}</td>
                  <td>{cal?.ShopOrder?.Event?.EventRoom?.room_name || "-"}</td>
                  <td>{cal?.ShopOrder?.Event?.dm_name || "-"}</td>
                  <td>
                    {cal?.ShopOrder.buyer_name} /{" "}
                    {cal?.ShopOrder.buyer_tel.slice(0, -4) + "****"}
                  </td>
                  <td>
                    <NumberFormat number={cal?.offer_amount} />원
                  </td>
                  <td>
                    <NumberFormat number={cal?.funeral_amount} />원
                  </td>
                  <td>
                    <NumberFormat number={cal?.ds_amount} />원
                  </td>
                  <td>
                    <NumberFormat number={cal?.cnemo_amount} />원
                  </td>

                  <td>{cal?.status === "paid" ? "결제완료" : "결제취소"}</td>
                  <td>
                    {cal?.cancel_dt
                      ? dayjs(cal?.cancel_dt).format("MM-DD HH:mm")
                      : dayjs(cal?.ShopOrder.paid_dt).format("MM-DD HH:mm")}
                  </td>
                </tr>
              ))
            )}
          </TableBody>
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="다음 >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< 이전"
          renderOnZeroPageCount={null}
          previousClassName={pageStyles.pageItem}
          nextClassName={pageStyles.pageItem}
          breakClassName={pageStyles.break}
          containerClassName={pageStyles.pagination}
          pageClassName={pageStyles.pageItem}
          activeClassName={pageStyles.active}
          disabledClassName={pageStyles.disabled}
        />
      </>
    );
  }

  return (
    <>
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>번호</th>
            <th className={styles.smallTh}>빈소</th>
            <th className={styles.smallTh}>고인명</th>
            <th>주문자 / 번호</th>

            <th className={styles.smallTh}>상태</th>
            <th className={styles.middleTh}>결제/취소 일시</th>
          </TableHeader>
          {funeralCalList?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            funeralCalList?.map((cal: any, idx: number) => (
              <tr key={cal?.shop_order_no}>
                <td> {idx + 1 + 10 * (+page - 1)}</td>
                <td>{cal?.ShopOrder?.Event?.EventRoom?.room_name || "-"}</td>
                <td>{cal?.ShopOrder?.Event?.dm_name || "-"}</td>
                <td>
                  {cal?.ShopOrder.buyer_name} /{" "}
                  {cal?.ShopOrder.buyer_tel.slice(0, -4) + "****"}
                </td>

                <td>{cal?.status === "paid" ? "결제완료" : "결제취소"}</td>
                <td>
                  {cal?.cancel_dt
                    ? dayjs(cal?.cancel_dt).format("MM-DD HH:mm")
                    : dayjs(cal?.ShopOrder.paid_dt).format("MM-DD HH:mm")}
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminCalDetailTable;

import React from "react";
import { AiOutlineCopy, AiOutlineClose } from "react-icons/ai";
import { RiKakaoTalkFill } from "react-icons/ri";

interface ServiceShareModalProps {
  setShareModalFlag: (status: boolean) => void;
}

const ServiceShareModal: React.FC<ServiceShareModalProps> = ({
  setShareModalFlag,
}) => {
  const handleModalContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <section className="share-modal" onClick={() => setShareModalFlag(false)}>
      <main onClick={handleModalContentClick}>
        <h4>공유할 방법을 선택해주세요</h4>
        <button onClick={() => setShareModalFlag(false)}>
          <AiOutlineClose className="close-icon" />
        </button>

        <div className="buttons">
          <button className="kakao-button">
            <RiKakaoTalkFill />
            <span>카카오톡</span>
          </button>
          <button className="copy-link-button">
            <AiOutlineCopy />
            <span>링크복사</span>
          </button>
        </div>

        <p>
          <span> 링크가 복사되었습니다 </span>
        </p>
      </main>
    </section>
  );
};

export default ServiceShareModal;

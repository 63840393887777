import MainFooter from "@components/Home/MainFooter";
import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import ServiceTemplateCard from "@components/Service/Templates/ServiceTemplateCard";
import Error from "@components/Service/Ui/Error";
import Loading from "@components/Service/Ui/Loading";
import DataLoading from "@components/Ui/Loading/DataLoading";
import { useApi } from "@context/ApiContext";
import { TemplateObj } from "@interfaces/admin";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface SampleVideosProps {}

const SampleVideos: React.FC<SampleVideosProps> = ({}) => {
  const { templateApi, serviceApi } = useApi();

  const navigator = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const cate = searchParams.get("cate");

  const {
    isLoading,
    isError,
    data: templates,
  } = useQuery<TemplateObj[], Error>(
    ["templates"],
    () => templateApi.getTemplates("shop"),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const { data: categorys } = useQuery(
    ["getTemplateCategorys"],
    () => templateApi.getTemplateCategorys(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  if (isError) return <Error />;

  const handleCheckTemplate = async (templateId: any) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("templateId", templateId);
    navigator(`/shops/detail?${queryParams.toString()}`);
    return;
  };

  return (
    <div id="service-template-check">
      {isLoading && <DataLoading />}
      <ServiceMainHeader
        title="영상 선택"
        handleLeftIconClick={() => {
          const queryParams = new URLSearchParams(location.search);
          navigator(`/shops?${queryParams.toString()}`);
        }}
        isHelpIcon={false}
      />

      <main>
        <nav className="type-nav">
          <ul>
            <li
              className={!cate ? "active" : ""}
              onClick={() => {
                const queryParams = new URLSearchParams(location.search);
                queryParams.delete("cate");
                navigator(`?${queryParams.toString()}`);
              }}
            >
              전체
            </li>
            {categorys?.map((item: any) => (
              <li
                key={item.id}
                className={item.id == cate ? "active" : ""}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("cate", item.id);
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                {item?.template_category_name}
              </li>
            ))}
          </ul>
        </nav>

        <section className="templates-section">
          {cate
            ? templates
                ?.filter(
                  (temp: any) => temp.TemplateCategoryId === parseInt(cate)
                )
                .map((template) => (
                  <ServiceTemplateCard
                    template={template}
                    key={template.id}
                    handleClick={() => {
                      handleCheckTemplate(template.id);
                    }}
                  />
                ))
            : templates?.map((template) => (
                <ServiceTemplateCard
                  template={template}
                  key={template.id}
                  handleClick={() => {
                    handleCheckTemplate(template.id);
                  }}
                />
              ))}
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default SampleVideos;

import React from "react";
import styles from "./ImageSetting.module.css";

export default function ImageSetting({
  image: {
    id,
    image_width,
    image_height,
    top_left_x,
    top_left_y,
    top_right_x,
    top_right_y,
    bottom_left_x,
    bottom_left_y,
    bottom_right_x,
    bottom_right_y,
  },
  idx,
  handleImages,
  handleImageFit,
}) {
  const handleChange = (e) => {
    handleImages(e);
  };
  const handleFit = () => {
    handleImageFit(id);
  };
  return (
    <section className={styles.wrap} key={id}>
      <div>
        <span className={styles.title}>썸네일 업로드 ({idx + 1})</span>
        <input
          id="img"
          name={id}
          type="file"
          className={styles.input}
          onChange={handleChange}
        />
      </div>
      <div>
        <div className={styles.fitButtonWrap}>
          <span className={styles.title}> 이미지 비율 설정 </span>
          <button
            className={styles.fitButton}
            type="button"
            onClick={handleFit}
          >
            FIT
          </button>
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="image_width">width</label>
          <input
            id="image_width"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={image_width || ""}
            onChange={handleChange}
          />

          <label htmlFor="image_height">height</label>
          <input
            id="image_height"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={image_height || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <span className={styles.title}>좌측 상단 위치 설정 </span>

        <div className={styles.inputWrap}>
          <label htmlFor="top_left_x">X좌표</label>
          <input
            id="top_left_x"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={top_left_x || ""}
            onChange={handleChange}
          />

          <label htmlFor="top_left_y">Y좌표</label>
          <input
            id="top_left_y"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={top_left_y || ""}
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <span className={styles.title}>우측 상단 위치 설정 </span>
        <div className={styles.inputWrap}>
          <label htmlFor="top_right_x">X좌표</label>
          <input
            id="top_right_x"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={top_right_x || ""}
            onChange={handleChange}
          />

          <label htmlFor="top_right_y">Y좌표</label>
          <input
            id="top_right_y"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={top_right_y || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <span className={styles.title}>좌측 하단 위치 설정 </span>

        <div className={styles.inputWrap}>
          <label htmlFor="bottom_left_x">X좌표</label>
          <input
            id="bottom_left_x"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={bottom_left_x || ""}
            onChange={handleChange}
          />

          <label htmlFor="bottom_left_y">Y좌표</label>
          <input
            id="bottom_left_y"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={bottom_left_y || ""}
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <span className={styles.title}>우측 하단 위치 설정 </span>
        <div className={styles.inputWrap}>
          <label htmlFor="bottom_right_x">X좌표</label>
          <input
            id="bottom_right_x"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={bottom_right_x || ""}
            onChange={handleChange}
          />

          <label htmlFor="bottom_right_y">Y좌표</label>
          <input
            id="bottom_right_y"
            name={id}
            placeholder="ex)1920"
            type="number"
            value={bottom_right_y || ""}
            onChange={handleChange}
          />
        </div>
      </div>
    </section>
  );
}

export default class ShopApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getShops(params) {
    return this.apiClient
      .getShops(params)
      .then((res) => {
        const { data } = res;

        return data.result.shops;
      })
      .catch((err) => console.error(err));
  }

  async getFuneralShopTemplates(params) {
    return this.apiClient
      .getFuneralShopTemplates(params)
      .then((res) => {
        const { data } = res;

        console.log(data);

        return data.result.funeralShops;
      })
      .catch((err) => console.error(err));
  }

  async getShopTemplate(params) {
    return this.apiClient
      .getShopTemplate(params)
      .then((res) => {
        const { data } = res;

        return data.result.shop;
      })
      .catch((err) => console.error(err));
  }

  async getShop(params) {
    return this.apiClient
      .getShop(params)
      .then((res) => {
        const { data } = res;

        return data.result.shop;
      })
      .catch((err) => console.error(err));
  }

  async getOrder(params) {
    return this.apiClient
      .getOrder(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }
        console.log("data", data);
        return data.result.shopOrder;
      })
      .catch((err) => console.error(err));
  }

  async getReOrder(params) {
    return this.apiClient
      .getReOrder(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }
        console.log("data", data);
        return data.result.shopOrder;
      })
      .catch((err) => console.error(err));
  }

  async saveOrUpdateOrder(params) {
    return this.apiClient
      .saveOrUpdateOrder(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return data;
        }

        return data;
      })
      .catch((err) => console.error(err));
  }

  async cancelOrder(params) {
    return this.apiClient
      .cancelOrder(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return data;
        }

        return data;
      })
      .catch((err) => console.error(err));
  }
}

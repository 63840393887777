import React from "react";
import styles from "./MainFooter.module.css";
import { Link } from "react-router-dom";
import dropdownClose from "@assets/images/service/fill-dropdown-close.svg";
import dropdownOpen from "@assets/images/service/fill-dropdown-open.svg";

interface MainFooterProps {
  page?: string;
}

const MainFooter: React.FC<MainFooterProps> = ({ page }) => {
  const pageClass = page ? styles[page] : "";

  const [toggleState, setToggleState] = React.useState(false);

  return (
    <footer className={`${styles.footer} ${pageClass}`}>
      <div className={styles.businessInfo}>
        <button
          className={styles.toggleButton}
          onClick={() => setToggleState(!toggleState)}
        >
          사업자 정보 및 문의
          {toggleState ? (
            <img src={dropdownOpen} alt="dropdown icon" />
          ) : (
            <i className="react-icons">
              <img src={dropdownClose} alt="dropdown icon" />
            </i>
          )}
        </button>
        {toggleState && (
          <div className={styles.toggleSections}>
            <section>
              <h4>COMPANY</h4>

              <ul className={styles.ul}>
                <li>
                  <Link
                    to="https://woolly-smile-dce.notion.site/9ab61359a3e840ccbbae9be6e49099b7"
                    target="_blank"
                  >
                    이용약관
                  </Link>
                </li>
                <div className={styles.divider}></div>
                <li>
                  <Link
                    to="https://woolly-smile-dce.notion.site/c0142221d99c40cba35cb2edd349d29f"
                    target="_blank"
                  >
                    개인정보처리방침
                  </Link>
                </li>
              </ul>

              <div>
                <div className={styles.companyDetails}>
                  <div>
                    <div className={styles.titleWrap}>
                      <h6>상호명</h6>
                      <span>시네모</span>
                    </div>
                    <div className={styles.titleWrap}>
                      <h6>주소</h6>
                      <span>전라북도 전주시 덕진구 편운로 54-46 시네모</span>
                    </div>
                    <div className={styles.titleWrap}>
                      <h6>대표자명</h6>
                      <span>김민형 홍동교 </span>
                    </div>
                    <div className={styles.titleWrap}>
                      <h6>사업자 번호</h6>
                      <span>242-32-00847</span>
                    </div>
                    <div className={styles.titleWrap}>
                      <h6>통신판매업신고</h6>
                      <span>2021-전주덕진-0134</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <h4>CONTACT</h4>

              <div className={styles.contactDetails}>
                <div className={styles.titleWrap}>
                  <h6>대표 전화</h6>
                  <span>1577-3264</span>
                </div>
                <div className={styles.titleWrap}>
                  <h6>이메일</h6>
                  <span>memorialone20@gmail.com</span>
                </div>
              </div>

              <div>
                <div className={styles.titleWrap}>
                  <h6>Copyright</h6>
                  <h6>2024 시네모. All Rights Reserved</h6>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </footer>
  );
};

export default MainFooter;

import React, { useEffect, useState } from "react";
import styles from "./MemberAddModal.module.css";
import Modal from "react-modal";

import { useApi } from "@context/ApiContext";

interface MemberAddModalProps {
  adminUser: any;
  adminGroups: any;
  isOpen: boolean;
  updateAdminUsers: () => void;
  onClose: () => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

type ServerApiContextType = any;
const MemberAddModal: React.FC<MemberAddModalProps> = ({
  adminUser,
  adminGroups,
  isOpen,
  updateAdminUsers,
  onClose,
}) => {
  const { adminApi } = useApi() as ServerApiContextType;

  const [user, setUser] = useState({
    user_id: "",
    password: "",
    email: "",
    LV: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setUser((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (adminUser) {
      setUser(adminUser);
    } else {
      setUser({
        user_id: "",
        password: "",
        email: "",
        LV: "",
      });
    }
  }, [adminUser]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>관리자 {adminUser ? "수정" : "등록"}</h3>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="user_id">아이디</label>
          <input
            id="user_id"
            type="text"
            placeholder="관리자 아이디를 입력하세요"
            value={user.user_id}
            onChange={handleInputChange}
            readOnly={adminUser}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="password">비밀번호</label>
          {adminUser ? (
            <button>비밀번호 초기화하기</button>
          ) : (
            <input
              id="password"
              type="text"
              placeholder="비밀번호를 입력하세요"
              value={user.password}
              onChange={handleInputChange}
            />
          )}
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="dm_name">이메일</label>
          <input
            id="email"
            type="text"
            placeholder="이메일을 입력하세요"
            value={user.email}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="LV">그룹 선택</label>
          <select id="LV" value={user?.LV} onChange={handleInputChange}>
            <option value="">그룹없음</option>
            {adminGroups?.map((adminGroup: any, idx: number) => (
              <option key={idx} value={adminGroup?.LV}>
                {adminGroup?.group_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            adminApi
              .saveOrUpdateAdminUser({
                user: {
                  ...user,
                  LV: parseInt(user?.LV),
                },
                state: adminUser ? "update" : "create",
              })
              .then((res: any) => {
                if (!res) return;

                alert("완료되었습니다");
                updateAdminUsers();
                onClose();
              });
          }}
        >
          {adminUser ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default MemberAddModal;

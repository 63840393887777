import React, { createContext, useContext } from "react";
import { useWindowSize } from "../hooks/useWindowSize";

interface WindowSizeContextProps {
  windowSize: { width: number; height: number };
  isMobile: () => boolean;
}

const WindowSizeContext = createContext<WindowSizeContextProps | undefined>(
  undefined
);

export const WindowSizeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const windowSize = useWindowSize();

  const isMobile = () => {
    return windowSize.width <= 1200;
  };

  const contextValue: WindowSizeContextProps = {
    windowSize,
    isMobile,
  };

  return (
    <WindowSizeContext.Provider value={contextValue}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSizeContext = (): WindowSizeContextProps => {
  const context = useContext(WindowSizeContext);
  if (!context) {
    throw new Error(
      "useWindowSizeContext must be used within a WindowSizeProvider"
    );
  }
  return context;
};

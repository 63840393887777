import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./AdminOrderInfoModal.module.css";
import StatusButton from "../Design/Button/StatusButton";
import dayjs from "dayjs";

import NumberFormat from "@components/Ui/NumberFormat";
import { useApi } from "@context/ApiContext";

interface AdminOrderInfoModalProps {
  order: any;
  isOpen: boolean;
  orderAction: string;
  setOrderAction: any;
  onClose: () => void;
  updateOrders: () => void;
  onRefresh: () => void;
}

const customStyles = {
  content: {
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "100%", // 너비 설정
    maxWidth: "600px",
    transition: "right 4s ease",
    cursor: "initial",
  },
  overlay: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const AdminOrderInfoModal: React.FC<AdminOrderInfoModalProps> = ({
  order,
  isOpen,
  orderAction,
  setOrderAction,
  onClose,
  updateOrders,
  onRefresh,
}) => {
  const animationStyle = {
    ...customStyles,
    content: {
      ...customStyles.content,
      right: isOpen ? "0" : "-700px", // 모달 상태에 따른 위치 변경
    },
  };

  const bankList = [
    { value: "04", text: "KB국민은행" },
    { value: "23", text: "SC제일은행" },
    { value: "39", text: "경남은행" },
    { value: "34", text: "광주은행" },
    { value: "03", text: "기업은행" },
    { value: "11", text: "농협" },
    { value: "31", text: "대구은행" },
    { value: "32", text: "부산은행" },
    { value: "02", text: "산업은행" },
    { value: "45", text: "새마을금고" },
    { value: "07", text: "수협" },
    { value: "88", text: "신한은행" },
    { value: "48", text: "신협" },
    { value: "81", text: "하나(외환)은행" },
    { value: "20", text: "우리은행" },
    { value: "71", text: "우체국" },
    { value: "37", text: "전북은행" },
    { value: "12", text: "축협" },
    { value: "90", text: "카카오뱅크" },
    { value: "89", text: "케이뱅크" },
    { value: "27", text: "한국씨티은행" },
    { value: "92", text: "토스뱅크" },
  ];

  const { shopApi } = useApi();

  const detailsRef = useRef<HTMLDetailsElement>(null);
  const [quickAmount, setQuickAmount] = useState("all");
  const [cancelAmount, setCancelAmount] = useState(0);
  const [refundInfo, setRefundInfo] = useState({
    refund_holder: "",
    refund_account: "",
    refund_bank: "",
    refund_bank_text: "",
  });

  const handleBankClick = (bank: any) => {
    setRefundInfo((prev) => ({
      ...prev,
      refund_bank: bank.value,
      refund_bank_text: bank.text,
    }));

    if (detailsRef.current) {
      detailsRef.current.removeAttribute("open"); // `<details>`를 닫습니다.
    }
  };

  const handleCancelOrder = async () => {
    try {
      if (cancelAmount === 0) {
        alert("0원은 환불할 수 없습니다");
        return;
      }

      if (order?.amount - order?.cancel_amount < cancelAmount) {
        alert("환불 금액은 환불가능 금액보다 클 수 없습니다");
        return;
      }
      const params = { order, cancelAmount, refundInfo };

      const res = await shopApi.cancelOrder(params);
      const { result } = res;

      if (!result) return;

      alert("결제가 취소되었습니다");

      updateOrders();
    } catch (error) {
      console.error(error);
      alert("네트워크 문제로 오류가 발생하였습니다. 다시 시도하여 주십시요");
    }
  };

  useEffect(() => {
    setRefundInfo({
      refund_holder: "",
      refund_account: "",
      refund_bank: "",
      refund_bank_text: "",
    });
  }, [order]);

  useEffect(() => {
    if (quickAmount === "all") {
      setCancelAmount(order?.amount - order?.cancel_amount);
    } else {
      setCancelAmount(0);
    }
  }, [order, quickAmount]);
  // 상세 동작
  return (
    <Modal
      style={animationStyle}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <h1>결제내역 상세</h1>

            <StatusButton state={order?.status} target="order" />
          </div>
          <nav>
            <button
              className={`${orderAction === "detail" ? styles.active : ""}`}
              onClick={() => setOrderAction("detail")}
            >
              <span>상세정보</span>
            </button>

            {(order?.status === "paid" || order?.status === "cancelled") && (
              <button
                className={`${orderAction === "cancel" ? styles.active : ""}`}
                onClick={() => setOrderAction("cancel")}
              >
                <span>결제취소</span>
              </button>
            )}
          </nav>
        </header>

        <main className={styles.mainWrap}>
          {orderAction === "detail" ? (
            <div>
              <div className={styles.customerWrap}>
                <span className={styles.title}>주문자 정보</span>

                <table>
                  <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{order?.buyer_name}</td>

                      <th>전화번호</th>
                      <td>{order?.buyer_tel}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.customerWrap}>
                <span className={styles.title}>받는이 정보</span>

                <table>
                  <tbody>
                    <tr>
                      <th>이름</th>
                      <td>{order?.receive_name}</td>

                      <th>전화번호</th>
                      <td>{order?.receive_tel}</td>
                    </tr>

                    <tr>
                      <th>우편번호</th>
                      <td colSpan={3}>{order?.receive_postcode}</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td colSpan={3}>
                        {order?.receive_addr} {order?.receive_addr_detail}
                      </td>
                    </tr>
                    <tr>
                      <th>배송메모</th>
                      <td colSpan={3}>{order?.deli_memo || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className={styles.paymentWrap}>
                <div className={styles.titleWrap}>
                  <span className={styles.title}>결제정보</span>
                  <p>{order?.merchant_uid}</p>
                </div>

                <div className={styles.infoWrap}>
                  <div>
                    <div className={styles.infoTitleWrap}>
                      <div className={styles.leftWrap}>
                        <h1>{order?.name}</h1>
                        <h2>
                          {order?.paid_dt
                            ? dayjs(order?.paid_dt).format("MM-DD HH:mm")
                            : "미결제"}
                        </h2>
                      </div>
                      {order?.status === "paid" && (
                        <div className={styles.rightWrap}>
                          <button
                            onClick={() => {
                              window.open(order?.receipt_url);
                            }}
                          >
                            <span>승인 영수증</span>
                          </button>
                        </div>
                      )}
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th>상세상태</th>
                          <td colSpan={3}>{order?.status}</td>
                        </tr>

                        <tr>
                          <th>포트원 거래번호</th>
                          <td colSpan={3}>{order?.imp_uid}</td>
                        </tr>

                        <tr>
                          <th>결제수단</th>
                          <td colSpan={3}>{order?.pay_method}</td>
                        </tr>
                        <tr>
                          <th>총 결제금액</th>
                          <td colSpan={3}>
                            <NumberFormat number={order?.amount} />원
                          </td>
                        </tr>
                        <tr>
                          <th>상품액</th>
                          <td colSpan={3}>
                            <NumberFormat number={order?.shop_price} />원
                          </td>
                        </tr>
                        <tr>
                          <th>수량</th>
                          <td colSpan={3}>{order?.quantity}</td>
                        </tr>
                        <tr>
                          <th>배송비</th>
                          <td colSpan={3}>
                            <NumberFormat number={order?.deli_cost} />원
                          </td>
                        </tr>
                        <tr>
                          <th>관리비용</th>
                          <td colSpan={3}>
                            <NumberFormat number={order?.shop_managed_cost} />원
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* 옵션 정보 */}

                  {order?.ShopOrderOptions &&
                    order?.ShopOrderOptions.length !== 0 && (
                      <div>
                        <div className={styles.infoTitleWrap}>
                          <div className={styles.leftWrap}>
                            <h1>옵션 정보</h1>
                          </div>
                        </div>
                        <table>
                          <tbody>
                            {order?.ShopOrderOptions.map((orderOption: any) => (
                              <>
                                <tr key={orderOption.order_option_id}>
                                  <th>옵션명</th>
                                  <td colSpan={3}>
                                    {orderOption?.option_name}
                                  </td>
                                </tr>

                                <tr>
                                  <th>옵션 수량</th>
                                  <td colSpan={3}>
                                    {orderOption?.option_quantity}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ) : order?.pay_method === "vbank" ? (
            // 가상 계좌 결제 취소 로직
            <div>
              <div className={styles.cancelTab}>
                <div className={styles.inputLabelWrap}>
                  <label>
                    환불계좌 은행
                    <span className={styles.required}> *</span>
                  </label>

                  <div>
                    <details ref={detailsRef} className={styles.details}>
                      <summary>
                        <span>{refundInfo.refund_bank_text || "은행선택"}</span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.911 12.902C9.71216 13.189 9.28784 13.189 9.089 12.902L5.54369 7.78474C5.31395 7.45313 5.55128 6.99999 5.95469 6.99999L13.0453 6.99999C13.4487 6.99999 13.6861 7.45313 13.4563 7.78474L9.911 12.902Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </summary>
                      <div className={styles.bankMenuWrap}>
                        <div>
                          {bankList.map((bank) => (
                            <div
                              key={bank.value}
                              className={styles.dropDownItem}
                              onClick={() => handleBankClick(bank)}
                            >
                              <span
                                className={`${
                                  refundInfo.refund_bank === bank.value &&
                                  styles.active
                                }`}
                              >
                                {bank.text}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </details>
                  </div>
                </div>

                <div className={styles.inputLabelWrap}>
                  <label>
                    환불계좌 예금주명
                    <span className={styles.required}> *</span>
                  </label>

                  <div>
                    <div className={styles.inputContainer}>
                      <div className={styles.inputWrap}>
                        <input
                          placeholder="예금주명을 입력해주세요"
                          type="text"
                          value={refundInfo.refund_holder}
                          onChange={(e) => {
                            setRefundInfo((prev) => ({
                              ...prev,
                              refund_holder: e.target.value,
                            }));
                          }}
                          style={{ textAlign: "left" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputLabelWrap}>
                  <label>
                    환불계좌 계좌번호
                    <span className={styles.required}> *</span>
                  </label>

                  <div>
                    <div className={styles.inputContainer}>
                      <div className={styles.inputWrap}>
                        <input
                          placeholder="계좌번호를 입력해주세요"
                          type="text"
                          value={refundInfo.refund_account}
                          onChange={(e) => {
                            setRefundInfo((prev) => ({
                              ...prev,
                              refund_account: e.target.value,
                            }));
                          }}
                          style={{ textAlign: "left" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputLabelWrap}>
                  <label>
                    취소 예정 금액
                    <span className={styles.required}> *</span>
                  </label>
                  <div>
                    <div className={styles.inputHintGroup}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrap}>
                          <input
                            placeholder="금액을 입력해주세요"
                            type="text"
                            value={cancelAmount}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setCancelAmount(0);
                              } else {
                                setCancelAmount(parseInt(e.target.value));
                              }
                            }}
                          />
                          <span>원</span>
                        </div>
                      </div>
                      <div className={styles.hintContainer}>
                        <span className={styles.hintText}>환불가능 금액</span>
                        <div className={styles.amountWrap}>
                          <span>
                            <NumberFormat
                              number={order?.amount - order?.cancel_amount}
                            />
                            원
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.amountBtnGroup}>
                      <button
                        className={`${
                          quickAmount === "all" ? styles.active : ""
                        }`}
                        onClick={() => setQuickAmount("all")}
                      >
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-testid="icon"
                          >
                            <path
                              d="M14 6L8.77778 13L6 9.36364"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          전액
                        </span>
                      </button>
                      <button
                        className={`${
                          quickAmount === "part" ? styles.active : ""
                        }`}
                        onClick={() => setQuickAmount("part")}
                      >
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-testid="icon"
                          >
                            <path
                              d="M14 6L8.77778 13L6 9.36364"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          부분
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // 기본 결제 취소 로직
            <div>
              <div className={styles.cancelTab}>
                <div className={styles.inputLabelWrap}>
                  <label>
                    취소 예정 금액
                    <span className={styles.required}> *</span>
                  </label>
                  <div>
                    <div className={styles.inputHintGroup}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrap}>
                          <input
                            placeholder="금액을 입력해주세요"
                            type="text"
                            value={cancelAmount}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setCancelAmount(0);
                              } else {
                                setCancelAmount(parseInt(e.target.value));
                              }
                            }}
                          />
                          <span>원</span>
                        </div>
                      </div>
                      <div className={styles.hintContainer}>
                        <span className={styles.hintText}>환불가능 금액</span>
                        <div className={styles.amountWrap}>
                          <span>
                            <NumberFormat
                              number={order?.amount - order?.cancel_amount}
                            />
                            원
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.amountBtnGroup}>
                      <button
                        className={`${
                          quickAmount === "all" ? styles.active : ""
                        }`}
                        onClick={() => setQuickAmount("all")}
                      >
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-testid="icon"
                          >
                            <path
                              d="M14 6L8.77778 13L6 9.36364"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          전액
                        </span>
                      </button>
                      <button
                        className={`${
                          quickAmount === "part" ? styles.active : ""
                        }`}
                        onClick={() => setQuickAmount("part")}
                      >
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-testid="icon"
                          >
                            <path
                              d="M14 6L8.77778 13L6 9.36364"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          부분
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>

        <footer className={styles.footer}>
          <button
            onClick={() => {
              updateOrders();
              onRefresh();
            }}
          >
            <span>새로고침</span>
          </button>

          <div>
            <button onClick={onClose}>
              <span>확인</span>
            </button>
            {orderAction === "cancel" && (
              <button
                className={styles.cancelCofirmBtn}
                onClick={handleCancelOrder}
              >
                <span>결제취소</span>
              </button>
            )}
          </div>
        </footer>
      </div>
    </Modal>
  );
};

export default AdminOrderInfoModal;

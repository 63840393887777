import React from "react";
import styles from "./MainSection.module.css";
import ArrowChevronRight from "@assets/images/home/arrowChevronRight.svg";
import { useNavigate } from "react-router-dom";

interface MainSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  path?: string;
}

const MainSection: React.FC<MainSectionProps> = ({
  title,
  children,
  className,
  path,
}) => {
  const navigator = useNavigate();

  return (
    <div className={`${styles.mainSection}`}>
      <div className={styles.sectionTitle}>
        <h2>{title}</h2>
      </div>
      <div className={`${styles.sectionContents}  ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default MainSection;

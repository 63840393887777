import BoardRoundMainButton from "@components/Ui/Button/BoardRoundMainButton";
import React from "react";
import { AiOutlinePicture, AiOutlinePlayCircle } from "react-icons/ai";

interface ServiceTemplateCardProps {
  template: {
    name: string;
    photo_length: string;
    type: string;
    description: string;
    time: string;
    thum: {
      url: string;
    };
    sample_url: string;
  };
  handleClick?: () => void;
}

const ServiceTemplateCard: React.FC<ServiceTemplateCardProps> = ({
  template: { type, name, description, photo_length, time, thum, sample_url },
  handleClick,
}) => {
  return (
    <div className="template_card">
      {sample_url ? (
        <iframe
          title="Product Video"
          src={sample_url}
          width="1920"
          height="1080"
          className="sample_video"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <img className="thum-img" src={thum.url} alt="preview" />
      )}

      <main>
        <div className="title_wraps">
          <h3 className="head-title medium">
            [{type === "free" ? "무료" : "프리미엄"}] {name}
          </h3>

          <h4 className="body-title x-small">{description}</h4>

          <div className="info_wraps">
            <div className="flex items-center mr-2">
              {photo_length && <AiOutlinePicture />}
              <span>{photo_length}장</span>
            </div>

            <div className="flex items-center">
              {time && <AiOutlinePlayCircle />}
              <span>{time}</span>
            </div>
          </div>
        </div>
        {handleClick && (
          <BoardRoundMainButton title="제작하기" handleClick={handleClick} />
        )}
      </main>
    </div>
  );
};

export default ServiceTemplateCard;

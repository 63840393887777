import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useApi } from "@context/ApiContext";
import { TemplateObj } from "@interfaces/admin";

import styles from "./Templates.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import TemplateCard from "@components/Admin/Templates/TemplateCard";
import AdminLoading from "@pages/Admin/AdminLoading";

interface TemplatesProps {}

const Templates: React.FC<TemplatesProps> = ({}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const category = searchParams.get("category");
  const category_id = searchParams.get("category_id");

  const { templateApi } = useApi();

  const [categoryList, setCategoryList] = useState<
    { id: string; name: string; type: string; mode: string }[] | undefined
  >([]);

  const [categoryFlag, setCategoryFlag] = useState(false);

  const handleCategoryFlag = () => {
    setCategoryFlag((prev) => !prev);
  };

  const {
    isLoading,
    error,
    data: templates,
  } = useQuery<TemplateObj[], Error>(
    ["templates"],
    () => {
      return templateApi.getTemplates();
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || !templates) {
    return <AdminLoading />;
  }

  return (
    <div className={styles.wrap}>
      <PageHeader
        title="영상 제작"
        desc="미리 업로드 된 템플릿으로 영상을 만들 수 있습니다"
      />
      <nav className={styles.categoryNav}>
        <Link
          to="/admin/dashboard/templates"
          className={!category ? styles.active : ""}
        >
          전체 카테고리
        </Link>
        <Link
          to="?category=memorial"
          className={category === "memorial" ? styles.active : ""}
        >
          추모 영상
        </Link>
      </nav>

      <div className={styles.mainWrap}>
        <div className={styles.categoryWrap}>
          <button
            className={styles.categoryBtn}
            type="button"
            onClick={handleCategoryFlag}
          >
            <span className={styles.buttonText}>사진 장수</span>
            <span className={styles.buttonIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className={styles.iconSvg}
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
              </svg>
            </span>
          </button>
          {categoryFlag && (
            <div className={styles.categorList}>
              <div className={styles.flexWrap}>
                <input
                  id="photo_length-0"
                  type="checkbox"
                  className={styles.checkboxInput}
                />
                <label
                  htmlFor="photo_length-0"
                  className={styles.checkboxLabel}
                >
                  0 ~ 15장
                </label>
              </div>
              <div className={styles.flexWrap}>
                <input
                  id="photo_length-2"
                  type="checkbox"
                  className={styles.checkboxInput}
                />
                <label
                  htmlFor="photo_length-2"
                  className={styles.checkboxLabel}
                >
                  15 ~ 30장
                </label>
              </div>
              <div className={styles.flexWrap}>
                <input
                  id="photo_length-3"
                  type="checkbox"
                  className={styles.checkboxInput}
                />
                <label
                  htmlFor="photo_length-3"
                  className={styles.checkboxLabel}
                >
                  30장 이상
                </label>
              </div>
            </div>
          )}
        </div>

        <main className={styles.templateCardWrap}>
          {templates &&
            templates.map((template) => (
              <TemplateCard template={template} key={template.id} />
            ))}
        </main>
      </div>
    </div>
  );
};

export default Templates;

import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminDeliveryTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";
import AdminOrderInfoModal from "./AdminOrderInfoModal";

interface AdminDeliveryTableProps {
  state: string;
  orders: any;
  totalPages: number;
  location?: any;
  updateOrders: () => void;
}

const AdminDeliveryTable: React.FC<AdminDeliveryTableProps> = ({
  state,
  orders,
  totalPages,
  updateOrders,
}) => {
  const { adminApi } = useApi();

  const location = useLocation();
  const navigator = useNavigate();

  const [tagetOrder, setTargetOrder] = useState<null | { id: any }>({
    id: "",
  });
  const [orderAction, setOrderAction] = useState<string>("");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

  const [selectedOrders, setSelectedOrders] = useState<Set<any>>(new Set());
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const pageCount = totalPages;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  const handleCheckboxChange = (order: any) => {
    setSelectedOrders((prevSelectedOrders) => {
      const newSelectedOrders = new Set(prevSelectedOrders);
      if (newSelectedOrders.has(order)) {
        newSelectedOrders.delete(order);
      } else {
        newSelectedOrders.add(order);
      }
      return newSelectedOrders;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOrders(new Set());
    } else {
      const allOrders = new Set(orders);
      setSelectedOrders(allOrders);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    setIsAllSelected(selectedOrders.size === orders?.length);
  }, [selectedOrders, orders]);

  useEffect(() => {
    setSelectedOrders(new Set());
  }, [orders]);

  const handleUpdateDelivery = (deli_status: string) => {
    if (selectedOrders.size === 0) {
      alert("선택된 주문이 없습니다");
      return;
    }

    const selectedOrdersArray = Array.from(selectedOrders); // Set을 배열로 변환

    const param = {
      deli_status,
      selectedOrders: selectedOrdersArray.map((order) => order.imp_uid),
    };

    adminApi.updateDelivery(param).then((res: any) => {
      if (!res) return;
      alert("완료되었습니다");

      if (updateOrders) updateOrders();
    });
  };
  return (
    <>
      <AdminOrderInfoModal
        order={tagetOrder}
        orderAction={orderAction}
        setOrderAction={setOrderAction}
        isOpen={isOrderModalOpen}
        updateOrders={updateOrders}
        onClose={() => {
          setTargetOrder(null);
          setIsOrderModalOpen(false);
          setOrderAction("");
        }}
        onRefresh={() => {
          setTargetOrder(
            orders.find((order: any) => order.id === tagetOrder?.id)
          );
        }}
      />
      {state === "confirmed" && (
        <div className={styles.funcWrap}>
          <button
            className={styles.deliBtn}
            onClick={() => handleUpdateDelivery("delivering")}
          >
            배송처리
          </button>

          {selectedOrders.size !== 0 && <p>{selectedOrders.size}개 선택됨</p>}
        </div>
      )}

      {state === "delivering" && (
        <div className={styles.funcWrap}>
          <button className={styles.deliBtn}>완료처리</button>

          {selectedOrders.size !== 0 && <p>{selectedOrders.size}개 선택됨</p>}
        </div>
      )}
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
            </th>
            <th className={styles.smallTh}>배송상태</th>
            <th className={styles.smallTh}>주문번호</th>
            <th className={styles.largeTh}>상품명</th>

            <th className={styles.smallTh}>고객정보</th>
            <th className={styles.exLargeTh}>배송지</th>
            <th className={styles.smallTh}>확정일시</th>
            <th className={styles.smallTh}>배송처리일시</th>

            {/* <th className={styles.exSmallTh}></th> */}
          </TableHeader>
          {!orders || orders?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            orders?.map((order: any, index: number) => (
              <tr
                className={styles.orderTr}
                key={
                  order.merchant_uid !== null
                    ? order.merchant_uid
                    : `order-${index}`
                }
                onClick={() => {
                  setIsOrderModalOpen(true);
                  setTargetOrder(order);
                  setOrderAction("detail");
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectedOrders.has(order)}
                    onChange={() => handleCheckboxChange(order)}
                    onClick={(e) => e.stopPropagation()} // Prevent row click event
                  />
                </td>
                <td>
                  <StatusButton state={order.deli_status} target="delivery" />
                </td>
                <td>{order?.imp_uid || "-"}</td>
                <td>{order?.name || "-"}</td>
                <td>
                  {order?.buyer_name} / {order?.buyer_tel}
                </td>

                <td>
                  {order?.receive_addr} {order?.receive_addr_detail}
                </td>

                <td>
                  {order?.Event.confirmed_dt
                    ? dayjs(order?.Event.confirmed_dt).format("MM-DD HH:mm:ss")
                    : "-"}
                </td>
                <td>
                  {order?.deli_start_dt
                    ? dayjs(order?.deli_start_dt).format("MM-DD HH:mm:ss")
                    : "-"}
                </td>

                {/* {order?.Event.state === "confirmed" &&
                order?.status === "paid" &&
                order?.deli_status === "not_started" ? (
                  <td
                    className={styles.cancelButton}
                    onClick={(e) => {
                      e.stopPropagation(); // 이벤트 버블링 중지
                      setIsOrderModalOpen(true);
                      setTargetOrder(order);
                      setOrderAction("cancel");
                    }}
                  >
                    <span>배송처리</span>
                  </td>
                ) : (
                  <td></td>
                )} */}
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminDeliveryTable;

import ServiceMainButton from "@components/Service/ServiceMainButton";
import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import Error from "@components/Service/Ui/Error";
import Loading from "@components/Service/Ui/Loading";
import TextPreview from "@components/Service/Uploads/Texts/TextPreview";
import BoardRoundMainButton from "@components/Ui/Button/BoardRoundMainButton";
import Textarea from "@components/Ui/Input/Textarea";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import { useApi } from "@context/ApiContext";
import { SubtitleObj } from "@interfaces/admin";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import helpImage from "@assets/images/service/text-help.webp";
import TextUploadBottomModal from "@components/Service/Uploads/Texts/TextUploadBottomModal";

interface TextUploadsProps {}

const TextUploads: React.FC<TextUploadsProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const [helpFlag, setHelpFlag] = useState(false);
  const [isResetModalFlag, setIsResetModalFlag] = useState(false);
  const [isDefaulTextModalFlag, setIsDefaulTextModalFlag] = useState(false);

  const [subtitle, setSubtitle] = useState<SubtitleObj | null>(null);
  const [subtitles, setSubtitles] = useState<SubtitleObj[]>([]);

  const handleHelpClose = () => {
    localStorage.setItem("text_help", "true");
    setHelpFlag(false);
  };

  const handleResetText = () => {
    setSubtitle((prev: any) => ({ ...prev, event_text: "" }));
    setSubtitles((prev: any) =>
      prev.map((sub: any) => ({ ...sub, event_text: "" }))
    );

    const resetedSubtitles = subtitles.map((sub) => ({
      ...sub,
      event_text: "",
    }));

    const params = {
      uid: event.uid,
      subtitles: resetedSubtitles,
    };

    serviceApi.saveEventSubtitle(params);

    setIsResetModalFlag(false);
  };

  const handleSubtitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    setSubtitle((prev: any) => ({ ...prev, event_text: value }));
  };

  const handleSubtitleChange = (direction: string) => {
    // 현재 활성화된 자막의 인덱스 찾기
    const currentIndex = subtitles.findIndex((sub) => sub.id === subtitle?.id);

    // 자막 배열 업데이트
    const updatedSubtitles = subtitles.map((sub) =>
      subtitle?.id === sub.id
        ? { ...sub, event_text: subtitle.event_text }
        : sub
    );

    setSubtitles(updatedSubtitles);

    // 다음 또는 이전 자막의 인덱스 계산 및 경계 처리
    if (direction === "next") {
      if (currentIndex === subtitles.length - 1) {
        alert("자막 수정이 완료되었습니다.");
        return;
      }

      setSubtitle(updatedSubtitles[currentIndex + 1]);
    } else if (direction === "prev") {
      if (currentIndex === 0) {
        alert("첫 번째 자막입니다.");
        return;
      }

      setSubtitle(updatedSubtitles[currentIndex - 1]);
    }
  };

  const handleSubtitleActive = (e: any) => {
    const { id } = e.target;

    setSubtitles((prev) => [
      ...prev.map((sub) =>
        subtitle?.id === sub.id
          ? { ...sub, event_text: subtitle.event_text }
          : sub
      ),
    ]);

    const newSubtitle = subtitles.find((v) => v.id == id) || null;

    setSubtitle(newSubtitle);
  };

  const handleSubtitleSubmit = () => {
    // 자막 배열 업데이트
    const updatedSubtitles = subtitles.map((sub) =>
      subtitle?.id === sub.id
        ? { ...sub, event_text: subtitle.event_text }
        : sub
    );

    setSubtitles(updatedSubtitles);
    const event_texts = updatedSubtitles.filter((v) => v.event_text);

    const params = {
      uid: event.uid,
      subtitles: updatedSubtitles,
    };

    if (event_texts.length === updatedSubtitles.length) {
      serviceApi.saveEventSubtitle(params);
      serviceApi
        .updateEvent({
          uid: event.uid,
          state: "completed",
          modify_dt: new Date(),
        })
        .then(() => {
          navigator(`/service/complete?uid=${event.uid}`);
        });

      return;
    }

    if (event_texts.length < subtitles.length) {
      setIsDefaulTextModalFlag(true);
    }
  };

  const handleSubtitleDefaultSubmit = () => {
    const params = {
      uid: event.uid,
      subtitles,
    };

    serviceApi.saveEventSubtitle(params).then((res: any) => {
      if (!res) return;

      serviceApi
        .updateEvent({
          uid: event.uid,
          state: "completed",
          modify_dt: new Date(),
        })
        .then(() => {
          navigator(`/service/complete?uid=${event.uid}`);
        });
    });

    return;
  };

  useEffect(() => {
    if (localStorage.getItem("text_help")) {
      setHelpFlag(false);
    } else {
      setHelpFlag(true);
    }
  }, [localStorage.getItem("text_help")]);

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(
    ["getEvent", uid],
    () => {
      return serviceApi.getEventSubtitle(uid).then((res: any) => {
        if (!res) return null;

        const {
          Template: { subtitle },
          EventSubtitles,
        } = res;

        if (subtitle?.length === 0) {
          return null;
        }

        const eventSubtitle = subtitle.map((sub: any) => {
          const foundEventSubtitle = EventSubtitles.find(
            (es: any) => es.template_subtitle_id === sub.id
          );

          return {
            ...sub,
            event_text_id: foundEventSubtitle ? foundEventSubtitle.id : null,
            event_text: foundEventSubtitle ? foundEventSubtitle.event_text : "",
          };
        });

        setSubtitles(eventSubtitle);
        setSubtitle(eventSubtitle[0]);

        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  if (!event) {
    return <div>고인의 행사정보가 없습니다</div>;
  }

  if (
    event?.template_state === "premium" &&
    ["completed", "making", "done", "confirmed"].includes(event?.state)
  ) {
    return <Navigate to={`/service/complete?uid=${event.uid}`} replace />;
  }

  if (!subtitle || subtitles.length === 0) {
    return <div>자막 정보가 없습니다</div>;
  }

  // 도움말
  if (helpFlag) {
    return (
      <div className="service-help_wrap" onClick={handleHelpClose}>
        <img src={helpImage} alt="help_image" className="service-help_image" />
      </div>
    );
  }

  return (
    <div id="service-text-uploads">
      <ServiceMainHeader
        handleLeftIconClick={() => navigator(`/service/uploads?uid=${uid}`)}
        isHelpIcon={true}
        title="자막 편집"
        handleHelpIconClick={() => setHelpFlag(true)}
      />

      <BasicTitle
        title={`영상의 자막을 원하는 \n내용으로 변경할 수 있습니다`}
      />

      <main>
        <>
          <TextPreview subtitle={subtitle} />

          <div>
            <Textarea
              id={subtitle?.id}
              handleSubtitle={handleSubtitle}
              value={subtitle?.event_text || ""}
              placeholder={subtitle?.text || ""}
            />
            <div className="button_wraps">
              <button onClick={() => handleSubtitleChange("prev")}>이전</button>
              <button
                className="reset-btn"
                onClick={() => setIsResetModalFlag(true)}
              >
                초기화
              </button>
              <button onClick={() => handleSubtitleChange("next")}>다음</button>
            </div>
          </div>
        </>
      </main>

      <article className="text-list_article">
        {subtitles.map((item: SubtitleObj) => (
          <img
            id={item.id}
            key={item.id}
            src={item?.thum?.url}
            alt="text thum"
            onClick={handleSubtitleActive}
            className={`${item.id === subtitle.id ? "active" : ""}`}
          />
        ))}
      </article>

      {isResetModalFlag && (
        <TextUploadBottomModal
          title={`입력하신 자막이 사라지고 \n기본 자막으로 변경됩니다.`}
          desc={`기본 자막으로 변경한 이후에는 \n작성했던 자막을 되돌릴 수 없습니다`}
          topBtn="자막 초기화"
          topBtnClick={handleResetText}
          bottomBtn="취소"
          bottomBtnClick={() => setIsResetModalFlag(false)}
        />
      )}

      {isDefaulTextModalFlag && (
        <TextUploadBottomModal
          title={`입력하지 않은 자막이 있습니다. \n해당 자막은 기본 자막으로 제작하시겠습니까?`}
          desc={``}
          topBtn="제작 시작하기"
          topBtnClick={handleSubtitleDefaultSubmit}
          bottomBtn="취소"
          bottomBtnClick={() => setIsDefaulTextModalFlag(false)}
        />
      )}

      <ServiceMainButton
        title="제작 시작하기"
        handleClick={handleSubtitleSubmit}
      />
    </div>
  );
};

export default TextUploads;

import React, { useState } from "react";
import styles from "./TemplateCard.module.css";
import { AiOutlinePicture, AiOutlinePlayCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { TemplateObj } from "@interfaces/admin";

interface TemplateCardProps {
  template: TemplateObj;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template }) => {
  const [hoverFlag, setHoverFlag] = useState<boolean>(false);

  return (
    <div
      className={styles.cardWrap}
      onMouseOver={() => setHoverFlag(true)}
      onMouseLeave={() => setHoverFlag(false)}
    >
      <img src={template.thum.url} alt="preview" />
      {hoverFlag && (
        <div className={styles.makeButtonWrap}>
          <Link to={`${template.id}`} className={styles.makeButton}>
            제작하기
          </Link>
        </div>
      )}

      <div className={styles.infoWrap}>
        <h3>{template.name}</h3>
        <h4>{template.description}</h4>
        <div className={styles.typeWrap}>
          <span>{template.type}</span>
        </div>

        <div className={styles.flexWrap}>
          <span className={styles.flexWrap}>
            {template.photo_length && <AiOutlinePicture />}
            <span>{template.photo_length}장</span>
          </span>
          {/* <span className={styles.flexWrap}>
            {template.time && <AiOutlinePlayCircle />}
            <span>{template.time}</span>
          </span> */}
          <span className={styles.flexWrap}>
            {<AiOutlinePlayCircle />}
            <span>2분 42초</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;

import React from "react";

interface ImageEditFunctionButtonProps {
  button: {
    id: string;
    image: string;
    title: string;
    isChecked?: boolean;
    click: () => void;
  };

  target: string;
}

const ImageEditFunctionButton: React.FC<ImageEditFunctionButtonProps> = ({
  button: { id, image, title, isChecked, click },
  target,
}) => {
  if (target === "edit") {
    return (
      <button
        className={`edit-function_btn ${isChecked && "checked"}`}
        onClick={click}
      >
        <div className="image_wraps edit">
          <img src={image} alt="아이콘" />
        </div>
        <span className="body-title small">{title}</span>
      </button>
    );
  }

  return (
    <button
      className={`edit-function_btn ${isChecked && "checked"}`}
      onClick={click}
    >
      <div className="image_wraps">
        <img src={image} alt="아이콘" />
      </div>
      <span className="body-title small">{title}</span>
    </button>
  );
};

export default ImageEditFunctionButton;

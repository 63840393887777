import React, { useEffect, FC, ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
// import { useAuthContext } from "../../context/AuthContext";

import styles from "./Login.module.css";
import { useAuthContext } from "@context/AuthContext";

interface LoginUser {
  userId: string;
  password: string;
}

// These should be replaced with the correct types from react-router-dom and your context files
type LocationState = any;
type NavigateFunction = (path: {}) => void;
type ServerApiContextType = any;
type AuthContextType = any;

const Login: FC = () => {
  const navigate = useNavigate() as NavigateFunction;
  const { adminApi } = useApi() as ServerApiContextType;
  const { user, setUser } = useAuthContext() as AuthContextType;

  const [show, setShow] = useState(false);
  const [loginUser, setLoginUser] = useState<LoginUser>({
    userId: "",
    password: "",
  });
  const { state } = useLocation() as { state: LocationState };

  const handleUser = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLoginUser((prev) => ({ ...prev, [id]: value }));
  };

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();

    if (!loginUser.userId.trim() || !loginUser.password.trim()) {
      alert("아이디 혹은 비밀번호를 입력하세요");
      return;
    }

    adminApi.login(loginUser).then((res: any) => {
      if (!res) return;

      setUser(res.result.admin);
      window.location.reload();
    });
  };

  useEffect(() => {
    if (user) {
      const defaultPath =
        user.LV === 99 ? "/admin/dashboard/events" : "/admin/dashboard/cals";
      const preLoginPath = localStorage.getItem("preLoginPath") || defaultPath;

      if (preLoginPath !== "/admin/login") {
        navigate(preLoginPath);
      } else {
        navigate(defaultPath);
      }

      localStorage.removeItem("preLoginPath");
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={require("@assets/images/admin/cnemoLogo3D.png")} alt="logo" />
        <h1>CNEMO</h1>
      </div>
      <form
        className={styles.form}
        // className="w-10/12 lg:w-1/3 xl:w-1/4"
        onSubmit={handleLogin}
      >
        {/* <nav>
          <span>로그인</span>
          <span>회원가입</span>
        </nav> */}
        <div className={styles.idPwContainer}>
          <div className={styles.idContainer}>
            {/* <h3 className="text-sm text-warning">
              존재하지 않는 아이디입니다.
            </h3> */}
            <div className={styles.inputWrap}>
              <input
                id="userId"
                type="text"
                placeholder="username"
                onChange={handleUser}
              />
              <label htmlFor="userId">
                <AiOutlineUser className={styles.icon} />
              </label>
            </div>
          </div>
          {/* <h3 className="text-sm text-warning">
            비밀번호가 일치하지 않습니다.
          </h3> */}
          <div className={styles.inputWrap}>
            <input
              id="password"
              type={show ? "text" : "password"}
              placeholder="password"
              onChange={handleUser}
            />
            <button
              className={styles.icon}
              onClick={() => setShow((prev) => !prev)}
              type="button"
            >
              {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
        </div>
        <div className={styles.funcContainer}>
          <div className={styles.checkboxWrap}>
            <input id="auto-login" type="checkbox" />
            <label htmlFor="auto-login">로그인 상태 유지</label>
          </div>
          <h4>비밀번호 찾기</h4>
        </div>

        <div className={styles.buttonContainer}>
          <button type="submit">로그인</button>
          {/* <button type="button">
            회원가입
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default Login;

import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { ServiceImageProps } from "@interfaces/service";

interface ServiceImagesUploadBoxProps {
  imageList: ServiceImageProps[];
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ServiceImagesUploadBox: React.FC<ServiceImagesUploadBoxProps> = ({
  handleUpload,
  imageList,
}) => {
  const handleClick = () => {
    const inputElement = document.querySelector("#img") as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <>
      <input
        id="img"
        type="file"
        className="input-hidden"
        multiple={true}
        onChange={handleUpload}
      />

      <button className="images-upload_box" onClick={handleClick}>
        <img
          src={require("@assets/images/service/image-upload.png")}
          alt="업로드 아이콘"
        />
        <h4>사진 불러오기</h4>
      </button>
    </>
  );
};

export default ServiceImagesUploadBox;

import React, { useEffect, useRef, useState } from "react";
import styles from "./ProjectAddModal.module.css";
import Modal from "react-modal";
import { uploadImage } from "@apis/uploader";

import { BiImageAdd } from "react-icons/bi";

import { useApi } from "@context/ApiContext";

interface ProjectAddModalProps {
  categorys: any;
  isOpen: boolean;
  onClose: () => void;
  updateTemplates: () => void;
  template?: any;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "810px",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ProjectAddModal: React.FC<ProjectAddModalProps> = ({
  categorys,
  isOpen,
  onClose,
  updateTemplates,
  template,
}) => {
  const { adminApi } = useApi();

  const imgRef = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState<File | null>();

  const [temp, setTemp] = useState<any>({
    name: "",
    photo_length: "",
    min_photo_length: "",
    description: "",
    time: "",
    s3_url: "",
    short_project_url: "",
    short_sample_url: "",
    sample_url: "",
    thum: {},
    TemplateCategoryId: null,
  });

  useEffect(() => {
    if (template) {
      setTemp(template);
    } else {
      setTemp({
        name: "",
        photo_length: "",
        min_photo_length: "",
        description: "",
        time: "",
        short_project_url: "",
        short_sample_url: "",
        s3_url: "",
        sample_url: "",
        thum: {},
        TemplateCategoryId: null,
      });
    }
  }, [template]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setTemp((prev: any) => ({ ...prev, [id]: value }));
  };

  const handleImageChange = (e: any) => {
    const { files } = e.target;

    setFile(files && files[0]);
    setTemp((prev: any) => ({
      ...prev,
      thum: {},
    }));
  };

  const handleThumUpload = () => {
    if (imgRef.current) {
      imgRef.current.click();
    }
  };

  const defaultInputSubmit = async () => {
    const {
      thum,
      name,
      description,
      time,
      photo_length,
      min_photo_length,
      TemplateCategoryId,
      s3_url,
      sample_url,
    } = temp;

    if (!thum && !file) {
      alert("썸네일을 업로드하세요");
      return false;
    }

    if (name?.trim() === "") {
      alert("템플릿 명을 입력하세요");
      return false;
    } else if (description?.trim() === "") {
      alert("설명을 입력하세요");
      return false;
    } else if (time?.trim() === "") {
      alert("러닝타임을 입력하세요");
      return false;
    } else if (!photo_length) {
      alert("사진 장 수를 입력하세요");
      return false;
    } else if (!min_photo_length) {
      alert("최소 반복 사진 장수를 입력하세요");
      return false;
    } else if (!TemplateCategoryId) {
      alert("카테고리를 선택하세요");
      return false;
    } else if (s3_url?.trim() === "") {
      alert("프로젝트 URL을 입력하세요");
      return false;
    }

    let imageData = temp?.thum;

    if (file) {
      imageData = await uploadImage(file, `template/${temp.name}/templateThum`); //
    }

    return { flag: true, thum: imageData };
  };

  const handleProjectAdd = async () => {
    const defaultInputSubmitFlag = await defaultInputSubmit();
    if (!defaultInputSubmitFlag) return;

    const { thum } = defaultInputSubmitFlag;

    adminApi
      .saveOrUpdateTemplate({
        template: {
          ...template,
          ...temp,
          thum,
        },
        state: template ? "update" : "create",
      })
      .then((res: any) => {
        if (!res) return;

        alert("완료되었습니다");
        updateTemplates();
        onClose();
      });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>
        {template ? "템플릿 기본정보 수정" : "템플릿 기본정보 등록"}
      </h3>
      <div className={styles.inputWraps}>
        <div className={styles.imageUploadWrap}>
          <input
            id="productThum"
            ref={imgRef}
            placeholder="썸네일을 업로드하세요"
            type="file"
            name="file"
            hidden
            onChange={handleImageChange}
          />
          {template?.thum?.public_id ? (
            <img
              className={styles.thumImg}
              src={template.thum.url}
              alt="local file"
            />
          ) : file ? (
            <img
              className={styles.thumImg}
              src={URL.createObjectURL(file)}
              alt="local file"
            />
          ) : (
            <button onClick={handleThumUpload}>
              <BiImageAdd style={{ fontSize: "100px" }} /> <br />
              이곳을 클릭해서 이미지를 업로드하세요
            </button>
          )}
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="name">템플릿명</label>
          <input
            id="name"
            type="text"
            placeholder="템플릿명을 입력하세요"
            value={temp.name}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="description">설명</label>
          <input
            id="description"
            type="text"
            placeholder="설명을 입력하세요"
            value={temp.description}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="time">러닝타임</label>
          <input
            id="time"
            type="text"
            placeholder="러닝타임을 입력하세요 Ex) 3분 20초"
            value={temp.time}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="photo_length">사진 장수</label>

          <input
            id="photo_length"
            name="photo_length"
            type="text"
            placeholder="업로드 될 사진 장수를 입력하세요 - 숫자만 입력"
            onChange={handleInputChange}
            value={temp.photo_length}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="min_photo_length">최소 반복 사진 장수</label>

          <input
            id="min_photo_length"
            name="min_photo_length"
            type="text"
            placeholder="최소 반복 장수를 입력하세요 - 숫자만 입력"
            onChange={handleInputChange}
            value={temp.min_photo_length}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="TemplateCategoryId">카테고리</label>
          <select
            id="TemplateCategoryId"
            value={temp.TemplateCategoryId}
            onChange={handleInputChange}
          >
            <option value="">카테고리를 선택하세요</option>
            {categorys?.map((cate: any) => (
              <option key={cate.id} value={cate.id}>
                {cate.template_category_name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="short_project_url">프로젝트 SHORT 버전 URL</label>

          <input
            id="short_project_url"
            name="short_project_url"
            type="text"
            placeholder="SHORT URL을 입력해주세요"
            onChange={handleInputChange}
            value={temp.short_project_url}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="short_sample_url">SHORT 버전 샘플 preview URL</label>

          <input
            id="short_sample_url"
            name="short_sample_url"
            type="text"
            placeholder="short sample url을 입력해주세요"
            onChange={handleInputChange}
            value={temp.short_sample_url}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="s3_url">프로젝트 URL</label>

          <input
            id="s3_url"
            name="s3_url"
            type="text"
            placeholder="S3 URL을 입력해주세요"
            onChange={handleInputChange}
            value={temp.s3_url}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="sample_url">샘플 preview URL</label>

          <input
            id="sample_url"
            name="sample_url"
            type="text"
            placeholder="vimeo 임베디드 url을 입력해주세요"
            onChange={handleInputChange}
            value={temp.sample_url}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={handleProjectAdd}>
          {template ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default ProjectAddModal;

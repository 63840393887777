import React, { useEffect, useState } from "react";
import styles from "./Queues.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import QueueTable from "@components/Admin/Queue/QueueTable";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useApi } from "@context/ApiContext";
import Error from "@components/Service/Ui/Error";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { addDays, endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import { MdRefresh } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import AdminLoading from "@pages/Admin/AdminLoading";

interface QueuesProps {}

const Queues: React.FC<QueuesProps> = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    uid: "",
    name: "",
    project_name: "",
    EventUid: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
    uid: "",
    name: "",
    project_name: "",
    EventUid: "",
    state: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: searchParams.get("state") || "",
      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      uid: searchParams.get("uid") || "",
      name: searchParams.get("name") || "",
      project_name: searchParams.get("project_name") || "",
      EventUid: searchParams.get("EventUid") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getQueues", searchFilter],
    () => {
      return adminApi.getQueues({ searchFilter });
    },
    {
      staleTime: 1000 * 10 * 1,
    }
  );

  const queryClient = useQueryClient();

  const updateQueues = () => {
    queryClient.invalidateQueries(["getQueues"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.uid) {
      queryParams.append("uid", searchInput.uid);
    }

    if (searchInput.name) {
      queryParams.append("name", searchInput.name);
    }

    if (searchInput.project_name) {
      queryParams.append("project_name", searchInput.project_name);
    }

    if (searchInput.EventUid) {
      queryParams.append("EventUid", searchInput.EventUid);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    const startDate = subWeeks(startOfDay(new Date()), 1);
    const endDate = endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: "",
      page: 1,
      pageSize: 10,
    };

    const initialInput = {
      uid: "",
      name: "",
      project_name: "",
      EventUid: "",
    };

    setQuickDateType("");
    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
    navigator("");
  };

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader
        title="렌더링 대기열"
        desc="렌더링 되고 있는 영상의 현황을 볼 수 있습니다"
      />

      <section className={styles.funcSection}>
        <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="uid">UID</label>
              <input
                id="uid"
                type="text"
                value={searchInput.uid}
                placeholder="UID를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="name">파일명</label>
              <input
                id="name"
                value={searchInput.name}
                type="text"
                placeholder="파일명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="project_name">템플릿</label>
              <input
                id="project_name"
                type="text"
                value={searchInput.project_name}
                placeholder="템플릿 명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>

            <div className={searchStyles.filterItem}>
              <label htmlFor="EventUid">행사번호</label>
              <input
                id="EventUid"
                type="text"
                value={searchInput.EventUid}
                placeholder="행사번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <div className={styles.filterWraps}>
                <nav className={searchStyles.statusFilterNav}>
                  <button
                    className={`${!searchFilter.state && searchStyles.active}`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.delete("state");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>전체</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blackTag}`}
                    >
                      {data.queueTotalCount || 0}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.state === "queued" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "queued");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>대기중</span>

                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blueTag}`}
                    >
                      {data.queuedCount || 0}
                    </span>
                  </button>
                  <button
                    className={`${
                      searchFilter.state === "started" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "started");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>렌더중</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.yellowTag}`}
                    >
                      {data.startedCount || 0}
                    </span>
                  </button>

                  <button
                    className={`${
                      searchFilter.state === "finished" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "finished");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>렌더완료</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.greenTag}`}
                    >
                      {data.finishedCount || 0}
                    </span>
                  </button>

                  <button
                    className={`${
                      searchFilter.state === "error" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "error");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>렌더오류</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.redTag}`}
                    >
                      {data.errorCount || 0}
                    </span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </SearchInput>
      </section>
      <main>
        <QueueTable
          queues={data.queues}
          styles={styles}
          totalPages={data?.totalPages || 0}
          location={location}
        />
      </main>
    </div>
  );
};

export default Queues;

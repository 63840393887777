import React, { useEffect, useState } from "react";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";

import styles from "./Delivery.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import AdminLoading from "@pages/Admin/AdminLoading";

import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineAlert } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import AdminOrderCalTable from "@components/Admin/Order/AdminOrderCalTable";
import AdminDeliveryTable from "@components/Admin/Order/AdminDeliveryTable";

interface DeliveryProps {}

const Delivery: React.FC<DeliveryProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    EventUid: "",
    imp_uid: "",
    buyer_name: "",
    buyer_tel: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
    EventUid: "",
    imp_uid: "",
    buyer_name: "",
    buyer_tel: "",
    state: "confirmed",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      page: searchParams.get("page") || 1,
      pageSize: 10,
      state: searchParams.get("state") || "confirmed",
    };

    const initialInput = {
      EventUid: searchParams.get("EventUid") || "",
      imp_uid: searchParams.get("imp_uid") || "",
      buyer_name: searchParams.get("buyer_name") || "",
      buyer_tel: searchParams.get("buyer_tel") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getDelivery", searchFilter],
    () => adminApi.getDelivery({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateDelivery = () => {
    queryClient.invalidateQueries(["getDelivery"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.EventUid) {
      queryParams.append("EventUid", searchInput.EventUid);
    }

    if (searchInput.imp_uid) {
      queryParams.append("imp_uid", searchInput.imp_uid);
    }

    if (searchInput.buyer_name) {
      queryParams.append("buyer_name", searchInput.buyer_name);
    }

    if (searchInput.buyer_tel) {
      queryParams.append("buyer_tel", searchInput.buyer_tel);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };

  return (
    <div>
      {isLoading && <AdminLoading />}
      <PageHeader title="배송 관리" desc="배송 목록을 관리합니다." />

      <section className={styles.funcSection}>
        <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="event_no">행사번호</label>
              <input
                id="event_no"
                type="text"
                value={searchInput.EventUid}
                placeholder="행사번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              {/* 주문번호로 검색 */}
              <label htmlFor="imp_uid">주문번호</label>
              <input
                id="imp_uid"
                value={searchInput.imp_uid}
                type="text"
                placeholder="주문번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="buyer_name">구매자명</label>
              <input
                id="buyer_name"
                type="text"
                value={searchInput.buyer_name}
                placeholder="구매자명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="buyer_tel">전화번호</label>
              <input
                id="buyer_tel"
                type="text"
                value={searchInput.buyer_tel}
                placeholder="구매자 전화번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
        </SearchInput>
      </section>

      <main className={styles.mainWrap}>
        <div className={styles.summaryContainer}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <button
                className={`${
                  searchFilter.state === "pre_confirmed" && styles.active
                }`}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("state", "pre_confirmed");
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                <div className={styles.icon}>
                  <AiOutlineAlert />
                </div>
                <div>
                  <p>미 확정</p>

                  <p>
                    <b>{data?.preConfirmedCount}</b>
                    <span>건</span>
                  </p>
                </div>
              </button>
            </li>

            <li>
              <button
                className={`${
                  searchFilter.state === "confirmed" && styles.active
                }`}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("state", "confirmed");
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                <div className={styles.icon}>
                  <FaCheck />
                </div>
                <div>
                  <p>영상 확정</p>

                  <p>
                    <b>{data?.confirmedCount}</b>
                    <span>건</span>
                  </p>
                </div>
              </button>
            </li>
            <li className={styles.listItem}>
              <button
                className={`${
                  searchFilter.state === "delivering" && styles.active
                }`}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("state", "delivering");
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                <div className={styles.icon}>
                  <TbTruckDelivery />
                </div>
                <div>
                  <p>배송중</p>

                  <p>
                    <b>{data?.deliveringCount}</b>
                    <span>건</span>
                  </p>
                </div>
              </button>
            </li>
            <li className={styles.listItem}>
              <button
                className={`${
                  searchFilter.state === "delivery_complete" && styles.active
                }`}
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("state", "delivery_complete");
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                <div className={styles.icon}>
                  <FaCheck />
                </div>
                <div>
                  <p>배송완료</p>

                  <p>
                    <b>{data?.deliveryCompleteCount}</b>
                    <span>건</span>
                  </p>
                </div>
              </button>
            </li>
          </ul>
        </div>

        <AdminDeliveryTable
          state={searchFilter.state}
          orders={data?.orders}
          totalPages={data?.totalPages || 0}
          updateOrders={updateDelivery}
        />
      </main>
    </div>
  );
};

export default Delivery;

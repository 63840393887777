import React from "react";

import dataLoading from "@assets/images/service/data-loading.gif";
import styles from "./DataLoading.module.css";

interface DataLoadingProps {}

const DataLoading: React.FC<DataLoadingProps> = ({}) => {
  return (
    <section className={styles.background}>
      <img src={dataLoading} alt="data-loading" />
    </section>
  );
};

export default DataLoading;

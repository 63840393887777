import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";

interface ServiceMainHeaderProps {
  title?: string;
  handleLeftIconClick: () => void;
  isHelpIcon?: boolean;
  handleHelpIconClick?: () => void;
  page?: any;
}

const ServiceMainHeader: React.FC<ServiceMainHeaderProps> = ({
  title,
  handleLeftIconClick,
  isHelpIcon,
  handleHelpIconClick,
  page,
}) => {
  return (
    <header className={`default-header ${page}`}>
      <button onClick={handleLeftIconClick}>
        <AiOutlineLeft />
      </button>
      <h4 className="head-title small">{title}</h4>

      <button className="help-icon_btn" onClick={handleHelpIconClick}>
        {isHelpIcon && <BiHelpCircle className="text-brand" />}
      </button>
    </header>
  );
};

export default ServiceMainHeader;

import React from "react";
import classNames from "classnames";
import styles from "./ImageScene.module.css";

interface ImageSceneProps {
  imageList: File[];
  imgIdx?: number;
  changeIndex?: number;
  change?: boolean;
  changeImage?: (idx: number) => void;
  currentImage?: (idx: number) => void;
}

const ImageScene: React.FC<ImageSceneProps> = ({
  imageList,
  imgIdx,
  changeIndex,
}) => {
  return (
    <div className={styles.image_scene}>
      <div className={styles.image_scene_list}>
        {imageList.map((image, idx) => (
          <div key={idx} className={styles.image_scene_contents}>
            <div className={styles.image_scene_index}>
              <span>{idx + 1}</span>
            </div>
            <img
              src={URL.createObjectURL(image)}
              alt=""
              className={classNames({
                [styles.show_img_outline]: idx === imgIdx,
                [styles.show_index_img_outline]: idx === changeIndex,
              })}
              // onClick={() => {
              //   if (props.change) {
              //     props.changeImage?.(idx);
              //   } else {
              //     props.currentImage?.(idx);
              //   }
              // }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageScene;

import React from "react";

interface BasicTitleProps {
  title: string;
}

const BasicTitle: React.FC<BasicTitleProps> = ({ title }) => {
  return (
    <div className="basic-title__wraps">
      <pre className="body-title large">{title}</pre>
    </div>
  );
};

export default BasicTitle;

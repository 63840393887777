import React from "react";

import styles from "./ProjectTable.module.css";

import ProjectCard from "./Card/ProjectCard";
import { TemplateObj } from "@interfaces/admin";
import { useApi } from "@context/ApiContext";
import { useNavigate } from "react-router-dom";

interface ProjectTableProps {
  templates: TemplateObj[];
  updateTemplates: () => void;
  setTargetTemplate: any;
  setIsModalOpen: any;
}

const ProjectTable: React.FC<ProjectTableProps> = ({
  templates,

  setTargetTemplate,
  setIsModalOpen,
}) => {
  const { adminApi } = useApi();

  const navigator = useNavigate();

  const handleDeleteProduct = (templateId: string) => {
    const confirmMsg = window.confirm("정말 삭제하시겠습니까?");

    if (confirmMsg) {
      adminApi
        .saveOrUpdateTemplate({
          template: {
            id: templateId,
          },
          state: "delete",
        })
        .then(() => {
          alert("삭제되었습니다.");
          navigator("");
        });
    } else {
      alert("취소 되었습니다");
    }
  };

  return (
    <div className={styles.container}>
      <header>
        <span>프로젝트 리스트</span>
      </header>

      {templates.length === 0 ? (
        <div className={styles.noDataText}>
          <span>데이터가 없습니다</span>
        </div>
      ) : (
        templates.map((template, idx) => (
          <ProjectCard
            template={template}
            key={idx}
            handleDeleteProduct={handleDeleteProduct}
            setTargetTemplate={setTargetTemplate}
            setIsModalOpen={setIsModalOpen}
          />
        ))
      )}
    </div>
  );
};

export default ProjectTable;

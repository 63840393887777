import React, { useEffect, useState } from "react";
import { useApi } from "@context/ApiContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";

import SearchInput from "@components/Admin/Design/Input/SearchInput";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import searchStyles from "@assets/css/components/ui/search.module.css";

import AdminLoading from "@pages/Admin/AdminLoading";

import { useNavigate, useLocation } from "react-router-dom";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import FeedbackTable from "@components/Admin/Feedback/FeedbackTable";

interface FeedbacksProps {}

const Feedbacks: React.FC<FeedbacksProps> = ({}) => {
  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    EventUid: "",
    contact_email: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
  });
  const navigator = useNavigate();
  const location = useLocation();

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,

      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    setSearchFilter({ ...initialFilter });
  }, [location.search]);
  const { isLoading, isError, data } = useQuery(
    ["getAdminFeedbacks", searchFilter],
    () => adminApi.getAdminFeedbacks({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();
  const updateFeedbacks = () => {
    queryClient.invalidateQueries(["getAdminFeedbacks", searchFilter]);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    navigator(`?${queryParams.toString()}`);
  };
  const handleReset = () => {
    navigator("");
  };

  return (
    <div>
      {isLoading && <AdminLoading />}

      <PageHeader title="후기 관리" desc="후기 목록을 확인합니다" />

      <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
        <div className={searchStyles.dateWrap}>
          <div className={searchStyles.leftWrap}>
            <div className={searchStyles.quickButtons}>
              <button
                className={`${
                  quickDateType === "6months" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("6months")}
              >
                6개월
              </button>
              <button
                className={`${
                  quickDateType === "1month" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("1month")}
              >
                1개월
              </button>
              <button
                className={`${
                  quickDateType === "1week" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("1week")}
              >
                1주
              </button>
            </div>

            <DefaultCalendar
              dateRange={dateRange}
              handleDateRangeChanged={handleDateRangeChanged}
            />
          </div>
        </div>
      </SearchInput>

      <main>
        <FeedbackTable
          feedbacks={data?.feedbacks}
          location={location}
          totalPages={data?.totalPages}
          updateFeedbacks={updateFeedbacks}
        />
      </main>
    </div>
  );
};

export default Feedbacks;

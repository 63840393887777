export default class FuneralApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getFunerals() {
    return this.apiClient
      .getFunerals()
      .then((res) => {
        const { data } = res;

        return data.result.funerals;
      })
      .catch((err) => console.error(err));
  }
}

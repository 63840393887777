import React from "react";
import TextCanvas from "./TextCanvas";
import { SubtitleObj } from "@interfaces/admin";

interface TextPreviewProps {
  subtitle: SubtitleObj;
}

const TextPreview: React.FC<TextPreviewProps> = ({
  subtitle,
  subtitle: { thum },
}) => {
  return (
    <article className="text-preview">
      <img src={thum && thum.url} alt="subtitle_preview" />

      <TextCanvas canvasWidth={1920} canvasHeight={1080} subtitle={subtitle} />
    </article>
  );
};

export default TextPreview;

import React from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";

import { Tooltip as ReactTooltip } from "react-tooltip";

import { useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import StatusButton from "../Design/Button/StatusButton";

import ReactPaginate from "react-paginate";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./FeedbackTable.module.css";
import { FaRegStar, FaStar } from "react-icons/fa6";

interface FeedbackTableProps {
  feedbacks: any;
  updateFeedbacks: () => void;
  totalPages: number;
  location?: any;
}

const FeedbackTable: React.FC<FeedbackTableProps> = ({
  feedbacks,
  totalPages,
  updateFeedbacks,
  location,
}) => {
  const navigator = useNavigate();
  const { adminApi } = useApi();

  const pageCount = totalPages ? Math.ceil(totalPages) : 1;

  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  return (
    <>
      <ReactTooltip
        id="feedback-tooltip"
        place="bottom"
        style={{ width: "300px", lineHeight: "1.6" }}
      />
      <Table>
        <TableHeader>
          <th className={styles.exSmallTh}>행사번호</th>
          <th className={styles.smallTh}>작성자</th>
          <th className={styles.smallTh}>별점</th>
          <th>내용</th>
          <th className={styles.smallTh}>작성일</th>
          <th className={styles.exSmallTh}>상태</th>

          <th className={styles.exSmallTh}></th>
          <th className={styles.exSmallTh}></th>
        </TableHeader>

        <TableBody>
          {!feedbacks || feedbacks?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다.</td>
            </tr>
          ) : (
            feedbacks?.map((feedback: any) => (
              <tr key={feedback.id}>
                <td>{feedback.EventUid}</td>
                <td>{feedback.name}</td>
                <td>
                  {Array(5)
                    .fill(null)
                    .map((_, starIndex) =>
                      starIndex < feedback.rating ? (
                        <FaStar key={starIndex} />
                      ) : (
                        <FaRegStar key={starIndex} />
                      )
                    )}
                </td>

                <td className={styles.description}>
                  <span
                    data-tooltip-id="feedback-tooltip"
                    data-tooltip-content={feedback.description}
                  >
                    {feedback.description}
                  </span>
                </td>

                <td>{dayjs(feedback.create_dt).format("MM-DD HH:mm:ss")}</td>
                <td>
                  <StatusButton
                    state={feedback.confirm_state}
                    target="feedback"
                  />
                </td>
                <td>
                  <button
                    className={styles.detailsButton}
                    onClick={async () => {
                      const result = await adminApi.confirmFeedback(
                        feedback.id
                      );

                      if (result) {
                        alert("확인 처리 되었습니다.");
                        updateFeedbacks();
                      }
                    }}
                  >
                    확인
                  </button>
                </td>
                <td>
                  <button
                    className={styles.cancelButton}
                    onClick={async () => {
                      if (
                        window.confirm("해당 리뷰를 정말 삭제하시겠습니까?")
                      ) {
                        const result = await adminApi.deleteFeedback(
                          feedback.id
                        );
                        if (result) {
                          alert("삭제 되었습니다.");
                          updateFeedbacks();
                        }
                      }
                    }}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default FeedbackTable;

import React from "react";
import { useCanvas } from "@hooks/useCanvas";
import { loadFont } from "@apis/font";

interface SubTitleCanvasProps {
  subtitle: {
    id: string;
    img: Blob;
    thum: any;
    text: string;
    align: string;
    positionX: string;
    positionY: string;
    lineHeight: number;
    fontSize: number;
    font: string;
    fontColor: string;
    shadow: any;
  };
  canvasWidth: number;
  canvasHeight: number;
}

const SubTitleCanvas: React.FC<SubTitleCanvasProps> = ({
  canvasWidth,
  canvasHeight,
  subtitle: {
    text,
    fontSize,
    fontColor,
    font,
    align,
    positionX,
    positionY,
    lineHeight,
    shadow,
  },
}) => {
  const enteredText = text?.split("\n").filter((v) => v !== "");

  // const fillBackGround = async (ctx: any) => {
  //   const newFont = await loadFont(font);

  //   newFont
  //     ?.load()
  //     .then(function (loadedFont) {
  //       // 이제 폰트가 로드되었으니 캔버스 작업을 시작할 수 있습니다.
  //       document.fonts.add(loadedFont);

  //       // 캔버스 전체를 초기화
  //       ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  //       ctx.font = `${fontSize}px ${font}`;
  //       ctx.textAlign = align;
  //       ctx.fillStyle = fontColor;

  //       if (shadow === "적용") {
  //         ctx.shadowOffsetX = 0.5;
  //         ctx.shadowOffsetY = 0.5;
  //         ctx.shadowBlur = 2;
  //         ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  //       }

  //       enteredText?.map((previewText, idx) => {
  //         return ctx.fillText(
  //           previewText,
  //           positionX,

  //           parseInt(positionY) +
  //             ((lineHeight || 0) * idx - 50 * enteredText.length)
  //         );
  //       });
  //     })
  //     .catch(function (error) {
  //       console.log("Failed to load font: " + error);
  //     });
  // };

  // const fillBackGround = (ctx: any) => {
  //   // 캔버스 초기화
  //   ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  //   ctx.font = `${fontSize}px '${font}'`; // 폰트를 CSS에서 정의된 폰트 패밀리로 설정

  //   ctx.textAlign = align;
  //   ctx.fillStyle = fontColor;

  //   if (shadow === "적용") {
  //     ctx.shadowOffsetX = 0.5;
  //     ctx.shadowOffsetY = 0.5;
  //     ctx.shadowBlur = 2;
  //     ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
  //   }

  //   enteredText?.forEach((previewText, idx) => {
  //     ctx.fillText(
  //       previewText,
  //       parseFloat(positionX),
  //       parseFloat(positionY) + (lineHeight * idx - 50 * enteredText.length)
  //     );
  //   });
  // };

  const fillBackGround = async (ctx: any) => {
    // 캔버스 초기화
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    // 폰트가 캔버스에서 사용될 준비가 될 때까지 기다립니다.
    await document.fonts.load(`${fontSize}px '${font}'`);

    ctx.font = `${fontSize}px '${font}'`; // 폰트를 CSS에서 정의된 폰트 패밀리로 설정
    ctx.textAlign = align;
    ctx.fillStyle = fontColor;

    if (shadow === "적용") {
      ctx.shadowOffsetX = 0.5;
      ctx.shadowOffsetY = 0.5;
      ctx.shadowBlur = 2;
      ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
    }

    enteredText?.forEach((previewText, idx) => {
      ctx.fillText(
        previewText,
        parseFloat(positionX),
        parseFloat(positionY) + (lineHeight * idx - 50 * enteredText.length)
      );
    });
  };

  const canvasRef = useCanvas(canvasWidth, canvasHeight, fillBackGround);

  return (
    <canvas ref={canvasRef} style={{ position: "absolute", width: "100%" }} />
  );
};

export default SubTitleCanvas;

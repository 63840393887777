import React, { useEffect, useState } from "react";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import searchStyles from "@assets/css/components/ui/search.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";
import AdminLoading from "@pages/Admin/AdminLoading";
import ContactTable from "@components/Admin/Contact/ContactTable";

interface AdminContactsProps {}

const AdminContacts: React.FC<AdminContactsProps> = ({}) => {
  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    contact_name: "",
    contact_email: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    contact_name: "",
    contact_email: "",
  });

  const navigator = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const initialInput = {
      contact_name: searchParams.get("contact_name") || "",
      contact_email: searchParams.get("contact_email") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter(initialInput);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getContacts", searchFilter],
    () => adminApi.getContacts({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (searchInput.contact_name) {
      queryParams.append("contact_name", searchInput.contact_name);
    }

    if (searchInput.contact_email) {
      queryParams.append("contact_email", searchInput.contact_email);
    }

    navigator(`?${queryParams.toString()}`);
  };
  const handleReset = () => {
    navigator("");
  };
  return (
    <div>
      {isLoading && <AdminLoading />}

      <PageHeader title="제휴 문의" desc="제휴 목록을 확인합니다" />

      <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
        <div className={searchStyles.filterItemWraps}>
          <div className={searchStyles.filterItem}>
            <label htmlFor="contact_name">문의자명</label>
            <input
              id="contact_name"
              type="text"
              value={searchInput.contact_name}
              placeholder="문의자명을 입력하세요"
              onChange={handleSearchInput}
            />
          </div>
          <div className={searchStyles.filterItem}>
            <label htmlFor="contact_email">주문번호</label>
            <input
              id="contact_email"
              type="text"
              value={searchInput.contact_email}
              placeholder="문의 이메일을 입력하세요"
              onChange={handleSearchInput}
            />
          </div>
        </div>
      </SearchInput>

      <main>
        <ContactTable contacts={data?.contacts} />
      </main>
    </div>
  );
};

export default AdminContacts;

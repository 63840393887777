import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./sidebar.css";
import { push as Menu } from "react-burger-menu";
import {
  AiOutlineTable,
  AiOutlineUser,
  AiOutlineShopping,
  AiOutlineBarChart,
  AiOutlineFileSearch,
} from "react-icons/ai";
import { GrContact } from "react-icons/gr";

import { PiQueue } from "react-icons/pi";

import { Link, useLocation } from "react-router-dom";
import {
  MdLogout,
  MdOutlinePlace,
  MdOutlineRateReview,
  MdOutlineVideoSettings,
} from "react-icons/md";
import { useAuthContext } from "@context/AuthContext";
import { RiVideoAddLine } from "react-icons/ri";

interface SideBarProps {
  isOpen: boolean;
  toggleOpen: (open: boolean) => void; // assuming you want to control the open state from a parent component
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, toggleOpen }) => {
  const location = useLocation();
  const { user, logout } = useAuthContext();

  const [eventMenus, setEventMenus] = useState<any>([
    {
      id: "1",
      title: "대시보드",
      icon: <AiOutlineBarChart />,
      to: "/admin/dashboard/start",
    },
    {
      id: "1-1",
      title: "추모영상 행사 관리",
      icon: <AiOutlineTable />,
      to: `/admin/dashboard/events`,
    },
    {
      id: "1-2",
      title: "렌더링 대기열",
      icon: <PiQueue />,
      to: `/admin/dashboard/queues`,
    },
    {
      id: "1-3",
      title: "장례식장 관리",
      icon: <MdOutlinePlace />,
      to: `/admin/dashboard/funerals`,
    },
    {
      id: "1-4",
      title: "영상 정산 관리",
      icon: <AiOutlineFileSearch />,
      to: `/admin/dashboard/cals`,
    },
    {
      id: "1-5",
      title: "후기 관리",
      icon: <MdOutlineRateReview />,
      to: `/admin/dashboard/feedback`,
    },
  ]);
  const [orderMenus, setOrderMenus] = useState<any>([
    {
      id: "2-1",
      title: "상품 관리",
      icon: <AiOutlineShopping />,
      to: `/admin/dashboard/shops`,
    },
    {
      id: "2-2",
      title: "주문 관리",
      icon: <AiOutlineFileSearch />,
      to: `/admin/dashboard/orders`,
    },
    {
      id: "2-3",
      title: "배송 관리",
      icon: <AiOutlineFileSearch />,
      to: `/admin/dashboard/delivery`,
    },

    // {
    //   id: "2-3",
    //   title: "상품 관리비 정산",
    //   icon: <AiOutlineShopping />,
    //   to: `/admin/dashboard/order-cals`,
    // },
  ]);
  const [adminMenus, setAdminMenus] = useState<any>([
    {
      id: "admin_1",
      title: "프로젝트 업로드",
      icon: <MdOutlineVideoSettings />,
      to: `/admin/dashboard/project`,
    },
    {
      id: "admin_2",
      title: "영상 제작",
      icon: <RiVideoAddLine />,
      to: `/admin/dashboard/templates`,
    },
    {
      id: "admin_3",
      title: "제휴 문의",
      icon: <GrContact />,
      to: `/admin/dashboard/contact`,
    },

    {
      id: "adming_4",
      title: "사용자 관리",
      icon: <AiOutlineUser />,
      to: `/admin/dashboard/member/list`,
    },
  ]);

  useEffect(() => {
    // LV 89인경우에는 추모영상 행사관리, 장례식장 관리, 정산 관리 탭만 보이게
    if (user?.LV === 89) {
      setAdminMenus([]);
      setEventMenus([
        {
          id: "1-1",
          title: "장례식장 관리",
          icon: <MdOutlinePlace />,
          to: `/admin/dashboard/funerals`,
        },
        {
          id: "1-2",
          title: "영상 정산 관리",
          icon: <AiOutlineFileSearch />,
          to: `/admin/dashboard/cals`,
        },
      ]);
      setOrderMenus([]);
    } else if (user?.LV === 29) {
      setAdminMenus([]);
      setEventMenus([
        {
          id: "1-1",
          title: "영상 정산 관리",
          icon: <AiOutlineFileSearch />,
          to: `/admin/dashboard/cals`,
        },
      ]);
      setOrderMenus([]);
    }
  }, [user]);

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  // const [isOpen, setIsOpen] = useState(false);
  const handleStateChange = (state: any) => {
    toggleOpen(state.isOpen);
  };

  if (!user) {
    return (
      <nav className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-type-title">
          <h1>Cnemo Admin</h1>
        </div>
      </nav>
    );
  }

  if (user?.LV === 29) {
    return (
      <nav className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-type-title">
          <h1>Cnemo Admin</h1>
        </div>
        <ul>
          <div className="sidebar-group_wrap">
            <h6>행사 & 영상</h6>
            {eventMenus?.map((menu: any) => {
              return (
                <Link
                  to={menu.to}
                  className={`sidebar-list ${
                    location?.pathname.includes(menu.to) ? "active" : ""
                  }`}
                  key={menu.id}
                >
                  {menu.icon}
                  <span>{menu.title}</span>
                </Link>
              );
            })}
          </div>

          <div className="logout_wrap">
            <a className="sidebar-list" onClick={logout}>
              <MdLogout />
              <span>로그아웃</span>
            </a>
          </div>
        </ul>
      </nav>
    );
  }

  if (user?.LV === 89) {
    return (
      <nav className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-type-title">
          <h1>Cnemo Admin</h1>
        </div>
        <ul>
          <div className="sidebar-group_wrap">
            <h6>행사 & 영상</h6>
            {eventMenus?.map((menu: any) => {
              return (
                <Link
                  to={menu.to}
                  className={`sidebar-list ${
                    location?.pathname.includes(menu.to) ? "active" : ""
                  }`}
                  key={menu.id}
                >
                  {menu.icon}
                  <span>{menu.title}</span>
                </Link>
              );
            })}
          </div>

          <div className="logout_wrap">
            <a className="sidebar-list" onClick={logout}>
              <MdLogout />
              <span>로그아웃</span>
            </a>
          </div>
        </ul>
      </nav>
    );
  }
  return (
    <nav className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-type-title">
        <h1>Cnemo Admin</h1>
      </div>
      <ul>
        <div className="sidebar-group_wrap">
          <h6>행사 & 영상</h6>
          {eventMenus?.map((menu: any) => {
            return (
              <Link
                to={menu.to}
                className={`sidebar-list ${
                  location?.pathname.includes(menu.to) ? "active" : ""
                }`}
                key={menu.id}
              >
                {menu.icon}
                <span>{menu.title}</span>
              </Link>
            );
          })}
        </div>
        <div className="sidebar-group_wrap">
          <h6>주문 & 상품</h6>
          {orderMenus?.map((menu: any) => {
            return (
              <Link
                to={menu.to}
                className={`sidebar-list ${
                  location?.pathname.includes(menu.to) ? "active" : ""
                }`}
                key={menu.id}
              >
                {menu.icon}
                <span>{menu.title}</span>
              </Link>
            );
          })}
        </div>
        <div className="sidebar-group_wrap">
          <h6>운영자 메뉴</h6>
          {adminMenus?.map((menu: any) => {
            return (
              <Link
                to={menu.to}
                className={`sidebar-list ${
                  location?.pathname.includes(menu.to) ? "active" : ""
                }`}
                key={menu.id}
              >
                {menu.icon}
                <span>{menu.title}</span>
              </Link>
            );
          })}
        </div>
        <div className="logout_wrap">
          <a className="sidebar-list" onClick={logout}>
            <MdLogout />
            <span>로그아웃</span>
          </a>
        </div>
      </ul>
    </nav>
    //  <Menu
    //    isOpen={!isMobile || isOpen}
    //    onStateChange={handleStateChange}
    //    noOverlay
    //    customBurgerIcon={isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
    //    customCrossIcon={false}
    //    width={250}
    //    pageWrapId={"page-wrap"}
    //    outerContainerId={"outer-container"}
    // >
    //   <div className="sidebar-type-title">
    //     <h1>Cnemo Admin</h1>
    //   </div>
    //   <ul>
    //     {menus &&
    //       menus.map((menu: any) => {
    //         if (menu.downMenus) {
    //           return (
    //             <div key={menu.id}>
    //               <li
    //                 className={`sidebar-list ${
    //                   location?.pathname.includes(menu.to) ? "active" : ""
    //                 }`}
    //                 onClick={() => handleDownMenu(menu)}
    //               >
    //                 <div>
    //                   {menu.icon}
    //                   <span>{menu.title}</span>
    //                 </div>
    //                 <BiChevronDown
    //                   className={menu.isToggleDownMenu && "rotate-180"}
    //                 />
    //               </li>
    //               {menu.downMenus.map((downMenu: any) => {
    //                 if (menu.isToggleDownMenu) {
    //                   return (
    //                     <li
    //                       className={`sidebar-list dropmenu ${
    //                         location?.pathname === downMenu.to ? "active" : ""
    //                       }`}
    //                       key={downMenu.id}
    //                     >
    //                       <Link to={downMenu.to}>
    //                         <span>{downMenu.title}</span>
    //                       </Link>
    //                     </li>
    //                   );
    //                 }
    //                 return null;
    //               })}
    //             </div>
    //           );
    //         } else {
    //           return (
    //             <li
    //               className={`sidebar-list ${
    //                 location?.pathname.includes(menu.to) ? "active" : ""
    //               }`}
    //               key={menu.id}
    //             >
    //               <Link to={menu.to}>
    //                 {menu.icon}
    //                 <span>{menu.title}</span>
    //               </Link>
    //             </li>
    //           );
    //         }
    //       })}
    //   </ul>
    // </Menu>
  );
};

export default SideBar;

import React from "react";
import SubTitleCanvas from "./SubTitleCanvas";
import styles from "./SubTitlePreview.module.css";

interface SubTitlePreviewProps {
  subtitle: {
    id: string;
    img: Blob;
    thum: any;
    text: string;
    align: string;
    positionX: string;
    positionY: string;
    lineHeight: number;
    fontSize: number;
    font: string;
    fontColor: string;
    shadow: any;
  };
}
const SubTitlePreview = React.memo<SubTitlePreviewProps>(({ subtitle }) => {
  return (
    <article className={styles.article}>
      {!subtitle.img && subtitle?.thum?.url ? (
        <img
          src={subtitle.thum.url}
          alt="subtitle_thum_preview"
          className={styles.thumImg}
        />
      ) : (
        <></>
      )}
      {subtitle.img && (
        <img
          src={URL.createObjectURL(subtitle.img)}
          alt="subtitle_preview"
          className={styles.thumImg}
        />
      )}

      <SubTitleCanvas
        canvasWidth={1920}
        canvasHeight={1080}
        subtitle={subtitle}
      />
    </article>
  );
});
export default SubTitlePreview;

import React from "react";

interface HeadingTitleDescProps {
  title: string;
  subTitle: string;
  desc?: string;
  isLink?: boolean;
}

const HeadingTitleDesc: React.FC<HeadingTitleDescProps> = ({
  title,
  subTitle,
  desc,
  isLink,
}) => {
  return (
    <div className="head-title-desc">
      <h1 className="head-title big">{title}</h1>
      <h1 className="head-title big">{subTitle}</h1>
      {isLink ? (
        <button>
          <p className={`body-title small ${isLink && "linkText"}`}>{desc}</p>
        </button>
      ) : (
        <p className="body-title small">{desc}</p>
      )}
    </div>
  );
};

export default HeadingTitleDesc;

import React from "react";
import ImageCanvas from "./ImageCanvas";
import styles from "./ImagePreview.module.css";

interface ImagePreviewProps {
  image?: {
    img: File;
    thum?: {
      url: string;
    };

    top_left_x?: any;
    top_left_y?: any;
    top_right_x?: any;
    top_right_y?: any;
    bottom_right_x?: any;
    bottom_right_y?: any;
    bottom_left_x?: any;
    bottom_left_y?: any;
  };

  mode?: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ image, mode }) => {
  if (mode === "making") {
    return (
      <article className={styles.article}>
        {image && (
          <ImageCanvas
            canvasWidth={1920}
            canvasHeight={1080}
            image={image}
            mode={mode}
          />
        )}

        {image?.thum && image?.thum.url && (
          <img
            src={image?.thum.url}
            alt="subtitle_preview"
            className={styles.thumImg}
          />
        )}
      </article>
    );
  }

  return (
    <article className={styles.article}>
      {image && (
        <ImageCanvas canvasWidth={1920} canvasHeight={1080} image={image} />
      )}
      {image?.thum && image?.thum.url ? (
        <img
          src={image?.thum.url}
          alt="subtitle_preview"
          className={styles.thumImg}
        />
      ) : (
        image?.img && (
          <img
            src={URL.createObjectURL(image?.img)}
            alt="subtitle_preview"
            className={styles.thumImg}
          />
        )
      )}
    </article>
  );
};

export default ImagePreview;

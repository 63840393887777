import React, { useEffect, useState } from "react";
import styles from "./Events.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import SearchInput from "@components/Admin/Design/Input/SearchInput";

import MemorialCustomerAddModal from "@components/Admin/Memorial/Modal/MemorialCustomerAddModal";
import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import AdminEventTable from "@components/Admin/Event/AdminEventTable";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { addDays, endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import { MdRefresh } from "react-icons/md";

import AdminLoading from "@pages/Admin/AdminLoading";
import { useAuthContext } from "@context/AuthContext";

interface EventsProps {}

const Events: React.FC<EventsProps> = ({}) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigator = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    event_no: "",
    funeral_name: "",
    dm_name: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
    event_no: "",
    funeral_name: "",
    dm_name: "",
    state: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: searchParams.get("state") || "",
      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      event_no: searchParams.get("event_no") || "",
      funeral_name: searchParams.get("funeral_name") || "",
      dm_name: searchParams.get("dm_name") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  // user가 있는경우에만 조회
  const { isLoading, isError, data } = useQuery(
    ["getEvents", searchFilter],
    () => adminApi.getEvents({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateEvents = () => {
    queryClient.invalidateQueries(["getEvents"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.event_no) {
      queryParams.append("event_no", searchInput.event_no);
    }

    if (searchInput.funeral_name) {
      queryParams.append("funeral_name", searchInput.funeral_name);
    }

    if (searchInput.dm_name) {
      queryParams.append("dm_name", searchInput.dm_name);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    const startDate = subWeeks(startOfDay(new Date()), 1);
    const endDate = endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      state: "",
      page: 1,
      pageSize: 10,
    };

    const initialInput = {
      event_no: "",
      funeral_name: "",
      dm_name: "",
    };

    setQuickDateType("");
    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);

    navigator("");
  };

  return (
    <div>
      {isLoading && <AdminLoading />}
      <PageHeader
        title="추모영상 행사 관리"
        desc="추모영상을 선택한 고객들의 현황과, 제작을 돕습니다"
        // addButton="고객등록"
        // handleAddButton={() => setIsModalOpen(true)}
      />

      <section className={styles.funcSection}>
        <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="event_no">행사번호</label>
              <input
                id="event_no"
                type="text"
                value={searchInput.event_no}
                placeholder="행사번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="funeral_name">장례식장</label>
              <input
                id="funeral_name"
                value={searchInput.funeral_name}
                type="text"
                placeholder="장례식장을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="dm_name">고인명</label>
              <input
                id="dm_name"
                type="text"
                value={searchInput.dm_name}
                placeholder="고인명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}></div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <div className={styles.filterWraps}>
                <nav className={searchStyles.statusFilterNav}>
                  {/* 전체 */}
                  <button
                    className={`${!searchFilter.state && searchStyles.active}`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.delete("state");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>전체</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blackTag}`}
                    >
                      {data?.eventCount}
                    </span>
                  </button>

                  {/* 등록 */}
                  <button
                    className={`${
                      searchFilter.state === "prepared" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "prepared");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>제작 예정</span>

                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.blueTag}`}
                    >
                      {data?.preparedCount}
                    </span>
                  </button>

                  {/* 업로드 중 */}
                  <button
                    className={`${
                      searchFilter.state === "uploading" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "uploading");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>업로드 중</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.yellowTag}`}
                    >
                      {data?.uploadingCount}
                    </span>
                  </button>

                  {/* 업로드 완료 */}
                  <button
                    className={`${
                      searchFilter.state === "completed" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "completed");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>업로드 완료</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.indigoTag}`}
                    >
                      {data?.completedCount}
                    </span>
                  </button>

                  {/* 수정 중 */}
                  <button
                    className={`${
                      searchFilter.state === "modifying" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "modifying");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>수정 중</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.yellowTag}`}
                    >
                      {data?.modifyingCount}
                    </span>
                  </button>
                  {/* 편집 중 */}
                  <button
                    className={`${
                      searchFilter.state === "making" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "making");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>편집 중</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.redTag}`}
                    >
                      {data?.makingCount}
                    </span>
                  </button>

                  {/* 제작완료 */}
                  <button
                    className={`${
                      searchFilter.state === "done" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "done");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>제작 완료</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.greenTag}`}
                    >
                      {data?.doneCount}
                    </span>
                  </button>

                  {/* 확정 */}
                  <button
                    className={`${
                      searchFilter.state === "confirmed" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "confirmed");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>확정</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.greenTag}`}
                    >
                      {data?.confirmedCount}
                    </span>
                  </button>

                  <button
                    className={`${
                      searchFilter.state === "cancel" && searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "cancel");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>제작 취소</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.redTag}`}
                    >
                      {data?.cancelCount}
                    </span>
                  </button>

                  <button
                    className={`${
                      searchFilter.state === "registering" &&
                      searchStyles.active
                    }`}
                    type="button"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set("state", "registering");
                      navigator(`?${queryParams.toString()}`);
                    }}
                  >
                    <span className={searchStyles.statusText}>미 결제</span>
                    <span
                      className={`${searchStyles.statusTag} ${searchStyles.greyTag}`}
                    >
                      {data?.registeringCount}
                    </span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </SearchInput>
      </section>

      <main>
        <AdminEventTable
          events={data?.events}
          totalPages={data?.totalPages || 0}
          location={location}
          updateEvents={updateEvents}
        />
      </main>

      <MemorialCustomerAddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Events;

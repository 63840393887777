import React from "react";
import styles from "./TextUploadBottomModal.module.css";

interface TextUploadBottomModalProps {
  title: string;
  desc: string;
  topBtn: string;
  bottomBtn: string;

  topBtnClick: () => void;
  bottomBtnClick: () => void;
}

const TextUploadBottomModal: React.FC<TextUploadBottomModalProps> = ({
  title,
  desc,
  topBtn,
  topBtnClick,
  bottomBtn,
  bottomBtnClick,
}) => {
  return (
    <div className={styles.overlay}>
      <section className={styles.container}>
        <div className={styles.titleWrap}>
          <pre className={styles.title}>{title}</pre>
          <pre className={styles.desc}>{desc}</pre>
        </div>

        <div className={styles.buttonWrap}>
          <button onClick={topBtnClick}>{topBtn}</button>
          <button onClick={bottomBtnClick}>{bottomBtn}</button>
        </div>
      </section>
    </div>
  );
};

export default TextUploadBottomModal;

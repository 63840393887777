import NumberFormat from "@components/Ui/NumberFormat";
import React, { useEffect, useRef, useState } from "react";
import detailImg from "@assets/images/shop/test.png";
import detailImg2 from "@assets/images/shop/test2.png";
import detailImg3 from "@assets/images/shop/test3.png";
import { BiCaretDown, BiMinus, BiPlus, BiX } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";
import KakaoInquiryModal from "@components/Ui/KakaoInquiryModal";
import DataLoading from "@components/Ui/Loading/DataLoading";
import MainFooter from "@components/Home/MainFooter";
import { AgreeState } from "@interfaces/service";

import alertIcon from "@assets/images/service/alert_icon.png";
import DefaultModal from "@components/Service/Ui/DefaultModal";
import ServiceInfoInputModal from "@components/Service/ServiceInfoInputModal";

interface ShopDetailProps {}

const ShopDetail: React.FC<ShopDetailProps> = ({}) => {
  const { shopApi, serviceApi } = useApi();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const templateId = searchParams.get("templateId");
  const FUNERAL_NO = searchParams.get("FUNERAL_NO");
  const uid = searchParams.get("uid");

  const navigator = useNavigate();

  const imageRef = useRef<HTMLDivElement>(null);

  const [event, setEvent] = useState<any>(null);
  const [duplicatePayModal, setDuplicatePayModal] = useState(false);
  const [kakaoInquiryModalFlag, setKakaoInquiryModalFlag] = useState(false);

  const [thumImage, setThumImage] = useState({ target: "", file_path: "" });
  const [orderImage, setOrderImage] = useState({ target: "", file_path: "" });
  const [processImage, setProcessImage] = useState({
    target: "",
    file_path: "",
  });
  const [deliImage, setDeliImage] = useState({ target: "", file_path: "" });

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [detailView, setDetailView] = useState("order");
  const [isOrderVisible, setIsOrderVisible] = useState(false);
  const [isOptionSelectOpen, setIsOptionSelectOpen] = useState(0);

  const [shopOptionGroup, setShopOptionGroup] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const [infoModalFlag, setInfoModalFlag] = useState(false);
  const [agreeState, setAgreeState] = useState<AgreeState>({
    serviceAgree: false,
    privacyAgree: false,
    marketingAgree: false,
  });

  useEffect(() => {
    if (!isInitialRender) {
      if (imageRef.current) {
        window.scrollTo({
          top: imageRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      setIsInitialRender(false);
    }
  }, [detailView]);

  const handleAddOption = (option: any) => {
    setSelectedOptions((prev) => [
      ...prev,
      {
        ...option,
        id: option.option_id,
        name: option.option_name,
        price:
          selectedOptions.filter(
            (item) => item.option_group_id === option.option_group_id
          ).length >= option.base_quantity
            ? option.incremental_price
            : option.base_price,
        quantity: 1,
      },
    ]);

    // 선택된 옵션을 optionGroup의 options에서 제거
    setShopOptionGroup((prev) =>
      prev.map((group) => {
        if (group.option_group_id === option.option_group_id) {
          // 현재 옵션 그룹에서 선택된 옵션 제거
          return {
            ...group,
            ShopOptions: group.ShopOptions.filter(
              (o: any) => o.option_id !== option.option_id
            ),
          };
        }
        return group; // 다른 옵션 그룹은 그대로 두기
      })
    );

    setIsOptionSelectOpen(0);
  };

  const handleDeleteOption = (option: any) => {
    setShopOptionGroup((prev) =>
      prev.map((group) => {
        if (group.option_group_id === option.option_group_id) {
          // 현재 옵션 그룹에서 선택된 옵션 제거
          return {
            ...group,
            ShopOptions: [...group.ShopOptions, option].sort(
              (a, b) => a.option_id - b.option_id
            ),
          };
        }
        return group; // 다른 옵션 그룹은 그대로 두기
      })
    );

    setSelectedOptions((prev) => prev.filter((u) => u.id !== option.id));
  };

  const handleOptionQuantity = (option: any, step: string) => {
    setSelectedOptions((prev) => {
      return prev.map((item) => {
        if (item.id === option.id) {
          if (step === "plus") {
            return {
              ...item,
              quantity: item.quantity + 1,
              price: item.price + option.incremental_price,
            };
          } else if (step === "minus") {
            // 수량이 1보다 클 경우에만 감소
            if (item.quantity > 1) {
              return {
                ...item,
                quantity: item.quantity - 1,
                price: item.price - option.incremental_price,
              };
            }
          }
        }
        return item;
      });
    });
  };

  // 결제정보 저장 함수
  const handleSaveShopOrderInfo = async () => {
    try {
      if (selectedOptions.length === 0) {
        alert(`기본 제공되는 USB 상품을 선택하여 주십시요.`);
        setIsOptionSelectOpen(shopOptionGroup[0].option_group_id);
        return;
      }
      // const option_price = selectedOptions.reduce(
      //   (total, option) => total + option.price,
      //   0
      // );

      // const { shop_no, shop_price, shop_name } = shop;
      // const orderInfo = {
      //   pg: "html5_inicis.MOIcnememo",
      //   pay_method: "card",
      //   shop_no,
      //   name: shop_name,
      //   shop_price,
      //   option_price,
      //   amount: shop_price + option_price,
      //   // FUNERAL_NO: parseInt(FUNERAL_NO as string),
      //   // EventUid: parseInt(uid as string),
      //   quantity: 1,
      //   options: selectedOptions.map((item) => ({
      //     option_id: item.option_id,
      //     option_name: item.name,
      //     option_price: item.price,
      //     option_quantity: item.quantity,
      //   })),

      //   shop_managed_cost: shop.shop_managed_flag ? shop.shop_managed_cost : 0,
      // };

      // const res = await shopApi.saveOrUpdateOrder({ orderInfo });
      // const { result } = res;

      // if (!result) return;

      // const { shopOrder } = result;

      // navigator(`/shops/order?merchant_uid=${shopOrder.merchant_uid}`);
    } catch (error) {
      console.error(error);
      alert("네트워크 문제로 오류가 발생하였습니다. 다시 시도하여 주십시요");
    }
  };
  const handlePurchase = async () => {
    if (!uid && !FUNERAL_NO) {
      setKakaoInquiryModalFlag(true);
      return;
    }

    const event = await serviceApi.checkEvent(uid);

    if (!event) {
      setKakaoInquiryModalFlag(true);
      return;
    }

    if (event?.ShopOrders?.length > 0) {
      setDuplicatePayModal(true);
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("shop_no", shop.shop_no);
    queryParams.set("templateId", shop.TemplateId);
    navigator(`/shops/order?${queryParams.toString()}`);
  };

  const handleBack = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("shop_no");
    queryParams.delete("templateId");
    navigator(`/shops/videos?${queryParams.toString()}`);
  };

  const registEventMember = async (eventUser: any) => {
    try {
      if (!eventUser?.name?.trim()) {
        alert("성함을 입력하여 주십시요.");
        return;
      }
      if (!eventUser?.phone_number?.trim()) {
        alert("휴대폰 번호를 입력하여 주십시요.");
        return;
      }

      if (!agreeState.serviceAgree) {
        alert("서비스 이용약관 동의는 필수 입니다.");
        return;
      }

      if (!agreeState.privacyAgree) {
        alert("개인정보 수집 및 이용 동의는 필수 입니다.");
        return;
      }

      const params = {
        uid,
        eventUser: {
          ...eventUser,
          service_agree: agreeState.serviceAgree,
          privacy_agree: agreeState.privacyAgree,
          marketing_agree: agreeState.marketingAgree,
        },
        state: "prepared",
        templateId: shop.TemplateId,
        shop: {
          shop_no: shop.shop_no,
          shop_price: shop.shop_price,
          shop_name: shop.shop_name,
        },
      };
      serviceApi.registerEvent(params).then((res: any) => {
        if (!res) return;
        navigator(`/service/info-check?uid=${res.event.uid}`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDirectMakeVideo = async () => {
    const event = await serviceApi.checkEvent(uid);
    setEvent(event);

    setInfoModalFlag(true);

    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("shop_no", shop.shop_no);
    // queryParams.set("templateId", shop.TemplateId);
    // navigator(`/service/info-check?uid=${uid}`);
  };

  useEffect(() => {
    const appElement = document.getElementById("app");
    if (isOrderVisible && appElement) {
      appElement.style.overflow = "hidden";
    } else if (appElement) {
      appElement.style.overflow = "";
    }
  }, [isOrderVisible]);

  const {
    isLoading,
    isError,
    data: shop,
  } = useQuery(
    ["getShopTemplate", templateId],
    async () =>
      await shopApi.getShopTemplate({
        templateId: parseInt(templateId as string),
        FUNERAL_NO: FUNERAL_NO ? parseInt(FUNERAL_NO as string) : undefined,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    const detailImages = shop?.ShopDetailImages;

    if (detailImages && detailImages.length !== 0) {
      setThumImage(detailImages.find((img: any) => img.target === "thum"));
      setOrderImage(detailImages.find((img: any) => img.target === "order"));
      setProcessImage(
        detailImages.find((img: any) => img.target === "process")
      );
      setDeliImage(detailImages.find((img: any) => img.target === "deli"));
    }

    const optionGroup = shop?.ShopOptionGroups;

    if (optionGroup && optionGroup.length !== 0) {
      setShopOptionGroup(optionGroup);
    }
  }, [shop]);

  return (
    <div id="shop-detail-page">
      {isLoading && <DataLoading />}

      <DefaultModal
        icon={alertIcon}
        title="결제가 완료된 행사입니다"
        mainText={`결제가 완료되어\n서비스 진행이 가능합니다. \n\n아래 제작하기 버튼을 통해\n제작을 진행하여 주십시요\n\n고객지원\n1577-3264`}
        buttonText="제작하기"
        handleClick={() => navigator(`/service/info-check?uid=${uid}`)}
        isOpen={duplicatePayModal}
        onClose={() => setDuplicatePayModal(false)}
      />

      <KakaoInquiryModal
        title="상주 전용 서비스입니다."
        mainText={`상주님께서는 알림톡 내 \n추모영상 제작하기 버튼을 통해\n해당 상품을 구매해 주시기 바랍니다.\n\n문의 또는 도움이 필요하신 경우\n아래 문의처를 통해 연락주시면 최대한\n신속하게 도와드리겠습니다.\n\n고객지원\n1577-3264 `}
        isOpen={kakaoInquiryModalFlag}
        onClose={() => setKakaoInquiryModalFlag(false)}
      />

      <div
        className={`overlay ${isOrderVisible ? "hideWrap" : ""} `}
        onClick={() => setIsOrderVisible(false)}
      ></div>
      <main>
        <div className="thum_wrap">
          <img src={thumImage.file_path} alt="thumImage" />
          {/* <div>
            <span>Premium</span>
            <h2>추억 보관 서비스</h2>
          </div>
          <div>
            <h6>소중한 순간을 </h6>
          </div>

          <div>
            <h6>더 많이 기억하고 싶으신가요?</h6>
            <h6>더 오래 간직하고 싶으신가요?</h6>
          </div> */}
        </div>

        <div className="detail_wrap">
          <ul className="list">
            <li
              className={detailView === "order" ? "active" : ""}
              onClick={() => setDetailView("order")}
            >
              상품 정보
            </li>
            <li
              className={detailView === "process" ? "active" : ""}
              onClick={() => setDetailView("process")}
            >
              제작 과정
            </li>
            <li
              className={detailView === "deli" ? "active" : ""}
              onClick={() => setDetailView("deli")}
            >
              배송 및 환불
            </li>
          </ul>

          <div ref={imageRef} className="detail-page_img">
            {shop?.Template?.sample_url && detailView === "order" && (
              <iframe
                title="Product Video"
                src={shop?.Template?.sample_url}
                width="1920"
                height="1080"
                className="sample_video"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}

            {shop?.Template?.short_sample_url && detailView === "order" && (
              <iframe
                title="Product Video"
                src={shop?.Template?.short_sample_url}
                width="1920"
                height="1080"
                className="short_sample_video"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
            {detailView === "order" ? (
              <img src={orderImage.file_path} alt="상품 정보" />
            ) : detailView === "process" ? (
              <img src={processImage.file_path} alt="제작 과정" />
            ) : (
              <img src={deliImage.file_path} alt="배송 및 환불" />
            )}
          </div>

          <MainFooter />
        </div>
      </main>

      {/* 하단 주문 창 */}
      <section
        className={`bottom-order_section ${
          !isOrderVisible ? "hide" : ""
        } expand-order_section`}
      >
        <div className="option-select-box_wrap">
          {shopOptionGroup?.map((optionGroup: any) => (
            <>
              <div
                key={optionGroup.option_group_id}
                className="option-select_wrap"
                onClick={() => {
                  if (isOptionSelectOpen == optionGroup.option_group_id) {
                    setIsOptionSelectOpen(0);
                  } else {
                    setIsOptionSelectOpen(optionGroup.option_group_id);
                  }
                }}
              >
                <p>{optionGroup.group_name}</p>
                <BiCaretDown />
              </div>

              {isOptionSelectOpen == optionGroup.option_group_id && (
                <div className="select-box_wraps">
                  {optionGroup.ShopOptions.map((option: any) => (
                    <div
                      key={`op_${option.option_id}`}
                      onClick={() => handleAddOption(option)}
                    >
                      <p>{option.option_name}</p>
                      <p>
                        +
                        <NumberFormat
                          number={
                            selectedOptions.filter(
                              (item) =>
                                item.option_group_id === option.option_group_id
                            ).length >= option.base_quantity
                              ? option.incremental_price
                              : option.base_price
                          }
                        />
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </>
          ))}
        </div>

        <div className="option-box_wraps">
          {selectedOptions.map((option) => (
            <div key={`so_${option.id}`} className="option-box_wrap">
              <div className="box_wrap">
                <p>{option.name}</p>

                <button
                  className="close-btn"
                  onClick={() => handleDeleteOption(option)}
                >
                  <BiX />
                </button>
              </div>

              <div className="box_wrap">
                <div className="quantity_wrap">
                  <BiMinus
                    onClick={() => handleOptionQuantity(option, "minus")}
                  />

                  <span>{option.quantity}</span>

                  <BiPlus
                    onClick={() => handleOptionQuantity(option, "plus")}
                  />
                </div>
                <p>
                  <NumberFormat number={option.price} />원
                </p>
              </div>
            </div>
          ))}

          <div className="option-box_wrap">
            <p>{shop?.shop_name}</p>
            <p>
              <NumberFormat number={shop?.shop_price} />원
            </p>
          </div>
        </div>
        <div className="border-line"></div>
        <div className="info_wrap">
          <div className="price-info_wrap">
            <span>주문금액</span>
            <p>
              <NumberFormat
                number={
                  shop?.shop_price +
                  selectedOptions.reduce((total, usb) => total + usb.price, 0)
                }
              />
              원
            </p>
          </div>
        </div>

        {/* 결제 정보 등록 */}
        <button className="buy_btn" onClick={handleSaveShopOrderInfo}>
          <span> 구매하기 </span>
        </button>
      </section>

      {/* 구매하기 버튼만 */}
      {shop?.shop_price == 0 ? (
        <>
          <ServiceInfoInputModal
            event={event}
            isOpen={infoModalFlag}
            onClose={() => setInfoModalFlag(false)}
            registEventMember={registEventMember}
            agreeState={agreeState}
            setAgreeState={setAgreeState}
          />
          <section className={`bottom-order_section free-shop_section`}>
            <button className="back_btn" onClick={handleBack}>
              <span>돌아가기 </span>
            </button>
            <button className="buy_btn" onClick={handleDirectMakeVideo}>
              <span> 바로 제작하기 </span>
            </button>
          </section>
        </>
      ) : (
        <section
          className={`bottom-order_section ${isOrderVisible ? "hide" : ""}`}
        >
          <div className="info_wrap">
            <p>{shop?.shop_name}</p>
            <div className="price-info_wrap">
              <span>{shop?.sale_amount}%</span>
              <p>
                <NumberFormat number={shop?.shop_price} />원
              </p>
            </div>
          </div>
          {/* <button className="buy_btn" onClick={() => setIsOrderVisible(true)}>
          <span> 구매하기</span>
        </button> */}
          <button className="buy_btn" onClick={handlePurchase}>
            <span> 구매하기 </span>
          </button>
        </section>
      )}
    </div>
  );
};

export default ShopDetail;

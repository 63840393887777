import { useEffect, useRef } from "react";

export const useCanvas = (canvasWidth, canvasHeight, animation) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    const setCanvas = () => {
      const devicePixelRatio = window.devicePixelRatio ?? 1;

      if (canvas && ctx) {
        canvas.width = 1920;
        canvas.height = 1080;

        ctx.scale(devicePixelRatio, devicePixelRatio);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    };
    setCanvas();

    if (ctx) {
      animation(ctx);
    }
  }, [canvasWidth, canvasHeight, animation]);

  return canvasRef;
};

import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";

interface EventImageDownloaderProps {
  dm_name: string;
  images: [];
}

const EventImageDownloader: React.FC<EventImageDownloaderProps> = ({
  images,
  dm_name,
}) => {
  const downloadImagesAsZip = async (images: any) => {
    if (images?.length === 0) {
      alert("업로드 된 이미지가 없습니다");
      return;
    }

    const zip = new JSZip();

    for (const image of images) {
      const response = await axios.get(image.url, {
        responseType: "blob",
      });
      zip.file(`0${image.id}.png`, response.data);
    }

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${dm_name}_이미지.zip`);
  };

  return (
    <button onClick={() => downloadImagesAsZip(images)}>
      이미지 일괄 다운로드
    </button>
  );
};

export default EventImageDownloader;

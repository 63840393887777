import React from "react";
import styles from "./ShopOrderComplete.module.css";
import orderCheckBox from "@assets/images/shop/order_checkbox.png";
import orderAlertCheckBox from "@assets/images/shop/alert_check.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import NumberFormat from "@components/Ui/NumberFormat";
import DataLoading from "@components/Ui/Loading/DataLoading";
import MainFooter from "@components/Home/MainFooter";

interface ShopOrderCompleteProps {}

const ShopOrderComplete: React.FC<ShopOrderCompleteProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();
  const { shopApi } = useApi();

  const searchParams = new URLSearchParams(location.search);
  const imp_uid = searchParams.get("imp_uid");
  const error_msg = searchParams.get("error_msg");
  const imp_success = searchParams.get("imp_success");
  const merchant_uid = searchParams.get("merchant_uid");

  const { isLoading, isError, data } = useQuery(
    ["getOrder", imp_uid],
    () => shopApi.getOrder(imp_uid),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const handleCopy = async () => {
    const textToCopy = `${data?.vbank_name} ${data?.vbank_num}`;

    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(textToCopy);
        alert("클립보드에 복사되었습니다.");
      } else {
        fallbackCopyTextToClipboard(textToCopy);
        alert("클립보드에 복사되었습니다.");
      }
    } catch (err) {
      fallbackCopyTextToClipboard(textToCopy);
      alert("클립보드에 복사되었습니다.");
    }
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.top = "0";
    textarea.style.left = "0";
    textarea.style.position = "fixed";

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textarea);
  };

  const handleRetyPayment = () => {
    const { EventUid, FUNERAL_NO, shop_no, TemplateId } = data;
    navigator(
      `/shops/order?uid=${EventUid}&FUNERAL_NO=${FUNERAL_NO}&templateId=${TemplateId}&shop_no=${shop_no}&merchant_uid=${merchant_uid}`
    );
  };

  const errorMessage = () => {
    if (!error_msg) return null;

    let decoded = decodeURIComponent(error_msg.replace(/\+/g, " "));
    let parts = decoded.split("(");
    return parts[0].trim().split(":")[1].trim();
  };

  if (error_msg && imp_success === "false") {
    return (
      <>
        <div className={styles.shopOrderCompletePage}>
          {isLoading && <DataLoading />}
          <div className={styles.titleWrap}>
            <img src={orderAlertCheckBox} alt="checkbox" />
            <h4>주문이 실패되었습니다</h4>
          </div>

          <main>
            <div className={styles.infoBox}>
              <div className={styles.infoWrap}>
                <h6>결제 상태 </h6>
                <p>실패</p>
              </div>

              <div className={styles.infoWrap}>
                <h6>실패 사유 </h6>
                <p>{errorMessage() || "-"}</p>
              </div>
            </div>
          </main>

          <button className={styles.retryButton} onClick={handleRetyPayment}>
            다시 주문하기
          </button>
        </div>

        <MainFooter page="orderComplete" />
      </>
    );
  }

  if (data?.pay_method === "vbank" && data?.status === "ready") {
    return (
      <>
        <div className={styles.shopOrderCompletePage}>
          {isLoading && <DataLoading />}
          <div className={styles.titleWrap}>
            <img src={orderCheckBox} alt="checkbox" />
            <h4>주문이 완료되었습니다</h4>
          </div>

          <main>
            <div className={styles.vbankBox}>
              <h4>가상계좌 정보</h4>
              <button onClick={handleCopy}>계좌번호 복사</button>
            </div>

            <div className={styles.infoBox}>
              <div className={styles.infoWrap}>
                <h6>입금계좌</h6>
                <p>
                  {data?.vbank_name || "-"} <br />
                  {data?.vbank_num || "-"}
                </p>
              </div>

              <div className={styles.infoWrap}>
                <h6>예금주 </h6>
                <p>{data?.vbank_holder || "-"}</p>
              </div>

              <div className={styles.infoWrap}>
                <h6>입금기한 </h6>
                <p>
                  {dayjs(data?.vbank_date).format("YYYY-MM-DD HH:mm") || "-"}
                </p>
              </div>

              <div className={styles.infoWrap}>
                <h6 className={styles.price}>결제 금액 </h6>
                <p className={styles.price}>
                  {data?.amount ? <NumberFormat number={data?.amount} /> : "-"}{" "}
                  원
                </p>
              </div>
            </div>
            <div className={styles.infoBox}>
              <div className={styles.infoWrap}>
                <h6>주문번호</h6>
                <p>{data?.imp_uid || "-"}</p>
              </div>
              <div className={styles.infoWrap}>
                <h6>상품명</h6>
                <p>{data?.name || "-"}</p>
              </div>

              <div className={styles.infoWrap}>
                <h6>총수량</h6>
                <p>{data?.quantity || "-"}개</p>
              </div>
            </div>

            <div className={styles.infoBox}>
              <div className={styles.infoWrap}>
                <h6>주문자명</h6>
                <p>{data?.buyer_name || "-"}</p>
              </div>
              <div className={styles.infoWrap}>
                <h6>연락처</h6>
                <p>{data?.buyer_tel || "-"}</p>
              </div>

              <div className={styles.infoWrap}>
                <h6>주소</h6>
                <p>
                  {data?.receive_addr || "-"} {data?.receive_addr_detail}
                </p>
              </div>
            </div>
          </main>
        </div>

        <MainFooter page="orderComplete" />
      </>
    );
  }

  return (
    <>
      <div className={styles.shopOrderCompletePage}>
        {isLoading && <DataLoading />}
        <div className={styles.titleWrap}>
          <img src={orderCheckBox} alt="checkbox" />
          <h4>주문이 완료되었습니다</h4>
        </div>

        <main>
          <div className={styles.infoBox}>
            <div className={styles.infoWrap}>
              <h6>주문번호</h6>
              <p>{data?.imp_uid || "-"}</p>
            </div>
            <div className={styles.infoWrap}>
              <h6>상품명</h6>
              <p>{data?.name || "-"}</p>
            </div>

            <div className={styles.infoWrap}>
              <h6>총수량</h6>
              <p>{data?.quantity || "-"}개</p>
            </div>
          </div>

          <div className={styles.infoBox}>
            <div className={styles.infoWrap}>
              <h6>주문자명</h6>
              <p>{data?.buyer_name || "-"}</p>
            </div>
            <div className={styles.infoWrap}>
              <h6>연락처</h6>
              <p>{data?.buyer_tel || "-"}</p>
            </div>

            <div className={styles.infoWrap}>
              <h6>주소</h6>
              <p>
                {data?.receive_addr || "-"} {data?.receive_addr_detail}
              </p>
            </div>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.infoWrap}>
              <h6>결제일시</h6>
              <p> {data?.paid_dt || "-"}</p>
            </div>
            {/* <div className={styles.infoWrap}>
            <h6>결제수단 </h6>
            <p>{data?.pay_method}</p>
          </div>
*/}
            <div className={styles.infoWrap}>
              <h6>결제구분 </h6>
              <p>
                {data?.card_quota === 0
                  ? "일시불"
                  : data?.card_quota
                  ? `${data?.card_quota}개월`
                  : "-"}
              </p>
            </div>

            <div className={styles.infoWrap}>
              <h6 className={styles.price}>결제 금액 </h6>
              <p className={styles.price}>
                {data?.amount ? <NumberFormat number={data?.amount} /> : "-"} 원
              </p>
            </div>
          </div>
        </main>

        <button
          onClick={() => navigator(`/service/info-check?uid=${data?.EventUid}`)}
          className={styles.orderBottomBtn}
        >
          제작하기
        </button>
      </div>

      <MainFooter page="orderComplete" />
    </>
  );
};

export default ShopOrderComplete;

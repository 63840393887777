import React, { useState } from "react";

import mainImage from "@assets/images/service/video-3D-icon 1.svg";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import Loading from "@components/Service/Ui/Loading";
import Error from "@components/Service/Ui/Error";

import alertIcon from "@assets/images/service/alert_icon.png";

import { GoChevronRight } from "react-icons/go";
import DefaultModal from "@components/Service/Ui/DefaultModal";

import kakao from "@assets/images/service/kakao.png";
import dayjs from "dayjs";
import { FaRegStar, FaStar } from "react-icons/fa6";

interface CompleteProps {}

const Complete: React.FC<CompleteProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const [confirmModal, setCofirmModal] = useState(false);
  const [feedback, setFeedback] = useState({ description: "", rating: 5 });

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(["getEvent", uid], () => serviceApi.getEvent(uid), {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: false,
  });

  const queryClient = useQueryClient();

  const updateEvent = () => {
    queryClient.invalidateQueries(["getEvent"]);
  };

  const handleModifying = () => {
    if (
      event?.EventImages &&
      event?.EventImages.some((image: any) => image.uid.startsWith("repeat_"))
    ) {
      serviceApi.deleteEventRepeatImage({ uid: event.uid });
    }
    serviceApi
      .updateEvent({
        uid: event.uid,
        state: "modifying",
        modify_count: event.modify_count + 1,
      })
      .then(() => {
        navigator(`/service/info-check?uid=${uid}`);
      });
  };

  const handleConfirmed = () => {
    const date = new Date();
    const nowInKorea = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    serviceApi
      .updateEvent({
        uid: event.uid,
        state: "confirmed",
        confirmed_dt: nowInKorea,
      })
      .then(() => {
        updateEvent();
      });
  };

  const handleFeedback = () => {
    serviceApi
      .saveFeedback({
        name: `${event?.FuneralInfo?.FUNERAL_NAME} ${event?.EventUsers[0].name}`,
        description: feedback.description,
        rating: feedback.rating,
        EventUid: event.uid,
      })
      .then((res: any) => {
        if (!res) return;

        alert("등록되었습니다.");
        setFeedback({ description: "", rating: 5 });
      });
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  // 렌더링 완료 영상 미리보기
  if (event?.state === "done") {
    return (
      <div id="service-complete-page" className="modifying_wrap">
        <DefaultModal
          icon={alertIcon}
          title="영상을 확정하시겠습니까?"
          mainText={`확정한 이후에는 USB에 담아 배송되어\n추가 수정이 어려울 수 있습니다.\n\n더이상 수정할 부분이 없으신 경우에만\n확정하기를 진행해주세요.`}
          buttonText="확정하기"
          handleClick={handleConfirmed}
          isOpen={confirmModal}
          onClose={() => setCofirmModal(false)}
        />
        <section className="main-image_wrap">
          <div className="head-title-desc">
            <h1 className="head-title x-large">{`故 ${event?.dm_name} 님의`}</h1>
            <h1 className="head-title x-large">
              추모영상 제작이 완료되었습니다
            </h1>
          </div>
          <div className="video_wrap">
            <iframe
              title="Product Video"
              src={event?.embed_url}
              width="1920"
              height="1080"
              className="sample_video"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        {event?.template_state === "premium" && (
          <section className="tip-section">
            <div className="title_wrap">
              <h6>확정 안내</h6>
            </div>

            <p>
              더이상 수정할 부분이 없으신 경우
              <br />
              확정하기 버튼을 눌러 USB를 배송 받아보세요.
            </p>

            <div className="tip-bottom_btns">
              <button onClick={() => setCofirmModal(true)}>확정하기</button>
            </div>
          </section>
        )}

        <section className="tip-section">
          <div className="title_wrap">
            <h6>수정 안내</h6>
          </div>

          <p>
            사진 및 자막을 수정하시고 싶으신 경우
            <br />
            수정하기 버튼을 눌러 진행해주세요.
          </p>

          <p>
            추가 사진(30장 이상) 또는 영상 삽입은 카카오톡 채널을 통해
            문의해주세요.
          </p>

          <Link to="http://pf.kakao.com/_tdxjxmK/chat" target="_blank">
            카카오톡으로 문의하기 <GoChevronRight />
          </Link>

          <div className="tip-bottom_btns">
            <button onClick={handleModifying}>수정하기</button>
          </div>
        </section>

        {/* 나중에 반복이미지 삭제해야할 때 살리기 */}
        {/* <ServiceMainButton
          title=""
          handleClick={() => {
            serviceApi.deleteEventRepeatImage({ uid: event.uid });

            serviceApi
              .updateEvent({
                uid: event.uid,
                state: "modifying",
                modify_count: event.modify_count + 1,
              })
              .then(() => {
                navigator(`/service/info-check?uid=${uid}`);
              });
          }} 
        />*/}
      </div>
    );
  }

  if (event?.state === "confirmed") {
    return (
      <div id="service-complete-page" className="modifying_wrap">
        <section className="main-image_wrap">
          <div className="head-title-desc">
            <h1 className="head-title x-large">{`故 ${event?.dm_name} 님의`}</h1>
            <h1 className="head-title x-large">
              추모영상 제작이 확정되었습니다
            </h1>
          </div>
          <div className="video_wrap">
            <iframe
              title="Product Video"
              src={event?.embed_url}
              width="1920"
              height="1080"
              className="sample_video"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <section className="tip-section">
          <div className="title_wrap">
            <h6>배송 안내</h6>
          </div>

          <p>
            <span>배송비는 무료</span>입니다.
            <br />
            (단, 제주 및 산간 지역은 별도 추가 비용이 발생)
          </p>

          <p>
            출고일로부터 <span>평군 2~3일</span> 정도 소요됩니다. <br />
            (주말 / 공휴일 제외)
          </p>

          <p>
            택배사 사정과 천재지변으로 지연 또는 배송 폭주, 품절 등으로 인한
            미출고 시 별도 안내드립니다.
          </p>

          <p>
            문의 또는 도움이 필요하신 경우 아래 문의처로 연락 주시면 신속하게
            도와드리겠습니다.
          </p>

          <button
            className="kakao_btn"
            onClick={() => {
              window.open("http://pf.kakao.com/_tdxjxmK/chat", "_blank");
            }}
          >
            <img src={kakao} alt="kakao" />
            카카오톡으로 문의하기
          </button>
        </section>

        {/* 서비스 이용 후기 (피드백) */}
        {!event?.Feedback && (
          <section className="feedback-section">
            <div className="title_wrap">
              <h6>서비스 이용 후기</h6>
            </div>

            <div className="stars">
              {Array(5)
                .fill(null)
                .map((_, starIndex) =>
                  starIndex < feedback.rating ? (
                    <button
                      key={starIndex}
                      onClick={() =>
                        setFeedback((prev) => ({
                          ...prev,
                          rating: starIndex + 1,
                        }))
                      }
                    >
                      <FaStar />
                    </button>
                  ) : (
                    <button
                      key={starIndex}
                      onClick={() =>
                        setFeedback((prev) => ({
                          ...prev,
                          rating: starIndex + 1,
                        }))
                      }
                    >
                      <FaRegStar />
                    </button>
                  )
                )}
            </div>

            <div className="feedback-textarea_wrap">
              <textarea
                placeholder="추모영상 서비스에 대한 후기를 남겨주세요."
                value={feedback.description}
                onChange={(e) =>
                  setFeedback((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
              <button
                className={feedback.description ? "active" : ""}
                onClick={handleFeedback}
              >
                등록하기
              </button>
            </div>
          </section>
        )}
      </div>
    );
  }

  // 제작 중 안내
  return (
    <div id="service-complete-page">
      <section className="main-notice_section">
        <div className="title_wrap">
          <h1 className="head-title x-large">
            {`故 ${event?.dm_name} 님의`} <br /> 추모영상이 제작되고 있습니다
          </h1>

          <p className="body-title small">
            최대 2시간 이내로 제작이 완료됩니다
          </p>
        </div>
        <div className="image_wrap">
          <img
            src={mainImage}
            alt="메인 이미지"
            className="service_main-image"
          />
          <p>
            제작 완료 후 알림 메시지가 전송되고 <br /> 전광판에서 보실 수
            있습니다
          </p>
        </div>

        {event?.template_state === "short" && (
          <button
            className="add-upload_btn"
            onClick={() => navigator(`/service/uploads?uid=${uid}`)}
          >
            추가 업로드 후 제작하기
          </button>
        )}
      </section>
    </div>
  );
};

export default Complete;

import React, { ReactNode } from "react";
import styles from "./TableHeader.module.css";

interface TableHeaderProps {
  children: ReactNode;
}

const TableHeader: React.FC<TableHeaderProps> = ({ children }) => {
  return <tr className={styles.tableHeader}>{children}</tr>;
};

export default TableHeader;

import React from "react";
import HeadingTitleDesc from "@components/Ui/Title/HeadingTitleDesc";

interface MainNoticeSectionProps {
  title: string;
  subTitle: string;
  desc?: string;
  subDesc?: string;
  children?: React.ReactNode;
}

const MainNoticeSection: React.FC<MainNoticeSectionProps> = ({
  title,
  subTitle,
  desc,
  subDesc,
  children,
}) => {
  return (
    <section className="main-notice_section">
      <HeadingTitleDesc title={title} subTitle={subTitle} desc={desc} />
      <div>{children}</div>
    </section>
  );
};

export default MainNoticeSection;

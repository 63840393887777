import Error from "@components/Service/Ui/Error";
import Loading from "@components/Service/Ui/Loading";
import ServiceImageChange from "@components/Service/Uploads/Images/ServiceImageChange";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import { useApi } from "@context/ApiContext";
import { ServiceImageProps } from "@interfaces/service";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface ImageEditProps {}

const ImageEdit: React.FC<ImageEditProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const [imageList, setImageList] = useState<ServiceImageProps[]>([]);
  const [selectedImage, setSelectedImage] = useState<ServiceImageProps | null>(
    null
  );

  const handleImageSave = (
    exImgId: string | undefined,
    newImgSrc: Blob | File
  ) => {
    // imageList에서 해당 ID를 가진 객체를 찾아 src를 업데이트
    const updatedImageList = imageList.map((img) => {
      if (img.uid === exImgId) {
        if (img.public_id) {
          delete img.public_id;
          delete img.url;
        }

        return { ...img, src: newImgSrc };
      }

      return img;
    });

    setImageList(updatedImageList);
  };

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(
    ["getEvent", uid],
    () => {
      return serviceApi.getEvent(uid).then((res: any) => {
        if (!res) return null;

        setImageList(res.EventImages);
        setSelectedImage(res.EventImages[0]);
        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <div id="service-upload">
      <BasicTitle title={`영상에 들어가는 \n사진을 편집할 수 있습니다`} />

      <main>
        {/* <ServiceImageChange
          selectedImage={selectedImage}
          onImageSave={handleImageSave}
        /> */}
      </main>
    </div>
  );
};

export default ImageEdit;

import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminCalsTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";
import NumberFormat from "@components/Ui/NumberFormat";

interface AdminCalsTableProps {
  LV: number;
  funeralCalList: any;
  totalPages: number;
  location?: any;
  updateFuneralCals?: () => void;
}

const AdminCalsTable: React.FC<AdminCalsTableProps> = ({
  LV,
  funeralCalList,
  totalPages,
  updateFuneralCals,
}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const pageCount = totalPages;
  const page = searchParams.get("page") || 1;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  if ([99, 89].includes(LV)) {
    return (
      <>
        <Table>
          <TableBody>
            <TableHeader>
              <th className={styles.exSmallTh}>번호</th>
              <th className={styles.exSmallTh}>장례식장명</th>
              <th className={styles.exSmallTh}>제작건수</th>
              <th className={styles.exSmallTh}>취소건수</th>
              <th className={styles.exSmallTh}>거래액</th>
              <th className={styles.exSmallTh}>거래취소액</th>
              <th className={styles.exSmallTh}>순거래액</th>
              <th className={styles.smallTh}>식장 (원)</th>
              <th className={styles.smallTh}>동성 (원)</th>
              <th className={styles.smallTh}>시네모 (원)</th>

              <th className={styles.exSmallTh}>상세</th>
            </TableHeader>
            {funeralCalList?.length === 0 ? (
              <tr>
                <td>데이터가 없습니다</td>
              </tr>
            ) : (
              funeralCalList?.map((funeral: any, idx: number) => (
                <tr key={funeral?.FUNERAL_NO}>
                  <td> {idx + 1 + 10 * (+page - 1)}</td>
                  <td>{funeral?.FUNERAL_NAME}</td>
                  <td>
                    <NumberFormat number={funeral.FuneralCals?.length || 0} />
                  </td>
                  <td>
                    <NumberFormat
                      number={
                        funeral.FuneralCals?.filter(
                          (cal: any) => cal.status === "cancelled"
                        ).length || 0
                      }
                    />
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.offer_amount),
                        0
                      )}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "cancelled"
                      ).reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.offer_amount),
                        0
                      )}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "paid"
                      ).reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.offer_amount),
                        0
                      )}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "paid"
                      ).reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.funeral_amount),
                        0
                      )}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "paid"
                      ).reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.ds_amount),
                        0
                      )}
                    />
                    원
                  </td>
                  <td>
                    <NumberFormat
                      number={funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "paid"
                      ).reduce(
                        (accumulator: any, cal: any) =>
                          accumulator + parseInt(cal.cnemo_amount),
                        0
                      )}
                    />
                    원
                  </td>

                  <td>
                    <button className={styles.detailsButton}>
                      <Link to={`/admin/dashboard/cals/${funeral?.FUNERAL_NO}`}>
                        상세보기
                      </Link>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </TableBody>
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="다음 >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< 이전"
          renderOnZeroPageCount={null}
          previousClassName={pageStyles.pageItem}
          nextClassName={pageStyles.pageItem}
          breakClassName={pageStyles.break}
          containerClassName={pageStyles.pagination}
          pageClassName={pageStyles.pageItem}
          activeClassName={pageStyles.active}
          disabledClassName={pageStyles.disabled}
        />
      </>
    );
  }

  return (
    <>
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>번호</th>
            <th>장례식장명</th>
            <th className={styles.exSmallTh}>제작건수</th>
            <th className={styles.exSmallTh}>취소건수</th>

            <th className={styles.exSmallTh}>상세</th>
          </TableHeader>
          {funeralCalList?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            funeralCalList?.map((funeral: any, idx: number) => (
              <tr key={funeral?.FUNERAL_NO}>
                <td> {idx + 1 + 10 * (+page - 1)}</td>
                <td>{funeral?.FUNERAL_NAME}</td>
                <td>
                  <NumberFormat number={funeral.FuneralCals?.length || 0} />건
                </td>
                <td>
                  <NumberFormat
                    number={
                      funeral.FuneralCals?.filter(
                        (cal: any) => cal.status === "cancelled"
                      ).length || 0
                    }
                  />
                  건
                </td>

                <td>
                  <button className={styles.detailsButton}>
                    <Link to={`/admin/dashboard/cals/${funeral?.FUNERAL_NO}`}>
                      상세보기
                    </Link>
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminCalsTable;

import Error from "@components/Service/Ui/Error";
import Loading from "@components/Service/Ui/Loading";
import NumberFormat from "@components/Ui/NumberFormat";
import { useApi } from "@context/ApiContext";
import { GoPlusCircle } from "react-icons/go";
import alertIcon from "@assets/images/service/alert_icon.png";

import {
  Iamport,
  RequestPayParams,
  RequestPayResponse,
} from "@interfaces/portone";
import PortOne from "@portone/browser-sdk/v2";
import { useQuery } from "@tanstack/react-query";

import React, { useState } from "react";
import { FaArrowLeftLong, FaChevronDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultModal from "@components/Service/Ui/DefaultModal";
import axios from "axios";
import DataLoading from "@components/Ui/Loading/DataLoading";

interface ShopOrderProps {}
declare global {
  interface Window {
    IMP?: Iamport;
  }
}

interface ShopOrder extends RequestPayParams {
  shop_price: number;
  quantity: number;
  deli_cost: number;
  receive_name: string;
  receive_postcode: string;
  receive_addr: string;
  receive_addr_detail: string;
  receive_tel: string;
  receive_phone1: string;
  receive_phone2: string;
  receive_phone3: string;

  deli_memo: string;
  agree_status: boolean;
  shop_no: number;
  FUNERAL_NO: number;
  EventUid: string;
}

const ShopOrder: React.FC<ShopOrderProps> = ({}) => {
  const { shopApi, funeralApi, serviceApi } = useApi();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const shop_no = searchParams.get("shop_no");
  const templateId = searchParams.get("templateId");
  const uid = searchParams.get("uid");
  const FUNERAL_NO = searchParams.get("FUNERAL_NO");
  const merchant_uid = searchParams.get("merchant_uid");

  const navigator = useNavigate();

  // state
  const [customDeliMemo, setCustomDeliMemo] = useState("");
  const [duplicatePayModal, setDuplicatePayModal] = useState(false);

  const [newShopOrder, setNewShopOrder] = useState<ShopOrder>({
    pg: "html5_inicis.MOIcnememo",
    pay_method: "card",
    merchant_uid: "", // 주문번호

    amount: 1000, // 결제금액
    name: "", // 주문명
    buyer_name: "", // 구매자 이름
    buyer_tel: "", // 구매자 전화번호
    notice_url: "",
    shop_price: 0,
    quantity: 1,
    deli_cost: 0,
    receive_name: "",
    receive_postcode: "",
    receive_addr: "",
    receive_addr_detail: "",
    receive_tel: "",
    receive_phone1: "", //010
    receive_phone2: "", //1234
    receive_phone3: "", // 1234
    deli_memo: "",
    agree_status: false,

    shop_no: parseInt(shop_no as string),
    FUNERAL_NO: parseInt(FUNERAL_NO as string),
    EventUid: uid as string,
  });

  const handleOrderInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    if (id === "agree_status") {
      setNewShopOrder((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    } else {
      setNewShopOrder((prev) => ({ ...prev, [id]: value }));
    }
  };

  // 우편번호 검색 클릭 시
  const handleOpenPostCode = async () => {
    new (window as any).daum.Postcode({
      oncomplete: async (data: any) => {
        const mainAddress = data.roadAddress || data.jibunAddress;

        setNewShopOrder((prev) => ({
          ...prev,

          receive_postcode: data?.zonecode,
          receive_addr: mainAddress,
        }));
      },
    }).open();
  };

  const handlePayment = async (step?: string) => {
    const event = await serviceApi.checkEvent(uid);

    if (event?.ShopOrders?.length > 0) {
      setDuplicatePayModal(true);
      return;
    }

    const updateOrder = validateInput();
    if (!updateOrder) {
      return;
    }
    if (step === "add") {
      updateOrder.merchant_uid = "";
    }
    try {
      if (!window.IMP) {
        console.error("초기화 안됌");
        return;
      }
      const { IMP } = window;
      IMP.init(process.env.REACT_APP_PORTONE_CODE as string); // 가맹점 식별코드
      /* 2. 결제 데이터 정의하기 */
      const orderInfo: RequestPayParams = {
        ...updateOrder,
      };
      /* 3. DB에 주문서 저장하기*/
      const data = await shopApi.saveOrUpdateOrder({ orderInfo });

      if (data.statusCode === "0003") {
        navigator(`/shops`, { replace: true });
        return;
      }

      // 이미 결제 되어 있는 행사
      if (data.statusCode === "0004") {
        navigator(`/service/new-event?event_no=${data.result.event.uid}`, {
          replace: true,
        });
        return;
      }

      if (!data.result) return;

      const { shopOrder } = data.result;
      setNewShopOrder((prev) => ({ ...prev, ...shopOrder }));

      if (data.statusCode === "0001") {
        navigator(`/shops/complete?imp_uid=${shopOrder.imp_uid}`, {
          replace: true,
        });
      }
      // 가상계좌 입금 대기
      if (data.statusCode === "0002") {
        navigator(`/shops/complete?imp_uid=${shopOrder.imp_uid}`, {
          replace: true,
        });
        return;
      }

      // const shopOrderString = JSON.stringify(shopOrder, null, 2); // 가독성을 위해 들여쓰기 추가

      // axios
      //   .post(`${process.env.REACT_APP_API_URL}/api/logs`, {
      //     message: "결제 요청 실패",
      //     shopOrder: shopOrderString,
      //     error: JSON.stringify(data, Object.getOwnPropertyNames(data)),
      //     orderId: shopOrder.imp_uid,
      //     timestamp: new Date().toISOString(),
      //   })
      //   .catch((logError) => {
      //     console.error("로그 전송 실패:", logError);
      //   });
      /* 4. 결제 창 호출하기 */
      if (data.statusCode === 200) {
        console.log("결제창 호출");

        try {
          IMP.request_pay(
            {
              ...shopOrder,
              notice_url: `${process.env.REACT_APP_API_URL}/api/order/portone-webhook`,
              m_redirect_url: `${process.env.REACT_APP_BASE_URL}/shops/complete?imp_uid=${shopOrder.imp_uid}`,
            },
            callback
          );
        } catch (error) {
          // 결제 시도 중 오류가 발생한 경우
          console.error("결제 요청 실패:", error);

          // 사용자에게 오류 메시지 표시
          alert("결제 요청 중 오류가 발생했습니다. 다시 시도해 주세요.");

          // 오류 정보를 서버로 전송하여 로그 기록 (예: axios를 사용하여 서버로 전송)
          axios
            .post(`${process.env.REACT_APP_API_URL}/api/logs`, {
              message: "결제 요청 실패",
              error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
              orderId: shopOrder.imp_uid,
              timestamp: new Date().toISOString(),
            })
            .catch((logError) => {
              console.error("로그 전송 실패:", logError);
            });
        }
      } else {
        alert("결제를 다시 시도하여 주십시요");
      }
    } catch (error) {
      console.error(error);
      alert("결제를 실패하였습니다");
    }
  };

  /* 3. 콜백 함수 정의하기 */
  const callback = (response: RequestPayResponse) => {
    const { success, error_msg, imp_uid } = response;

    if (success) {
      navigator(`/shops/complete?imp_uid=${imp_uid}`);
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  };

  // 유효성 검사
  const validateInput = () => {
    const {
      receive_name,
      receive_postcode,
      receive_addr,
      receive_addr_detail,
      receive_phone1,
      receive_phone2,
      receive_phone3,
      agree_status,
    } = newShopOrder;

    if (!receive_name?.trim()) {
      alert("주문자를 입력해 주세요.");
      return false;
    }

    if (!receive_postcode) {
      alert("우편번호를 입력하여 주십시오");
      return false;
    }
    if (!receive_name || !receive_addr.trim()) {
      alert("배송지를 입력하여 주십시오");
      return false;
    }

    if (!receive_name || !receive_addr_detail.trim()) {
      alert("상세주소를 입력하여 주십시오");
      return false;
    }

    if (
      !receive_phone1?.trim() ||
      !receive_phone2?.trim() ||
      !receive_phone3?.trim()
    ) {
      alert("휴대폰 번호를 입력하여 주십시오");
      return false;
    }

    // 개인정보 동의
    if (!agree_status) {
      alert("개인정보 동의에 체크하여 주세요.");
      return false;
    }

    const updateOrder = {
      ...newShopOrder,
      receive_tel:
        newShopOrder?.receive_phone1 +
        newShopOrder?.receive_phone2 +
        newShopOrder?.receive_phone3,
      buyer_name: receive_name,
      buyer_tel:
        newShopOrder?.receive_phone1 +
        newShopOrder?.receive_phone2 +
        newShopOrder?.receive_phone3,

      name: shop?.shop_name,
      shop_price: shop?.shop_price,
      deli_cost: 0,
      amount: parseInt(shop?.shop_price) + 0,
    };

    // 배송 메모가 직접입력인 경우
    if (customDeliMemo.trim()) {
      updateOrder.deli_memo = customDeliMemo;
    }

    setNewShopOrder(updateOrder);
    return updateOrder;
  };

  const handleBackRouting = () => {
    if (
      window.confirm(
        "페이지를 벗어나면 입력된 주문 정보가 사라집니다. 계속하시겠습니까?"
      )
    ) {
      const queryParams = new URLSearchParams(location.search);
      navigator(`/service/new-event?event_no=${queryParams.get("uid")}`, {
        replace: true,
      });

      return;
    }
  };

  const {
    isLoading,
    isError,
    data: shop,
  } = useQuery(
    ["getShopTemplate", templateId],
    async () =>
      await shopApi.getShopTemplate({
        templateId: parseInt(templateId as string),
        FUNERAL_NO: FUNERAL_NO ? parseInt(FUNERAL_NO as string) : undefined,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: funerals } = useQuery(
    ["getFunerals"],
    async () => await funeralApi.getFunerals(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: order } = useQuery(
    ["getReOrder", merchant_uid],
    async () => {
      if (merchant_uid) {
        const order = await shopApi.getReOrder(merchant_uid);
        setNewShopOrder((prev) => ({
          ...prev,
          ...order,
          receive_phone1: order?.receive_tel.slice(0, 3),
          receive_phone2: order?.receive_tel.slice(3, 7),
          receive_phone3: order?.receive_tel.slice(7),
        }));

        return order;
      }

      return null;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  // const formattedPrice = useNumberFormat(shopOrder?.shop_price);

  return (
    <div id="shop-order-page">
      {isLoading && <DataLoading />}

      <DefaultModal
        icon={alertIcon}
        title="결제가 완료된 행사입니다"
        mainText={`결제가 완료되어\n서비스 진행이 가능합니다. \n\n아래 제작하기 버튼을 통해\n제작을 진행하여 주십시요\n\n고객지원\n1577-3264`}
        buttonText="제작하기"
        handleClick={() => navigator(`/service/info-check?uid=${uid}`)}
        isOpen={duplicatePayModal}
        onClose={() => setDuplicatePayModal(false)}
      />

      <header>
        <button onClick={handleBackRouting}>
          <FaArrowLeftLong />
        </button>
      </header>
      {/* 고인정보 */}
      {!uid && !FUNERAL_NO && (
        <section className="order-section">
          <div className="order-title_wrap">
            <h4>고인정보</h4>
            <FaChevronDown />
          </div>
          <div className="order-deil-input_wraps">
            <div className="order-deil-select_wrap">
              <label className="required" htmlFor="receive_postcode">
                장례식장
              </label>

              <select
                id="deli_memo"
                value={newShopOrder?.deli_memo}
                onChange={handleOrderInputChange}
              >
                <option value="">장례식장을 선택해주세요</option>

                {funerals?.map((funeral: any) => (
                  <option
                    key={funeral.FUNERAL_NO}
                    value={funeral?.FUNERAL_NAME}
                  >
                    {funeral?.FUNERAL_NAME}
                  </option>
                ))}
              </select>
            </div>
            <div className="order-deil-select_wrap">
              <label className="required" htmlFor="receive_postcode">
                빈소선택
              </label>

              <select
                id="deli_memo"
                value={newShopOrder?.deli_memo}
                onChange={handleOrderInputChange}
              >
                <option value="">빈소를 선택해주세요</option>
              </select>
            </div>

            <div className="order-deil-input_wrap">
              <label className="required" htmlFor="receive_name">
                고인명
              </label>
              <input
                id="receive_name"
                type="text"
                placeholder="주문자명을 입력하세요"
                value={newShopOrder?.receive_name}
                onChange={handleOrderInputChange}
              />
            </div>

            {newShopOrder?.deli_memo === "custom" && (
              <div className="order-deil-input_wrap">
                <input
                  type="text"
                  placeholder="요청사항을 입력하세요"
                  value={customDeliMemo}
                  onChange={(e) => setCustomDeliMemo(e.target.value)}
                />
              </div>
            )}
          </div>
        </section>
      )}

      {/* 배송정보 */}
      <section className="order-section">
        <div className="order-title_wrap">
          <h4>배송정보</h4>
          <FaChevronDown />
        </div>
        <div className="order-deil-input_wraps">
          <div className="order-deil-input_wrap">
            <label className="required" htmlFor="receive_name">
              주문자명
            </label>
            <input
              id="receive_name"
              type="text"
              placeholder="주문자명을 입력하세요"
              value={newShopOrder?.receive_name}
              onChange={handleOrderInputChange}
            />
          </div>

          <div className="order-deil-input_wrap addr-input_wrap">
            <label className="required" htmlFor="receive_postcode">
              배송지
            </label>

            <div>
              <input
                id="receive_postcode"
                type="text"
                readOnly
                value={newShopOrder?.receive_postcode}
                onClick={handleOpenPostCode}
              />

              <button className="postcode-btn" onClick={handleOpenPostCode}>
                우편번호 검색
              </button>
            </div>
          </div>
          <div className="order-deil-input_wrap">
            <label htmlFor="receive_addr"></label>

            <input
              id="receive_addr"
              type="text"
              value={newShopOrder?.receive_addr}
              onChange={handleOrderInputChange}
            />
          </div>
          <div className="order-deil-input_wrap">
            <label htmlFor="receive_addr_detail"></label>

            <input
              id="receive_addr_detail"
              type="text"
              placeholder="상세주소를 입력하세요"
              value={newShopOrder?.receive_addr_detail}
              onChange={handleOrderInputChange}
            />
          </div>

          <div className="order-deil-input_wrap phone-input_wrap">
            <label className="required" htmlFor="receive_phone1">
              연락처1
            </label>
            <div>
              <input
                id="receive_phone1"
                type="tel"
                value={newShopOrder?.receive_phone1}
                onChange={handleOrderInputChange}
              />
              <span> - </span>
              <input
                id="receive_phone2"
                type="tel"
                value={newShopOrder?.receive_phone2}
                onChange={handleOrderInputChange}
              />
              <span> - </span>
              <input
                id="receive_phone3"
                type="tel"
                value={newShopOrder?.receive_phone3}
                onChange={handleOrderInputChange}
              />
            </div>
          </div>

          <div className="order-deil-select_wrap">
            <select
              id="deli_memo"
              value={newShopOrder?.deli_memo}
              onChange={handleOrderInputChange}
            >
              <option value="">배송시 요청사항을 선택해 주세요</option>
              <option value="문 앞에 놔주세요">문 앞에 놔주세요</option>
              <option value="경비실에 맡겨주세요">경비실에 맡겨주세요</option>
              <option value="custom">직접입력</option>
            </select>
          </div>
          {newShopOrder?.deli_memo === "custom" && (
            <div className="order-deil-input_wrap">
              <input
                type="text"
                placeholder="요청사항을 입력하세요"
                value={customDeliMemo}
                onChange={(e) => setCustomDeliMemo(e.target.value)}
              />
            </div>
          )}
        </div>
      </section>

      <section className="order-section">
        <div className="order-title_wrap">
          <h4>주문상품 정보</h4>
        </div>

        <div className="order-shop-info_wrap">
          <div>
            <h6>{shop?.shop_name}</h6>
            <p className="order-shop-info_price">
              - 장례식장 상영 <br /> - USB 3개 포함 <br /> - 무제한 링크 제공
            </p>

            {/* <p className="order-shop-info_option">옵션</p> */}

            {/* {shopOrder?.ShopOrderOptions?.map((item: any) => (
              <p className="order-shop-info_price">
                {item.option_name} / 가격 :
                <NumberFormat number={item.option_price} />원 / 수량 : {""}
                {item.option_quantity}개
              </p>
            ))} */}
          </div>
          <img src={shop?.shop_image} alt="상품 이미지" />
        </div>
      </section>

      <section className="order-section">
        <div className="order-title_wrap">
          <h4>결제금액</h4>
          <div className="order-price_wrap">
            <span>
              <b>
                <NumberFormat number={shop?.shop_price} />
              </b>
              원
            </span>
            <FaChevronDown />
          </div>
        </div>

        <div className="order-price-info_wrap">
          <div className="price_wrap">
            <h6>상품금액</h6>
            <span>
              <NumberFormat number={shop?.shop_price} />원
            </span>
          </div>
          <GoPlusCircle />

          <div className="price_wrap">
            <h6>배송비</h6>
            <span>
              <NumberFormat number={0} />원
            </span>
          </div>
        </div>

        <div className="info_wrap">
          <p>배송 비용은 주문 금액에 포함됩니다.</p>
          <p>(단, 제주 및 특수 도서 산간 지역은 별도 추가 운임비 발생)</p>
        </div>
      </section>

      <section className="order-section last-section">
        <div className="order-title_wrap">
          <h4>결제방법</h4>
          <FaChevronDown />
        </div>

        <div className="order-pay-methods_wrap">
          <div>
            <button
              className={newShopOrder?.pay_method === "card" ? "active" : ""}
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "card" }))
              }
            >
              신용/체크카드
            </button>
            <button
              className={
                newShopOrder?.pay_method === "kakaopay" ? "active" : ""
              }
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "kakaopay" }))
              }
            >
              카카오페이
            </button>
          </div>
          <div>
            <button
              className={
                newShopOrder?.pay_method === "naverpay" ? "active" : ""
              }
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "naverpay" }))
              }
            >
              네이버페이
            </button>
            <button
              className={newShopOrder?.pay_method === "tosspay" ? "active" : ""}
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "tosspay" }))
              }
            >
              토스페이
            </button>
          </div>
          <div>
            <button
              className={newShopOrder?.pay_method === "samsung" ? "active" : ""}
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "samsung" }))
              }
            >
              삼성페이
            </button>
            <button
              className={newShopOrder?.pay_method === "vbank" ? "active" : ""}
              onClick={() =>
                setNewShopOrder((prev) => ({ ...prev, pay_method: "vbank" }))
              }
            >
              가상계좌
            </button>
          </div>
        </div>

        <div className="order-agree_wrap">
          <input
            id="agree_status"
            type="checkbox"
            checked={newShopOrder?.agree_status}
            onChange={handleOrderInputChange}
          />
          <label htmlFor="agree_status">
            주문하실 상품 및 결제, 주문정보를 확인하였으며, 이에 동의합니다.
            (필수)
          </label>
        </div>
      </section>

      <button className="order-bottom-btn" onClick={() => handlePayment()}>
        <NumberFormat number={shop?.shop_price} /> 원 결제하기
      </button>
    </div>
  );
};

export default ShopOrder;

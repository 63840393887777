import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";

import styles from "./ContactTable.module.css";
import dayjs from "dayjs";

interface ContactTableProps {
  contacts: any;

  location?: any;
}

const ContactTable: React.FC<ContactTableProps> = ({ contacts }) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.smallTh}>문의자명</th>
            <th className={styles.middleTh}>이메일</th>
            <th className={styles.largeTh}>내용</th>
            <th className={styles.smallTh}>등록일</th>
          </TableHeader>
          {!contacts || contacts.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            contacts?.map((contact: any, index: number) => (
              <tr className={styles.orderTr} key={index}>
                <td>{contact?.contact_name || "-"}</td>
                <td>{contact?.contact_email || "-"}</td>
                <td className={styles.contentTd}>{contact?.contact_content}</td>

                <td>{dayjs(contact?.create_dt).format("YYYY-MM-DD")}</td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ContactTable;

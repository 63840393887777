import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Tick,
  Scale,
  CoreScaleOptions,
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barOptions: ChartOptions<"bar"> = {
  scales: {
    y: {
      ticks: {
        callback: function (
          this: Scale<CoreScaleOptions>,
          tickValue: string | number,
          index: number,
          ticks: Tick[]
        ) {
          return typeof tickValue === "number" ? `${tickValue}건` : tickValue;
        },
        font: {
          size: 14,
        },
        color: "#A2A2A2",
      },
    },
    x: {
      ticks: {
        font: {
          size: 14,
        },
        color: "#A2A2A2",
      },
    },
  },
};

interface MakingBarChartProps {
  label: string;
}

const MakingBarChart: React.FC<MakingBarChartProps> = ({ label }) => {
  const [data, setData] = useState<{
    labels: (string | string[])[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  }>({
    labels: [],
    datasets: [
      {
        label: "전체 유입수",
        data: [65, 59, 140, 81, 56, 55, 40],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "상세페이지 유입",
        data: [65, 59, 140, 81, 56, 55, 40],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "구매전환",
        data: [10, 11, 16, 21, 46, 56, 20],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    let dynamicLabels: string[];

    if (label === "today") {
      dynamicLabels = Array.from(
        { length: 24 },
        (_, i) => `${i.toString().padStart(2, "0")}:00`
      );

      setData((prev) => ({
        ...prev,
        labels: dynamicLabels,
      }));
    } else {
      switch (label) {
        case "weeks":
          dynamicLabels = Array.from({ length: 7 }, (_, i) =>
            dayjs().subtract(i, "day").format("MM-DD")
          );
          break;
        case "months":
          dynamicLabels = Array.from({ length: 30 }, (_, i) =>
            dayjs().subtract(i, "day").format("MM-DD")
          );
          break;
        case "years":
          dynamicLabels = Array.from({ length: 12 }, (_, i) =>
            dayjs().subtract(i, "month").format("YYYY-MM")
          );
          break;
        default:
          dynamicLabels = Array.from({ length: 24 }, (_, i) =>
            dayjs().hour(i).format("HH:mm")
          );
          break;
      }

      setData((prev) => ({
        ...prev,
        labels: dynamicLabels.reverse(),
      }));
    }
  }, [label]);

  return <Bar data={data} options={barOptions} />;
};

export default MakingBarChart;

// src/job.js
import { useState, useEffect } from "react";

const NEXRENDER_JOB_POLLING =
  process.env.REACT_APP_NEXRENDER_JOB_POLLING || 10 * 1000;

export const useJob = (
  wrappedFetch,
  initialJob,
  polling = NEXRENDER_JOB_POLLING
) => {
  const [job, setJob] = useState(initialJob);
  const [error, setError] = useState(null);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const updatedJob = await wrappedFetch(`/jobs/${job.uid}/status`);

        if (updatedJob.state !== job.state) {
          setJob(updatedJob);
        }

        if (job.state === "finished" || job.state === "error") {
          clearInterval(interval);
        }
      } catch (err) {
        clearInterval(interval);
        setError(err);
      }
    }, polling);

    return () => clearInterval(interval);
  }, [job, wrappedFetch, polling]);

  return [job, error];
};

export const jobAPI = (wrappedFetch, polling) => ({
  listJobs: async () => await wrappedFetch(`/jobs`),
  fetchJob: async (id) => await wrappedFetch(`/jobs/${id}`),
  pickupJob: async (selector) =>
    await wrappedFetch(`/jobs/pickup${selector ? `/${selector}` : ""}`),

  addJob: async (data) => {
    return await wrappedFetch(`/jobs`, {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    });
  },

  updateJob: async (id, data) =>
    await wrappedFetch(`/jobs/${id}`, {
      method: "put",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    }),

  removeJob: async (id) =>
    await wrappedFetch(`/jobs/${id}`, {
      method: "delete",
    }),
});

// Deprecated methods
export const removejob = async (id) => {
  console.warn(
    "`removejob()` has been deprecated and will be removed in a future version. Please use `removeJob()` instead."
  );
  return jobAPI.removeJob(id);
};

export const getJob = async (id) => {
  console.warn(
    "`getJob()` has been deprecated and will be removed in a future version. Please use `resumeJob()` instead."
  );
  return jobAPI.resumeJob(id);
};

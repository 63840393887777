import React, { useEffect, useRef, useState } from "react";
import styles from "./ShopAddModal.module.css";
import Modal from "react-modal";
import imageCompression from "browser-image-compression";

import { useApi } from "@context/ApiContext";
import { uploadImage } from "@apis/uploader";
import AdminLoading from "@pages/Admin/AdminLoading";
import { TemplateObj } from "@interfaces/admin";

interface ShopAddModalProps {
  shop: any;
  shopCategory: any;
  isOpen: boolean;
  updateShops: () => void;
  onClose: () => void;
  templates: TemplateObj[];
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "600px",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
    overflowY: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

type ServerApiContextType = any;
const ShopAddModal: React.FC<ShopAddModalProps> = ({
  shop,
  shopCategory,
  isOpen,
  updateShops,
  onClose,
  templates,
}) => {
  const { adminApi } = useApi() as ServerApiContextType;

  const [loading, setLoading] = useState(false);
  const [newShop, setNewShop] = useState({
    shop_name: "",
    shop_price: "",
    shop_sell_flag: true,
    shop_managed_flag: false,
    shop_managed_cost: "",
    shop_image: "",
    ShopCategoryId: "",
    TemplateId: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    type NewShopKey = keyof typeof newShop;

    if (["shop_sell_flag", "shop_managed_flag"].includes(id as NewShopKey)) {
      setNewShop((prev) => ({ ...prev, [id]: !prev[id as NewShopKey] }));
    } else {
      setNewShop((prev) => ({ ...prev, [id as NewShopKey]: value }));
    }
  };
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = () => {
    // 버튼 클릭 시 input의 click 메서드 호출

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageFileChange = (e: any) => {
    let uploadFile = e.target.files[0];

    setFile(uploadFile);
  };

  const compressImage = async (imageFile: any) => {
    const options = {
      maxSizeMB: 10, // 5MB로 설정
      useWebWorker: true,
      quality: 0.8,
    };

    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      console.error("압축 중 오류 발생:", error);
    }
  };

  const handleSaveShop = async () => {
    setLoading(true);
    if (!file) {
      adminApi
        .saveOrUpdateShop({
          newShop: {
            ...newShop,
            shop_no: parseInt(shop?.shop_no),
            ShopCategoryId: parseInt(newShop.ShopCategoryId),
            TemplateId: parseInt(newShop.TemplateId),
          },
          state: shop ? "update" : "create",
        })
        .then((res: any) => {
          if (!res) return;

          setLoading(false);
          alert("완료되었습니다");
          updateShops();
          onClose();
        });

      return;
    }

    const compressedFile = await compressImage(file);
    uploadImage(compressedFile, `shops/thum/${newShop.shop_name}`).then(
      (imageData) => {
        setNewShop((prev) => ({ ...prev, shop_image: imageData.url }));

        adminApi
          .saveOrUpdateShop({
            newShop: {
              ...newShop,
              shop_no: parseInt(shop?.shop_no),
              shop_image: imageData.url,
            },
            state: shop ? "update" : "create",
          })
          .then((res: any) => {
            if (!res) return;
            setLoading(false);
            alert("완료되었습니다");
            updateShops();
            onClose();
          });
      }
    );
  };

  useEffect(() => {
    if (shop) {
      setNewShop(shop);
    } else {
      setNewShop({
        shop_name: "",
        shop_price: "",
        shop_sell_flag: true,
        shop_managed_flag: false,
        shop_managed_cost: "",
        shop_image: "",
        ShopCategoryId: "",
        TemplateId: "",
      });
    }
  }, [shop]);

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} style={customModalStyles}>
      <h3 className={styles.title}>상품 {shop ? "수정" : "등록"}</h3>

      {loading && <AdminLoading />}
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="ShopCategoryId">카테고리 선택</label>
          <select
            id="ShopCategoryId"
            value={newShop?.ShopCategoryId}
            onChange={handleInputChange}
          >
            <option value="">카테고리 선택</option>
            {shopCategory?.map((category: any) => (
              <option key={category.id} value={category?.id}>
                {category?.shop_category_name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="TemplateId">프로젝트 선택</label>
          <select
            id="TemplateId"
            value={newShop?.TemplateId}
            onChange={handleInputChange}
          >
            <option value="">프로젝트 선택</option>
            {templates?.map((template: any) => (
              <option key={template.id} value={template?.id}>
                {template?.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="shop_name">상품명</label>
          <input
            id="shop_name"
            type="text"
            placeholder="상품명을 입력하세요"
            value={newShop.shop_name}
            onChange={handleInputChange}
            readOnly={shop}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="shop_price">가격</label>

          <input
            id="shop_price"
            type="text"
            placeholder="가격을 입력하세요"
            value={newShop.shop_price}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="shop_sell_flag">판매유무</label>
          <input
            id="shop_sell_flag"
            type="checkbox"
            checked={newShop.shop_sell_flag}
            onChange={handleInputChange}
          />
          <span>{newShop.shop_sell_flag ? "판매" : "미판매"}</span>
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="shop_managed_flag">관리비 적용</label>
          <input
            id="shop_managed_flag"
            type="checkbox"
            checked={newShop.shop_managed_flag}
            onChange={handleInputChange}
          />
          <span>{newShop.shop_managed_flag ? "적용" : "미적용"}</span>
        </div>
        {newShop.shop_managed_flag && (
          <div className={styles.inputWrap}>
            <label htmlFor="shop_managed_cost">관리비용</label>

            <input
              id="shop_managed_cost"
              type="text"
              placeholder="관리비용을 입력하세요"
              value={newShop.shop_managed_cost}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className={styles.inputWrap}>
          <label htmlFor="shop_managed_cost">상품 이미지</label>

          <button
            className={styles.uploadBtn}
            onClick={handleImageUpload}
            type="button"
          >
            이미지 업로드
          </button>

          <input
            ref={fileInputRef}
            type="file"
            hidden
            onChange={handleImageFileChange}
          />
        </div>

        <div className={styles.imagePreviewWrap}>
          {file && (
            <img
              src={URL.createObjectURL(file)}
              alt="subtitle_preview"
              className={styles.thumImg}
            />
          )}
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={handleSaveShop}>
          {shop ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default ShopAddModal;

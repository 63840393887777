import React, { useEffect, useRef, useState } from "react";
import styles from "./TemplateMaking.module.css";

import { uploadImage } from "@apis/uploader";
import createClient from "@apis/nexrender/nexrender-api";
import TemplateEdit from "./TemplateEdit";
import { addNewQueue } from "@apis/firebase/queues";
import PageHeader from "../Design/PageHeader/PageHeader";
import { TemplateObj } from "@interfaces/admin";
import { useNavigate } from "react-router-dom";

interface TemplateMakingProps {
  template: TemplateObj;
}

const TemplateMaking: React.FC<TemplateMakingProps> = ({ template }) => {
  const navigator = useNavigate();

  const NEXRENDER_API = process.env.REACT_APP_NEXRENDER_API;
  const BASE_URL = "http://18.221.25.236";

  const client = createClient({
    host: BASE_URL,
    secret: NEXRENDER_API,
  });

  const [preview, setPreview] = useState(template?.subtitle[0]);
  const [imagePreview, setImagePreview] = useState(template?.images[0]);

  const [job, setJob] = useState({
    template: {
      src: template.s3_url,
      composition: "Render",
      outputExt: "mp4",
      outputModule: "H.264 - Match Render Settings - 15 Mbps",
    },
    assets: template.assets,
    actions: {
      postrender: [
        {
          module: "@nexrender/action-encode",
          output: "C:\\Users\\cnemo\\Desktop\\output\\nexrenderTest.mp4",
        },
      ],
    },
  });

  const handleChangeSubtitle = (e: any) => {
    const { name, value } = e.target;

    setJob((prev) => ({
      ...prev,
      assets: [
        ...prev?.assets.map((asset) =>
          asset.layerName === name
            ? {
                ...asset,
                value,
              }
            : asset
        ),
      ],
    }));
  };

  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = () => {
    // 버튼 클릭 시 input의 click 메서드 호출
    if (files.length >= +template.photo_length) {
      // alert("사진 장수를 초과해서 업로드 할 수 없습니다");
      // return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageFileChange = (e: any) => {
    let uploadFiles = e.target.files;

    if (uploadFiles.length > +template.photo_length) {
      alert("필요한 사진 장수 만큼만 업로드 됩니다");
    }

    setFiles([...files, ...uploadFiles]);
    // setFiles((prev) => prev.slice(0, +template.photo_length));
  };

  const [isAssetsUpdated, setIsAssetsUpdated] = useState(false);

  const uploadAndSetAssets = async () => {
    let updatedAssets = [...job.assets];

    for (const file of files) {
      const imageData = await uploadImage(
        file,
        `template/${template.name}/upload`
      );
      const assetToUpdate = updatedAssets.find(
        (asset) => asset.type === "image" && asset.src === ""
      );
      if (assetToUpdate) {
        assetToUpdate.src = imageData.url;
      }
    }

    setJob((prev) => ({ ...prev, assets: updatedAssets }));
    setIsAssetsUpdated(true); // 상태 업데이트가 완료되었음을 표시
  };

  const handleAddJob = async () => {
    await uploadAndSetAssets();
  };

  const onCrop = async (image: string) => {};

  const [step, setStep] = useState("upload");

  useEffect(() => {
    if (isAssetsUpdated) {
      const addJobToClient = async () => {
        const res = await client.addJob(job);

        await addNewQueue(res);
      };
      addJobToClient();
      setIsAssetsUpdated(false); // 상태 업데이트 플래그를 리셋
    }
  }, [isAssetsUpdated, job, client]);

  if (step === "upload") {
    return (
      <div>
        <PageHeader
          title={template.name}
          addButton="홈으로"
          handleAddButton={() => {
            navigator("/admin/dashboard/templates");
          }}
        />
        <main className={styles.mainWarp}>
          {/* preview section */}
          <section className={styles.previewSection}>
            <img src={preview?.thum?.url} alt="preview" />
            <h4>{preview?.title}</h4>
            <p>위 이미지에 들어갈 자막을 입력하세요</p>
          </section>

          {/* input section */}
          <section className={styles.inputSection}>
            <div>
              <div className={styles.inputSectionHeader}>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  <span>Render video</span>
                </h3>

                <button type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 text-gray-500 -ml-1 mr-2"
                  >
                    <path d="M17 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM17 15.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM3.75 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM4.5 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM10 11a.75.75 0 01.75.75v5.5a.75.75 0 01-1.5 0v-5.5A.75.75 0 0110 11zM10.75 2.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM10 6a2 2 0 100 4 2 2 0 000-4zM3.75 10a2 2 0 100 4 2 2 0 000-4zM16.25 10a2 2 0 100 4 2 2 0 000-4z"></path>
                  </svg>
                  Options
                </button>
              </div>

              <div className={styles.inputSectionBody}>
                <h4>자막 입력</h4>
                <p>필요한 자막 : {template.subtitle.length}개</p>
                {template?.subtitle.map((subtitle) => (
                  <div className={styles.inputLabelWrap}>
                    <div className={styles.labelWrap}>
                      <label
                        htmlFor={subtitle.id}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {subtitle.title}
                      </label>
                    </div>
                    <div className={styles.inputWrap}>
                      <textarea
                        autoComplete="off"
                        id={subtitle.id}
                        name={subtitle.layerName}
                        aria-describedby="Heading-description"
                        value={
                          job?.assets?.filter(
                            (asset) => asset.layerName === subtitle.layerName
                          )[0]?.value || ""
                        }
                        onChange={handleChangeSubtitle}
                        placeholder={`${subtitle.title} 입력`}
                        onFocus={() => setPreview(subtitle)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.imageUploadWrap}>
                <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  multiple
                  onChange={handleImageFileChange}
                />
                <div>
                  <h4>이미지 업로드</h4>
                  <p>필요한 사진 장수 : {template.photo_length}장</p>
                </div>
                <button onClick={handleImageUpload}>파일 업로드</button>
              </div>

              <div className={styles.imagePreviewWrap}>
                {files?.length !== 0 &&
                  files.map((file) => (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="subtitle_preview"
                      className={styles.thumImg}
                    />
                  ))}
              </div>

              <div className={styles.bottmWrap}>
                {/* <button
                  className={styles.nextButton}
                  onClick={() => setStep("edit")}
                >
                  다음 단계
                </button> */}
                <button className={styles.nextButton} onClick={handleAddJob}>
                  제작하기
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }

  return (
    <TemplateEdit
      imageList={files}
      imagePreview={imagePreview}
      onCrop={onCrop}
    />
  );
};

export default TemplateMaking;

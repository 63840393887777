export default class ServiceApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async checkEvent(event_no) {
    return this.apiClient
      .checkEvent(event_no)
      .then((res) => {
        const { data } = res;

        if (data.statusCode === 204) {
          return null;
        }

        if (data.statusCode !== 200) {
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async registerEvent(params) {
    return this.apiClient
      .registerEvent(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async selectedEventUser(uid, userId) {
    return this.apiClient
      .selectedEventUser(uid, userId)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async getEvent(uid) {
    return this.apiClient
      .getEvent(uid)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  // 행사정보와 주문 배송지 업데이트
  async updateEventOrder(params) {
    return this.apiClient
      .updateEventOrder(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async updateEvent(params) {
    return this.apiClient
      .updateEvent(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async saveEventImageURL(params) {
    return this.apiClient
      .saveEventImageURL(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async deleteEventImageURL(params) {
    return this.apiClient
      .deleteEventImageURL(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async deleteEventRepeatImage(params) {
    return this.apiClient
      .deleteEventRepeatImage(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async getEventSubtitle(uid) {
    return this.apiClient
      .getEventSubtitle(uid)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  async saveEventSubtitle(params) {
    return this.apiClient
      .saveEventSubtitle(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result.event;
      })
      .catch((err) => {
        alert("네트워크 문제로 오류가 발생하였습니다.");
        console.error(err);
      });
  }

  // 짧은 버전 렌더링 시작하기
  async startShortQueue(params) {
    return this.apiClient
      .startShortQueue(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }

  // 후기 가져오기
  async getFeedbacks(params) {
    return this.apiClient
      .getFeedbacks(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  // 후기 등록
  async saveFeedback(params) {
    return this.apiClient
      .saveFeedback(params)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return data.result;
      })
      .catch((err) => console.error(err));
  }
}

import React, { useEffect, useState } from "react";
import styles from "./KakaoInquiryModal.module.css";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";

import userIcon from "@assets/images/service/user-icon.svg";
import closeIcon from "@assets/images/service/close-icon.svg";
import kakao from "@assets/images/service/kakao.png";

interface KakaoInquiryModalProps {
  title: string;
  mainText: string;
  isOpen: boolean;
  onClose: () => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "500px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "var(--dark-light-bg-color)",
    overflow: "hidden",
    borderRadius: "12px",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  },
};

const KakaoInquiryModal: React.FC<KakaoInquiryModalProps> = ({
  title,
  mainText,
  isOpen,
  onClose,
}) => {
  const [eventUser, setEventUser] = useState<{
    id?: number;
    name: string;
    phone_number: string;
  }>({
    name: "",
    phone_number: "",
  });
  const [addFlag, setAddFlag] = useState(false);

  const navigator = useNavigate();

  useEffect(() => {
    setAddFlag(false);
  }, [isOpen]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    if (id === "phone_number") {
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      setEventUser((prev) => ({ ...prev, [id]: sanitizedValue }));
    } else {
      setEventUser((prev) => ({ ...prev, [id]: value }));
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      // onRequestClose={onClose}
      style={customModalStyles}
    >
      <div className={styles.iconWrap}>
        <img className={styles.userIcon} src={userIcon} alt="" />
        <img src={closeIcon} alt="" onClick={onClose} />
      </div>
      <h3 className={styles.title}>{title}</h3>
      <pre className={styles.desc}>{mainText}</pre>

      <div className={styles.buttonWrap}>
        <button
          onClick={() => {
            window.open("http://pf.kakao.com/_tdxjxmK/chat", "_blank");
          }}
        >
          <img src={kakao} alt="kakao" />
          카카오톡으로 문의하기
        </button>
      </div>
    </Modal>
  );
};

export default KakaoInquiryModal;

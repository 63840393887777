import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminEventTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";

interface AdminEventTableProps {
  events: any;
  totalPages: number;
  location?: any;
  updateEvents?: () => void;
}

const AdminEventTable: React.FC<AdminEventTableProps> = ({
  events,
  totalPages,
  updateEvents,
}) => {
  const { adminApi } = useApi();

  const location = useLocation();
  const navigator = useNavigate();

  const pageCount = totalPages;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  const handleEventCancel = (event: any) => {
    if (window.confirm(`${event.dm_name}님 행사를 정말 취소 하시겠습니까?`)) {
      adminApi
        .saveOrUpdateEvent({
          event: {
            uid: event.uid,
            state: "cancel",
          },
          state: "update",
        })
        .then((res: any) => {
          alert("완료 되었습니다");

          if (updateEvents) updateEvents();
        });
    }
  };
  const handleEventDelete = (event: any) => {
    if (window.confirm(`${event.dm_name}님 행사를 정말 삭제하시겠습니까?`)) {
      adminApi
        .saveOrUpdateEvent({
          event: {
            uid: event.uid,
          },
          state: "delete",
        })
        .then((res: any) => {
          alert("삭제되었습니다");

          if (updateEvents) updateEvents();
        });
    }
  };

  return (
    <>
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.smallTh}>상태</th>

            <th>장례식장</th>
            <th className={styles.smallTh}>행사번호</th>
            <th className={styles.smallTh}>고인명</th>

            <th className={styles.smallTh}>상주명</th>
            <th className={styles.smallTh}>전화번호</th>

            <th>템플릿</th>
            <th className={styles.smallTh}>유형</th>

            <th className={styles.smallTh}>생성일</th>

            <th className={styles.smallTh}>상세</th>
            <th className={styles.smallTh}>삭제</th>
          </TableHeader>
          {events?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            events?.map((event: any) => (
              <tr key={event?.uid}>
                <td>
                  <StatusButton state={event?.state} target="event" />
                </td>

                <td>{event?.FuneralInfo?.FUNERAL_NAME}</td>
                <td>{event?.event_no || "-"}</td>
                <td>{event?.dm_name || "-"}</td>
                <td>{event?.EventUsers[0]?.name || "-"}</td>
                <td>{event?.EventUsers[0]?.phone_number || "-"}</td>

                <td>{event?.ShopOrders ? event?.ShopOrders[0]?.name : "-"}</td>

                <td>
                  {event?.template_state === "short"
                    ? "빠른제작"
                    : event?.template_state === "premium"
                    ? "프리미엄"
                    : "-"}
                </td>

                <td>{dayjs(event.create_dt).format("YYYY-MM-DD")}</td>

                <td>
                  <button
                    className={styles.detailsButton}
                    onClick={() => {
                      navigator(`/admin/dashboard/events/${event?.event_no}`);
                    }}
                  >
                    상세보기
                  </button>
                </td>
                {/* <td>
                  <button
                    className={styles.cancelButton}
                    onClick={() => {
                      handleEventCancel(event);
                    }}
                  >
                    제작취소
                  </button>
                </td> */}
                <td>
                  <button
                    className={styles.cancelButton}
                    onClick={() => {
                      handleEventDelete(event);
                    }}
                  >
                    행사삭제
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminEventTable;

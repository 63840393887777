import React from "react";
import styles from "./StatusButton.module.css";

interface StatusButtonProps {
  state: any;
  target: string;
}

const StatusButton: React.FC<StatusButtonProps> = ({ state, target }) => {
  if (target === "event") {
    const getTagStyle = (state: string) => {
      switch (state) {
        case "registering":
          return styles.greyTag;
        case "prepared":
          return styles.blueTag;
        case "uploading":
        case "modifying":
          return styles.yellowTag;
        case "completed":
          return styles.indigoTag;
        case "making":
          return styles.redTag;
        case "done":
        case "confirmed":
          return styles.greenTag;
        default:
          return styles.redTag;
      }
    };

    const getTagText = (state: string) => {
      switch (state) {
        case "registering":
          return "미결제";
        case "prepared":
          return "등록";
        case "uploading":
          return "업로드 중";
        case "modifying":
          return "수정 중";
        case "completed":
          return "업로드 완료";
        case "making":
          return "편집 중";
        case "done":
          return "제작 완료";
        case "confirmed":
          return "확정";

        default:
          return state;
      }
    };

    return (
      <span className={`${styles.tag} ${getTagStyle(state)}`}>
        {getTagText(state)}
      </span>
    );
  }

  if (target === "delivery") {
    let status;
    const getTagStyle = (state: string) => {
      switch (state) {
        case "not_started":
          status = "배송전";
          return styles.yellowTag;

        case "delivering":
          status = "배송중";
          return styles.blueTag;

        case "delivery_complete":
          status = "배송완료";
          return styles.greenTag;

        case "cancelled":
          status = "취소";
          return styles.redTag;
      }
    };

    return (
      <span className={`${styles.tag} ${getTagStyle(state)}`}>{status}</span>
    );
  }

  if (target === "order") {
    let status;
    const getTagStyle = (state: string) => {
      switch (state) {
        case "paid":
          status = "결제완료";
          return styles.blueTag;

        case "ready":
          status = "입금예정";
          return styles.yellowTag;

        case "cancelled":
          status = "결제취소";
          return styles.redTag;
        default:
          status = "결제대기";
          return styles.greyTag;
      }
    };

    return (
      <span className={`${styles.tag} ${getTagStyle(state)}`}>{status}</span>
    );
  }

  if (target === "queue") {
    const getTagStyle = (state: string) => {
      switch (state) {
        case "queued":
          return styles.blueTag;
        case "started":
          return styles.yellowTag;
        case "rendering":
          return styles.indigoTag;
        case "finished":
          return styles.greenTag;
        default:
          return styles.redTag;
      }
    };

    const getTagText = (state: string) => {
      switch (state) {
        case "queued":
          return "대기중";
        case "started":
          return "렌더 중";

        case "finished":
          return "렌더완료";
        case "cancelled":
          return "취소";
        case "error":
          return "렌더오류";

        default:
          return state;
      }
    };

    return (
      <span className={`${styles.tag} ${getTagStyle(state)}`}>
        {getTagText(state)}
      </span>
    );
  }

  if (target === "feedback") {
    const getTagStyle = (state: boolean) => {
      switch (state) {
        case true:
          return styles.greenTag;
        default:
          return styles.yellowTag;
      }
    };
    return (
      <span className={`${styles.tag} ${getTagStyle(state)}`}>
        {state ? "확인" : "미확인"}
      </span>
    );
  }

  return <span className={styles.tag}>{state}</span>;
};

export default StatusButton;

import React from "react";
import styles from "./SummaryCard.module.css";
import NumberFormat from "@components/Ui/NumberFormat";

interface SummaryCardProps {
  title: string;
  summary: string;
  iconImg: any;
  backgroundColor: string;
  count: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  summary,
  iconImg,
  backgroundColor,
  count,
}) => {
  return (
    <div className={styles.wrap}>
      <div>
        <h6>{title}</h6>
        <h4>
          <NumberFormat number={count} />
          {summary}
        </h4>
      </div>
      <div style={{ backgroundColor }} className={styles.iconWrap}>
        <img src={iconImg} alt="icon" />
      </div>
    </div>
  );
};

export default SummaryCard;

import React, { useEffect, useRef, useState } from "react";
import styles from "./ShopDetailImageAddModal.module.css";
import Modal from "react-modal";
import imageCompression from "browser-image-compression";

import { useApi } from "@context/ApiContext";
import { uploadImage } from "@apis/uploader";
import AdminLoading from "@pages/Admin/AdminLoading";

interface ShopDetailImageAddModalProps {
  shop: any;
  isOpen: boolean;
  updateShops: () => void;
  onClose: () => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "1500px",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "scroll",
    margin: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

type ServerApiContextType = any;
const ShopDetailImageAddModal: React.FC<ShopDetailImageAddModalProps> = ({
  shop,

  isOpen,
  updateShops,
  onClose,
}) => {
  const { adminApi } = useApi() as ServerApiContextType;

  const [loading, setLoading] = useState(false);
  const [thumImage, setThumImage] = useState({ target: "", file_path: "" });
  const [orderImage, setOrderImage] = useState({ target: "", file_path: "" });
  const [processImage, setProcessImage] = useState({
    target: "",
    file_path: "",
  });
  const [deliImage, setDeliImage] = useState({ target: "", file_path: "" });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    // type NewShopKey = keyof typeof newShop;

    // if (["shop_sell_flag", "shop_managed_flag"].includes(id as NewShopKey)) {
    //   setNewShop((prev) => ({ ...prev, [id]: !prev[id as NewShopKey] }));
    // } else {
    //   setNewShop((prev) => ({ ...prev, [id as NewShopKey]: value }));
    // }
  };

  const [thumFile, setThumFile] = useState<File>();
  const thumFileInputRef = useRef<HTMLInputElement>(null);

  const [orderFile, setOrderFile] = useState<File>();
  const orderFileInputRef = useRef<HTMLInputElement>(null);

  const [processFile, setProcessFile] = useState<File>();
  const processFileInputRef = useRef<HTMLInputElement>(null);

  const [deliFile, setDeliFile] = useState<File>();
  const deliFileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (target: string) => {
    // 버튼 클릭 시 input의 click 메서드 호출

    if (target === "thum") {
      if (thumFileInputRef.current) {
        thumFileInputRef.current.click();
      }
    } else if (target === "order") {
      if (orderFileInputRef.current) {
        orderFileInputRef.current.click();
      }
    } else if (target === "process") {
      if (processFileInputRef.current) {
        processFileInputRef.current.click();
      }
    } else if (target === "deli") {
      if (deliFileInputRef.current) {
        deliFileInputRef.current.click();
      }
    }
  };

  const handleImageFileChange = (e: any) => {
    const { id } = e.target;
    let uploadFile = e.target.files[0];
    if (id === "thum") {
      setThumFile(uploadFile);
    } else if (id === "order") {
      setOrderFile(uploadFile);
    } else if (id === "process") {
      setProcessFile(uploadFile);
    } else if (id === "deli") {
      setDeliFile(uploadFile);
    }
  };

  const compressImage = async (imageFile: any) => {
    const options = {
      maxSizeMB: 10, // 5MB로 설정
      useWebWorker: true,
      quality: 0.8,
    };

    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      console.error("압축 중 오류 발생:", error);
    }
  };

  const handleSaveShopDetailImage = async () => {
    if (!thumImage.file_path && !thumFile) {
      alert("상세 썸네일을 업로드하여 주십시요");
      return;
    }

    if (!orderImage.file_path && !orderFile) {
      alert("상품정보 이미지를 업로드하여 주십시요");
      return;
    }

    if (!processImage.file_path && !processFile) {
      alert("제작과정 이미지를 업로드하여 주십시요");
      return;
    }
    if (!deliImage.file_path && !deliFile) {
      alert("배송 및 환불 이미지를 업로드하여 주십시요");
      return;
    }

    const detailImages = [];
    setLoading(true);
    if (thumFile) {
      const compressedFile = await compressImage(thumFile);
      detailImages.push({
        ...thumImage,
        target: "thum",
        file: compressedFile,
      });
    }

    if (orderFile) {
      const compressedFile = await compressImage(orderFile);
      detailImages.push({
        ...orderImage,
        target: "order",
        file: compressedFile,
      });
    }

    if (processFile) {
      const compressedFile = await compressImage(processFile);
      detailImages.push({
        ...processImage,
        target: "process",
        file: compressedFile,
      });
    }

    if (deliFile) {
      const compressedFile = await compressImage(deliFile);

      detailImages.push({
        ...deliImage,
        target: "deli",
        file: compressedFile,
      });
    }

    Promise.all(
      detailImages.map(async (image) => {
        const { file, target } = image;
        return new Promise(async (resolve, reject) => {
          if (!file) {
            resolve(image);
          } else {
            // 이미지 압축

            uploadImage(
              file,
              `shops/detailImages/${shop.shop_name}/${target}`
            ).then((imageData) => {
              image.file_path = imageData.url;
              resolve(image);
            });
          }
        });
      })
    ).then((images) => {
      const params = {
        shop_no: shop.shop_no,
        images,
      };

      adminApi.saveOrUpdateShopDetailImage(params).then((res: any) => {
        if (!res) return;

        setLoading(false);
        alert("완료되었습니다");
        updateShops();
        onClose();
      });
    });
  };

  useEffect(() => {
    const detailImages = shop?.ShopDetailImages;

    if (detailImages && detailImages.length !== 0) {
      setThumImage(detailImages.find((img: any) => img.target === "thum"));
      setOrderImage(detailImages.find((img: any) => img.target === "order"));
      setProcessImage(
        detailImages.find((img: any) => img.target === "process")
      );
      setDeliImage(detailImages.find((img: any) => img.target === "deli"));
    }
  }, [shop]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={customModalStyles}
      onRequestClose={onClose}
    >
      <h3 className={styles.title}>상품 상세 페이지 등록</h3>

      {loading && <AdminLoading />}
      <main>
        <div className={styles.inputWraps}>
          <div>
            <div className={styles.inputWrap}>
              <label>상품 상세 썸네일</label>

              <button
                className={styles.uploadBtn}
                onClick={() => handleImageUpload("thum")}
                type="button"
              >
                이미지 업로드
              </button>

              <input
                id="thum"
                ref={thumFileInputRef}
                type="file"
                hidden
                onChange={handleImageFileChange}
              />
            </div>

            <div className={styles.imagePreviewWrap}>
              {thumFile ? (
                <img
                  src={URL.createObjectURL(thumFile)}
                  alt="subtitle_preview"
                  className={styles.thumImg}
                />
              ) : thumImage.file_path ? (
                <img
                  src={thumImage.file_path}
                  alt="subtitle_preview"
                  className={styles.thumImg}
                />
              ) : (
                <p onClick={() => handleImageUpload("thum")}>
                  업로드 된 파일이 없습니다
                </p>
              )}
            </div>
          </div>
          <div>
            <div className={styles.inputWrap}>
              <label>상품 정보 이미지</label>

              <button
                className={styles.uploadBtn}
                onClick={() => handleImageUpload("order")}
                type="button"
              >
                이미지 업로드
              </button>

              <input
                id="order"
                ref={orderFileInputRef}
                type="file"
                hidden
                onChange={handleImageFileChange}
              />
            </div>

            <div className={styles.imagePreviewWrap}>
              {orderFile ? (
                <img
                  src={URL.createObjectURL(orderFile)}
                  alt="subtitle_preview"
                  className={styles.orderImg}
                />
              ) : orderImage.file_path ? (
                <img
                  src={orderImage.file_path}
                  alt="subtitle_preview"
                  className={styles.thumImg}
                />
              ) : (
                <p onClick={() => handleImageUpload("order")}>
                  업로드 된 파일이 없습니다
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.inputWraps}>
          <div>
            <div className={styles.inputWrap}>
              <label>제작과정 이미지</label>

              <button
                className={styles.uploadBtn}
                onClick={() => handleImageUpload("process")}
                type="button"
              >
                이미지 업로드
              </button>

              <input
                id="process"
                ref={processFileInputRef}
                type="file"
                hidden
                onChange={handleImageFileChange}
              />
            </div>

            <div className={styles.imagePreviewWrap}>
              {processFile ? (
                <img
                  src={URL.createObjectURL(processFile)}
                  alt="subtitle_preview"
                />
              ) : processImage.file_path ? (
                <img src={processImage.file_path} alt="subtitle_preview" />
              ) : (
                <p onClick={() => handleImageUpload("process")}>
                  업로드 된 파일이 없습니다
                </p>
              )}
            </div>
          </div>
          <div>
            <div className={styles.inputWrap}>
              <label>배송 및 환불 이미지</label>

              <button
                className={styles.uploadBtn}
                onClick={() => handleImageUpload("deli")}
                type="button"
              >
                이미지 업로드
              </button>

              <input
                id="deli"
                ref={deliFileInputRef}
                type="file"
                hidden
                onChange={handleImageFileChange}
              />
            </div>

            <div className={styles.imagePreviewWrap}>
              {deliFile ? (
                <img
                  src={URL.createObjectURL(deliFile)}
                  alt="subtitle_preview"
                  className={styles.deliImg}
                />
              ) : deliImage.file_path ? (
                <img
                  src={deliImage.file_path}
                  alt="subtitle_preview"
                  className={styles.thumImg}
                />
              ) : (
                <p onClick={() => handleImageUpload("deli")}>
                  업로드 된 파일이 없습니다
                </p>
              )}
            </div>
          </div>
        </div>
      </main>

      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={handleSaveShopDetailImage}>완료</button>
      </div>
    </Modal>
  );
};

export default ShopDetailImageAddModal;

import React from "react";

import Perspective from "perspectivejs";
import { useCanvas } from "@hooks/useCanvas";

interface ImageProps {
  canvasWidth: number;
  canvasHeight: number;
  image: {
    img: File;
    thum?: { url: string };
    top_left_x?: number;
    top_left_y?: number;
    top_right_x?: number;
    top_right_y?: number;
    bottom_right_x?: number;
    bottom_right_y?: number;
    bottom_left_x?: number;
    bottom_left_y?: number;
  };

  mode?: string;
}

const ImageCanvas: React.FC<ImageProps> = ({
  canvasWidth,
  canvasHeight,
  image: {
    img,
    thum,
    top_left_x,
    top_left_y,
    top_right_x,
    top_right_y,
    bottom_right_x,
    bottom_right_y,
    bottom_left_x,
    bottom_left_y,
  },
  mode,
}) => {
  const fillImage = async (ctx: CanvasRenderingContext2D) => {
    if (!img && !thum) {
      return;
    }

    let previewImg = new Image();
    if (mode === "making") {
      previewImg.src = URL.createObjectURL(img);
    } else {
      previewImg.src = require("@assets/images/admin/testimage.jpg");
    }

    previewImg.onload = function () {
      const p = new Perspective(ctx, previewImg);
      p.draw([
        [parseInt(String(top_left_x)) || 0, parseInt(String(top_left_y)) || 0],
        [
          parseInt(String(top_right_x)) || 960,
          parseInt(String(top_right_y)) || 0,
        ],
        [
          parseInt(String(bottom_right_x)) || 960,
          parseInt(String(bottom_right_y)) || 540,
        ],
        [
          parseInt(String(bottom_left_x)) || 0,
          parseInt(String(bottom_left_y)) || 540,
        ],
      ]);
    };
  };

  const canvasRef = useCanvas(canvasWidth, canvasHeight, fillImage);

  return (
    <canvas ref={canvasRef} style={{ position: "absolute", width: "100%" }} />
  );
};

export default ImageCanvas;

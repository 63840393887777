import React, { useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminOrderTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";
import AdminOrderInfoModal from "./AdminOrderInfoModal";

import NumberFormat from "@components/Ui/NumberFormat";

interface AdminOrderTableProps {
  orders: any;
  totalPages: number;
  location?: any;
  updateOrders: () => void;
}

const AdminOrderTable: React.FC<AdminOrderTableProps> = ({
  orders,
  totalPages,
  updateOrders,
}) => {
  const { adminApi } = useApi();

  const location = useLocation();
  const navigator = useNavigate();

  const [tagetOrder, setTargetOrder] = useState<null | { id: any }>({
    id: "",
  });
  const [orderAction, setOrderAction] = useState<string>("");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

  const pageCount = totalPages;

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  const getPayMethodText = (method: string) => {
    switch (method) {
      case "card":
        return "카드결제";
      case "vbank":
        return "가상계좌";
      case "kakaopay":
        return "카카오페이";
      case "samsung":
        return "삼성페이";
      case "naverpay":
        return "네이버페이";

      default:
        return "기타 결제";
    }
  };

  return (
    <>
      <AdminOrderInfoModal
        order={tagetOrder}
        orderAction={orderAction}
        setOrderAction={setOrderAction}
        isOpen={isOrderModalOpen}
        updateOrders={updateOrders}
        onClose={() => {
          setTargetOrder(null);
          setIsOrderModalOpen(false);
          setOrderAction("");
        }}
        onRefresh={() => {
          setTargetOrder(
            orders.find((order: any) => order.id === tagetOrder?.id)
          );
        }}
      />
      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.smallTh}>결제상태</th>
            <th className={styles.middleTh}>주문번호</th>
            <th className={styles.largeTh}>상품명</th>

            <th className={styles.middleTh}>고객정보</th>
            <th className={styles.smallTh}>결제구분</th>
            <th className={styles.smallTh}>결제금액</th>

            <th className={styles.middleTh}>결제완료일시</th>
            <th className={styles.smallTh}></th>
          </TableHeader>
          {!orders || orders.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            orders?.map((order: any, index: number) => (
              <tr
                className={styles.orderTr}
                key={
                  order.merchant_uid !== null
                    ? order.merchant_uid
                    : `order-${index}`
                }
                onClick={() => {
                  setIsOrderModalOpen(true);
                  setTargetOrder(order);
                  setOrderAction("detail");
                }}
              >
                <td>
                  <StatusButton state={order.status} target="order" />
                </td>
                <td>{order?.imp_uid || "-"}</td>
                <td>{order?.name || "-"}</td>
                <td>
                  {order?.buyer_name} / {order?.buyer_tel}
                </td>

                <td>{getPayMethodText(order?.pay_method) || "-"}</td>
                <td>
                  <NumberFormat number={order?.amount} />원
                </td>
                <td>
                  {order?.paid_dt
                    ? dayjs(order?.paid_dt).format("MM-DD HH:mm")
                    : "-"}
                </td>

                {order?.status === "paid" || order?.status === "cancelled" ? (
                  <td
                    className={styles.cancelButton}
                    onClick={(e) => {
                      e.stopPropagation(); // 이벤트 버블링 중지
                      setIsOrderModalOpen(true);
                      setTargetOrder(order);
                      setOrderAction("cancel");
                    }}
                  >
                    <span>취소</span>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminOrderTable;

import axios from "axios";

export async function uploadImage(file, folder) {
  const data = new FormData();
  data.append("file", file);
  data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
  data.append("folder", folder);
  data.append("quality", "auto:good"); // 클라우드너리의 압축 지원 추가

  const res = await axios.post(process.env.REACT_APP_CLOUDINARY_URL, data);

  const { public_id, url } = res.data;

  return { public_id, url };
}

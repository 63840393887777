import SideBar from "@components/Admin/SideBar/SideBar";
import Hamburger from "@components/Admin/Templates/Hamburger";
import ScrollToTop from "@components/ScrollToTop";
import { ApiProvider } from "@context/ApiProvider";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { AuthContextProvider } from "@context/AuthContext";

export default function App() {
  const location = useLocation();

  const queryClient = new QueryClient();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // 현재 경로를 기반으로 레이아웃 스타일 결정
  const layoutStyle = location.pathname.startsWith("/service")
    ? "service-layout"
    : location.pathname.startsWith("/admin")
    ? "admin-layout"
    : location.pathname.startsWith("/shop")
    ? "shop-layout"
    : "default-layout";

  if (layoutStyle === "admin-layout") {
    return (
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <ScrollToTop />
            {/* <Header /> */}
            <div id="outer-container" className={layoutStyle}>
              <Hamburger isOpen={sidebarOpen} onClick={toggleSidebar} />
              <SideBar isOpen={sidebarOpen} toggleOpen={toggleSidebar} />
              {sidebarOpen && (
                <div className="backdrop" onClick={toggleSidebar} />
              )}
              <main id="page-wrap">
                <Outlet />
              </main>
            </div>
          </AuthContextProvider>
        </QueryClientProvider>
      </ApiProvider>
    );
  }

  return (
    <ApiProvider>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <div id="app" className={layoutStyle}>
          {/* <Header /> */}

          <Outlet />
        </div>
      </QueryClientProvider>
    </ApiProvider>
  );
}

import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./FuneralPaybackModal.module.css";
import { formatISO, isPast, startOfDay } from "date-fns";
import { useApi } from "@context/ApiContext";

interface FuneralPaybackModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateFunerals: (() => void) | undefined;
  funeral?: any;
}

const FuneralPaybackModal: React.FC<FuneralPaybackModalProps> = ({
  funeral,
  isOpen,
  onClose,
  updateFunerals,
}) => {
  const customModalStyles: Modal.Styles = {
    content: {
      maxWidth: "600px",
      height: "fit-content",
      margin: "auto",
      backgroundColor: "rgb(255, 255, 255)",
      overflow: "hidden",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const { adminApi } = useApi();

  const [payback, setPayback] = useState({
    offer_amount: "",
    funeral_amount: "",
    ds_amount: "",
    cnemo_amount: "",
    desired_apply_date: formatISO(startOfDay(new Date()), {
      representation: "date",
    }), // 날짜 객체를 ISO 문자열로 변환
  });

  const [applyMethod, setApplyMethod] = useState("immediately");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setPayback((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = () => {
    // 필드에 값이 있는지 확인
    const {
      offer_amount,
      funeral_amount,
      ds_amount,
      cnemo_amount,
      desired_apply_date,
    } = payback;

    if (!offer_amount || !funeral_amount || !ds_amount || !cnemo_amount) {
      alert("모든 필드를 채워주세요.");
      return;
    }

    // 정수로 변환
    const offerAmountInt = parseInt(offer_amount, 10);
    const funeralAmountInt = parseInt(funeral_amount, 10);
    const dsAmountInt = parseInt(ds_amount, 10);
    const cnemoAmountInt = parseInt(cnemo_amount, 10);

    // applyMethod가 'immediately'인 경우 날짜 필드 처리
    let apply_date = desired_apply_date || null;
    if (applyMethod === "immediately" && desired_apply_date) {
      setPayback((prev) => ({ ...prev, desired_apply_date: "" }));
      apply_date = null;
    }

    // 날짜 유효성 검사
    if (applyMethod === "desired" && desired_apply_date) {
      const desiredDate = new Date(desired_apply_date);
      if (isPast(startOfDay(desiredDate))) {
        alert("적용 희망일은 오늘 이후여야 합니다.");
        return;
      }
    }

    const param = {
      ...payback,
      FUNERAL_NO: funeral.FUNERAL_NO,
      offer_amount: offerAmountInt,
      funeral_amount: funeralAmountInt,
      ds_amount: dsAmountInt,
      cnemo_amount: cnemoAmountInt,
      desired_apply_date: apply_date,
    };

    adminApi.saveOrUpdateFuneralPayback(param).then((res: any) => {
      if (!res) return;
      alert("완료되었습니다");

      if (updateFunerals) updateFunerals();
      onClose();
    });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>페이백 설정</h3>

      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="offer_amount">추모영상 제공가</label>
          <input
            id="offer_amount"
            type="text"
            placeholder="추모영상 제공가를 입력하세요"
            value={payback.offer_amount}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="funeral_amount">장례식장 지원율</label>
          <input
            id="funeral_amount"
            type="text"
            placeholder="장례식장 지원율을 입력하세요"
            value={payback.funeral_amount}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="ds_amount">동성 지원율</label>
          <input
            id="ds_amount"
            type="text"
            placeholder="동성 지원율을 입력하세요"
            value={payback.ds_amount}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.inputWrap}>
          <label htmlFor="cnemo_amount">시네모 지원율</label>
          <input
            id="cnemo_amount"
            type="text"
            placeholder="시네모 지원율을 입력하세요"
            value={payback.cnemo_amount}
            onChange={handleInputChange}
          />
        </div>

        <div className={styles.radioWraps}>
          <div>
            <input
              id="immediately"
              name="applyMethod"
              type="radio"
              value="immediately"
              checked={applyMethod === "immediately"}
              onChange={(e) => setApplyMethod(e.target.value)}
            />
            <label htmlFor="immediately">즉시적용</label>
          </div>

          <div>
            <input
              id="desired"
              name="applyMethod"
              type="radio"
              value="desired"
              checked={applyMethod === "desired"}
              onChange={(e) => setApplyMethod(e.target.value)}
            />
            <label htmlFor="desired">날짜적용</label>
          </div>
        </div>

        {applyMethod === "desired" && (
          <div className={styles.inputWrap} style={{ width: "200px" }}>
            <input
              id="desired_apply_date"
              value={payback.desired_apply_date}
              type="date"
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>

      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={handleSubmit}>등록하기</button>
      </div>
    </Modal>
  );
};

export default FuneralPaybackModal;

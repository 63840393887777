import React, { useEffect, useRef, useState } from "react";

import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import ServiceImagesUploadBox from "@components/Service/Uploads/Images/ServiceImagesUploadBox";
import ServiceImagePreviewsBox from "@components/Service/Uploads/Images/ServiceImagePreviewsBox";

import uuid from "react-uuid";
import { ServiceImageProps } from "@interfaces/service";
import ServiceMainButton from "@components/Service/ServiceMainButton";
import ServiceShareModal from "@components/Service/ServiceShareModal";
import ServiceImageChange from "@components/Service/Uploads/Images/ServiceImageChange";
import { useApi } from "@context/ApiContext";
import { useQuery } from "@tanstack/react-query";
import Loading from "@components/Service/Ui/Loading";
import Error from "@components/Service/Ui/Error";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { uploadImage } from "@apis/uploader";
import imageCompression from "browser-image-compression";

import MainNoticeSection from "@components/Service/Ui/MainNoticeSection";
import mainImage from "@assets/images/service/make-info_1.png";
import helpImage from "@assets/images/service/photo-help.webp";

import Lottie from "lottie-react";
import loadingLottie from "@assets/lottie/uploading.json";

interface UploadsProps {}

const Uploads: React.FC<UploadsProps> = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const uploadFileRef = useRef<HTMLInputElement | null>(null);
  const changeFileRef = useRef<HTMLInputElement | null>(null);

  const [helpFlag, setHelpFlag] = useState(false);
  const [shareModalFlag, setShareModalFlag] = useState(false);

  const [editModalFlag, setEditModalFlag] = useState(false);
  const [uploadingFlag, setUploadingFlag] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ServiceImageProps | null>(
    null
  );

  const [imageList, setImageList] = useState<ServiceImageProps[]>([]);

  const handleHelpClose = () => {
    localStorage.setItem("upload_help", "true");
    setHelpFlag(false);
  };

  const handleUploadClick = () => {
    if (!uploadFileRef.current) return;

    if (event.Template.photo_length <= imageList.length) {
      alert(
        "30장 이상 업로드할 수 없습니다. 다른 사진을 삭제 후 업로드 해주십시요"
      );

      return;
    }

    uploadFileRef.current.click();
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files || files?.length === 0) return;

    // 설정된 최대 이미지 장수
    const maxPhotoLength = event.Template.photo_length;

    // 현재 이미지 리스트에 있는 이미지 수
    const currentImageCount = imageList.length;

    // 업로드 가능한 최대 이미지 수 계산
    const availableSlots = maxPhotoLength - currentImageCount;

    if (availableSlots <= 0) {
      alert("더 이상 이미지를 추가할 수 없습니다.");
      return;
    }

    // 파일 리스트를 배열로 변환하고, 필요한 경우에 잘라내기
    const fileListArray = Array.from(files)
      .slice(0, availableSlots)
      .map((file) => ({
        uid: uuid(),
        src: file,
      }));

    // 이미지 리스트 업데이트
    setImageList((prev) => [...prev, ...fileListArray]);

    if (fileListArray.length < files.length) {
      alert(
        `최대 ${maxPhotoLength}장의 이미지만 업로드할 수 있습니다. 초과하는 ${
          files.length - fileListArray.length
        }장은 제외되었습니다.`
      );
    }
  };

  const handleCheck = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const { id } = target;

    setImageList((prev) => [
      ...prev.map((img) =>
        img.uid === id ? { ...img, isChecked: !img.isChecked } : img
      ),
    ]);
  };

  const handleCheckReset = () => {
    setImageList((prev) => [
      ...prev.map((img) => ({ ...img, isChecked: false })),
    ]);
  };

  const handleUploadComplete = async () => {
    if (imageList.length === 0) {
      alert("업로드된 사진이 없습니다");
      return;
    }

    setUploadingFlag(true);

    const compressImage = async (imageFile: any) => {
      const options = {
        maxSizeMB: 10, // 5MB로 설정
        useWebWorker: true,
        quality: 0.8,
      };

      try {
        return await imageCompression(imageFile, options);
      } catch (error) {
        console.error("압축 중 오류 발생:", error);
      }
    };

    Promise.all(
      imageList.map(async (image) => {
        const { src, public_id, url } = image;
        return new Promise(async (resolve, reject) => {
          // 기존 이미지가 있음
          if (public_id && url) {
            // 새로 올린게 없음 - 리턴
            if (!src) {
              resolve(image);
            } else {
              // 새로올린게 있음 =>

              uploadImage(
                src,
                `events/${event.event_no}_${event?.dm_name}`
              ).then((imageData) => {
                image.state = "new";
                image.url = imageData.url;
                resolve(image);
              });
            }
          } else {
            // 이미지 압축

            uploadImage(src, `events/${event.event_no}_${event.dm_name}`).then(
              (imageData) => {
                image.state = "new";
                image.url = imageData.url;
                image.public_id = imageData.public_id;
                resolve(image);
              }
            );
          }
        });
      })
    ).then((images) => {
      const newImages = images.filter((img: any) => img.state === "new");

      const params = {
        uid: event.uid,
        images: newImages,
      };

      serviceApi.saveEventImageURL(params).then(() => {
        setUploadingFlag(false);

        if (event.template_state === "premium") {
          navigator(`/service/text-uploads?uid=${event.uid}`);
        } else {
          navigator(`/service/change-state?uid=${event.uid}`);
        }

        // if (images.length <= event.Template.min_photo_length) {
        //   serviceApi.startShortQueue({ uid: event.uid }).then((res: any) => {
        //     if (!res) return;

        //     navigator(`/service/complete?uid=${event.uid}`);
        //   });

        //   if (newImages.length !== 0) {
        //     serviceApi.startShortQueue({ uid: event.uid }).then((res: any) => {
        //       if (!res) return;

        //       navigator(`/service/complete?uid=${event.uid}`);
        //     });
        //   } else {
        //     navigator(`/service/complete?uid=${event.uid}`);
        //   }
        // } else if (images.length < event.Template.photo_length) {
        //   navigator(`/service/complete?uid=${event.uid}`);
        // } else {
        //   navigator(`/service/text-uploads?uid=${event.uid}`);
        // }
      });
    });
  };

  const handleSelectImage = (image: ServiceImageProps) => {
    // setEditModalFlag(true);
    setSelectedImage(image);

    if (!changeFileRef.current) return;

    changeFileRef.current.click();
  };

  const handleDeleteImage = () => {
    const checkedImages = imageList.filter((image) => image.isChecked);

    if (checkedImages.length === 0) {
      alert("선택된 사진이 없습니다");
      return;
    }
    const remainingImages = imageList.filter((image) => !image.isChecked);

    setImageList(remainingImages);

    const params = {
      uid: event.uid,
      images: remainingImages,
    };

    serviceApi.deleteEventImageURL(params);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];

    handleImageSave(selectedImage?.uid, file);
    setSelectedImage(null);
  };

  const handleImageSave = (
    exImgId: string | undefined,
    newImgSrc: Blob | File
  ) => {
    // imageList에서 해당 ID를 가진 객체를 찾아 src를 업데이트
    const updatedImageList = imageList.map((img) => {
      if (img.uid === exImgId) {
        if (img.public_id) {
          delete img.public_id;
          delete img.url;
        }

        return { ...img, src: newImgSrc };
      }

      return img;
    });

    // 업데이트된 이미지 리스트로 상태 업데이트
    setImageList(updatedImageList);
  };

  useEffect(() => {
    if (localStorage.getItem("upload_help")) {
      setHelpFlag(false);
    } else {
      setHelpFlag(true);
    }
  }, [localStorage.getItem("upload_help")]);

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(
    ["getEvent", uid],
    () => {
      return serviceApi.getEvent(uid).then((res: any) => {
        if (!res) return null;

        setImageList(res.EventImages);

        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  if (!event) {
    return <div>고인의 행사정보가 없습니다</div>;
  }

  if (
    event?.template_state === "premium" &&
    ["completed", "making", "done", "confirmed"].includes(event?.state)
  ) {
    return <Navigate to={`/service/complete?uid=${event.uid}`} replace />;
  }

  // 도움말
  if (helpFlag) {
    return (
      <div className="service-help_wrap" onClick={handleHelpClose}>
        <img src={helpImage} alt="help_image" className="service-help_image" />
      </div>
    );
  }

  // 업로드 중 안내 화면
  if (uploadingFlag) {
    return (
      <div id="service-upload" className="uploading_wrap">
        <MainNoticeSection
          title="사진이 업로드 되고 있습니다"
          subTitle="잠시만 기다려주세요"
        >
          <Lottie animationData={loadingLottie} />

          <p className="body-title small">
            업로드 완료 전 <b>종료 할 경우 </b>
            <br /> 영상이 제작되지 않을 수 있습니다.
          </p>
        </MainNoticeSection>
      </div>
    );
  }

  return (
    <div id="service-upload">
      <input
        type="file"
        ref={changeFileRef}
        className="input-hidden"
        onChange={handleFileChange}
        accept="image/*"
        multiple={false}
      />

      <input
        type="file"
        className="input-hidden"
        ref={uploadFileRef}
        multiple={true}
        accept="image/*"
        onChange={handleUpload}
      />

      <ServiceMainHeader
        handleLeftIconClick={() => navigator(`/service/info-check?uid=${uid}`)}
        isHelpIcon={true}
        handleHelpIconClick={() => setHelpFlag(true)}
      />
      <section className="share-section">
        <BasicTitle
          title={`고인과의 소중한 순간을 담은 \n사진 ${event.Template.photo_length}장을 업로드해 주세요`}
        />
        <button className="images-upload_btn" onClick={handleUploadClick}>
          <AiOutlineCloudUpload className="images-upload_btn-icon" />
          <span>업로드</span>
        </button>

        {/* 
        <ServiceShareButton
          title="공유하기"
          handleClick={() => setShareModalFlag(true)}
        /> */}
      </section>

      <main>
        {imageList && imageList.length === 0 ? (
          <ServiceImagesUploadBox
            imageList={imageList}
            handleUpload={handleUpload}
          />
        ) : (
          <ServiceImagePreviewsBox
            imageList={imageList}
            handleUpload={handleUpload}
            handleCheck={handleCheck}
            handleCheckReset={handleCheckReset}
            handleSelectImage={handleSelectImage}
            handleDeleteImage={handleDeleteImage}
          />
        )}
        <ServiceMainButton
          title="완료"
          isDisable={imageList && imageList.length === 0}
          handleClick={handleUploadComplete}
        />
        {shareModalFlag && (
          <ServiceShareModal setShareModalFlag={setShareModalFlag} />
        )}
      </main>
    </div>
  );
};

export default Uploads;

import React from "react";
import styles from "./TemplateEdit.module.css";
import ImageScene from "./Image/ImageScene";
import ImagePreview from "../Project/Product/Image/ImagePreview";
import ImageCrop from "./Image/ImageCrop";
import PageHeader from "../Design/PageHeader/PageHeader";

interface TemplateEditProps {
  imageList: File[];
  imagePreview: {};
  onCrop: (image: string) => void;
}

const TemplateEdit: React.FC<TemplateEditProps> = ({
  imageList,
  imagePreview,
  onCrop,
}) => {
  return (
    <div>
      <PageHeader
        title="이미지 편집"
        desc="업로드 된 이미지를 편집합니다"
        addButton="제작"
        handleAddButton={() => {}}
      />

      <div className={styles.container}>
        <section className={styles.previewContainer}>
          <ImagePreview
            image={{ ...imagePreview, img: imageList[0] }}
            mode="making"
          />
          <ImageScene imageList={imageList} />
        </section>
        <section className={styles.editContainer}>
          <div>
            <ImageCrop
              onCrop={onCrop}
              aspectRatio={16 / 9}
              image={imageList[0]}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default TemplateEdit;

import React, { useEffect, useState } from "react";
import styles from "./ProjectAssetsAdd.module.css";

import JSONView from "react-json-view";

import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import { v4 as uuid } from "uuid";
import PageHeader from "../Design/PageHeader/PageHeader";
import ContentCard from "../Design/Card/ContentCard";
import { AssetObj, TemplateObj } from "@interfaces/admin";

import { useApi } from "@context/ApiContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AdminLoading from "@pages/Admin/AdminLoading";

interface ProjectAssetsAddProps {
  template?: any;
}

const ProjectAssetsAdd: React.FC<ProjectAssetsAddProps> = ({ template }) => {
  const { templateApi } = useApi();

  const [shortAssetsJson, setShortAssetsJson]: any = useState(null);
  const [shortAssetsText, setShortAssetsText]: any = useState("");
  const [isUploading, setIsUploading]: any = useState(false);

  const [assetsJson, setAssetsJson]: any = useState(null);
  const [assetsText, setAssetsText]: any = useState("");

  const [storage, setStorage] = useState<"S3" | "LOCAL">("LOCAL");

  const navigator = useNavigate();

  const handleShortChange = (e: any) => {
    const { value } = e.target;
    setShortAssetsText(value);
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setAssetsText(value);
  };

  const handleInputComplete = (target?: string) => {
    try {
      if (target === "short") {
        setShortAssetsJson(shortAssetsText);
      } else if (target === "default") {
        setAssetsJson(assetsText);
      }

      if (target === "save") {
        handleSubmit({
          shortAssetsText,
          assetsText,
          storage,
        });
      }
    } catch (error) {
      alert("json 형식이 올바르지 않습니다");
    }
  };

  const handleJsonEdit = (edit: any) => {
    if (edit.namespace.length === 0) {
      // 전체 JSON 데이터가 편집되었을 때
      setAssetsJson(JSON.stringify(edit.updated_src));
      setAssetsText(JSON.stringify(edit.updated_src));
    } else {
      // 특정 속성이 편집되었을 때
      setAssetsJson(JSON.stringify(edit.updated_src));
      setAssetsText(JSON.stringify(edit.updated_src));
    }
  };

  const handleSubmit = async (asset: any) => {
    setIsUploading(true);
    const { shortAssetsText, assetsText } = asset;

    const updateShortAssets = JSON.parse(shortAssetsText);
    const updateAssets = JSON.parse(assetsText);

    templateApi
      .updateTemplateAssets({
        storage,
        templateId: template.id,
        updateShortAssets: updateShortAssets.map((prev: any) => ({
          ...prev,
          storage,
        })),
        updateAssets: updateAssets.map((prev: any) => ({ ...prev, storage })),
      })

      .then((res: any) => {
        if (!res) return;

        setTimeout(() => {
          navigator("/admin/dashboard/project");
        }, 1000);

        setIsUploading(false);
        alert("assets 변경에 성공하였습니다");
      });
  };

  const { isLoading, error, data } = useQuery<any, Error>(
    ["getTemplateAssets", storage],
    () => {
      return templateApi.getTemplateAssets({
        templateId: template.id,
        storage,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    setShortAssetsJson(JSON.stringify(data?.short_assets || ""));
    setShortAssetsText(JSON.stringify(data?.short_assets || ""));

    setAssetsJson(JSON.stringify(data?.assets || ""));
    setAssetsText(JSON.stringify(data?.assets || ""));
  }, [data]);

  return (
    <div className={styles.wrap}>
      {isUploading && <AdminLoading />}
      <PageHeader
        title="Assets - 템플릿 기본 에셋 편집"
        addButton="저장"
        handleAddButton={() => handleInputComplete("save")}
      />

      <main className={styles.mainWrap}>
        <div className={styles.storageButtonWrap}>
          <button
            className={storage === "LOCAL" ? styles.active : ""}
            onClick={() => {
              setStorage("LOCAL");
            }}
          >
            LOCAL
          </button>
          <button
            className={storage === "S3" ? styles.active : ""}
            onClick={() => {
              setStorage("S3");
            }}
          >
            S3
          </button>
        </div>
        <ContentCard headerTitle={`${storage} SHORT ASSET JSON 입력`}>
          <p className={styles.desc}>
            Short 버전 제작에 기본적으로 필요한 데이터만 표시됩니다.
          </p>

          <div>
            <textarea
              name="assets"
              id="assets"
              className={styles.textarea}
              value={shortAssetsText}
              onChange={handleShortChange}
            />

            <button
              className={styles.formatButton}
              onClick={() => handleInputComplete("short")}
            >
              입력완료
            </button>
          </div>
        </ContentCard>
        <ContentCard headerTitle={`${storage} ASSET JSON 입력`}>
          <p className={styles.desc}>
            Footage, Music, watermark 등 기본적으로 필요한 데이터만 표시됩니다.
          </p>

          <div>
            <textarea
              name="assets"
              id="assets"
              className={styles.textarea}
              value={assetsText}
              onChange={handleChange}
            />

            <button
              className={styles.formatButton}
              onClick={() => handleInputComplete("default")}
            >
              입력완료
            </button>
          </div>
        </ContentCard>
        <ContentCard headerTitle="JSON 미리보기 및 개별 편집">
          <div>
            {assetsJson && (
              <JSONView
                src={JSON.parse(assetsJson)}
                theme="rjv-default"
                onEdit={handleJsonEdit}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            )}
          </div>
        </ContentCard>
      </main>
      <ToastContainer position="top-center" theme="dark" />
    </div>
  );
};

export default ProjectAssetsAdd;

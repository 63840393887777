import { db } from "./index";

const queuesRef = db.collection("queues");

export async function getQueues(search, status) {
  try {
    let query = queuesRef;

    // if (search !== "total" && photo_length !== "total") {
    //   query = query
    //     .where("type", "==", type)
    //     .where("photo_length", "==", photo_length);
    // } else if (type !== "total") {
    //   query = query.where("type", "==", type);
    // } else if (photo_length !== "total") {
    //   query = query.where("photo_length", "==", photo_length);
    // }

    const querySnapshot = await query.get();
    const queues = [];

    querySnapshot.forEach((doc) => {
      queues.push({ id: doc.id, ...doc.data() });
    });

    return queues;
  } catch (error) {
    console.error("Error getting queues: ", error);
    return [];
  }
}
export async function getQueue(queueId) {
  try {
    return queuesRef
      .doc(queueId)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          alert("데이터가 존재하지 않습니다");

          return;
        }

        return { ...doc.data(), id: doc.id };
      });
  } catch (error) {
    console.error("Error getting queues: ", error);
    return [];
  }
}

export async function addNewQueue(queue) {
  return queuesRef.add({
    ...queue,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  });
}

import React, { useEffect, useRef } from "react";
import styles from "./EventImageEditPreview.module.css";
import Perspective from "perspectivejs";
import { ImageEditProps } from "@interfaces/service";

interface EventImageEditPreviewProps {
  image: ImageEditProps | null;
  templateImageInfo: any;
}

const EventImageEditPreview: React.FC<EventImageEditPreviewProps> = ({
  image,
  templateImageInfo,
}) => {
  const canvasRefs = useRef<HTMLCanvasElement | null>(null);

  const transformImageUrl = (
    url: string | undefined,
    transformation: string
  ) => {
    const parts = url?.split("/upload/");

    if (!parts) {
      return;
    }

    return `${parts[0]}/upload/${transformation || "c_thumb,w_200"}/${
      parts[1]
    }`;
  };

  useEffect(() => {
    if (!image) return; // 이미지 URL이 undefined일 경우 무시

    const canvas = canvasRefs.current;
    if (!canvas) return; // 캔버스 참조가 없는 경우 무시

    const ctx = canvas.getContext("2d");
    if (!ctx) return; // 캔버스 2D 컨텍스트를 가져오지 못한 경우 무시

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const img = new Image();

    if (image?.state === "crop") {
      if (
        image?.src &&
        (image.src instanceof Blob || image.src instanceof File)
      ) {
        img.src = URL.createObjectURL(image.src);
      } else {
        // image?.src가 이미 URL이거나 다른 형태의 데이터인 경우
        img.src = image.updated_url as string;
      }
    } else {
      img.src = image.url as string; // 오리지널 URL 사용
    }

    const frame = new Image();
    const frameUrl = transformImageUrl(templateImageInfo.thum.url, "");
    if (frameUrl) {
      frame.src = frameUrl;
    }

    img.onload = () => {
      const info = templateImageInfo;
      const p = new Perspective(ctx, img);

      p.draw([
        [info.top_left_x, info.top_left_y],
        [info.top_right_x, info.top_right_y],
        [info.bottom_right_x, info.bottom_right_y],
        [info.bottom_left_x, info.bottom_left_y],
      ]);

      if (frame !== undefined) {
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.drawImage(frame, 0, 0, 1920, 1080);

        frame.onload = function () {
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.drawImage(frame, 0, 0, 1920, 1080);
        };
      }
    };
  }, [image, templateImageInfo]);

  return (
    <div className={styles.imgEditPreview}>
      <canvas
        id={image?.uid}
        ref={canvasRefs}
        className={styles.canvas}
        width={1920}
        height={1080}
      />
    </div>
  );
};

export default EventImageEditPreview;

import React from "react";

interface TextareaProps {
  id: string;
  handleSubtitle: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder: string;
}

const Textarea: React.FC<TextareaProps> = ({
  id,
  handleSubtitle,
  value,
  placeholder,
}) => {
  return (
    <>
      <textarea
        id={id}
        className="default-textarea"
        cols={30}
        rows={10}
        value={value}
        placeholder={placeholder}
        onChange={handleSubtitle}
      ></textarea>
      {/* <p className="error-text body-title x-small">적용하기를 눌러주세요</p> */}
    </>
  );
};

export default Textarea;

export default class TemplateApi {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getTemplate(id, target) {
    return this.apiClient
      .getTemplate(id, target)
      .then((res) => {
        const { data } = res;

        return data.result.template;
      })
      .catch((err) => console.error(err));
  }

  async getTemplates(target) {
    return this.apiClient
      .getTemplates(target)
      .then((res) => {
        const { data } = res;

        return data.result.templates;
      })
      .catch((err) => console.error(err));
  }

  async getTemplateAssets(params) {
    return this.apiClient
      .getTemplateAssets(params)
      .then((res) => {
        const { data } = res;

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async getTemplateAssetDetail(templateId) {
    return this.apiClient
      .getTemplateAssetDetail(templateId)
      .then((res) => {
        const { data } = res;

        return data.result;
      })
      .catch((err) => console.error(err));
  }

  async updateTemplateAssets(updateAssets) {
    return this.apiClient
      .updateTemplateAssets(updateAssets)
      .then((res) => {
        const { data } = res;

        if (data.statusCode !== 200) {
          alert(data.message);
          return null;
        }

        return true;
      })
      .catch((err) => console.error(err));
  }

  // 템플릿 카테고리 불러오기
  async getTemplateCategorys() {
    return this.apiClient
      .getTemplateCategorys()
      .then((res) => {
        const { data } = res;

        return data.result.templateCategorys;
      })
      .catch((err) => console.error(err));
  }
}

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import makeInfoImg1 from "@assets/images/service/make-info_1.webp";
import makeInfoImg2 from "@assets/images/service/make-info_2.webp";
import makeInfoImg3 from "@assets/images/service/make-info_3.webp";
import cardImage1 from "@assets/images/service/card_icon_1.webp";
import cardImage2 from "@assets/images/service/card_icon_2.webp";
import cardImage3 from "@assets/images/service/card_icon_3.webp";

import loadingText from "@assets/images/service/loading_text.webp";
import Thumbnail from "@assets/images/service/serviceThumbnail.webp";
import petal from "@assets/images/service/Petal.webp";
import star from "@assets/images/service/star.webp";

import BasicTitleDesc from "@components/Ui/Title/BasicTitleDesc";
import AddFunctionPhotoBox from "@components/Service/Ui/AddFunctionPhotoBox";

import MainFooter from "@components/Home/MainFooter";
import Feedbacks from "@components/Service/Feedbacks";
import VideoSample from "@components/Service/VideoSample";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "@context/ApiContext";

import { TemplateObj } from "@interfaces/admin";
import ServiceDetail from "@components/Service/ServiceDetail";
import KakaoInquiryButton from "@components/Service/KakaoInquiryButton";

interface ShopsProps {}

const Shops: React.FC<ShopsProps> = ({}) => {
  const { templateApi } = useApi();
  const navigator = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    setTimeout(() => {
      setLoading(false);
    }, 1800);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [loading]);

  const { isLoading: isTemplateLoading, data: templates } = useQuery<
    TemplateObj[],
    Error
  >(["templates"], () => templateApi.getTemplates("shop"), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { data: categorys } = useQuery(
    ["getTemplateCategorys"],
    () => templateApi.getTemplateCategorys(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  return (
    <div id="newEvent">
      {loading && (
        <div id="splash-loading">
          <img src={loadingText} alt="loading-text-img" />
        </div>
      )}
      <div className="newEvent-mainImage" style={{ marginTop: "40px" }}>
        <img className="newEvent-petal" src={petal} alt="" />
        <div className="newEvent-title">
          <div className="newEvent-title-en">Memorial Movie</div>
          <div>소중한 순간,</div>
          <div>아름다운 기억을 남깁니다.</div>
          <p>사랑하는 마음을 기억하는 방법</p>
        </div>
        <div className="newEvent-PersonalInfo">
          <img className="thumbnail" src={Thumbnail} alt="" />
        </div>
      </div>

      <VideoSample
        templates={templates}
        categorys={categorys}
        isLoading={isTemplateLoading}
      />

      <article className="newEvent-article">
        <div>
          <h3>사진이 부족하고...</h3>
          <h3>시간이 모자라서...</h3>
        </div>
        <div>
          <img src={star} alt="" />
        </div>
        <div>
          <p>
            <span>사진 1장만 있어도</span> 만들 수 있습니다.
          </p>
          <p>
            <span>장례식 이후에도</span> 제작 가능합니다.
          </p>
          <h3>기억이 담긴 사진만 올려주세요.</h3>
        </div>
      </article>
      <div className="newEvent-feedbacks">
        <Feedbacks />
      </div>

      <ServiceDetail template={templates?.[0]} />

      <MainFooter />

      {!loading && (
        <section className="bottom-order-section">
          <KakaoInquiryButton style={{ width: "100%", margin: 0 }} />
        </section>
      )}
    </div>
  );
};

export default Shops;

// import ServiceMainHeader from "@components/Service/ServiceMainHeader";
// import NumberFormat from "@components/Ui/NumberFormat";
// import { useApi } from "@context/ApiContext";
// import { useQuery } from "@tanstack/react-query";
// import React from "react";
// import { useNavigate } from "react-router-dom";

// interface ShopsProps {}

// const Shops: React.FC<ShopsProps> = ({}) => {
//   const { shopApi } = useApi();

//   const navigator = useNavigate();

//   const {
//     isLoading,
//     isError,
//     data: shops,
//   } = useQuery(["getShops"], () => shopApi.getShops(), {
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: true,
//     refetchInterval: false,
//   });

//   return (
//     <div id="shops-page">
//       <header className="default-header">
//         <button></button>
//         <h4 className="head-title small">상품 목록</h4>
//         <button></button>
//       </header>

//       <main>
//         {shops?.map((shop: any) => (
//           <div
//             className="shop-card"
//             onClick={() => {
//               navigator(`/shops/detail?shop_no=${shop?.shop_no}`);
//             }}
//           >
//             <img src={shop.shop_image} alt="shop _thum" />

//             <h6>{shop.shop_name}</h6>
//             <span>
//               <NumberFormat number={shop?.shop_price} />원
//             </span>
//           </div>
//         ))}
//       </main>
//     </div>
//   );
// };

// export default Shops;

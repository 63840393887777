import React from "react";

import SubTitlePreview from "./SubTitlePreview";
import SubTitleSetting from "./SubTitleSetting";
import styles from "./SubTitleForm.module.css";
import ContentCard from "@components/Admin/Design/Card/ContentCard";

interface SubTitleFormProps {
  subtitle?: any;

  handleSubtitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SubTitleForm = React.memo<SubTitleFormProps>(
  ({ subtitle, handleSubtitle, handleSubmit }) => {
    return (
      <form>
        <main>
          {subtitle &&
            subtitle.map((subtitle: any, idx: number) => (
              <section className={styles.flexWrap} key={subtitle.id}>
                <ContentCard headerTitle="미리보기">
                  <SubTitlePreview subtitle={subtitle} />
                </ContentCard>
                <ContentCard>
                  <SubTitleSetting
                    subtitle={subtitle}
                    idx={idx}
                    handleSubtitle={handleSubtitle}
                  />
                </ContentCard>
              </section>
            ))}
        </main>
      </form>
    );
  }
);

export default SubTitleForm;

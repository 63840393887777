import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import styles from "./ProjectCategoryAddModal.module.css";

import { useApi } from "@context/ApiContext";

interface ProjectCategoryAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateCategorys: () => void;
  category?: any;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "fit-content",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const ProjectCategoryAddModal: React.FC<ProjectCategoryAddModalProps> = ({
  isOpen,
  onClose,
  updateCategorys,
  category,
}) => {
  const { adminApi } = useApi();

  const [cate, setCate] = useState({
    template_category_name: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setCate((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (category) {
      setCate(category);
    } else {
      setCate({
        template_category_name: "",
      });
    }
  }, [category]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>
        {category ? "프로젝트 카테고리 수정" : "프로젝트 카테고리 등록"}
      </h3>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="template_category_name">카테고리명</label>
          <input
            id="template_category_name"
            type="text"
            placeholder="카테고리명을 입력하세요"
            value={cate.template_category_name}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            adminApi
              .saveOrUpdateTemplateCategory({
                category: {
                  ...category,
                  template_category_name: cate.template_category_name,
                },
                state: category ? "update" : "create",
              })
              .then((res: any) => {
                if (!res) return;

                alert("완료되었습니다");
                updateCategorys();
                onClose();
              });
          }}
        >
          {category ? "수정하기" : "추가하기"}
        </button>
      </div>
    </Modal>
  );
};

export default ProjectCategoryAddModal;

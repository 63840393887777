import React from "react";
import styles from "./Queue.module.css";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import ContentCard from "@components/Admin/Design/Card/ContentCard";
import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import StatusButton from "@components/Admin/Design/Button/StatusButton";
import dayjs from "dayjs";
import Table from "@components/Admin/Table/Table";
import TableBody from "@components/Admin/Table/TableBody";
import TableHeader from "@components/Admin/Table/TableHeader";
import AdminLoading from "@pages/Admin/AdminLoading";

interface QueueProps {}

const Queue: React.FC<QueueProps> = ({}) => {
  const { queueId } = useParams();
  const { adminApi } = useApi();
  const {
    isLoading,
    isError,
    data: queue,
  } = useQuery<any>(
    ["getQueue"],
    () => {
      return adminApi.getQueue(queueId);
    },
    {
      staleTime: 1000 * 10 * 1,
    }
  );

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader title="렌더링 Queue 상세" desc="Queue의 상태를 확인합니다." />

      <main className={styles.mainWrap}>
        <div className={styles.infoWrap}>
          <ContentCard>
            <h4 className={styles.infoTitle}>기본 정보</h4>
            <div className={styles.basicInfoWrap}>
              <div>
                <div className={styles.titleWrap}>
                  <h6>파일명</h6>
                  <p>{queue?.name}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>uid</h6>
                  <p>{queue?.uid}</p>
                </div>
              </div>
              <div>
                <div className={styles.titleWrap}>
                  <h6>행사번호</h6>
                  <p>{queue?.Event?.uid || "-"}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>고인명</h6>
                  <p>{queue?.Event?.dm_name || "-"}</p>
                </div>
              </div>
              <div>
                <div className={styles.titleWrap}>
                  <h6>영상유형</h6>
                  <p>{queue?.project_name}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>상태</h6>
                  <StatusButton state={queue.state} target="queue" />
                </div>
              </div>
              <div>
                <div className={styles.titleWrap}>
                  <h6>생성일</h6>
                  <p>{dayjs(queue?.create_dt).format("YYYY-MM-DD")}</p>
                </div>
                <div className={styles.titleWrap}>
                  <h6>수정일</h6>
                  <p>{dayjs(queue?.update_dt).format("YYYY-MM-DD")}</p>
                </div>
              </div>
            </div>
          </ContentCard>
          <ContentCard>
            <h4 className={styles.infoTitle}>Queue Text Assets</h4>

            <Table>
              <TableBody>
                <TableHeader>
                  <th className={styles.smallTh}>composition</th>
                  <th className={styles.smallTh}>layerName</th>
                  <th className={styles.largeTh}>value</th>
                </TableHeader>

                {queue?.assets?.map((que: any) => {
                  // "F"나 "S"로 시작하는 경우 행을 렌더링하지 않음 (푸티지 & 사운드 소스 )
                  if (que?.TemplateAsset?.layerName.startsWith("T")) {
                    return (
                      <tr key={que.id}>
                        <td>{que?.TemplateAsset?.composition}</td>
                        <td>{que?.TemplateAsset?.layerName}</td>
                        <td>{que.value}</td>
                      </tr>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </ContentCard>
          <ContentCard>
            <h4 className={styles.infoTitle}>Queue Image Assets</h4>

            <Table>
              <TableBody>
                <TableHeader>
                  <th className={styles.exSmallTh}>id</th>

                  <th className={styles.exSmallTh}>composition</th>
                  <th className={styles.exSmallTh}>layerName</th>
                  <th className={styles.largeTh}>src</th>
                </TableHeader>

                {queue?.assets?.map((que: any) => {
                  // "F"나 "S"로 시작하는 경우 행을 렌더링하지 않음 (푸티지 & 사운드 소스 )
                  if (que?.TemplateAsset?.layerName.startsWith("P")) {
                    return (
                      <tr key={que.id}>
                        <td>{que.id}</td>
                        <td>{que?.TemplateAsset?.composition}</td>
                        <td>{que?.TemplateAsset?.layerName}</td>
                        <td>
                          {que.type === "image" ? (
                            <img
                              className={styles.asset_thumb}
                              src={que.src}
                              alt="올라간 이미지"
                            />
                          ) : (
                            que.src
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </ContentCard>
        </div>
        <div className={styles.previewWrap}>
          <ContentCard>
            <h4 className={styles.infoTitle}>Queue Actions</h4>
            {queue.actions.map((act: any) => (
              <div className={styles.actionsWrap}>
                <div>
                  <h6>target</h6>
                  <span>{act.action_target}</span>
                </div>
                <div>
                  <h6>module</h6>
                  <span>{act.module}</span>
                </div>
                <div>
                  <h6>output</h6>
                  <span>{act.output}</span>
                </div>
              </div>
            ))}
          </ContentCard>

          <ContentCard>
            <h4 className={styles.infoTitle}>Queue Setting</h4>

            <div className={styles.actionsWrap}>
              <div>
                <h6>composition</h6>
                <span>{queue.template.composition}</span>
              </div>
              <div>
                <h6>outputExt</h6>
                <span>{queue.template.outputExt}</span>
              </div>
              <div>
                <h6>outputModule</h6>
                <span>{queue.template.outputModule}</span>
              </div>
            </div>
          </ContentCard>
        </div>
      </main>
    </div>
  );
};

export default Queue;

import React, { useState } from "react";
import { useApi } from "@context/ApiContext";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "@components/Service/Ui/Loading";
import Error from "@components/Service/Ui/Error";
import ServiceMainHeader from "@components/Service/ServiceMainHeader";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import ServiceMainButton from "@components/Service/ServiceMainButton";
import dayjs from "dayjs";

interface InfoCheckProps {}

const InfoCheck: React.FC<InfoCheckProps> = ({}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const { serviceApi } = useApi();

  const uid = new URLSearchParams(location.search).get("uid");

  const [dmName, setDmName] = useState("");
  const [birthDt, setBirthDt] = useState("");
  const [deadDt, setDeadDt] = useState("");

  const handleDmNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDmName(e.target.value);
  };
  const handleBirthDtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthDt(e.target.value);
  };

  const handleDeadDtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeadDt(e.target.value);
  };

  const {
    isLoading,
    isError,
    data: event,
  } = useQuery(
    ["getEvent", uid],
    () => {
      return serviceApi.getEvent(uid).then((res: any) => {
        if (!res) return null;

        setDmName(res.dm_name);
        setBirthDt(res.birth_dt);
        setDeadDt(dayjs(res.dead_dt).format("YYYY. MM .DD"));

        return res;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  if (!event) {
    return <div>고인의 행사정보가 없습니다</div>;
  }

  if (
    event?.template_state === "premium" &&
    ["completed", "making", "done", "confirmed"].includes(event?.state)
  ) {
    return <Navigate to={`/service/complete?uid=${event.uid}`} replace />;
  }

  const handleClick = async () => {
    // 리다이렉션 로직
    if (!dmName.trim()) {
      alert("고인 성함 입력은 필수입니다.");
      return;
    }
    const params = {
      dm_name: dmName,
      birth_dt: birthDt,
      dead_dt: deadDt,
      event_no: event.event_no,
      uid: event.uid,
      state: event.state === "prepared" ? "uploading" : event.state,
    };

    const res = await serviceApi.updateEvent(params);

    if (!res) return;

    navigator(`/service/uploads?uid=${uid}`);
  };

  return (
    <div id="service-info-check">
      <ServiceMainHeader
        title="정보 확인"
        handleLeftIconClick={() => {
          navigator(`/service/new-event?event_no=${event?.event_no}`);
        }}
        isHelpIcon={false}
      />

      <main>
        <BasicTitle title={`고인에 대한 정보를 \n확인하고 입력해주세요`} />

        <section className="info-input_section">
          <div>
            <label htmlFor="dm_name">고인 성함</label>
            <input
              id="dm_name"
              type="text"
              placeholder="고인 성함을 입력하세요"
              value={dmName}
              onChange={handleDmNameChange}
            />
          </div>
          <div>
            <label htmlFor="birth">생년월일</label>
            <input
              id="birth"
              type="text"
              placeholder="생년월일을 입력하세요 (선택 사항)"
              value={birthDt}
              onChange={handleBirthDtChange}
            />
          </div>
          <div>
            <label htmlFor="dead_dt">사망년월일</label>
            <input
              id="dead_dt"
              type="text"
              placeholder="사망년월일을 입력하세요"
              value={deadDt}
              onChange={handleDeadDtChange}
            />
          </div>
        </section>

        <ServiceMainButton
          title="추모영상 제작하기"
          handleClick={handleClick}
        />
      </main>
    </div>
  );
};

export default InfoCheck;

import React from "react";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import makeInfoImg1 from "@assets/images/service/make-info_1.png";
import makeInfoImg2 from "@assets/images/service/make-info_2.svg";
import makeInfoImg3 from "@assets/images/service/make-info_3.svg";
import BasicTitleDesc from "@components/Ui/Title/BasicTitleDesc";

interface MakeInfoSectionProps {}

const MakeInfoSection: React.FC<MakeInfoSectionProps> = () => {
  const items = [
    {
      id: "1",
      step: "STEP 1",
      title: "사진 모으기",
      desc: "링크를 공유해 사진을 모아보세요",
      image: makeInfoImg1,
    },
    {
      id: "2",
      step: "STEP 2",
      title: "사진 업로드",
      desc: "사진을 업로드 하세요",
      image: makeInfoImg2,
    },
    {
      id: "3",
      step: "STEP 3",
      title: "자막 편집",
      desc: "자막을 확인, 편집...",
      image: makeInfoImg3,
    },
  ];

  return (
    <section>
      <BasicTitle title={`추모영상의 \n간단한 제작방법`} />

      {items.map((item) => (
        <BasicTitleDesc
          key={item.id}
          step={item.step}
          title={item.title}
          desc={item.desc}
          image={item.image}
        />
      ))}
    </section>
  );
};

export default MakeInfoSection;

import React, { useEffect, useState } from "react";
import styles from "./MemberList.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import MemberTable from "@components/Admin/Member/MemberTable";
import { useApi } from "@context/ApiContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Error from "@components/Service/Ui/Error";
import MemberAddModal from "@components/Admin/Member/MemberAddModal";
import MemberGroupAddModal from "@components/Admin/Member/MemberGroupAddModal";
import { AiOutlineSetting } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import AdminLoading from "@pages/Admin/AdminLoading";

interface MemberListProps {}

const MemberList: React.FC<MemberListProps> = ({}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigator = useNavigate();
  const LV = searchParams.get("LV");

  const { adminApi } = useApi();
  const [tagetGroup, setTargetGroup] = useState(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);

  const [tagetMember, setTargetMember] = useState(null);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState({
    user_id: "",
    email: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    user_id: "",
    email: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialFilter = {
      user_id: searchParams.get("user_id") || "",
      email: searchParams.get("email") || "",
    };
    setSearchInput(initialFilter);
    setSearchFilter(initialFilter);
  }, [location.search]);

  const {
    isLoading,
    isError,
    data: admins,
  } = useQuery(
    ["getAdmins", searchFilter],
    () => adminApi.getAdmins({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const { data: adminGroups } = useQuery(
    ["getAdminGroups"],
    () => adminApi.getAdminGroups(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateAdminGroups = () => {
    queryClient.invalidateQueries(["getAdminGroups"]);
  };
  const updateAdminUsers = () => {
    queryClient.invalidateQueries(["getAdmins"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (searchInput.user_id) {
      queryParams.append("user_id", searchInput.user_id);
    }

    if (searchInput.email) {
      queryParams.append("email", searchInput.email);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };
  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div className={styles.wrap}>
      <MemberAddModal
        adminUser={tagetMember}
        adminGroups={adminGroups}
        isOpen={isMemberModalOpen}
        updateAdminUsers={updateAdminUsers}
        onClose={() => {
          setTargetMember(null);
          setIsMemberModalOpen(false);
        }}
      />
      <MemberGroupAddModal
        adminGroup={tagetGroup}
        isOpen={isGroupModalOpen}
        updateAdminGroups={updateAdminGroups}
        onClose={() => {
          setTargetGroup(null);
          setIsGroupModalOpen(false);
        }}
      />

      <div className={styles.categoryWrap}>
        <div className={styles.categorList}>
          <li
            className={`${styles.total} ${!LV ? styles.active : ""}`}
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.delete("LV");
              navigator(`?${queryParams.toString()}`);
            }}
          >
            전체 사용자
          </li>
          {adminGroups?.map((group: any) => (
            <li
              key={group.LV}
              className={`${styles.total} ${
                LV === `${group.LV}` ? styles.active : ""
              }`}
            >
              <span
                onClick={() => {
                  const queryParams = new URLSearchParams(location.search);
                  queryParams.set("LV", group.LV);
                  navigator(`?${queryParams.toString()}`);
                }}
              >
                {group.group_name}
              </span>

              <div className={styles.iconWrap}>
                <AiOutlineSetting
                  onClick={() => {
                    setTargetGroup(group);
                    setIsGroupModalOpen(true);
                  }}
                />
                <MdDeleteOutline
                  onClick={() => {
                    if (
                      window.confirm(
                        `${group.group_name} 을 정말 삭제하시겠습니까?`
                      )
                    ) {
                      adminApi
                        .saveOrUpdateAdminGroup({
                          group: {
                            LV: parseInt(group.LV),
                          },
                          state: "delete",
                        })
                        .then(() => {
                          alert("삭제되었습니다");
                          updateAdminGroups();
                        });
                    }
                  }}
                />
              </div>
            </li>
          ))}
        </div>
        <div
          className={styles.setting}
          onClick={() => setIsGroupModalOpen(true)}
        >
          새 그룹 만들기
        </div>
      </div>
      <div className={styles.mainWrap}>
        <PageHeader
          title="사용자 목록"
          addButton="사용자 추가"
          handleAddButton={() => {
            setTargetMember(null);
            setIsMemberModalOpen(true);
          }}
        />
        <div className={styles.searchInputWrap}>
          <SearchInput handleSearch={handleSearch} handleReset={handleReset}>
            <div className={searchStyles.filterItemWraps}>
              <div className={searchStyles.filterItem}>
                <label htmlFor="user_id">아이디</label>
                <input
                  id="user_id"
                  type="text"
                  value={searchInput.user_id}
                  placeholder="아이디를 입력하세요"
                  onChange={handleSearchInput}
                />
              </div>
              <div className={searchStyles.filterItem}>
                <label htmlFor="email">이메일</label>
                <input
                  id="email"
                  value={searchInput.email}
                  type="text"
                  placeholder="이메일을 입력하세요"
                  onChange={handleSearchInput}
                />
              </div>
            </div>
          </SearchInput>
        </div>
        <main>
          <MemberTable
            members={
              LV
                ? admins.filter((admin: any) => admin.LV === parseInt(LV))
                : admins
            }
            updateAdminUsers={updateAdminUsers}
            handleMemberModalOpen={(member) => {
              setTargetMember(member);
              setIsMemberModalOpen(true);
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default MemberList;

import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

interface ImageEditRangeInputProps {
  value: number;
  onChange: (value: number) => void;
  target: string;
}

const ImageEditRangeInput: React.FC<ImageEditRangeInputProps> = ({
  value,
  onChange,
  target,
}) => {
  const handleChangRotate = (value: number | number[]) => {
    if (typeof value === "number") {
      onChange(value);
    }
  };

  const handleChangeBright = (value: number | number[]) => {
    if (typeof value === "number") {
      onChange(value);
    }
  };

  if (target === "rotate") {
    const marks = {
      "-270": "-270",
      "-180": "-180",
      "-90": "-90",
      0: "0",
      90: "90",
      180: "180",
      270: "270",
    };

    return (
      <div className="slide_container">
        <Slider
          min={-270}
          max={270}
          step={5}
          startPoint={0}
          marks={marks}
          onChange={handleChangRotate}
          value={value}
        />
      </div>
    );
  }

  const marks = {
    "-10": "-10",
    "-20": "-20",
    "-30": "-30",
    0: "0",
    10: "10",
    20: "20",
    30: "30",
  };

  return (
    <div className="slide_container">
      <Slider
        min={-30}
        max={30}
        step={5}
        startPoint={0}
        marks={marks}
        onChange={handleChangeBright}
        value={value}
      />
    </div>
  );
};

export default ImageEditRangeInput;

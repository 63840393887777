import React, { ReactNode } from "react";
import styles from "./TableBody.module.css";

interface TableBodyProps {
  children: ReactNode;
}

const TableBody: React.FC<TableBodyProps> = ({ children }) => {
  return <tbody className={styles.tableBody}>{children}</tbody>;
};

export default TableBody;

import React, { useState } from "react";

import styles from "./ShopTable.module.css";

import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useApi } from "@context/ApiContext";
import { BsUpload } from "react-icons/bs";
import ShopDetailImageAddModal from "./ShopDetailImageAddModal";

interface ShopTableProps {
  shops: any;
  updateShops: () => void;
  handleShopModalOpen: (shop: any) => void;
}

const ShopTable: React.FC<ShopTableProps> = ({
  shops,
  updateShops,
  handleShopModalOpen,
}) => {
  const { adminApi } = useApi();

  const [targetShop, setTargetShop] = useState(null);
  const [isShopModalOpen, setIsShopModalOpen] = useState<boolean>(false);

  const handleShopDelete = (shop: any) => {
    if (window.confirm(`${shop.user_id} 을 정말 삭제하시겠습니까?`)) {
      adminApi
        .saveOrUpdateAdminUser({
          user: {
            user_id: shop.user_id,
          },
          state: "delete",
        })
        .then(() => {
          alert("삭제되었습니다");
          updateShops();
        });
    }
  };

  return (
    <>
      <ShopDetailImageAddModal
        shop={targetShop}
        isOpen={isShopModalOpen}
        updateShops={updateShops}
        onClose={() => {
          setTargetShop(null);
          setIsShopModalOpen(false);
        }}
      />

      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>NO</th>
            <th className={styles.middleTh}>썸네일</th>
            <th>상품명</th>
            <th className={styles.middleTh}>가격</th>
            <th className={styles.smallTh}>카테고리</th>
            <th className={styles.smallTh}>판매유무</th>
            <th className={styles.smallTh}>수정 및 삭제</th>
            <th className={styles.smallTh}>상세페이지</th>
          </TableHeader>

          {shops.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            shops?.map((shop: any, idx: number) => (
              <tr key={shop?.shop_no}>
                <td>{idx + 1}</td>
                <td>
                  <img
                    src={shop.shop_image}
                    alt="thum"
                    width={"100px"}
                    height={"30px"}
                  />
                </td>
                <td>{shop.shop_name}</td>
                <td>{shop.shop_price || "-"}원</td>
                <td>{shop?.ShopCategory?.shop_category_name}</td>
                <td>{shop.shop_sell_flag ? "판매중" : "미판매"}</td>
                <td>
                  <button
                    className={styles.editButton}
                    onClick={() => handleShopModalOpen(shop)}
                  >
                    수정
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => {
                      handleShopDelete(shop);
                    }}
                  >
                    삭제
                  </button>
                </td>
                <td>
                  <button
                    className={styles.uploadButton}
                    onClick={() => {
                      setTargetShop(shop);
                      setIsShopModalOpen(true);
                    }}
                  >
                    업로드
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ShopTable;

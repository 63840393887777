import React, { useEffect, useState } from "react";
import styles from "./DashboardStart.module.css";

import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";

import TitleCountCard from "@components/Admin/DashBoard/TitleCountCard";

import Loading from "@components/Service/Ui/Loading";
import Error from "@components/Service/Ui/Error";
import { useApi } from "@context/ApiContext";
import { useQuery } from "@tanstack/react-query";
import SalesLineChart from "@components/Admin/DashBoard/SalesLineChart";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { addDays, endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import QueueTable from "@components/Admin/Queue/QueueTable";
import SummaryCard from "@components/Admin/DashBoard/SummaryCard";

import ChartLine from "@assets/images/admin/Chart_Line.svg";
import ChartBarVertical from "@assets/images/admin/Chart_Bar_Vertical.svg";
import ChartFuneral from "@assets/images/admin/Chart_Funeral.svg";
import MakingBarChart from "@components/Admin/DashBoard/MakingBarChart";
import AdminLoading from "@pages/Admin/AdminLoading";

interface DashboardStartProps {}

const DashboardStart: React.FC<DashboardStartProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();

  //날짜
  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");
  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
  });

  const [salesLabel, setSalesLabel] = useState("today");
  const [makingLabel, setMakingLabel] = useState("today");

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
    };

    setSearchFilter({ ...initialFilter });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getDashboard"],
    () => adminApi.getDashboard(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const {
    isLoading: isQueueLoading,
    isError: isQueueError,
    data: queueData,
  } = useQuery(
    ["getQueues", searchFilter],
    () => {
      return adminApi.getQueues({ searchFilter });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const handleSearch = (e: any) => {
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };

  // if (isLoading || isQueueLoading) return <AdminLoading />;
  if (isError || isQueueError) return <Error />;

  return (
    <div>
      <main>
        {isLoading && <AdminLoading />}
        {/* <div className={searchStyles.dateWrap}>
          <div className={searchStyles.leftWrap}>
            <div className={searchStyles.quickButtons}>
              <button
                className={`${
                  quickDateType === "6months" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("6months")}
              >
                6개월
              </button>
              <button
                className={`${
                  quickDateType === "1month" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("1month")}
              >
                1개월
              </button>
              <button
                className={`${
                  quickDateType === "1week" && searchStyles.active
                }`}
                type="button"
                onClick={() => setRange("1week")}
              >
                1주
              </button>
            </div>

            <DefaultCalendar
              dateRange={dateRange}
              handleDateRangeChanged={handleDateRangeChanged}
            />
          </div>
          <div className={searchStyles.rightWrap}>
            <button className={searchStyles.checkButton} onClick={handleSearch}>
              조회
            </button>
            <button
              className={searchStyles.refreshButton}
              onClick={handleReset}
            >
              <MdRefresh />
            </button>
          </div>
        </div> */}

        <section className={styles.section}>
          <div className={styles.titleCountCards}>
            <div>
              <SummaryCard
                title="이번달 총 매출"
                summary="원"
                count={data?.totalSales || 0}
                iconImg={ChartLine}
                backgroundColor="#F1FFEB"
              />
              <SummaryCard
                title="이번달 총 수익"
                summary="원"
                count={data?.totalProfit || 0}
                iconImg={ChartLine}
                backgroundColor="#F1FFEB"
              />
            </div>
            <div>
              <SummaryCard
                title="이번달 총 제작 건"
                summary="건"
                count={data?.totalMakingCount || 0}
                iconImg={ChartBarVertical}
                backgroundColor="#F4F0FF"
              />
              <SummaryCard
                title="등록된 장례식장"
                summary="곳"
                count={data?.totalFuneralCount || 0}
                iconImg={ChartFuneral}
                backgroundColor="#EBF4FF"
              />
            </div>
          </div>

          <div className={styles.chartWraps}>
            <div className={styles.chartWrap}>
              <div className={styles.titleWrap}>
                <span>기간별 수입</span>

                <ul>
                  <li
                    className={`${salesLabel === "today" && styles.active}`}
                    onClick={() => setSalesLabel("today")}
                  >
                    오늘
                  </li>
                  <li
                    className={`${salesLabel === "weeks" && styles.active}`}
                    onClick={() => setSalesLabel("weeks")}
                  >
                    7일
                  </li>
                  <li
                    className={`${salesLabel === "months" && styles.active}`}
                    onClick={() => setSalesLabel("months")}
                  >
                    30일
                  </li>
                  <li
                    className={`${salesLabel === "years" && styles.active}`}
                    onClick={() => setSalesLabel("years")}
                  >
                    1년
                  </li>
                </ul>
              </div>

              <SalesLineChart label={salesLabel} />
            </div>
            <div className={styles.chartWrap}>
              <div className={styles.titleWrap}>
                <span>기간별 유입 및 구매전환</span>

                <ul>
                  <li
                    className={`${makingLabel === "today" && styles.active}`}
                    onClick={() => setMakingLabel("today")}
                  >
                    오늘
                  </li>
                  <li
                    className={`${makingLabel === "weeks" && styles.active}`}
                    onClick={() => setMakingLabel("weeks")}
                  >
                    7일
                  </li>
                  <li
                    className={`${makingLabel === "months" && styles.active}`}
                    onClick={() => setMakingLabel("months")}
                  >
                    30일
                  </li>
                  <li
                    className={`${makingLabel === "years" && styles.active}`}
                    onClick={() => setMakingLabel("years")}
                  >
                    1년
                  </li>
                </ul>
              </div>

              <MakingBarChart label={makingLabel} />
            </div>
          </div>
        </section>

        <section className={styles.section}>
          <h4>최근 렌더링 현황</h4>

          <div className={styles.renderCountCards}>
            <TitleCountCard title="대기중" count={queueData?.queuedCount} />
            <TitleCountCard title="시작" count={queueData?.startedCount} />

            <TitleCountCard title="렌더링" count={queueData?.renderingCount} />
            <TitleCountCard title="렌더완료" count={queueData?.finishedCount} />

            <TitleCountCard title="렌더오류" count={queueData?.errorCount} />
          </div>

          <div className={styles.eventTableWrap}>
            <QueueTable
              queues={queueData?.queues}
              totalPages={0}
              styles={styles}
              location={location}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default DashboardStart;

import React, { useState } from "react";
import styles from "./MemorialCustomerAddModal.module.css";
import Modal from "react-modal";

import { useApi } from "@context/ApiContext";

import { Customer, TemplateObj } from "@interfaces/admin";

interface MemorialCustomerAddModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const customModalStyles: Modal.Styles = {
  content: {
    maxWidth: "600px",
    height: "610px",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

type ServerApiContextType = any;
const MemorialCustomerAddModal: React.FC<MemorialCustomerAddModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { serverApi } = useApi() as ServerApiContextType;

  // const {
  //   isLoading,
  //   error,
  //   data: templates,
  // } = useQuery(
  //   ["templates"],
  //   () => serverApi.getTemplates("추모영상", "total")
  //   // {
  //   //   staleTime: Infinity,
  //   //   refetchOnWindowFocus: false,
  //   // }
  // );

  const [customer, setCustomer] = useState<Customer>({
    cm_name: "",
    cm_phone: "",
    dm_name: "",
    video_type: "추모영상",
    funeral: "",
    creator: "",
    state: "",
  });

  const [template, setTemplate] = useState<
    TemplateObj | { id: string; name: string }
  >({
    id: "",
    name: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setCustomer((prev) => ({ ...prev, [id]: value }));
  };
  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    // // const foundTemplate = templates?.find(
    // //   (template) => template.name === value
    // // );

    // if (foundTemplate) {
    //   setTemplate(foundTemplate);
    // }
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>고객 등록</h3>
      <div className={styles.inputWraps}>
        <div className={styles.inputWrap}>
          <label htmlFor="cm_name">상주명</label>
          <input
            id="cm_name"
            type="text"
            placeholder="상주명을 입력하세요"
            value={customer.cm_name}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="cm_phone">휴대폰 번호</label>
          <input
            id="cm_phone"
            type="text"
            placeholder="휴대폰 번호를 입력하세요"
            value={customer.cm_phone}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="dm_name">고인 성함</label>
          <input
            id="dm_name"
            type="text"
            placeholder="고인 성함을 입력하세요"
            value={customer.dm_name}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="template">템플릿 선택</label>
          <select
            id="template"
            value={template?.name}
            onChange={handleTemplateChange}
          >
            <option value="">선택하세요</option>
            {/* {templates?.map((template) => (
              <option key={template.id} value={template?.name}>
                {template?.name}
              </option>
            ))} */}
          </select>
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="funeral">장례식장</label>
          <select
            id="funeral"
            value={customer.funeral}
            onChange={handleInputChange}
          >
            <option value="">장례식장을 선택하세요</option>
            <option value="">없음</option>
          </select>
        </div>
        <div className={styles.inputWrap}>
          <label htmlFor="creator">생성자</label>
          <select
            id="creator"
            value={customer.creator}
            onChange={handleInputChange}
          >
            <option value="">생성자를 선택하세요</option>
            <option value="시네모">시네모</option>
            <option value="동성">동성</option>
            <option value="피플맥">피플맥</option>
          </select>
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button
          onClick={() => {
            // useApi.addNewCustomer(customer, template);
          }}
        >
          추가하기
        </button>
      </div>
    </Modal>
  );
};

export default MemorialCustomerAddModal;

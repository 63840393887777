import React from "react";
import styles from "./ProjectCard.module.css";
import { useNavigate } from "react-router-dom";

import { LuFileJson } from "react-icons/lu";
import { AiOutlineEdit, AiOutlineSetting } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { TemplateObj } from "@interfaces/admin";

interface ProjectCardProps {
  template: TemplateObj;
  handleDeleteProduct: Function;
  setTargetTemplate: any;
  setIsModalOpen: any;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  template,
  handleDeleteProduct,

  setTargetTemplate,
  setIsModalOpen,
}) => {
  const navigator = useNavigate();

  const dateFomat = (timestamp: string) => {
    const date = new Date(timestamp);

    const year = date.getFullYear().toString().slice(-2); // 뒤의 두 자리 연도
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월을 가져온 뒤 1을 더하고 두 자리로 만들고 앞에 0을 추가

    const formattedDate = `${year}.${month}`;

    return formattedDate;
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleWrap}>
        <h4>{template?.name}</h4>
        <div>
          <p>생성일. {dateFomat(template.create_dt)}</p>
          &nbsp;&nbsp;
          <p>수정일. {dateFomat(template.update_dt)}</p>
        </div>
      </div>

      <div className={styles.infoWrap}>
        <div>
          <p>
            {template?.TemplateCategory?.template_category_name} /{" "}
            {template?.photo_length}장
          </p>
        </div>
        <div className={styles.buttonWrap}>
          <button>
            <AiOutlineSetting
              onClick={() => {
                setTargetTemplate(template);
                setIsModalOpen(true);
              }}
            />
          </button>
          <button
            className={styles.assetsButton}
            onClick={() =>
              navigator(
                `/admin/dashboard/project?mode=assets_add&id=${template.id}`
              )
            }
          >
            <LuFileJson fontSize="20px" />
          </button>

          <button
            className={styles.editButton}
            onClick={() =>
              navigator(
                `/admin/dashboard/project?mode=update&id=${template.id}`
              )
            }
          >
            <AiOutlineEdit fontSize="20px" />
          </button>

          <button
            className={styles.deleteButton}
            onClick={() => handleDeleteProduct(template.id)}
          >
            <BsTrash fontSize="20px" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

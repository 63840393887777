import React, { ReactNode } from "react";
import styles from "./ContentCard.module.css";

interface ContentCardProps {
  children?: ReactNode;
  headerTitle?: string;
  headerButtonText?: string;
  handleHeaderButton?: () => void;
}

const ContentCard: React.FC<ContentCardProps> = ({
  children,
  headerTitle,
  headerButtonText,
  handleHeaderButton,
}) => {
  return (
    <article className={styles.article}>
      {headerTitle && (
        <section className={styles.headerSection}>
          <span>{headerTitle}</span>
          {headerButtonText && (
            <button onClick={handleHeaderButton}>{headerButtonText}</button>
          )}
        </section>
      )}
      <section className={styles.bodySection}>{children}</section>
    </article>
  );
};

export default ContentCard;

import React, { ReactNode } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { GrPowerReset } from "react-icons/gr";

import styles from "@assets/css/components/ui/search.module.css";
interface SearchInputProps {
  children?: ReactNode;
  handleReset?: () => void;
  handleSearch?: (e: React.FormEvent<HTMLFormElement>) => void;
  handleExportExcel?: () => void;
  isExcel?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  children,
  handleReset,
  handleSearch,
  handleExportExcel,
  isExcel,
}) => {
  return (
    <form className={styles.wrap} onSubmit={handleSearch}>
      <div className={styles.searchBox}>
        <div className={styles.searchFilters}>{children}</div>

        <div className={styles.searchBtns}>
          <button type="button" className={styles.reset} onClick={handleReset}>
            <GrPowerReset />
          </button>
          <button type="submit">
            <AiOutlineSearch />
          </button>
          {isExcel && (
            <button
              type="button"
              className={styles.excel}
              onClick={handleExportExcel}
            >
              엑셀
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default SearchInput;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./FuneralProductModal.module.css";
import { formatISO, isPast, startOfDay } from "date-fns";
import { useApi } from "@context/ApiContext";
import Table from "../Table/Table";
import TableBody from "../Table/TableBody";
import TableHeader from "../Table/TableHeader";

interface FuneralProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateFunerals: (() => void) | undefined;
  funeral?: any;
}

const FuneralProductModal: React.FC<FuneralProductModalProps> = ({
  funeral,
  isOpen,
  onClose,
  updateFunerals,
}) => {
  const customModalStyles: Modal.Styles = {
    content: {
      maxWidth: "1280px",
      height: "fit-content",
      margin: "auto",
      backgroundColor: "rgb(255, 255, 255)",
      overflow: "hidden",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const { adminApi } = useApi();

  const [shopList, setShopList] = useState<any[]>([]);

  useEffect(() => {
    if (!funeral) return;

    adminApi.getFuneralShop(funeral.FUNERAL_NO).then((res: any) => {
      setShopList(res.shopList.map((item: any) => ({ ...item })));
    });
  }, [funeral]);

  const handleSaveFuneralShop = (shop: any) => {
    adminApi
      .saveOrUpdateFuneralShop({ shop, funeral_no: funeral.FUNERAL_NO })
      .then((res: any) => {
        alert("저장되었습니다.");
      });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customModalStyles}
    >
      <h3 className={styles.title}>장례식장 상품 설정</h3>

      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>NO</th>
            <th className={styles.middleTh}>썸네일</th>
            <th>상품명</th>
            <th className={styles.middleTh}>가격</th>

            <th className={styles.smallTh}>판매유무</th>
            <th className={styles.smallTh}>수정</th>
          </TableHeader>

          {shopList.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            shopList?.map((shop: any, idx: number) => (
              <tr key={shop?.shop_no}>
                <td>{idx + 1}</td>
                <td>
                  <img
                    src={shop.shop_image}
                    alt="thum"
                    width={"100px"}
                    height={"30px"}
                  />
                </td>
                <td>{shop.shop_name}</td>
                <td className={styles.inputWrap}>
                  <input
                    type="text"
                    value={shop.shop_price || "-"}
                    onChange={(e) => {
                      setShopList((prev) =>
                        prev.map((s: any) =>
                          s.shop_no === shop.shop_no
                            ? { ...s, shop_price: e.target.value }
                            : s
                        )
                      );
                    }}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={shop.shop_sell_flag}
                    onChange={(e) => {
                      setShopList((prev) =>
                        prev.map((s: any) =>
                          s.shop_no === shop.shop_no
                            ? { ...s, shop_sell_flag: e.target.checked }
                            : s
                        )
                      );
                    }}
                  />
                  판매중
                </td>

                <td>
                  <button
                    className={styles.editButton}
                    onClick={() => {
                      handleSaveFuneralShop(shop);
                    }}
                  >
                    저장하기
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>

      <div className={styles.buttonWrap}>
        <button className={styles.cancel} onClick={onClose}>
          취소
        </button>
        <button onClick={onClose}> 확인</button>
      </div>
    </Modal>
  );
};

export default FuneralProductModal;

import React from "react";

interface BasicTitleDescProps {
  image: string;
  step: string;
  title: string;
  desc: string;
}

const BasicTitleDesc: React.FC<BasicTitleDescProps> = ({
  image,
  step,
  title,
  desc,
}) => {
  return (
    <div className="basic-title-desc">
      <div className="title-wrap">
        <h4 className="step-title">{step}</h4>
        <h6 className="head-title">{title}</h6>
        <p className="body-title">{desc}</p>
      </div>
      {image && (
        <div>
          <img src={image} alt="제작 안내 이미지" />
        </div>
      )}
    </div>
  );
};

export default BasicTitleDesc;

import React from "react";

interface BoardRoundMainButtonProps {
  isDisable?: boolean;
  title: string;
  handleClick: () => void;
}

const BoardRoundMainButton: React.FC<BoardRoundMainButtonProps> = ({
  title,
  isDisable,
  handleClick,
}) => {
  return (
    <button
      className={`board-round-main_btn ${isDisable && "disable"}`}
      onClick={handleClick}
    >
      <h6 className="head-title medium_15">{title}</h6>
    </button>
  );
};

export default BoardRoundMainButton;

import React, { useEffect, useRef, useState } from "react";
import styles from "./ProjectAdd.module.css";

import SubTitleForm from "./Product/SubTitle/SubTitleForm";
import { v4 as uuid } from "uuid";

import ImageForm from "./Product/Image/ImageForm";

import { useNavigate } from "react-router-dom";

import { uploadImage } from "@apis/uploader";

import { addNewTemplate, updateTemplate } from "@apis/firebase";
import PageHeader from "../Design/PageHeader/PageHeader";
import { useApi } from "@context/ApiContext";
import { useQuery } from "@tanstack/react-query";
import { TemplateObj } from "@interfaces/admin";

interface ProjectAddProps {
  template: TemplateObj | undefined;
}

interface SubtitleObj {
  id: string;
  img: string;
  thum: any;
  text: string;
  align: string;
  positionX: number;
  positionY: number;
  lineHeight: number;
  fontSize: number;
  font: string;
}

interface ImageObj {
  id: string;
  img: string;
  thum: any;
}

const ProjectAdd: React.FC<ProjectAddProps> = ({ template }) => {
  const { adminApi, templateApi } = useApi();

  const navigator = useNavigate();

  const [temp, setTemp] = useState<any>({});

  const [subtitle, setSubtitle] = useState<SubtitleObj[]>([]);
  const [images, setImages] = useState<ImageObj[]>([]);

  const { isLoading, error, data } = useQuery<any, Error>(
    ["getTemplateAssetDetail"],
    () => {
      return templateApi.getTemplateAssetDetail(template?.id);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    setTemp({ ...temp, ...template });
    setSubtitle(data?.subtitle as []);
    setImages(data?.images as []);
  }, [data]);

  // 자막 관련 수정
  const handleSubtitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, id, files } = e.target;
    const newValue = id === "img" ? (files && files[0]) || "" : value;

    setSubtitle((prev: any) => [
      ...prev.map((subtitle: any) => {
        if (subtitle.id != name) return subtitle; // 변경 없이 동일 객체 반환
        return {
          ...subtitle,
          [id]: newValue,
          thum: id === "img" && subtitle.thum ? null : subtitle.thum,
        };
      }),
    ]);
  };

  const handleImageFit = (id: string) => {
    setImages((prev: any) => [
      ...prev.map((image: any) => {
        if (image.id != id) return image; // 변경 없이 동일 객체 반환
        return {
          ...image,
          image_width: 1920,
          image_height: 1080,
          top_left_x: 0,
          top_left_y: 0,
          top_right_x: 1920,
          top_right_y: 0,
          bottom_left_x: 0,
          bottom_left_y: 1080,
          bottom_right_x: 1920,
          bottom_right_y: 1080,
        };
      }),
    ]);
  };

  const handleImages = (e: any) => {
    const { name, value, id, files } = e.target;

    const newValue = id === "img" ? files && files[0] : value;

    setImages((prev: any) => [
      ...prev.map((image: any) => {
        if (image.id != name) return image; // 변경 없이 동일 객체 반환
        return {
          ...image,
          [id]: newValue,
          thum: image.thum && id === "img" ? null : image.thum,
        };
      }),
    ]);
  };

  const handleSubmit = async () => {
    const subTitleSubmitFlag = await subTitleInputSubmit();
    if (!subTitleSubmitFlag) return;

    const imgSubmitFlag = await imageInputSubmit();
    if (!imgSubmitFlag) return;

    adminApi.updateTempSubAndImage({
      subtitle,
      images,
    });

    // if (templateId && template.update_dt) {
    //   updateTemplate(template);
    // } else {
    //   addNewTemplate(template);
    // }

    setTimeout(() => {
      navigator("/admin/dashboard/project");
    }, 1000);
  };

  const subTitleInputSubmit = async () => {
    try {
      let vaild: Boolean = true;

      subtitle.forEach((sub: SubtitleObj, idx: number) => {
        // if (!sub.img && !sub.thum) {
        //   const thumError = `${idx + 1}번째 자막 썸네일을 업로드하여 주십시오`;
        //   alert(thumError);
        //   vaild = false;
        //   return;
        // }
        // if (!sub.text) {
        //   const textError = `${idx + 1}번째 자막이 비어있습니다`;
        //   alert(textError);
        //   vaild = false;
        //   return;
        // }
      });

      if (!vaild) return false;

      const updatedSubtitles: any = [];

      const subUpdatePromises = subtitle.map(async (sub: any) => {
        const { img, thum } = sub;

        if (img && (!thum || !thum.public_id)) {
          const imageData = await uploadImage(
            img,
            `template/${temp.name}/subTitleThum`
          );

          sub.thum = imageData;
          delete sub.img;
        }

        updatedSubtitles.push(sub);
      });
      await Promise.all(subUpdatePromises);
      setSubtitle(updatedSubtitles);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const imageInputSubmit = async () => {
    let vaild: Boolean = true;

    try {
      // images.forEach((sub: ImageObj, idx: number) => {
      //   if (!sub.img && !sub.thum) {
      //     const thumError = `${idx + 1}번째 편집 썸네일을 업로드하여 주십시오`;
      //     alert(thumError);
      //     vaild = false;

      //     return;
      //   }
      // });

      if (!vaild) return false;

      const updatedImage: any = [];
      const imageUpdatePromises = images.map(async (image: any) => {
        const { img, thum } = image;

        if (img && (!thum || !thum.public_id)) {
          const imageData = await uploadImage(
            img,
            `template/${temp.name}/imagesThum`
          );

          image.thum = imageData;
          delete image.img;
        }

        updatedImage.push(image);
      });

      // 모든 이미지 업데이트 작업을 기다립니다.
      await Promise.all(imageUpdatePromises);

      // 모든 처리가 끝난 후 이미지 상태를 업데이트합니다.
      setImages(updatedImage);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <div className={styles.wrap}>
      <PageHeader
        title="템플릿 편집 및 추가"
        addButton="저장"
        handleAddButton={handleSubmit}
      />

      <main className={styles.mainWrap}>
        <div>
          <section className={styles.headTilteSection}>
            <div>
              <h1>자막 설정</h1>
            </div>
          </section>

          <SubTitleForm
            subtitle={subtitle}
            handleSubmit={handleSubmit}
            handleSubtitle={handleSubtitle}
          />
        </div>

        <div>
          <section className={styles.headTilteSection}>
            <div>
              <h1>이미지 설정</h1>
            </div>
          </section>

          <ImageForm
            images={images}
            handleImages={handleImages}
            handleImageFit={handleImageFit}
          />
        </div>
      </main>
    </div>
  );
};

export default ProjectAdd;

import React from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";

import { Link, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import ReactPaginate from "react-paginate";
import pageStyles from "@assets/css/components/ui/pagination.module.css";

interface QueueTableProps {
  queues: any;
  styles: any;
  totalPages: number;
  location?: any;
}

const QueueTable: React.FC<QueueTableProps> = ({
  queues,
  styles,
  totalPages,
  location,
}) => {
  const navigator = useNavigate();
  const { adminApi } = useApi();

  const pageCount = totalPages;

  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  const handleCancelJob = (queue: any) => {
    const confirmMsg = `해당 job(${queue.uid})을 취소하시겠습니까?`;

    if (window.confirm(confirmMsg)) {
      adminApi.cancelQueue({ uid: queue.uid });
    }
  };

  const handleRetryJob = (queue: any) => {
    const confirmMsg = `해당 job(${queue.uid})을 재시작 하시겠습니까?`;

    const retryQueue = {
      ...queue,
      assets: queue.assets.map((a: any) => ({
        ...a.TemplateAsset,
        value: a.value,
        src: a.src,
      })),
    };

    if (window.confirm(confirmMsg)) {
      adminApi.retryQueue({ queue: retryQueue });
    }
  };

  // const handleRetryJob = (queue: any) => {
  //   const confirmMsg = `해당 job(${queue.uid})을  하시겠습니까?`;

  //   if (window.confirm(confirmMsg)) {
  //     adminApi.retryQueue({ queue });
  //   }
  // };

  return (
    <>
      <Table>
        <TableHeader>
          <th className={styles.smallTh}>상태</th>

          <th className={styles.smallTh}>행사번호</th>
          <th>uid</th>
          <th>파일명</th>
          <th>템플릿</th>
          <th>업데이트</th>

          <th className={styles.smallTh}>상세</th>
          <th className={styles.smallTh}></th>
        </TableHeader>

        <TableBody>
          {!queues || queues?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다.</td>
            </tr>
          ) : (
            queues?.map((queue: any) => (
              <tr key={queue.uid}>
                <td>
                  <StatusButton state={queue.state} target="queue" />
                </td>

                <td>{queue.EventUid}</td>
                <td className={styles.uid}>{queue.uid}</td>

                <td>{queue.name}</td>
                <td>{queue.project_name}</td>
                <td>{dayjs(queue.update_dt).format("MM-DD HH:mm:ss")}</td>

                <td>
                  <button
                    className={styles.detailsButton}
                    onClick={() => {
                      navigator(`/admin/dashboard/queues/${queue.id}`);
                    }}
                  >
                    상세보기
                  </button>
                </td>
                {queue?.state === "queued" && (
                  <td
                    className={styles.cancelButton}
                    onClick={() => handleCancelJob(queue)}
                  >
                    <span>취소</span>
                  </td>
                )}

                {["finished", "error", "cancelled"].includes(queue?.state) && (
                  <td
                    className={styles.cancelButton}
                    onClick={() => handleRetryJob(queue)}
                  >
                    <span>retry</span>
                  </td>
                )}

                {/* 
                {!["finished", "error", "cancelled", "started"].includes(
                  queue?.state
                ) ? (
                 
                ) : (
                  <td></td>
                )} */}
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default QueueTable;

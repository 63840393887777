import HomeNavbar from "@components/Home/HomeNavbar";
import LandingMain from "@components/Home/LandingMain";
import MainFooter from "@components/Home/MainFooter";
import React, { useRef } from "react";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const projectRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const handleScrollToProject = () => {
    if (projectRef.current) {
      window.scrollTo({
        top: projectRef.current?.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const handleScrollToContact = () => {
    if (contactRef.current) {
      window.scrollTo({
        top: contactRef.current?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div id="home-page">
      <HomeNavbar
        handleScrollToProject={handleScrollToProject}
        handleScrollToContact={handleScrollToContact}
      />

      <LandingMain projectRef={projectRef} contactRef={contactRef} />

      <MainFooter page="home" />
    </div>
  );
};

export default Home;

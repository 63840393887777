import React from "react";
import chevronDown from "@assets/images/service/chevron-down.svg";

interface ExpandBoxProps {
  id: string;
  title: string;
  desc: string;
  isToggle: boolean;
  setIsToggle: (value: string) => void;
}

const ExpandBox: React.FC<ExpandBoxProps> = ({
  id,
  title,
  desc,
  isToggle,
  setIsToggle,
}) => {
  return (
    <div className="expand-box">
      <div className="title__wraps">
        <h6 className="body-title small">{title}</h6>

        <button onClick={() => setIsToggle(id)}>
          <img
            className={`${isToggle && "toggleImg"}`}
            src={chevronDown}
            alt="아래 화살표"
          />
        </button>
      </div>

      <div className={`${isToggle && "toggleExpandInfo"} expandInfo`}>
        {isToggle && <p className="body-title small">{desc}</p>}
      </div>
    </div>
  );
};

export default ExpandBox;

import React, { useState } from "react";
import BasicTitle from "@components/Ui/Title/BasicTitle";
import ExpandBox from "@components/Ui/Box/ExpandBox";

interface AddInfoSectionProps {}

const AddInfoSection: React.FC<AddInfoSectionProps> = () => {
  const [items, setItems] = useState([
    {
      id: "1",
      title: "평생 보관  & 무제한 다운로드",
      desc: "장례식이 끝난 후에도 볼 수 있도록 영상을 소장할 수 있습니다",
      isToggle: false,
    },
    {
      id: "2",
      title: "USB 제공",
      desc: "장례식이 끝난 후에도 볼 수 있도록 영상을 소장할 수 있습니다",
      isToggle: false,
    },
    {
      id: "3",
      title: "장례식장 상영 (프리미엄)",
      desc: "장례식이 끝난 후에도 볼 수 있도록 영상을 소장할 수 있습니다",
      isToggle: false,
    },
    {
      id: "4",
      title: "맞춤형 영상 제작",
      desc: "장례식이 끝난 후에도 볼 수 있도록 영상을 소장할 수 있습니다",
      isToggle: false,
    },
  ]);

  const setIsToggle = (id: string) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === id) {
          return { ...item, isToggle: !item.isToggle };
        }

        return item;
      })
    );
  };
  return (
    <section>
      <BasicTitle title={`더 의미있는 \n추모영상 추가 기능`} />

      {items.map((item) => (
        <ExpandBox
          key={item.id}
          id={item.id}
          title={item.title}
          desc={item.desc}
          isToggle={item.isToggle}
          setIsToggle={setIsToggle}
        />
      ))}
    </section>
  );
};

export default AddInfoSection;

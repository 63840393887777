import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Tick,
  Scale,
  CoreScaleOptions,
} from "chart.js";
import dayjs from "dayjs";
import { useApi } from "@context/ApiContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface SalesLineChartProps {
  label: string;
}

const SalesLineChart: React.FC<SalesLineChartProps> = ({ label }) => {
  const { adminApi } = useApi();
  const [options, setOptions] = useState<ChartOptions<"line">>({
    scales: {
      y: {
        ticks: {
          callback: function (
            this: Scale<CoreScaleOptions>,
            tickValue: string | number,
            index: number,
            ticks: Tick[]
          ) {
            return typeof tickValue === "number" ? `${tickValue}원` : tickValue;
          },
          font: {
            size: 14,
          },
          color: "#A2A2A2",
        },
      },
      x: {
        ticks: {
          font: {
            size: 14,
          },
          color: "#A2A2A2",
        },
      },
    },
  });
  const [data, setData] = useState<{
    labels: (string | string[])[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
      tension: number;
    }[];
  }>({
    labels: [],
    datasets: [
      {
        label: "총 매출",
        data: [],
        fill: false,
        borderColor: "#FF6484",
        tension: 0.1,
      },
      {
        label: "총 수익",
        data: [],
        fill: false,
        borderColor: "#36A2EB",
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    let dynamicLabels: string[];

    if (label === "today") {
      dynamicLabels = Array.from(
        { length: 24 },
        (_, i) => `${i.toString().padStart(2, "0")}:00`
      );

      setData((prev) => ({
        ...prev,
        labels: dynamicLabels,
      }));
    } else {
      switch (label) {
        case "weeks":
          dynamicLabels = Array.from({ length: 7 }, (_, i) =>
            dayjs().subtract(i, "day").format("MM-DD")
          );
          break;
        case "months":
          dynamicLabels = Array.from({ length: 30 }, (_, i) =>
            dayjs().subtract(i, "day").format("MM-DD")
          );
          break;
        case "years":
          dynamicLabels = Array.from({ length: 12 }, (_, i) =>
            dayjs().subtract(i, "month").format("YYYY-MM")
          );
          break;
        default:
          dynamicLabels = Array.from({ length: 24 }, (_, i) =>
            dayjs().hour(i).format("HH:mm")
          );
          break;
      }

      setData((prev) => ({
        ...prev,
        labels: dynamicLabels.reverse(),
      }));
    }

    // 시간대별 수익 받아오기
    adminApi
      .getSalesChartData({ label, dateArray: dynamicLabels })
      .then((res: any) => {
        const {
          datasets: { sales, profit },
        } = res;

        if (
          sales.every((data: number) => data === 0) ||
          profit.every((data: number) => data === 0)
        ) {
          setOptions((prev) => ({
            ...prev,
            scales: {
              y: {
                min: 0,
                max: 300000,
              },
            },
          }));
        } else {
          setOptions((prev) => ({
            ...prev,
            scales: {
              y: {
                min: 0,
              },
            },
          }));
        }
        setData((prev) => ({
          ...prev,
          datasets: [
            {
              label: "총 매출",
              data: sales,
              fill: false,
              borderColor: "#FF6484",
              tension: 0.1,
            },
            {
              label: "총 수익",
              data: profit,
              fill: false,
              borderColor: "#36A2EB",
              tension: 0.1,
            },
          ],
        }));
      });
  }, [label]);

  return <Line data={data} options={options} />;
};

export default SalesLineChart;

import React, { useState } from "react";
import { ServiceImageProps } from "@interfaces/service";
import ServiceImageBox from "./ServiceImageBox";

interface ServiceImagePreviewsBoxProps {
  imageList: ServiceImageProps[];
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckReset: () => void;
  handleCheck: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSelectImage: (e: ServiceImageProps) => void;
  handleDeleteImage: () => void;
}

const ServiceImagePreviewsBox: React.FC<ServiceImagePreviewsBoxProps> = ({
  imageList,
  handleCheck,
  handleCheckReset,
  handleSelectImage,
  handleDeleteImage,
}) => {
  const [selectFlag, setSelectFlag] = useState(false);

  return (
    <section className="service-image-preview_section">
      <div className="section-header">
        <span>사진 {imageList.length}/ 30</span>
        {selectFlag ? (
          <div>
            <button
              onClick={() => {
                handleCheckReset();
                setSelectFlag((selectFlag) => !selectFlag);
              }}
            >
              취소
            </button>
            <button className="delete_btn" onClick={handleDeleteImage}>
              삭제
            </button>
          </div>
        ) : (
          <button
            className="choose_btn"
            onClick={() => setSelectFlag((selectFlag) => !selectFlag)}
          >
            선택
          </button>
        )}
      </div>
      <main className="main-content">
        <div className="image-grid">
          {imageList.map((image, idx) => (
            <ServiceImageBox
              key={idx}
              image={image}
              selectFlag={selectFlag}
              handleCheck={handleCheck}
              handleSelectImage={handleSelectImage}
            />
          ))}
        </div>
      </main>
    </section>
  );
};

export default ServiceImagePreviewsBox;

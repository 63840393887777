import React from "react";

interface NumberFormatProps {
  number: number;
}

const formatKRW = (value: any) => {
  const formatter = new Intl.NumberFormat("ko-KR", {
    style: "decimal", // 숫자 포맷으로 설정
  });

  return formatter.format(value);
};

const NumberFormat: React.FC<NumberFormatProps> = ({ number }) => {
  return <>{formatKRW(number)}</>;
};

export default NumberFormat;

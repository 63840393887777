import React, { useEffect, useState } from "react";
import PageHeader from "@components/Admin/Design/PageHeader/PageHeader";

import styles from "./OrderCals.module.css";
import searchStyles from "@assets/css/components/ui/search.module.css";
import SearchInput from "@components/Admin/Design/Input/SearchInput";
import AdminLoading from "@pages/Admin/AdminLoading";
import Error from "@components/Service/Ui/Error";
import dayjs from "dayjs";
import NumberFormat from "@components/Ui/NumberFormat";
import DefaultCalendar from "@components/Admin/DashBoard/DefaultCalendar";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "@context/ApiContext";
import { endOfDay, startOfDay, subMonths, subWeeks } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import AdminOrderCalTable from "@components/Admin/Order/AdminOrderCalTable";

interface OrderCalsProps {}

const OrderCals: React.FC<OrderCalsProps> = ({}) => {
  const location = useLocation();
  const navigator = useNavigate();

  const { adminApi } = useApi();
  const [searchInput, setSearchInput] = useState({
    event_no: "",
    funeral_name: "",
    dm_name: "",
  });

  const [searchFilter, setSearchFilter] = useState({
    startDate: subWeeks(startOfDay(new Date()), 1),
    endDate: endOfDay(new Date()),
    event_no: "",
    funeral_name: "",
    dm_name: "",
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: subWeeks(startOfDay(new Date()), 1),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [quickDateType, setQuickDateType] = useState("");

  const handleDateRangeChanged = (ranges: any) => {
    setDateRange([ranges.selection]);
  };

  const setRange = (type: string) => {
    let startDate;
    let endDate = endOfDay(new Date());

    switch (type) {
      case "6months":
        startDate = subMonths(startOfDay(new Date()), 6);

        break;
      case "1month":
        startDate = subMonths(startOfDay(new Date()), 1);
        break;
      case "1week":
      default:
        startDate = subWeeks(startOfDay(new Date()), 1);
        break;
    }
    setQuickDateType(type);
    setDateRange([{ startDate, endDate, key: "selection" }]);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // URL에서 받아온 날짜를 Date 객체로 변환
    const startDateParam = searchParams.get("startDate");
    const endDateParam = searchParams.get("endDate");

    const startDate = startDateParam
      ? new Date(startDateParam)
      : subWeeks(startOfDay(new Date()), 1);
    const endDate = endDateParam
      ? new Date(endDateParam)
      : endOfDay(new Date());

    const initialFilter = {
      startDate: startDate,
      endDate: endDate,
      page: searchParams.get("page") || 1,
      pageSize: 10,
    };

    const initialInput = {
      event_no: searchParams.get("event_no") || "",
      funeral_name: searchParams.get("funeral_name") || "",
      dm_name: searchParams.get("dm_name") || "",
    };

    setSearchInput(initialInput);
    setSearchFilter({ ...initialFilter, ...initialInput });
    setDateRange([{ startDate, endDate, key: "selection" }]);
  }, [location.search]);

  const { isLoading, isError, data } = useQuery(
    ["getOrderCals", searchFilter],
    () => adminApi.getOrderCals({ searchFilter }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    }
  );

  const queryClient = useQueryClient();

  const updateOrders = () => {
    queryClient.invalidateQueries(["getOrderCals"]);
  };

  const handleSearchInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;

    setSearchInput((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();

    if (dateRange[0].startDate) {
      queryParams.append("startDate", dateRange[0].startDate.toISOString());
    }

    if (dateRange[0].endDate) {
      queryParams.append("endDate", dateRange[0].endDate.toISOString());
    }

    if (searchInput.event_no) {
      queryParams.append("event_no", searchInput.event_no);
    }

    if (searchInput.funeral_name) {
      queryParams.append("funeral_name", searchInput.funeral_name);
    }

    if (searchInput.dm_name) {
      queryParams.append("dm_name", searchInput.dm_name);
    }

    navigator(`?${queryParams.toString()}`);
  };

  const handleReset = () => {
    navigator("");
  };

  const requestGetAllOrderCals = async () => {
    try {
      const { orders } = await adminApi.getOrderCals({
        ...searchFilter,
        pageSize: 9999999,
      });

      const excelList = orders.map((order: any, idx: number) => {
        const result = {
          No: idx + 1,
          주문번호: order.imp_uid,
          상품명: order.name,
          고객정보: order?.buyer_name,
          결제구분: order?.pay_method,
          결제금액: order?.amount,
          관리비: order?.shop_managed_cost,
          결제완료일시: order?.paid_dt
            ? dayjs(order?.paid_dt).format("MM-DD HH:mm")
            : "",
          결제취소일시: order?.cancel_dt
            ? dayjs(order?.cancel_dt).format("MM-DD HH:mm")
            : "",
          결제상태: order.status === "paid" ? "결제완료" : "결제취소",
        };

        return result;
      });

      return excelList;
    } catch (error) {
      console.error(error);
      alert("excel 전체 데이터 불러오기를 실패하였습니다");
    }
  };

  const handleExportExcel = async () => {
    try {
      const excel = await requestGetAllOrderCals();

      const Xlsx = require("xlsx-js-style");
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.aoa_to_sheet([]);
      const border = {
        top: { style: "thin", color: { rgb: "000000" } }, // 위쪽 테두리
        bottom: { style: "thin", color: { rgb: "000000" } }, // 아래쪽 테두리
        left: { style: "thin", color: { rgb: "000000" } }, // 왼쪽 테두리
        right: { style: "thin", color: { rgb: "000000" } }, // 오른쪽 테두리
      };
      // 엑셀 스타일을 적용하기 위한 설정
      const headerStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        fill: {
          fgColor: { rgb: "FFC247" }, // 셀 배경색 (노란색)
        },
        border,
      };

      const cellStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        border,
      };

      const redBgCellStyle = {
        font: {
          name: "돋움",
          sz: 8,
        },
        fill: {
          fgColor: { rgb: "FF0000" }, // 셀 배경색 (빨간색)
        },
        border,
      };

      // 데이터 추가
      Xlsx.utils.sheet_add_json(workSheet, excel, {
        origin: -1,
      });
      const range = Xlsx.utils.decode_range(workSheet["!ref"]);

      // 첫 번째 줄에 스타일 적용
      const headerRange = {
        s: { r: 0, c: 0 },
        e: { r: 0, c: range.e.c },
      };
      for (let colNum = headerRange.s.c; colNum <= headerRange.e.c; colNum++) {
        const cellAddress = Xlsx.utils.encode_cell({
          r: headerRange.s.r,
          c: colNum,
        });
        const cell = workSheet[cellAddress];
        cell.s = headerStyle;
      }

      // 나머지 셀에는 기본 스타일 적용
      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = Xlsx.utils.encode_cell({
            r: rowNum,
            c: colNum,
          });
          const cell = workSheet[cellAddress];

          // status가 'cancelled'인 경우 빨간색 배경 스타일 적용
          if (excel[rowNum - 1] && excel[rowNum - 1].결제상태 === "결제취소") {
            cell.s = redBgCellStyle;
          } else {
            cell.s = cellStyle;
          }

          cell.b = cellStyle.border; // 테두리 적용
        }
      }

      Xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        `${dayjs(searchFilter.startDate).format("YYYY-MM-DD")} ~ ${dayjs(
          searchFilter.endDate
        ).format("YYYY-MM-DD")}`
      );

      Xlsx.writeFile(
        workBook,
        `시네모_상품 관리비 정산(${dayjs(searchFilter.startDate).format(
          "YYYY-MM-DD"
        )} ~ ${dayjs(searchFilter.endDate).format("YYYY-MM-DD")}).xlsx`
      );
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) return <AdminLoading />;
  if (isError) return <Error />;

  return (
    <div>
      <PageHeader
        title="상품 관리비 정산 관리"
        desc="상품 관리비 정산 목록을 관리합니다."
      />

      <section className={styles.funcSection}>
        <SearchInput
          handleSearch={handleSearch}
          handleReset={handleReset}
          isExcel
          handleExportExcel={handleExportExcel}
        >
          <div className={searchStyles.dateWrap}>
            <div className={searchStyles.leftWrap}>
              <div className={searchStyles.quickButtons}>
                <button
                  className={`${
                    quickDateType === "6months" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("6months")}
                >
                  6개월
                </button>
                <button
                  className={`${
                    quickDateType === "1month" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1month")}
                >
                  1개월
                </button>
                <button
                  className={`${
                    quickDateType === "1week" && searchStyles.active
                  }`}
                  type="button"
                  onClick={() => setRange("1week")}
                >
                  1주
                </button>
              </div>

              <DefaultCalendar
                dateRange={dateRange}
                handleDateRangeChanged={handleDateRangeChanged}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="event_no">행사번호</label>
              <input
                id="event_no"
                type="text"
                value={searchInput.event_no}
                placeholder="행사번호를 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
            <div className={searchStyles.filterItem}>
              <label htmlFor="funeral_name">장례식장</label>
              <input
                id="funeral_name"
                value={searchInput.funeral_name}
                type="text"
                placeholder="장례식장을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>

          <div className={searchStyles.filterItemWraps}>
            <div className={searchStyles.filterItem}>
              <label htmlFor="dm_name">고인명</label>
              <input
                id="dm_name"
                type="text"
                value={searchInput.dm_name}
                placeholder="고인명을 입력하세요"
                onChange={handleSearchInput}
              />
            </div>
          </div>
        </SearchInput>
      </section>

      <main className={styles.mainWrap}>
        <table className={styles.sumaryTable}>
          <thead>
            <tr>
              <th>총 주문 수량</th>
              <th>총 거래액</th>
              <th>총 순거래액</th>
              <th>총 거래취소액</th>
              <th>총 관리비</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <NumberFormat
                  number={data?.totalOrderCalInfo?.totalPaidOrderCount}
                />
              </td>
              <td>
                <NumberFormat
                  number={data?.totalOrderCalInfo?.totalPaidOrderAmount}
                />
                원
              </td>
              <td>
                <NumberFormat
                  number={data?.totalOrderCalInfo?.totalRealOrderAmount}
                />
                원
              </td>
              <td>
                <NumberFormat
                  number={data?.totalOrderCalInfo?.totalCancelledOrderAmount}
                />
                원
              </td>
              <td>
                <NumberFormat
                  number={data?.totalOrderCalInfo?.totalManagedAmount}
                />
                원
              </td>
            </tr>
          </tbody>
        </table>
        <AdminOrderCalTable
          orders={data.orders}
          totalPages={data?.totalPages || 0}
          updateOrders={updateOrders}
        />
      </main>
    </div>
  );
};

export default OrderCals;

import React from "react";
import styles from "./ImageForm.module.css";

import ImagePreview from "./ImagePreview";
import ImageSetting from "./ImageSetting";
import ContentCard from "@components/Admin/Design/Card/ContentCard";

export default function ImageForm({ images, handleImages, handleImageFit }) {
  return (
    <form>
      <main>
        {images &&
          images.map((image, idx) => (
            <div className={styles.flexWrap} key={image.id}>
              <ContentCard headerTitle="미리보기">
                <ImagePreview image={image} />
              </ContentCard>
              <ContentCard>
                <ImageSetting
                  image={image}
                  idx={idx}
                  handleImages={handleImages}
                  handleImageFit={handleImageFit}
                />
              </ContentCard>
            </div>
          ))}
      </main>
    </form>
  );
}

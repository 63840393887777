import React, { useEffect, useRef, useState } from "react";
import Table from "../Table/Table";
import TableHeader from "../Table/TableHeader";
import TableBody from "../Table/TableBody";
import dayjs from "dayjs";
import pageStyles from "@assets/css/components/ui/pagination.module.css";
import styles from "./AdminFuneralTable.module.css";

import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusButton from "../Design/Button/StatusButton";
import { CgDetailsMore } from "react-icons/cg";
import { useApi } from "@context/ApiContext";
import FuneralPaybackModal from "./FuneralPaybackModal";
import NumberFormat from "@components/Ui/NumberFormat";
import FuneralProductModal from "./FuneralProductModal";

interface Funeral {
  FUNERAL_NO: string;
  FUNERAL_NAME: string;
  BANNER_COUNT: number;
  PAMPHLET_COUNT: number;
  FuneralPaybacks: {
    payback_apply: boolean;
    funeral_amount: number;
    ds_amount: number;
    cnemo_amount: number;
  }[];
}

interface AdminFuneralTableProps {
  funerals: Funeral[];
  totalPages: number;
  location?: any;
  updateFunerals?: () => void;
}

const AdminFuneralTable: React.FC<AdminFuneralTableProps> = ({
  funerals,
  totalPages,
  updateFunerals,
}) => {
  const { adminApi } = useApi();

  const location = useLocation();
  const navigator = useNavigate();

  const pageCount = totalPages;

  const [targetFuneral, setTargetFuneral] = useState<Funeral | null>(null);
  const [isPaybackModalOpen, setIsPaybackModalOpen] = useState<boolean>(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);

  const [bannerCount, setBannerCount] = useState<number>(0);
  const [pamphletCount, setPamphletCount] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Invoke when user click to request another page.
  const handlePageClick = (e: any) => {
    if (pageCount < e.selected + 1) {
      alert("마지막 페이지 입니다.");
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", e.selected + 1);
    navigator(`?${queryParams.toString()}`);
  };

  const handleEditCount = (funeral: Funeral) => {
    const params = {
      FUNERAL_NO: funeral.FUNERAL_NO,
      BANNER_COUNT: bannerCount,
      PAMPHLET_COUNT: pamphletCount,
    };

    adminApi.saveOrUpdateFuneral(params).then((res: any) => {
      alert("수정되었습니다.");
      setIsEdit(false);
      setTargetFuneral(null);
      setBannerCount(0);
      setPamphletCount(0);

      if (updateFunerals) updateFunerals();
    });
  };
  const handleDeleteFuneral = (funeral: Funeral) => {
    const confirmMsg = `정말 ${funeral.FUNERAL_NAME}을 삭제하시겠습니까?`;
    if (!window.confirm(confirmMsg)) return;

    adminApi.deleteFuneral(funeral.FUNERAL_NO).then((res: any) => {
      alert("삭제되었습니다.");

      if (updateFunerals) updateFunerals();
    });
  };
  return (
    <>
      <FuneralPaybackModal
        funeral={targetFuneral}
        isOpen={isPaybackModalOpen}
        updateFunerals={updateFunerals}
        onClose={() => {
          setTargetFuneral(null);
          setIsPaybackModalOpen(false);
        }}
      />
      <FuneralProductModal
        funeral={targetFuneral}
        isOpen={isProductModalOpen}
        updateFunerals={updateFunerals}
        onClose={() => {
          setTargetFuneral(null);
          setIsProductModalOpen(false);
        }}
      />

      <Table>
        <TableBody>
          <TableHeader>
            <th className={styles.exSmallTh}>고유 ID</th>
            <th className={styles.middleTh}>장례식장명</th>

            <th className={styles.middleTh}>식장 지원액</th>
            <th className={styles.middleTh}>동성 지원액</th>
            <th className={styles.middleTh}>시네모 지원액</th>

            <th className={styles.exSmallTh}>페이백 설정</th>
            <th className={styles.exSmallTh}>상품 설정</th>
            <th>배너 / 팜플렛</th>
            <th className={styles.exSmallTh}>삭제</th>
          </TableHeader>
          {funerals?.length === 0 ? (
            <tr>
              <td>데이터가 없습니다</td>
            </tr>
          ) : (
            funerals?.map((funeral: Funeral) => (
              <tr key={funeral?.FUNERAL_NO}>
                <td>{funeral?.FUNERAL_NO || "-"}</td>
                <td>{funeral?.FUNERAL_NAME || "-"}</td>

                {/* <td>
                  <NumberFormat
                    number={
                      funeral.FuneralPaybacks.find(
                        (payback: any) => payback.payback_apply
                      )?.offer_amount || 0
                    }
                  />
                </td> */}
                <td>
                  <NumberFormat
                    number={
                      funeral.FuneralPaybacks.find(
                        (payback: any) => payback.payback_apply
                      )?.funeral_amount || 0
                    }
                  />
                </td>
                <td>
                  <NumberFormat
                    number={
                      funeral.FuneralPaybacks.find(
                        (payback: any) => payback.payback_apply
                      )?.ds_amount || 0
                    }
                  />
                </td>
                <td>
                  <NumberFormat
                    number={
                      funeral.FuneralPaybacks.find(
                        (payback: any) => payback.payback_apply
                      )?.cnemo_amount || 0
                    }
                  />
                </td>

                <td>
                  <button
                    className={styles.paybackButton}
                    onClick={() => {
                      setTargetFuneral(funeral);
                      setIsPaybackModalOpen(true);
                    }}
                  >
                    설정하기
                  </button>
                </td>

                <td>
                  <button
                    className={styles.productButton}
                    onClick={() => {
                      setTargetFuneral(funeral);
                      setIsProductModalOpen(true);
                    }}
                  >
                    상품설정
                  </button>
                </td>

                {isEdit && targetFuneral?.FUNERAL_NO === funeral?.FUNERAL_NO ? (
                  <td className={styles.countInputs}>
                    <input
                      type="number"
                      name="BANNER_COUNT"
                      id=""
                      value={bannerCount}
                      onChange={(e) => {
                        setBannerCount(parseInt(e.target.value));
                      }}
                    />
                    /
                    <input
                      type="number"
                      name="PAMPHLET_COUNT"
                      id=""
                      value={pamphletCount}
                      onChange={(e) => {
                        setPamphletCount(parseInt(e.target.value));
                      }}
                    />
                    <button
                      className={styles.editButton}
                      onClick={() => handleEditCount(funeral)}
                    >
                      완료
                    </button>
                  </td>
                ) : (
                  <td className={styles.countInputs}>
                    <span>{funeral.BANNER_COUNT}</span>/
                    <span>{funeral.PAMPHLET_COUNT}</span>
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        setIsEdit(true);
                        setTargetFuneral(funeral);
                        setBannerCount(funeral.BANNER_COUNT);
                        setPamphletCount(funeral.PAMPHLET_COUNT);
                      }}
                    >
                      수정
                    </button>
                  </td>
                )}

                <td className={styles.cancelButton}>
                  <button onClick={() => handleDeleteFuneral(funeral)}>
                    <span>삭제</span>
                  </button>
                </td>
              </tr>
            ))
          )}
        </TableBody>
      </Table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="다음 >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< 이전"
        renderOnZeroPageCount={null}
        previousClassName={pageStyles.pageItem}
        nextClassName={pageStyles.pageItem}
        breakClassName={pageStyles.break}
        containerClassName={pageStyles.pagination}
        pageClassName={pageStyles.pageItem}
        activeClassName={pageStyles.active}
        disabledClassName={pageStyles.disabled}
      />
    </>
  );
};

export default AdminFuneralTable;

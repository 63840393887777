import React from "react";
import MainNoticeSection from "@components/Service/Ui/MainNoticeSection";

import mainImage from "@assets/images/service/service_main_1.webp";
import MakeInfoSection from "@components/Service/Ui/MakeInfoSection";
import AddInfoSection from "@components/Service/Ui/AddInfoSection";

import ServiceMainButton from "@components/Service/ServiceMainButton";
import { useNavigate } from "react-router-dom";

interface SerivceProps {}

const Serivce: React.FC<SerivceProps> = () => {
  const navigator = useNavigate();

  const handleRoutingUpload = async () => {
    navigator(`uploads`);
    // if (!obituary) {
    //   const res = await setFreeQueueWithoutObituary();
    //   const { id } = res;
    //   console.log(res, "res", id);
    //   navigator(`/uploads/free/${id}`, { state: { queue: res } });
    //   return;
    // }
  };
  return (
    <div id="service-page">
      <MainNoticeSection
        title="고인과 함께한"
        subTitle="순간을 영상으로 남겨보세요"
        desc="부고장에서 영상을 볼 수 있습니다"
      >
        <img
          src={mainImage}
          alt="메인 이미지"
          width="191px"
          className="service_main-image"
        />
        <button>
          <p className="body-title small">추모영상 샘플 보러 가기</p>
        </button>
      </MainNoticeSection>

      <main>
        <MakeInfoSection />
        <AddInfoSection />

        <p className="body-title x-small">
          * 옵션에 따라 별도의 비용이 발생할 수 있습니다
        </p>

        <ServiceMainButton
          title="추모영상 구매하기"
          handleClick={handleRoutingUpload}
        />
      </main>
    </div>
  );
};

export default Serivce;
